import { Injectable } from '@angular/core';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { AppVersionsActions } from '.';
import { AppVersionsService } from './app-versions.service';

@Injectable()
export class AppVersionsEffects {
  constructor(
    private actions$: Actions,
    private appVersionsService: AppVersionsService,
  ) {}

  loadCurrentAppVersions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppVersionsActions.AppVersionsActionTypes.LOAD_CURRENT_APP_VERSIONS),
      switchMap((action: any) =>
        from(this.appVersionsService.getAppVersionsForApp(action.appId)).pipe(
          map((data) => data.sort((a, b) => (a.versionNumber < b.versionNumber ? 1 : -1))),
          map((data) => AppVersionsActions.loadCurrentAppVersionsSuccess({ appVersions: data })),
          catchError((error) => observableOf(AppVersionsActions.loadCurrentAppVersionsFailure({ error }))),
        ),
      ),
    ),
  );
}
