import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { EventWorkflowProcess } from './event-process';

interface EventWorkflowProcessesState extends EntityState<EventWorkflowProcess> {}

export const DEFAULT_PAGE_SIZES = [25, 50, 100];
export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZES[0];

interface CachedEventWorkflowProcessesState extends EntityState<EventWorkflowProcess> {}

export interface State {
  eventWorkflowProcesses: EventWorkflowProcessesState;
  templateIdEventWorkflowProcesses: EventWorkflowProcessesState;
  currentEventWorkflowProcess: EventWorkflowProcess;
  nextPageKey: string;
  cachedProcesses: CachedEventWorkflowProcessesState;
  currentPage: number;
  currentPageSize: number;
  loading: boolean;
}
export const eventWorkflowProcessesEntityAdapter: EntityAdapter<EventWorkflowProcess> =
  createEntityAdapter<EventWorkflowProcess>({});

export const cachedEventWorkflowProcessesEntityAdapter: EntityAdapter<EventWorkflowProcess> =
  createEntityAdapter<EventWorkflowProcess>({});

export const eventWorkflowProcessesInitialState = {
  eventWorkflowProcesses: eventWorkflowProcessesEntityAdapter.getInitialState({}),
  templateIdEventWorkflowProcesses: eventWorkflowProcessesEntityAdapter.getInitialState({}),
  currentEventWorkflowProcess: null,
  nextPageKey: null,
  cachedProcesses: cachedEventWorkflowProcessesEntityAdapter.getInitialState({}),
  currentPage: DEFAULT_PAGE,
  currentPageSize: DEFAULT_PAGE_SIZE,
  loading: false,
};

export const { selectAll, selectEntities, selectIds, selectTotal } =
  cachedEventWorkflowProcessesEntityAdapter.getSelectors();
