import { createAction, props } from '@ngrx/store';
import { ScheduleJobChanges, ScheduleJobDefinition } from './schedule-job';

export enum ScheduleJobActionTypes {
  LOAD_SCHEDULE_JOB_ACTION = '[ScheduleJob] Load Schedule Job',
  LOAD_SCHEDULE_JOB_ACTION_SUCCESS = '[ScheduleJob] Load Schedule Job Success',
  LOAD_SCHEDULE_JOB_ACTION_FAILURE = '[ScheduleJob] Load Schedule Job Failure',

  CREATE_SCHEDULE_JOB_ACTION = '[ScheduleJob] Create Schedule Job',
  CREATE_SCHEDULE_JOB_ACTION_SUCCESS = '[ScheduleJob] Create Schedule Job Success',
  CREATE_SCHEDULE_JOB_ACTION_FAILURE = '[ScheduleJob] Create Schedule Job Failure',

  UPDATE_SCHEDULE_JOB_ACTION = '[ScheduleJob] Update Schedule Job',
  UPDATE_SCHEDULE_JOB_ACTION_SUCCESS = '[ScheduleJob] Update Schedule Job Success',
  UPDATE_SCHEDULE_JOB_ACTION_FAILURE = '[ScheduleJob] Update Schedule Job Failure',

  LOAD_CURRENT_SCHEDULE_JOB_ACTION = '[ScheduleJob] Load Current Schedule Job',
  LOAD_CURRENT_SCHEDULE_JOB_ACTION_SUCESS = '[ScheduleJob] Load Current Schedule Job Success',
  LOAD_CURRENT_SCHEDULE_JOB_ACTION_FAILURE = '[ScheduleJob] Load Current Schedule Job Failure',

  CLEAR_CURRENTSCHEDULE_JOB_ACTION = '[ScheduleJob] Clear Current Schedule Job',
}

export const createScheduleJobAction = createAction(
  ScheduleJobActionTypes.CREATE_SCHEDULE_JOB_ACTION,
  props<{
    name: string;
    workflowId: string;
    cron: string;
    isActive: boolean;
    event: any;
  }>(),
);

export const createScheduleJobActionSuccess = createAction(
  ScheduleJobActionTypes.CREATE_SCHEDULE_JOB_ACTION_SUCCESS,
  props<{ scheduleJobAction: ScheduleJobDefinition }>(),
);

export const createScheduleJobActionFailure = createAction(
  ScheduleJobActionTypes.CREATE_SCHEDULE_JOB_ACTION_FAILURE,
  props<{ error: any }>(),
);

export const updateScheduleJobAction = createAction(
  ScheduleJobActionTypes.UPDATE_SCHEDULE_JOB_ACTION,
  props<{
    id: string;
    changes: ScheduleJobChanges;
  }>(),
);

export const updateScheduleJobActionSuccess = createAction(
  ScheduleJobActionTypes.UPDATE_SCHEDULE_JOB_ACTION_SUCCESS,
  props<{ id: string; changes: ScheduleJobDefinition }>(),
);

export const updateScheduleJobActionFailure = createAction(
  ScheduleJobActionTypes.UPDATE_SCHEDULE_JOB_ACTION_FAILURE,
  props<{ error: any }>(),
);

export const loadScheduleJobAction = createAction(ScheduleJobActionTypes.LOAD_SCHEDULE_JOB_ACTION);

export const loadScheduleJobActionSuccess = createAction(
  ScheduleJobActionTypes.LOAD_SCHEDULE_JOB_ACTION_SUCCESS,
  props<{ data: Array<ScheduleJobDefinition> }>(),
);

export const loadScheduleJobActionFailure = createAction(
  ScheduleJobActionTypes.LOAD_SCHEDULE_JOB_ACTION_FAILURE,
  props<{ error: any }>(),
);

export const loadCurrentScheduleJobAction = createAction(
  ScheduleJobActionTypes.LOAD_CURRENT_SCHEDULE_JOB_ACTION,
  props<{ id: string }>(),
);

export const loadCurrentScheduleJobActionSuccess = createAction(
  ScheduleJobActionTypes.LOAD_CURRENT_SCHEDULE_JOB_ACTION_SUCESS,
  props<{ scheduleJobAction: ScheduleJobDefinition }>(),
);

export const loadCurrentScheduleJobActionFailure = createAction(
  ScheduleJobActionTypes.LOAD_CURRENT_SCHEDULE_JOB_ACTION_FAILURE,
  props<{ error: any }>(),
);

export const clearCurrentScheduleJobAction = createAction(ScheduleJobActionTypes.LOAD_CURRENT_SCHEDULE_JOB_ACTION);
