import { createAction, props } from '@ngrx/store';
import { AppOrg } from './org-app';

export enum OrgAppsActionTypes {
  LOAD_ORG_APPS = '[OrgApps] Load OrgApps',
  LOAD_ORG_APPS_SUCCESS = '[OrgApps] Load OrgApps Success',
  LOAD_ORG_APPS_FAILURE = '[OrgApps] Load OrgApps Failure',

  CREATE_ORG_APP = '[OrgApps] Create OrgApp',
  CREATE_ORG_APP_SUCCESS = '[OrgApps] Create OrgApp Success',
  CREATE_ORG_APP_FAILURE = '[OrgApps] Create OrgApp Failure',

  DELETE_ORG_APP = '[OrgApps] Delete OrgApp',
  DELETE_ORG_APP_SUCCESS = '[OrgApps] Delete OrgApp Success',
  DELETE_ORG_APP_FAILURE = '[OrgApps] Delete OrgApp Failure',

  UPDATE_ORG_APP = '[OrgApps] Update OrgApp',
  UPDATE_ORG_APP_SUCCESS = '[OrgApps] Update OrgApp Success',
  UPDATE_ORG_APP_FAILURE = '[OrgApps] Update OrgApp Failure',

  LOAD_CURRENT_ORG_APP = '[OrgApps] Load Current OrgApp',
  LOAD_CURRENT_ORG_APP_SUCCESS = '[OrgApps] Load Current OrgApp Success',
  LOAD_CURRENT_ORG_APP_FAILURE = '[OrgApps] Load Current OrgApp Failure',
}

/// /////////////////////////////////////////////////////////////////////////////
export const loadOrgApps = createAction(OrgAppsActionTypes.LOAD_ORG_APPS);

export const loadOrgAppsSuccess = createAction(OrgAppsActionTypes.LOAD_ORG_APPS_SUCCESS, props<{ data: Array<any> }>());

export const loadOrgAppsFailure = createAction(OrgAppsActionTypes.LOAD_ORG_APPS_FAILURE, props<{ error: any }>());

export const createOrgApp = createAction(OrgAppsActionTypes.CREATE_ORG_APP, props<{ appId: string }>());

export const createOrgAppSuccess = createAction(OrgAppsActionTypes.CREATE_ORG_APP_SUCCESS, props<{ orgApp: AppOrg }>());

export const createOrgAppFailure = createAction(OrgAppsActionTypes.CREATE_ORG_APP_FAILURE, props<{ error: any }>());

export const updateOrgApp = createAction(OrgAppsActionTypes.UPDATE_ORG_APP, props<{ id: string; changes: AppOrg }>());

export const updateOrgAppSuccess = createAction(
  OrgAppsActionTypes.UPDATE_ORG_APP_SUCCESS,
  props<{ id: string; changes: AppOrg }>(),
);

export const updateOrgAppFailure = createAction(OrgAppsActionTypes.UPDATE_ORG_APP_FAILURE, props<{ error: any }>());

export const deleteOrgApp = createAction(OrgAppsActionTypes.DELETE_ORG_APP, props<{ orgApp: AppOrg }>());

export const deleteOrgAppSuccess = createAction(OrgAppsActionTypes.DELETE_ORG_APP_SUCCESS, props<{ orgApp: AppOrg }>());

export const deleteOrgAppFailure = createAction(OrgAppsActionTypes.DELETE_ORG_APP_FAILURE, props<{ error: any }>());

export const loadCurrentOrgApp = createAction(OrgAppsActionTypes.LOAD_CURRENT_ORG_APP, props<{ id: string }>());

export const loadCurrentOrgAppSuccess = createAction(
  OrgAppsActionTypes.LOAD_CURRENT_ORG_APP_SUCCESS,
  props<{ orgApp: AppOrg }>(),
);

export const loadCurrentOrgAppFailure = createAction(
  OrgAppsActionTypes.LOAD_CURRENT_ORG_APP_FAILURE,
  props<{ error: any }>(),
);
