import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { User } from 'src/app/users/users/user-org';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'userName',
})
export class UserNamePipe implements PipeTransform {
  transform(user: User, ..._args: unknown[]): string {
    if (user?.firstName || user?.lastName) {
      return user?.firstName + ' ' + user?.lastName;
    } else if (user?.email) {
      return user?.email.split('@')[0];
    } else {
      return 'N/A';
    }
  }
}
