import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor() {}

  public getUserOrgs() {
    return new Entities().get({ entityName: 'user-org', relations: ['groupPrincipals', 'group', 'principal'] });
  }
}
