import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FilterPropertyDefinition } from 'src/app/common/types/filters/filter-definition';

@Component({
  selector: 'app-filter-entity',
  templateUrl: './filter-entity.component.html',
  styleUrls: ['./filter-entity.component.scss'],
})
export class FilterEntityComponent implements OnChanges, AfterContentChecked {
  @Input() filter: FilterPropertyDefinition;
  @Input() clearAll = false;
  @Output() filterValueChanged = new EventEmitter();
  @ViewChildren('filterSelectionBoxes') private checkboxesMultiple: QueryList<any>;

  constructor() {}
  ngAfterContentChecked(): void {
    this.applyInputFilters();
  }
  ngOnChanges(): void {
    if (this.clearAll) {
      this.clearAllFilters();
    }
  }

  applyFilter(filterValue) {
    const values = this.filter.values || [];
    if (values.includes(filterValue)) {
      const index = values.indexOf(filterValue);
      values.splice(index, 1);
    } else {
      values.push(filterValue);
    }
    this.filter.values = values;
    this.filterValueChanged.emit(this.filter);
  }

  clearAllFilters() {
    if (this.checkboxesMultiple) {
      const checkboxesArray = this.checkboxesMultiple.toArray();
      for (const key of Object.getOwnPropertyNames(checkboxesArray)) {
        if (key === 'length') {
          continue;
        }
        checkboxesArray[key].checked = false;
      }
    }
  }
  applyInputFilters() {
    if (!this.checkboxesMultiple || !this.filter.values) {
      return;
    }
    const checkboxesArray = this.checkboxesMultiple.toArray();
    for (const key of Object.getOwnPropertyNames(checkboxesArray)) {
      if (key === 'length') {
        continue;
      }
      if (checkboxesArray[key] && this.filter.values.includes(checkboxesArray[key].value)) {
        checkboxesArray[key].checked = true;
      }
    }
  }
}
