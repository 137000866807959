import { Component } from '@angular/core';

@Component({
  selector: 'app-page-section-body',
  templateUrl: './page-section-body.component.html',
  styleUrls: ['./page-section-body.component.scss'],
})
export class PageSectionBodyComponent {
  constructor() {}
}
