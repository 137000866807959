import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MonacoEditorComponent } from '@materia-ui/ngx-monaco-editor';
import { CodeViewModalService } from './code-view-modal.service';

@Component({
  selector: 'app-code-view-modal',
  templateUrl: './code-view-modal.component.html',
  styleUrls: ['./code-view-modal.component.scss'],
})
export class CodeViewModalComponent implements AfterViewInit {
  loading: boolean = false;
  code: string;
  editorOptions: any = {};
  title: string;
  @ViewChild('editor', { static: true }) editor!: MonacoEditorComponent;

  constructor(
    private dialogDataService: CodeViewModalService,
    public dialogRef: MatDialogRef<CodeViewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.code = data.code;
    this.title = data.title;
    this.loading = data.loading;
  }

  ngAfterViewInit(): void {
    this.editorOptions = {
      ...this.editorOptions,
      readOnly: true,
      theme: this.data.theme,
      language: this.data.language,
      minimap: {
        enabled: false,
      },
      wordWrap: this.data.wordWrap,
    };

    this.dialogDataService.getData().subscribe((data) => {
      if (data) {
        this.code = data.code;
        this.title = data.title;
        this.loading = data.loading;
      }
    });
  }
}
