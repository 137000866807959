import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { LoaderHistory } from './loader-history';
import { LoaderHistoryDetail } from './loader-history-detail';
import { PreprocessingStepDetail } from '../../loader-interface';

interface LoaderHistoryState extends EntityState<LoaderHistory> {}
export interface State {
  loaderHistory: LoaderHistoryState;
  hasError: boolean;
  loading: boolean;
  currentLoaderHistory: LoaderHistory;
  currentLoaderHistoryDetail: LoaderHistoryDetail;
  fileData: any;
  logs: null;
  startDate: string;
  endDate: string;
  nextPageKey: string;
  preprocessingStep: PreprocessingStepDetail;
  processingStepLoading: boolean;
}
export const loaderHistoryEntityAdapter: EntityAdapter<LoaderHistory> = createEntityAdapter<LoaderHistory>({});

export const loaderHistoryInitialState = {
  loading: false,
  hasError: false,
  loaderHistory: loaderHistoryEntityAdapter.getInitialState({}),
  currentLoaderHistory: null,
  currentLoaderHistoryDetail: null,
  fileData: null,
  logs: null,
  startDate: null,
  endDate: null,
  nextPageKey: null,
  preprocessingStep: null,
  processingStepLoading: false,
};
