import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { chartReducer } from './event-dashboard.reducers';
import { ChartEffects } from './event-dashboard.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('eventWorkflowDashboard', chartReducer),
    EffectsModule.forFeature([ChartEffects]),
  ],
})
export class EventWorkflowDashboardStoreModule {}
