import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { TypeRoot } from '@contrail/types';

@Injectable({
  providedIn: 'root',
})
export class TypeRootsService {
  public async getTypeRoots(): Promise<Array<TypeRoot>> {
    return new Entities().get({ entityName: 'type-root' });
  }
}
