import { Type } from '@contrail/types';
import { createAction, props } from '@ngrx/store';

export enum TypesActionTypes {
  LOAD_TYPES = '[Type Manager - Types] Load Type Types',
  LOAD_TYPES_SUCCESS = '[Type Manager - Types] Load Type Types Success',
  LOAD_TYPES_FAILURE = '[Type Manager - Types] Load Type Types Failure',
  CLEAR_TYPES = '[Type Manager - Types] Clear Types',
  CREATE_SUB_TYPE = '[Type Manager - Types] Create Sub Type',
  CREATE_SUB_TYPE_SUCCESS = '[Type Manager - Types] Create Sub Type Success',
  CREATE_SUB_TYPE_FAILURE = '[Type Manager - Types] Create Sub Type Failure',
}

/// /////////////////////////////////////////////////////////////////////////////
export const loadTypes = createAction(TypesActionTypes.LOAD_TYPES);

export const loadTypesSuccess = createAction(TypesActionTypes.LOAD_TYPES_SUCCESS, props<{ data: Array<any> }>());

export const loadTypesFailure = createAction(TypesActionTypes.LOAD_TYPES_FAILURE, props<{ error: any }>());

export const createSubType = createAction(TypesActionTypes.CREATE_SUB_TYPE, props<{ entity: Type }>());

export const createSubTypeSuccess = createAction(TypesActionTypes.CREATE_SUB_TYPE_SUCCESS, props<{ entity: Type }>());

export const createSubTypeFailure = createAction(TypesActionTypes.CREATE_SUB_TYPE_FAILURE, props<{ error: any }>());

export const clearTypes = createAction(TypesActionTypes.CLEAR_TYPES);
