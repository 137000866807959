<div class="additional-fields-container">
  <form [formGroup]="form">
    <ng-container formGroupName="fields">
      <ng-container *ngIf="form?.controls?.fields.controls?.length">
        <ng-container *ngFor="let field of form['controls'].fields['controls']; let i = index">
          <div class="field-container flex" [formGroupName]="i">
            <div class="field-inputs">
              <mat-form-field class="field" data-test="dynamic-input-container">
                <mat-label>
                  {{ field?.value?.placeholder }}
                </mat-label>
                <ng-container [ngSwitch]="field?.value?.type">
                  <input
                    class="dynamic-fields"
                    *ngSwitchCase="'text'"
                    matInput
                    formControlName="value"
                    [attr.data-test]="'dynamic-input-' + field?.value?.key + '-' + i"
                  />
                  <mat-select
                    class="dynamic-fields"
                    *ngSwitchCase="'single_select'"
                    formControlName="value"
                    [attr.data-test]="'dynamic-select-' + field?.value?.key + '-' + i"
                  >
                    <mat-option>None</mat-option>
                    <mat-option
                      *ngFor="let option of field?.value?.options"
                      [value]="option.value"
                      [disabled]="uniqueValues[option.value]"
                      data-test="dynamic-select-option"
                    >
                      {{ option?.label }}
                    </mat-option>
                  </mat-select>
                </ng-container>
              </mat-form-field>
            </div>

            <div *ngIf="canDelete && field.value.key === 'value'">
              &nbsp;
              <button
                mat-icon-button
                class="remove-field cursor-pointer"
                data-test="btn-remove-field"
                (click)="handleDelete(field.value.id)"
              >
                <mat-icon>delete_forever</mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <div class="button-container mb-3">
      <button
        mat-stroked-button
        color="primary"
        class="btn add-field mt-3"
        data-test="btn-add-field"
        (click)="addField()"
      >
        <mat-icon>add</mat-icon>{{ addBtnLabel }}
      </button>
    </div>
  </form>
</div>
