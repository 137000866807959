import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Entities } from '@contrail/sdk';
import { RoutingService } from 'src/app/common/routing/routing.service';

@Component({
  selector: 'app-entities-search',
  templateUrl: './entities-search.component.html',
  styleUrls: ['./entities-search.component.scss'],
})
export class EntitiesSearchComponent {
  public entityType: string;
  public criteria: string;
  public relations: Array<string>;
  public results: Array<any>;
  public resultsString;
  public error;
  constructor(
    private routingService: RoutingService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.entityType = params.entityType;
      this.get();
    });
  }

  async get() {
    const config: any = {};
    config.entityName = this.entityType;
    config.relations = this.relations || ['item'];
    if (this.criteria) {
      config.criteria = JSON.parse(this.criteria);
    }

    try {
      this.results = await new Entities().get(config);
      this.resultsString = JSON.stringify(this.results, null, 4);
    } catch (error) {
      this.error = error;
    }
  }

  format(json) {
    return JSON.stringify(json, null, 4);
  }
  go(obj) {
    this.routingService.go(`/entities/details/${this.entityType}/${obj.id}`);
  }
}
