import { createSelector } from '@ngrx/store';
import { systemNotificationEntityAdapter } from './system-notification.state';
import { SystemNotification } from './system-notification';

export const featureKey = 'systemNotifications';

export const { selectAll, selectEntities, selectIds, selectTotal } = systemNotificationEntityAdapter.getSelectors();

const systemNotificationEntities = (state: any) => state[featureKey].systemNotifications.entities;

export const systemNotifications = createSelector(
  systemNotificationEntities,
  (state) => Object.values(state) as Array<SystemNotification>,
);

export const currentEventWorkflowTemplate = (state: any) => state[featureKey].currentEventWorkflowTemplate;

export const getLoadingState = (state: any) => state[featureKey].loading;
