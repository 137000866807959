import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { EventWorkflowTask } from './event-task';

interface EventWorkflowTasksState extends EntityState<EventWorkflowTask> {}

export interface State {
  processIdEventWorkflowTasks: EventWorkflowTasksState;
  processActionIdEventWorkflowTasks: EventWorkflowTasksState;
  currentEventWorkflowTask: EventWorkflowTask;
}
export const eventWorkflowTasksEntityAdapter: EntityAdapter<EventWorkflowTask> = createEntityAdapter<EventWorkflowTask>(
  {},
);

export const eventWorkflowTasksInitialState = {
  processIdEventWorkflowTasks: eventWorkflowTasksEntityAdapter.getInitialState({}),
  processActionIdEventWorkflowTasks: eventWorkflowTasksEntityAdapter.getInitialState({}),
  currentEventWorkflowTask: null,
};
