<app-page-section *ngIf="isFormulaProperty">
  <app-page-section-header>
    <app-page-section-title title="Formula"></app-page-section-title>
  </app-page-section-header>
  <app-page-section-body>
    <div class="code">{{ typeProperty.formula }}</div>

    <ngx-monaco-editor
      class="border p-4 block h-64 w-full mb-2"
      [formControl]="formulaFunctionControl"
      [options]="editorOptions"
      #editor
    >
    </ngx-monaco-editor>
    <div class="text-rose-600 mb-2" *ngIf="!formulaFunctionControl?.valid">Function includeds errors.</div>
    <button mat-raised-button [disabled]="!formulaFunctionControl?.valid" (click)="saveFormulaFunction()">Save</button>

    <!-- <mat-form-field style="width:100%" appearance="outline">
      <mat-label>Formula Function</mat-label>
      <textarea matInput [formControl]="formulaFunctionControl" (blur)="updateFormulaFunction()"></textarea>
      <mat-hint>Javascript function for computing a value.</mat-hint>
    </mat-form-field> -->
  </app-page-section-body>
</app-page-section>
