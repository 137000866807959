import { createSelector } from '@ngrx/store';
import { EventWorkflowProcess } from './event-process';
import { cachedEventWorkflowProcessesEntityAdapter } from './event-processes.state';

export const { selectAll, selectEntities, selectIds, selectTotal } =
  cachedEventWorkflowProcessesEntityAdapter.getSelectors();

const eventWorkflowProcessEntities = (state: any) => state.eventWorkflowProcesses.eventWorkflowProcesses.entities;

const templateIdEventWorkflowProcessEntities = (state: any) =>
  state.eventWorkflowProcesses.templateIdEventWorkflowProcesses.entities;

export const eventWorkflowProcesses = createSelector(
  eventWorkflowProcessEntities,
  (state) => Object.values(state) as Array<EventWorkflowProcess>,
);
export const templateIdEventWorkflowProcesses = createSelector(
  templateIdEventWorkflowProcessEntities,
  (state) => Object.values(state) as Array<EventWorkflowProcess>,
);
export const currentEventWorkflowProcess = (state: any) => state.eventWorkflowProcesses.currentEventWorkflowProcess;

export const isLoading = (state: any) => state.eventWorkflowProcesses.loading;

export const getNextPageKey = (state: any) => state.eventWorkflowProcesses.nextPageKey;

export const getCurrentPageNumber = (state: any) => state.eventWorkflowProcesses.currentPage;

export const getCurrentPageSize = (state: any) => state.eventWorkflowProcesses.currentPageSize;

export const getTotalCachedRecords = (state: any) => state.eventWorkflowProcesses.cachedProcesses.ids.length;
