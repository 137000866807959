<div *ngFor="let propertyConfiguration of propertyConfigurations trackBy:propertyTrackByFn">
  <app-type-property-form-field
    *ngIf="values && propertyConfiguration"
    [propertyFormConfiguration]="propertyConfiguration"
    [appearance]="formFieldAppearance"
    [entity]="values"
    [value]="values[propertyConfiguration.typeProperty.slug]"
    [errorMessage]="errors[propertyConfiguration.typeProperty.slug]"
  >
  </app-type-property-form-field>
</div>
