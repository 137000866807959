import { Action, createReducer, on } from '@ngrx/store';
import { State, systemNotificationEntityAdapter, systemNotificationInitialState } from './system-notification.state';

import * as SystemNotificationActions from './system-notification.actions';

const setData = (state: State, { data }) => ({
  ...state,
  loading: false,
  hasError: false,
  systemNotifications: systemNotificationEntityAdapter.setAll(data, state.systemNotifications),
});

const setCurrent = (state: State, { systemNotification }) => ({
  ...state,
  loading: false,
  hasError: false,
  currentSystemNotification: systemNotification,
});

const startLoading = (state: State) => ({
  ...state,
  loading: true,
  hasError: false,
});

const clearData = (state: State) => ({
  ...state,
  loading: false,
  hasError: false,
  currentSystemNotification: null,
});

const setError = (state: State) => ({
  ...state,
  loading: false,
  hasError: true,
});

export const systemNotificationReducers = createReducer(
  systemNotificationInitialState,
  on(SystemNotificationActions.loadSystemNotificationSuccess, setData),
  on(SystemNotificationActions.loadCurrentSystemNotificationSuccess, setCurrent),
  on(SystemNotificationActions.loadCurrentSystemNotification, startLoading),
  on(SystemNotificationActions.loadSystemNotification, startLoading),
  on(SystemNotificationActions.clearCurrentSystemNotification, clearData),
  on(SystemNotificationActions.loadCurrentSystemNotificationFailure, setError),
  on(SystemNotificationActions.loadSystemNotificationFailure, setError),
);

/**
 *
 * @param state
 * @param action
 */
export function reducer(state: State | undefined, action: Action): any {
  return systemNotificationReducers(state, action);
}
