import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TypeRoot } from '@contrail/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerActions, TypeManagerSelectors } from '../type-manager-store';
import { TypesService } from '../types.service';

@Injectable({
  providedIn: 'root',
})
export class TypeRootResolver implements Resolve<TypeRoot> {
  constructor(
    private typeService: TypesService,
    private store: Store<RootStoreState.State>,
  ) {}
  resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<TypeRoot> {
    const rootSlug = route.params.rootSlug;
    this.setCurrentRoot(rootSlug);
    return this.store.select(TypeManagerSelectors.currentTypeRoot).pipe(
      filter((root) => root && root.slug === rootSlug),
      take(1),
    );
  }
  setCurrentRoot(slug) {
    this.store
      .select(TypeManagerSelectors.typeRoots)
      .pipe(
        filter((roots) => roots && roots.length > 0),
        map((roots) => roots.find((root) => root.slug === slug)),
        tap((root) => {
          this.store.dispatch(TypeManagerActions.setCurrentTypeRoot({ typeRoot: root }));
        }),
        take(1),
      )
      .subscribe();
  }
}
