import { BehaviorSubject, Observable } from 'rxjs';

export class CodeViewModalService {
  private dataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  setData(data: any): void {
    this.dataSubject.next(data);
  }

  getData(): Observable<any> {
    return this.dataSubject.asObservable();
  }
}
