import { Action, createReducer, on } from '@ngrx/store';
import { State, orgConfigInitialState } from './org-config.state';
import * as OrgConfigActions from './org-config.actions';

const setData = (state: State, { data }) => ({
  ...state,
  orgConfig: data,
});

const update = (state: State, { changes }) => ({
  ...state,
  orgConfig: { ...state.orgConfig, ...changes },
});

export const orgConfigReducers = createReducer(
  orgConfigInitialState,
  on(OrgConfigActions.loadOrgConfigSuccess, setData),
  on(OrgConfigActions.updateOrgConfigSuccess, update),
);

/**
 *
 * @param state
 * @param action
 */
export function reducer(state: State | undefined, action: Action): any {
  return orgConfigReducers(state, action);
}
