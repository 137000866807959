<div class="root-selector">
  <span class="selector-label">Entity:</span>
  <div [matMenuTriggerFor]="menu" class="selector">
    {{ (selectedTypeRoot$ | async)?.label }}
    <mat-icon>expand_more</mat-icon>
  </div>
</div>
<mat-menu #menu="matMenu">
  <div style="height: 300px; overflow-y: scroll" *ngIf="typeRoots?.length || prioritisedTypeRoots?.length">
    <button (click)="goToRoot(typeRoot)" mat-menu-item *ngFor="let typeRoot of prioritisedTypeRoots">
      {{ typeRoot.label }}
    </button>
    <hr />
    <button (click)="goToRoot(typeRoot)" mat-menu-item *ngFor="let typeRoot of typeRoots">
      {{ typeRoot.label }}
    </button>
  </div>
</mat-menu>
