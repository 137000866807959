import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-sub-type',
  templateUrl: './create-sub-type.component.html',
  styleUrls: ['./create-sub-type.component.scss'],
})
export class CreateSubTypeComponent implements OnInit, AfterViewInit {
  @ViewChild('slugField') labelElement: ElementRef;
  @Output() cancelEdit = new EventEmitter();
  @Output() done = new EventEmitter();
  public labelInput: UntypedFormControl;
  public slugInput: UntypedFormControl;

  constructor() {}
  ngOnInit(): void {
    this.labelInput = new UntypedFormControl(null, Validators.required);
    this.slugInput = new UntypedFormControl(null, Validators.required);
  }

  ngAfterViewInit() {
    this.labelElement?.nativeElement.focus();
  }

  setLabel() {
    const slug = this.slugInput.value;
    let labelForSlug = slug.replace(/([A-Z])/g, ' $1');
    labelForSlug = labelForSlug.charAt(0).toUpperCase() + labelForSlug.slice(1);
    this.labelInput.setValue(labelForSlug);
  }
  async addSubType() {
    const newType: any = {
      label: this.labelInput.value,
      slug: this.slugInput.value,
    };
    this.done.emit(newType);
  }
  cancel() {
    this.cancelEdit.emit(null);
  }

  isValid() {
    return !(this.labelInput.invalid || this.slugInput.invalid);
  }
}
