<div class="pagination flex">
  <mat-label class="page-size-label">Items Per Page:</mat-label>
  <mat-form-field class="page-size-select" data-test="page-size-select-form-field">
    <mat-select (selectionChange)="onPageSizeChange($event)" [ngModel]="pageSize" data-test="page-size-select">
      <mat-option *ngFor="let size of pageSizes" [value]="size" data-test="page-size-select-options">
        {{ size }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="navigation-button">
    <button
      mat-icon-button
      [disabled]="!hasPreviousPage"
      (click)="previousPage()"
      matTooltip="Previous Page"
      data-test="previous-page-button"
    >
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <span class="page-number" matTooltip="Current Page" data-test="current-page">{{ currentPage }}</span>
    <button
      mat-icon-button
      [disabled]="!hasNextPage"
      (click)="nextPage()"
      [matTooltip]="getNextPageTooltip()"
      data-test="next-page-button"
    >
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>
</div>
