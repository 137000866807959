import { Component, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import { TypeRuleSet } from '../../type-manager-store/type-rule-sets/type-rule-sets.state';

@Component({
  selector: 'app-type-rule-set-list',
  templateUrl: './type-rule-set-list.component.html',
  styleUrls: ['./type-rule-set-list.component.scss'],
})
export class TypeRuleSetListComponent implements OnDestroy {
  @Input() ruleSets: Array<TypeRuleSet>;
  @Input() selectable: boolean;
  @Input() showActions = true;
  @Output() clicked: EventEmitter<TypeRuleSet> = new EventEmitter();
  @Output() deleted: EventEmitter<TypeRuleSet> = new EventEmitter();
  @Output() edit: EventEmitter<TypeRuleSet> = new EventEmitter();
  constructor() {}

  ngOnDestroy() {
    this.unsubscribe();
  }
  subscribe() {}
  unsubscribe() {}

  handleClick(ruleSet: TypeRuleSet) {
    this.clicked.emit(ruleSet);
  }
  handleDelete($event: MouseEvent, ruleSet: TypeRuleSet) {
    $event.stopPropagation();
    this.deleted.emit(ruleSet);
  }
  handleEdit($event: MouseEvent, ruleSet: TypeRuleSet) {
    $event.stopPropagation();
    this.edit.emit(ruleSet);
  }
}
