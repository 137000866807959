import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {
  public async getWorkspaces() {
    return await new Entities().get({
      entityName: 'workspace',
      relations: ['workspacePrincipals', 'updatedBy', 'createdBy'],
    });
  }
}
