import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appHoverActive]',
})
export class HoverActiveDirective {
  constructor() {}

  @HostBinding('class.active') active!: boolean;
  @HostListener('mouseover') activated() {
    this.active = true;
  }

  @HostListener('mouseleave') deactivated() {
    this.active = false;
  }
}
