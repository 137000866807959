<app-page-section *ngIf="enabled">
  <app-page-section-header>
    <app-page-section-title title="Carryover Behavior"></app-page-section-title>
  </app-page-section-header>
  <app-page-section-body>
    <div class="mb-3">
      <mat-form-field>
        <mat-label>Choose Carryover Behavior</mat-label>
        <mat-select [formControl]="formControl">
          <ng-container *ngFor="let option of carryoverOptions">
            <mat-option
              [value]="option.value"
              *ngIf="!(option.value === 'default' && carryoverOnlyTypes.includes(typeProperty.propertyType))"
            >
              {{ option.displayValue }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      class="mb-3"
      *ngIf="formControl.value === 'default' && !carryoverOnlyTypes.includes(typeProperty.propertyType)"
    >
      <app-type-property-form-field
        [propertyFormConfiguration]="formConfiguration"
        [value]="
          typeProperty.carryOverDefault === 'true'
            ? true
            : typeProperty.carryOverDefault === 'false'
              ? false
              : typeProperty.carryOverDefault
        "
      >
      </app-type-property-form-field>
    </div>
  </app-page-section-body>
</app-page-section>
