import { Injectable } from '@angular/core';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { EventWorkflowTasksActions } from '.';
import { EventWorkflowTasksService } from './event-tasks.service';

@Injectable()
export class EventWorkflowTasksEffects {
  constructor(
    private actions$: Actions,
    private eventWorkflowTasksService: EventWorkflowTasksService,
  ) {}

  loadCurrentEventWorkflowTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventWorkflowTasksActions.EventWorkflowTasksActionTypes.LOAD_CURRENT_EVENT_WORKFLOW_TASK),
      switchMap((action: any) =>
        from(this.eventWorkflowTasksService.getEventWorkflowTasksById(action.id)).pipe(
          map((data) =>
            EventWorkflowTasksActions.loadCurrentEventWorkflowTaskSuccess({
              eventWorkflowTask: data,
            }),
          ),
          catchError((error) =>
            observableOf(
              EventWorkflowTasksActions.loadCurrentEventWorkflowTaskFailure({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );
  loadEventWorkflowTasksByProcessId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventWorkflowTasksActions.EventWorkflowTasksActionTypes.LOAD_PROCESSID_EVENTWORKFLOWTASKS),
      switchMap((action: any) =>
        from(this.eventWorkflowTasksService.getEventWorkflowTasksByProcessId(action.processId)).pipe(
          map((data) =>
            EventWorkflowTasksActions.loadProcessIdEventWorkflowTasksSuccess({
              data,
            }),
          ),
          catchError((error) =>
            observableOf(
              EventWorkflowTasksActions.loadProcessIdEventWorkflowTasksFailure({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  loadEventWorkflowTasksByProcessActionId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventWorkflowTasksActions.EventWorkflowTasksActionTypes.LOAD_PROCESS_ACTION_ID_EVENTWORKFLOWTASKS),
      switchMap((action: any) =>
        from(
          this.eventWorkflowTasksService.getEventWorkflowTasksByProcessActionId(action.processId, action.actionId),
        ).pipe(
          map((data) => EventWorkflowTasksActions.loadProcessActionIdEventWorkflowTasksSuccess({ data })),
          catchError((error) =>
            observableOf(EventWorkflowTasksActions.loadProcessActionIdEventWorkflowTasksFailure({ error })),
          ),
        ),
      ),
    ),
  );

  loadCurrentEventWorkflowTasksAndActionDefinition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        EventWorkflowTasksActions.EventWorkflowTasksActionTypes.LOAD_PROCESSID_EVENTWORKFLOWTASK_AND_ACTION_DEFINITION,
      ),
      switchMap((action: any) =>
        from(this.eventWorkflowTasksService.getEventWorkflowTaskAndActionDefinitionById(action.id)).pipe(
          map((data) => EventWorkflowTasksActions.loadProcessIdEventWorkflowTaskAndActionDefinitionSuccess({ data })),
          catchError((error) =>
            observableOf(EventWorkflowTasksActions.loadProcessIdEventWorkflowTaskAndActionDefinitionFailure({ error })),
          ),
        ),
      ),
    ),
  );
}
