import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TypeProperty } from '@contrail/types';

@Component({
  selector: 'app-type-property-label',
  templateUrl: './type-property-label.component.html',
  styleUrls: ['./type-property-label.component.scss'],
})
export class TypePropertyLabelComponent implements OnInit, OnChanges {
  @Input() typeProperty: TypeProperty;
  @Output() changes = new EventEmitter();
  public labelFormField: UntypedFormControl;
  constructor() {}
  ngOnInit() {
    if (this.typeProperty) {
      this.labelFormField.setValue(this.typeProperty.label);
    }
  }
  ngOnChanges() {
    this.labelFormField = new UntypedFormControl({ value: this.typeProperty.label, disabled: false });
  }

  updateValue() {
    const newValue = this.labelFormField.value;
    if (!newValue || newValue === this.typeProperty.label) {
      return;
    }
    const valuChanges = {
      label: newValue,
    };
    this.changes.emit(valuChanges);
  }
}
