import { TypeProperty } from '@contrail/types';
import { createSelector } from '@ngrx/store';
import { typePropertiesEntityAdapter } from './type-properties.state';

export const { selectAll, selectEntities, selectIds, selectTotal } = typePropertiesEntityAdapter.getSelectors();

const typePropertyEntities = (state: any) => state.typeManager.typeProperties.entities;
export const typeProperties = createSelector(
  typePropertyEntities,
  (state) => Object.values(state) as Array<TypeProperty>,
);
