<app-page-section>
  <app-page-section-header>
    <app-page-section-title [title]="'Properties'"></app-page-section-title>
    <app-page-section-actions>
      <app-add-property-to-type></app-add-property-to-type>
    </app-page-section-actions>
  </app-page-section-header>
  <app-type-properties-list
    [properties]="typeProperties$ | async"
    (propertyDelete)="removeProperty($event)"
    (propertyClicked)="showPropertyDetails($event)"
    (propertyToggled)="toggleArchivedProperty($event)"
  >
  </app-type-properties-list>
</app-page-section>
