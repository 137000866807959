import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AppOrg } from './org-app';

interface OrgAppsState extends EntityState<AppOrg> {}

export interface State {
  orgApps: OrgAppsState;
  currentOrgApp: AppOrg;
}
export const orgAppsEntityAdapter: EntityAdapter<AppOrg> = createEntityAdapter<AppOrg>({});

export const orgAppsInitialState = {
  orgApps: orgAppsEntityAdapter.getInitialState({}),
  currentOrgApp: null,
};
