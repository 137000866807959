import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(arr: string[], filterargs: any) {
    if (filterargs.key !== null && filterargs.key !== 'undefined' && filterargs.key.length > 0) {
      return arr.filter((item) => item[filterargs.key] === filterargs.value);
    }
    return arr;
  }
}
