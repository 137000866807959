<div>
  <div class="header">Create {{ type?.label }}</div>
  <div class="body">
    <app-type-property-form [propertyConfigurations]="type?.typeProperties" [values]="{}"></app-type-property-form>
  </div>
  <div class="footer">
    <button mat-raised-button (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" (click)="create()">Create</button>
  </div>
</div>
