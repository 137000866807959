import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';

@Injectable({
  providedIn: 'root',
})
export class EventWorkflowTasksService {
  constructor() {}

  public async getEventWorkflowTasksById(id: string) {
    return new Entities().get({ entityName: 'event-workflow-task', id });
  }
  public async getEventWorkflowTasksByProcessId(processId: string) {
    return new Entities().get({ entityName: 'event-workflow-task', criteria: { eventWorkflowProcessId: processId } });
  }
  public async getEventWorkflowTasksByProcessActionId(processId: string, actionId: string) {
    return new Entities().get({
      entityName: 'event-workflow-task',
      criteria: { eventWorkflowProcessId: processId, eventWorkflowActionDefinitionId: actionId },
    });
  }
  public async getEventWorkflowTaskAndActionDefinitionById(id: string) {
    return new Entities().get({
      entityName: 'event-workflow-task',
      criteria: { eventWorkflowProcessId: id },
      relations: ['eventWorkflowActionDefinition'],
    });
  }
}
