<div>
  <div class="flex gap-2 items-center justify-center">
    <div class="my-1">
      <mat-form-field class="date-range-time-picker-form">
        <mat-date-range-input [formGroup]="dateForm" [rangePicker]="picker" [min]="minDate" [max]="maxDate">
          <input matStartDate placeholder="Start date" formControlName="start" readonly />
          <input matEndDate placeholder="End date" formControlName="end" readonly />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix (click)="openDatePicker()" class="event-date-picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <div class="pb-2">({{ startDate | TimezonePipe }})</div>
  </div>
</div>
