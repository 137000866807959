import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

export interface CurrentPageInfo {
  pageSize: number;
  pageIndex: number;
  fromIndex: number;
  toIndex: number;
  numberOfPages: number;
  pageSizeOptions: number[];
  pageEvent: PageEvent;
}

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, AfterViewInit, OnChanges {
  constructor() {}

  @Input() data = [];
  @Input() inputPageSize = 25; // optional
  @Input() showFirstLastButtons = true; // optional

  @Input() defaultSize = 25; // optional
  // needed if mulitple paging elements used for same data set in the app
  @Input() pageEvent: PageEvent; // optional

  @Input() hidePageSize = false; // optional

  @Output() currentPageInfo = new EventEmitter<CurrentPageInfo>();

  currentPageSize;
  pageSizeOptions: number[] = [];
  fromIndex = 0;
  toIndex = 0;
  pageIndex = 0;
  numberOfPages = 0;

  @ViewChild('ItemPagination') paginator: MatPaginator;
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputPageSize || changes.data) {
      this.setPageOptions(); // reset the page options and  the paginator
    }
    if (changes.pageEvent) {
      this.handlePageChangeEvent(this.pageEvent, true);
    }
  }
  setPageOptions() {
    if (this.data?.length) {
      if (this.inputPageSize < this.defaultSize && this.inputPageSize < this.data.length) {
        this.inputPageSize = this.defaultSize;
      }

      this.pageIndex = 0;

      if (this.data.length < this.inputPageSize) {
        this.currentPageSize = this.data.length;
      } else {
        this.currentPageSize = this.inputPageSize;
      }

      this.populatePageSizeOptions();
      this.calculateNumberOfPages();

      // reset the paginator to first page when the # of items per page changes
      if (this.paginator) {
        this.resetPaginator(this.paginator);
      }
    }
  }

  populatePageSizeOptions() {
    this.pageSizeOptions = [];
    // populate the size options
    let sizeOptions = this.data.length < this.defaultSize ? this.data.length : this.defaultSize;
    do {
      this.pageSizeOptions.push(sizeOptions);
      sizeOptions += sizeOptions;
    } while (sizeOptions <= this.data.length);

    if (this.pageSizeOptions.indexOf(this.data.length) < 0) {
      this.pageSizeOptions.push(this.data.length);
    }
  }

  calculateNumberOfPages() {
    // calculate the number of pages per current page size
    const recordsPerPage =
      this.currentPageSize > this.defaultSize && this.currentPageSize === this.data.length
        ? this.defaultSize
        : this.currentPageSize;

    this.numberOfPages = Math.ceil(this.data.length / recordsPerPage);
  }

  resetPaginator(paginator: MatPaginator) {
    paginator.pageSize = this.currentPageSize;
    paginator.firstPage();
  }

  handlePageChangeEvent(event?: PageEvent, skipEmit = false) {
    if (!event) {
      return;
    }
    this.currentPageSize = event.pageSize;

    if (event.pageIndex === this.pageIndex + 1) {
      this.fromIndex = this.fromIndex + this.currentPageSize;
    } else if (event.pageIndex === this.pageIndex - 1) {
      this.fromIndex = this.fromIndex - this.currentPageSize < 0 ? 0 : this.fromIndex - this.currentPageSize;
    }

    if (this.fromIndex > this.data.length) {
      this.fromIndex = this.data.length - 1;
    }

    this.toIndex = this.fromIndex + this.currentPageSize;

    if (this.toIndex > this.data.length) {
      this.toIndex = this.data.length;
    }

    // console.log(` 1. fromIndex ${this.fromIndex}
    // this.currentPageSize ${this.currentPageSize}
    // toIndex ${this.toIndex}
    // event.pageIndex ${event.pageIndex}
    // this.pageIndex  ${this.pageIndex }
    // this.numberOfPages ${this.numberOfPages}
    // event.pageSize ${event.pageSize}
    // `);

    if (event.pageIndex === 0) {
      // first page
      this.fromIndex = 0;
      this.toIndex = this.currentPageSize;

      console.log('2*. inside if');
    } else if (event.pageIndex === this.numberOfPages - 1) {
      // last page
      this.toIndex = this.data.length;
      this.fromIndex = this.currentPageSize * event.pageIndex;
      console.log('2*. else if');
    }

    this.pageIndex = event.pageIndex;

    // console.log(` 2. fromIndex ${this.fromIndex}
    // this.currentPageSize ${this.currentPageSize}
    // toIndex ${this.toIndex}
    // event.pageIndex ${event.pageIndex}
    // this.pageIndex  ${this.pageIndex }
    // this.numberOfPages ${this.numberOfPages}
    // event.pageSize ${event.pageSize}
    // `);

    // skip emitting the change as it was received from the duplicate pagination element
    if (!skipEmit) {
      this.emitCurrentPageInfo(event);
    }

    return event;
  }

  emitCurrentPageInfo(event: PageEvent) {
    this.currentPageInfo.emit({
      pageSize: this.currentPageSize,
      pageIndex: this.pageIndex,
      fromIndex: this.fromIndex,
      toIndex: this.toIndex,
      numberOfPages: this.numberOfPages,
      pageSizeOptions: this.pageSizeOptions,
      pageEvent: event,
    });
  }

  ngAfterViewInit(): void {
    this.setPageOptions();
  }

  ngOnInit(): void {
    this.fromIndex = 0;
    this.toIndex = this.currentPageSize;
  }
}
