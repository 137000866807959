import { TypeRoot, Type, TypeProperty } from '@contrail/types';
import { createAction, props } from '@ngrx/store';
import { OptionSetHierarchy } from './option-set-hierarchies/option-set-hierarchies.state';
import { TypePropertyOptionSet } from './option-sets/option-sets.state';
import { TypePropertyUserList } from './user-lists/user-lists.state';
import { TypePropertyPolicy } from './type-property-policies/type-property-policies.state';
import { TypeRuleSet } from './type-rule-sets/type-rule-sets.state';
import { TypePolicy } from './type-policies/type-policies.state';

export enum TypeManagerActionTypes {
  SET_CURRENT_TYPE_ROOT = '[Type Manager] Set Current Type Root',
  SET_CURRENT_TYPE_PROPERTY = '[Type Manager] Set Current Type Property',
  SET_CURRENT_TYPE_PROPERTY_OPTION_SET = '[Type Manager] Set Current Type Property Option Set',
  SET_CURRENT_TYPE_PROPERTY_USER_LIST = '[Type Manager] Set Current Type Property User List',
  SET_CURRENT_TYPE_PROPERTY_POLICY = '[Type Manager] Set Current Type Property Policy',
  SET_CURRENT_TYPE_POLICY = '[Type Manager] Set Current Type Policy',
  SET_CURRENT_TYPE_RULE_SET = '[Type Manager] Set Current Type Rule Set',
  SET_CURRENT_OPTION_SET_HIERARCHY = '[Type Manager] Set Current Option Set Hierarchy',

  LOAD_CURRENT_TYPE = '[Type Manager] Load Current Type',
  LOAD_CURRENT_TYPE_SUCCESS = '[Type Manager] Load Current Type Success',
  LOAD_CURRENT_TYPE_FAILURE = '[Type Manager] Load Current Type Failure',

  ADD_PROPERTY_TO_TYPE = '[Type Manager] Add Property To Type',
  ADD_PROPERTY_TO_TYPE_SUCCESS = '[Type Manager] Add Property To Type Success',
  ADD_PROPERTY_TO_TYPE_FAILURE = '[Type Manager] Add Property To Type Failure',

  REMOVE_PROPERTY_FROM_TYPE = '[Type Manager] Remove Property From Type',
  REMOVE_PROPERTY_FROM_TYPE_SUCCESS = '[Type Manager] Remove Property From Type Success',
  REMOVE_PROPERTY_FROM_TYPE_FAILURE = '[Type Manager] Remove Property From Type Failure',

  LOADING_TYPE = '[Type Manager] Loading Type',
  UPDATING_TYPE = '[Type Manager] Updating Type',
}
/// /////////////////////////////////////////////////////////////////////////////

export const setCurrentTypePropertyPolicy = createAction(
  TypeManagerActionTypes.SET_CURRENT_TYPE_PROPERTY_POLICY,
  props<{ currentTypePropertyPolicy: TypePropertyPolicy }>(),
);
export const setCurrentTypePolicy = createAction(
  TypeManagerActionTypes.SET_CURRENT_TYPE_POLICY,
  props<{ currentTypePolicy: TypePolicy }>(),
);

export const setCurrentTypeRuleSet = createAction(
  TypeManagerActionTypes.SET_CURRENT_TYPE_RULE_SET,
  props<{ currentTypeRuleSet: TypeRuleSet }>(),
);

export const setCurrentOptionSetHierarchy = createAction(
  TypeManagerActionTypes.SET_CURRENT_OPTION_SET_HIERARCHY,
  props<{ currentOptionSetHierarchy: OptionSetHierarchy }>(),
);

export const setCurrentTypeRoot = createAction(
  TypeManagerActionTypes.SET_CURRENT_TYPE_ROOT,
  props<{ typeRoot: TypeRoot }>(),
);

export const setCurrentTypeProperty = createAction(
  TypeManagerActionTypes.SET_CURRENT_TYPE_PROPERTY,
  props<{ currentTypeProperty: TypeProperty }>(),
);
export const setCurrentTypePropertyOptionSet = createAction(
  TypeManagerActionTypes.SET_CURRENT_TYPE_PROPERTY_OPTION_SET,
  props<{ currentTypePropertyOptionSet: TypePropertyOptionSet }>(),
);

export const setCurrentTypePropertyUserList = createAction(
  TypeManagerActionTypes.SET_CURRENT_TYPE_PROPERTY_USER_LIST,
  props<{ currentTypePropertyUserList: TypePropertyUserList }>(),
);

export const loadCurrentType = createAction(TypeManagerActionTypes.LOAD_CURRENT_TYPE, props<{ id: string }>());

export const loadCurrentTypeSuccess = createAction(
  TypeManagerActionTypes.LOAD_CURRENT_TYPE_SUCCESS,
  props<{ currentType: Type }>(),
);

export const loadCurrentTypeFailure = createAction(
  TypeManagerActionTypes.LOAD_CURRENT_TYPE_FAILURE,
  props<{ error: any }>(),
);

export const addPropertyToType = createAction(
  TypeManagerActionTypes.ADD_PROPERTY_TO_TYPE,
  props<{ typeProperty: TypeProperty }>(),
);

export const addPropertyToTypeSuccess = createAction(
  TypeManagerActionTypes.ADD_PROPERTY_TO_TYPE_SUCCESS,
  props<{ typeProperty: TypeProperty }>(),
);

export const addPropertyToTypeFailure = createAction(
  TypeManagerActionTypes.ADD_PROPERTY_TO_TYPE_FAILURE,
  props<{ error: any }>(),
);

export const removePropertyFromType = createAction(
  TypeManagerActionTypes.REMOVE_PROPERTY_FROM_TYPE,
  props<{ typeProperty: TypeProperty }>(),
);

export const removePropertyFromTypeSuccess = createAction(
  TypeManagerActionTypes.REMOVE_PROPERTY_FROM_TYPE_SUCCESS,
  props<{ typeProperty: TypeProperty }>(),
);

export const removePropertyFromTypeFailure = createAction(
  TypeManagerActionTypes.REMOVE_PROPERTY_FROM_TYPE_FAILURE,
  props<{ error: any }>(),
);

export const loading = createAction(TypeManagerActionTypes.LOADING_TYPE, props<{ loading: boolean }>());

export const updating = createAction(TypeManagerActionTypes.UPDATING_TYPE, props<{ updating: boolean }>());
