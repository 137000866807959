<div *ngIf="selectedOrg" class="mx-3">
  <button mat-button [matMenuTriggerFor]="aboveMenu" class="w-full hover:scale-110">
    <!-- <img [src]="selectedOrg.thumbnail" class="org-thumbnail" />-->
    {{ selectedOrg?.orgName || selectedOrg?.orgSlug }}
  </button>
  <mat-menu #aboveMenu="matMenu" yPosition="below" xPosition="before" class="menu-md square-scroll">
    <p class="text-title-2 px-3 py-2">You have access to the following organizations</p>
    <div class="max-h-[75vh]">
      <button class="org-button" *ngFor="let org of visibleOrgs" mat-menu-item (click)="selectOrg(org)">
        <!-- <img [src]="org.thumbnail" class="org-thumbnail" /> -->
        {{ org?.orgName || org.orgSlug }}
      </button>
    </div>
  </mat-menu>
</div>
