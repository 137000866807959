import { createSelector } from '@ngrx/store';
import { TypePropertyOptionSet, typePropertyOptionSetsEntityAdapter } from './option-sets.state';

export const { selectAll, selectEntities, selectIds, selectTotal } = typePropertyOptionSetsEntityAdapter.getSelectors();

const typePropertyOptionSetsEntities = (state: any) => state.typeManager.typePropertyOptionSets.entities;
export const typePropertyOptionSets = createSelector(
  typePropertyOptionSetsEntities,
  (state) => Object.values(state) as Array<TypePropertyOptionSet>,
);
