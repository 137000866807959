import { createSelector } from '@ngrx/store';
import { TypePropertyPolicy, typePropertyPoliciesEntityAdapter } from './type-property-policies.state';

export const { selectAll, selectEntities, selectIds, selectTotal } = typePropertyPoliciesEntityAdapter.getSelectors();

const typePropertyPoliciesEntities = (state: any) => state.typeManager.typePropertyPolicies.entities;
export const typePropertyPolicies = createSelector(
  typePropertyPoliciesEntities,
  (state) => Object.values(state) as Array<TypePropertyPolicy>,
);
