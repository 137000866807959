import { AppExtension } from '@contrail/entity-types';
import { createAction, props } from '@ngrx/store';

export enum AppExtensionsActionTypes {
  LOAD_APP_EXTENSIONS = '[App Extensions] Load App Extensions',
  LOAD_APP_EXTENSIONS_SUCCESS = '[App Extensions] Load App Extensions Success',
  LOAD_APP_EXTENSIONS_FAILURE = '[App Extensions] Load App Extensions Failure',

  LOAD_SOURCE_APP_EXTENSION = '[App Extensions] Load Source AppExtension',
  LOAD_SOURCE_APP_EXTENSION_SUCCESS = '[App Extensions] Load Source AppExtension Success',
  LOAD_SOURCE_APP_EXTENSION_FAILURE = '[App Extensions] Load Source AppExtension Failure',

  CLEAR_SOURCE_APP_EXTENSION = '[App Extensions] Load Source AppExtension Failure',
}

////////////////////////////////////////////////////////////////////////////////
export const loadAppExtensions = createAction(AppExtensionsActionTypes.LOAD_APP_EXTENSIONS);

export const loadAppExtensionsSuccess = createAction(
  AppExtensionsActionTypes.LOAD_APP_EXTENSIONS_SUCCESS,
  props<{ data: Array<AppExtension> }>(),
);

export const loadAppExtensionsFailure = createAction(
  AppExtensionsActionTypes.LOAD_APP_EXTENSIONS_FAILURE,
  props<{ error: any }>(),
);
