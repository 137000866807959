import { Content } from '@contrail/sdk';
import { Component, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Entity } from '../entity';

@Component({
  selector: 'app-content-managed',
  templateUrl: './content-managed.component.html',
  styleUrls: ['./content-managed.component.scss'],
})
export class ContentManagedComponent {
  public entity: Entity;
  public content: Array<any> = [];
  constructor(private route: ActivatedRoute) {
    this.init();
  }
  async init() {
    this.route.parent.params.subscribe(async (params) => {
      console.log('params: ', params);
      if (params.entityType) {
        const entityType = params.entityType;
        const id = params.id;

        this.entity = new Entity(entityType, id, [
          'content',
          'content.primaryFile',
          'content.largeViewable',
          'content.mediumViewable',
          'content.smallViewable',
          'content.tinyViewable',
        ]);
        await this.entity.load();
        this.content = this.entity?.getRaw().content || [];
      }
    });
  }

  @HostListener('dragover', ['$event']) async onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  @HostListener('drop', ['$event']) async onDrop(event) {
    event.stopPropagation();
    event.preventDefault();
    if (event.dataTransfer?.files?.length) {
      const files: FileList = event.dataTransfer?.files;
      for (const file of Array.from(files)) {
        const content = await new Content().create({ contentHolderReference: this.entity.getEntityReference(), file });
        this.content.push(content);
      }
    }
  }

  startDrag(event) {
    const id = event.target.id;
    const entity = this.content.filter((e) => e.id === id).shift();
    entity.entityType = 'content';
    event.dataTransfer.setData('entity', JSON.stringify(entity));
  }
}
