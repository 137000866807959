import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { RootStoreState } from '../../root-store';
import { TypeManagerActions, TypeManagerSelectors } from '../type-manager-store';
import { TypePropertyUserList } from '../type-manager-store/user-lists/user-lists.state';

@Injectable({
  providedIn: 'root',
})
export class TypePropertyUserListsResolver implements Resolve<Array<TypePropertyUserList>> {
  constructor(private store: Store<RootStoreState.State>) {}
  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<Array<TypePropertyUserList>> {
    this.loadTypePropertyUserLists();
    return this.store.select(TypeManagerSelectors.typePropertyUserLists).pipe(take(1));
  }

  loadTypePropertyUserLists() {
    this.store.dispatch(TypeManagerActions.loadTypePropertyUserLists());
  }
}
