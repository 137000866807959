import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterPropertyDefinition } from 'src/app/common/types/filters/filter-definition';

@Component({
  selector: 'app-filter-menu-modal',
  templateUrl: './filter-menu-modal.component.html',
  styleUrls: ['./filter-menu-modal.component.scss'],
})
export class FilterMenuModalComponent {
  @Output() selectedFiltersEvent: EventEmitter<any> = new EventEmitter();
  @Output() showFilterEvent: EventEmitter<any> = new EventEmitter();

  @Input() filters: Array<FilterPropertyDefinition>;
  @Input() clearAll = false;

  public filterNames = [];
  public selectedValues = new Set<string>();
  constructor() {}

  emitSelectedFilter() {
    this.selectedFiltersEvent.emit(this.filters);
  }

  toggleFilterMenu() {
    this.showFilterEvent.emit(false);
  }
}
