import { State } from './app-extensions.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as AppExtensionsActions from './app-extensions.actions';
import { appExtensionsEntityAdapter } from './app-extensions.state';
import { appExtensionsInitialState } from './app-extensions.state';

const setData = (state: State, { data }) => {
  return {
    ...state,
    appExtensions: appExtensionsEntityAdapter.setAll(data, state.appExtensions),
  };
};

export const appExtensionsReducer = createReducer(
  appExtensionsInitialState,
  on(AppExtensionsActions.loadAppExtensionsSuccess, setData),
);

export function reducer(state: State | undefined, action: Action): any {
  return appExtensionsReducer(state, action);
}
