import { createAction, props } from '@ngrx/store';
import { App } from './store-app';

export enum AppsActionTypes {
  LOAD_APPS = '[Apps] Load Apps',
  LOAD_APPS_SUCCESS = '[Apps] Load Apps Success',
  LOAD_APPS_FAILURE = '[Apps] Load Apps Failure',

  CREATE_APP = '[Apps] Create App',
  CREATE_APP_SUCCESS = '[Apps] Create App Success',
  CREATE_APP_FAILURE = '[Apps] Create App Failure',

  DELETE_APP = '[Apps] Delete App',
  DELETE_APP_SUCCESS = '[Apps] Delete App Success',
  DELETE_APP_FAILURE = '[Apps] Delete App Failure',

  UPDATE_APP = '[Apps] Update App',
  UPDATE_APP_SUCCESS = '[Apps] Update App Success',
  UPDATE_APP_FAILURE = '[Apps] Update App Failure',

  LOAD_CURRENT_APP = '[Apps] Load Current App',
  LOAD_CURRENT_APP_SUCCESS = '[Apps] Load Current App Success',
  LOAD_CURRENT_APP_FAILURE = '[Apps] Load Current App Failure',
}

/// /////////////////////////////////////////////////////////////////////////////
export const loadApps = createAction(AppsActionTypes.LOAD_APPS, props<{ loadHidden: boolean }>());

export const loadAppsSuccess = createAction(AppsActionTypes.LOAD_APPS_SUCCESS, props<{ data: Array<any> }>());

export const loadAppsFailure = createAction(AppsActionTypes.LOAD_APPS_FAILURE, props<{ error: any }>());

export const createApp = createAction(AppsActionTypes.CREATE_APP, props<{ id: string }>());

export const createAppSuccess = createAction(AppsActionTypes.CREATE_APP_SUCCESS, props<{ app: App }>());

export const createAppFailure = createAction(AppsActionTypes.CREATE_APP_FAILURE, props<{ error: any }>());

export const updateApp = createAction(AppsActionTypes.UPDATE_APP, props<{ id: string; changes: App }>());

export const updateAppSuccess = createAction(AppsActionTypes.UPDATE_APP_SUCCESS, props<{ id: string; changes: App }>());

export const updateAppFailure = createAction(AppsActionTypes.UPDATE_APP_FAILURE, props<{ error: any }>());

export const deleteApp = createAction(AppsActionTypes.DELETE_APP, props<{ app: App }>());

export const deleteAppSuccess = createAction(AppsActionTypes.DELETE_APP_SUCCESS, props<{ app: App }>());

export const deleteAppFailure = createAction(AppsActionTypes.DELETE_APP_FAILURE, props<{ error: any }>());

export const loadCurrentApp = createAction(AppsActionTypes.LOAD_CURRENT_APP, props<{ id: string }>());

export const loadCurrentAppSuccess = createAction(AppsActionTypes.LOAD_CURRENT_APP_SUCCESS, props<{ app: App }>());

export const loadCurrentAppFailure = createAction(AppsActionTypes.LOAD_CURRENT_APP_FAILURE, props<{ error: any }>());
