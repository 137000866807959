import { Injectable } from '@angular/core';
import { AppExtensionMessage, AppExtensionMessageHandler } from '@contrail/extensions-sdk';

@Injectable({
  providedIn: 'root',
})
export class UtilityAppExtensionMessageHandler implements AppExtensionMessageHandler {
  constructor() {}

  public async handleMessage(message: AppExtensionMessage) {
    console.debug('Utility App Extension NOT handling message ', message);
  }
}
