import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { TypeRoot } from '@contrail/types';
import { RootStoreState } from '@rootstore';
import { TypeManagerActions, TypeManagerSelectors } from 'src/app/type-manager/type-manager-store';
import { TypesService } from 'src/app/type-manager/types.service';
import { AuthService } from '@common/auth/auth.service';
import { AppExtensionsActions, AppExtensionsSelectors } from '@common/app-extensions/app-extensions-store';
import { AppExtension, AppExtensionType, AppExtensionUserApps } from '@contrail/entity-types';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  public authContext;
  public utilityExtensions: Array<AppExtension> = [];
  public navItems = [];
  public allItems = [];

  constructor(
    private authService: AuthService,
    public router: Router,
    private store: Store<RootStoreState.State>,
    private typesService: TypesService,
  ) {}

  ngOnInit(): void {
    this.authService.authContext.subscribe((authContext) => {
      this.authContext = authContext;
      if (this.authContext) {
        this.setNavItems();
        this.store.dispatch(TypeManagerActions.loadTypeRoots());
        this.store.dispatch(AppExtensionsActions.loadAppExtensions());
      }
    });

    this.store
      .select(TypeManagerSelectors.typeRoots)
      .pipe(
        filter((typeRoots) => !!typeRoots?.length),
        take(1),
      )
      .subscribe((typeRoots) => {
        const customEntityRootType = typeRoots.find((typeRoot) => typeRoot.slug === 'custom-entity');
        this.setLibraries(customEntityRootType);
      });

    this.store.select(AppExtensionsSelectors.appExtensions).subscribe((extensions) => {
      this.utilityExtensions = extensions.filter(
        (extension) =>
          extension.userApps.includes(AppExtensionUserApps.ADMIN_CONSOLE) &&
          extension.extensionType === AppExtensionType.ADMIN_UTILITY,
      );
      this.setNavItems();
    });
  }

  setNavItems() {
    this.navItems = [];
    const orgDetails = {
      title: 'Org Setup',
      icon: 'domain',
      expanded: false,
      options: [{ label: 'Org Details', route: 'org-setup/details' }],
    };
    this.navItems.push(orgDetails);

    const types = {
      title: 'Types & Rules',
      icon: 'construction',
      expanded: false,
      options: [
        { label: 'Types', route: 'type-manager/types' },
        { label: 'Option Sets', route: 'type-manager/option-sets' },
        { label: 'Option Hierarchies', route: 'type-manager/option-set-hierarchies' },
        { label: 'Rule Sets', route: 'type-manager/rule-sets' },
        { label: 'Type Policies', route: 'type-manager/type-policies' },
        { label: 'Property Policies', route: 'type-manager/type-property-policies' },
      ],
    };
    this.navItems.push(types);

    if (this.authService.isAdmin()) {
      const libraries = {
        title: 'Libraries',
        svgIcon: 'library',
        expanded: false,
        options: [],
      };
      this.navItems.push(libraries);
    }

    const apps = {
      title: 'Apps & Integration',
      icon: 'apps',
      expanded: false,
      options: [
        { label: 'App Marketplace', route: 'app-store' },
        { label: 'Event Workflows', route: 'event-workflows' },
        { label: 'API/SDK Reference', route: 'api/api-docs' },
        { label: 'System Notifications', route: 'system-notifications' },
        { label: 'Schedules', route: 'schedules' },
      ],
    };

    this.navItems.push(apps);

    const loading = {
      title: 'Data Loading',
      icon: 'upload_file',
      expanded: false,
      options: [],
    };
    loading.options.push({ label: 'Load History', route: 'loader/history' });
    if (this.authContext.user.email.indexOf('vibeiq') > -1) {
      loading.options.push({ label: 'Load Images', route: 'loader/load-images' });
      loading.options.push({ label: 'Load File', route: 'loader/load-file' });
      loading.options.push({ label: 'Load Colors', route: 'loader/load-colors' });
      loading.options.push({ label: 'Load Configs', route: 'loader/load-configs' });
      loading.options.push({ label: 'Load Custom Entities', route: 'loader/load-custom-entities' });
    }
    this.navItems.push(loading);

    const users = {
      title: 'User Administration',
      icon: 'perm_identity',
      expanded: false,
      options: [
        { label: 'Users', route: 'users/users' },
        { label: 'User Groups', route: 'users/groups' },
      ],
    };
    this.navItems.push(users);

    if (this.utilityExtensions.length > 0) {
      const utilityExtensions = {
        title: 'Utilities',
        icon: 'build',
        expanded: false,
        options: this.utilityExtensions.map((extension) => ({
          label: extension.name,
          route: `utility-extensions/${extension.identifier}`,
        })),
      };
      this.navItems.push(utilityExtensions);
    }

    this.allItems = this.navItems;
  }

  async setLibraries(customEntityRootType: TypeRoot) {
    const isOrgAdmin = this.authService.isAdmin();
    if (!customEntityRootType?.id || !isOrgAdmin) {
      return;
    }

    const customEntityTypes = await this.typesService.getTypesForRoot(customEntityRootType);
    const customEntitySubTypes = customEntityTypes.filter((type) => !!type.parentId);

    const libraryNavItem = this.navItems.find((item) => item.title === 'Libraries');
    libraryNavItem.options = customEntitySubTypes.map((type) => ({
      label: type.label,
      route: `library/custom-entity/${type.typePath}`,
    }));
  }

  optionShow(idx) {
    this.navItems[idx].expanded = !this.navItems[idx].expanded;
  }

  searchNav(event) {
    const key = event.toLowerCase();
    if (!key) {
      this.navItems = this.allItems;
      return;
    }

    this.navItems = this.allItems.filter((item) => {
      const title = item.title.toLowerCase().includes(key);
      let label = false;
      item.options.some((item) => {
        if (item.label.toLowerCase().includes(key)) {
          label = true;
          return true;
        }
        return false;
      });
      return title || label;
    });
  }

  // go(route) {
  //   const newPath = `/org/${this.authContext?.currentOrg?.orgSlug}/${route}`;
  //   this.router.navigate([newPath]);
  // }
}
