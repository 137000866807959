import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AppVersion } from '@contrail/entity-types';

interface AppVersionsState extends EntityState<AppVersion> {}

export interface State {
  appVersions: AppVersionsState;
}
export const appVersionsEntityAdapter: EntityAdapter<AppVersion> = createEntityAdapter<AppVersion>({});

export const appVersionsInitialState = {
  appVersions: appVersionsEntityAdapter.getInitialState({}),
};
