import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { AppVersion } from '@contrail/entity-types';

@Injectable({
  providedIn: 'root',
})
export class AppVersionsService {
  constructor() {}

  public async getAppVersionsForApp(appId: string): Promise<Array<AppVersion>> {
    return await new Entities().get({ entityName: 'app-version', criteria: { appId: appId } });
  }
  public async getAppVersionById(id: string): Promise<AppVersion> {
    return new Entities().get({ entityName: 'app-version', id });
  }
}
