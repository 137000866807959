<app-page-section *ngIf="enabled">
  <app-page-section-header>
    <app-page-section-title title="Default Value"></app-page-section-title>
  </app-page-section-header>
  <app-page-section-body>
    <app-type-property-form-field
      [propertyFormConfiguration]="formConfiguration"
      [value]="
        typeProperty.defaultValue === 'true'
          ? true
          : typeProperty.defaultValue === 'false'
            ? false
            : typeProperty.defaultValue
      "
    >
    </app-type-property-form-field>
  </app-page-section-body>
</app-page-section>
