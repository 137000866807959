<div *ngIf="typeMenu$ | async">
  <div class="type-selector">
    <span class="selector-label">Type:</span>
    <div [matMenuTriggerFor]="topMenu" class="selector" data-test="type-menu">
      {{ (selectedType$ | async)?.label }}
      <mat-icon>expand_more</mat-icon>
    </div>
  </div>
  <mat-menu #topMenu [overlapTrigger]="false">
    <div>
      <button
        [matMenuTriggerFor]="menu.childMenu"
        mat-menu-item
        #menuTrigger="matMenuTrigger"
        data-test="btn-type-first"
        (click)="goToRoot()"
        (mouseenter)="menuTrigger.openMenu()"
      >
        {{ (typeMenu$ | async)?.label }}
      </button>
    </div>
    <app-type-selector-menu #menu [typeMenu]="typeMenu$ | async"></app-type-selector-menu>
  </mat-menu>
</div>
