<app-page-section>
  <!-- <app-page-section-header>
    <app-page-section-title title="Label"></app-page-section-title>
  </app-page-section-header> -->
  <app-page-section-body>
    <mat-form-field style="width: 100%" appearance="outline">
      <mat-label>Label</mat-label>
      <input matInput [formControl]="labelFormField" (blur)="updateValue()" />
      <mat-hint>How the property will be displayed to users.</mat-hint>
    </mat-form-field>
  </app-page-section-body>
</app-page-section>
