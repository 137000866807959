import { createAction, props } from '@ngrx/store';
import { OptionSetHierarchy } from './option-set-hierarchies.state';

export enum OptionSetHierarchiesActionTypes {
  LOAD_OPTION_SET_HIERARCHIES = '[Type Manager - Option Set Hierarchies] Load Option Set Hierarchies',
  LOAD_OPTION_SET_HIERARCHIES_SUCCESS = '[Type Manager - Option Set Hierarchies] Load Option Set Hierarchies Success',
  LOAD_OPTION_SET_HIERARCHIES_FAILURE = '[Type Manager - Option Set Hierarchies] Load Option Set Hierarchies Failure',

  CREATE_OPTION_SET_HIERARCHY = '[Type Manager - Option Set Hierarchies] Create Option Set Hierarchy',
  CREATE_OPTION_SET_HIERARCHY_SUCCESS = '[Type Manager - Option Set Hierarchies] Create Option Set Hierarchy Success',
  CREATE_OPTION_SET_HIERARCHY_FAILURE = '[Type Manager - Option Set Hierarchies] Create Option Set Hierarchy Failure',

  DELETE_OPTION_SET_HIERARCHY = '[Type Manager - Option Set Hierarchies] Delete Option Set Hierarchy',
  DELETE_OPTION_SET_HIERARCHY_SUCCESS = '[Type Manager - Option Set Hierarchies] Delete Option Set Hierarchy Success',
  DELETE_OPTION_SET_HIERARCHY_FAILURE = '[Type Manager - Option Set Hierarchies] Delete Option Set Hierarchy Failure',

  UPDATE_OPTION_SET_HIERARCHY = '[Type Manager - Option Set Hierarchies] Update Option Set Hierarchy',
  UPDATE_OPTION_SET_HIERARCHY_SUCCESS = '[Type Manager - Option Set Hierarchies] Update Option Set Hierarchy Success',
  UPDATE_OPTION_SET_HIERARCHY_FAILURE = '[Type Manager - Option Set Hierarchies] Update Option Set Hierarchy Failure',
}

/// /////////////////////////////////////////////////////////////////////////////

export const loadOptionSetHierarchies = createAction(OptionSetHierarchiesActionTypes.LOAD_OPTION_SET_HIERARCHIES);

export const loadOptionSetHierarchiesSuccess = createAction(
  OptionSetHierarchiesActionTypes.LOAD_OPTION_SET_HIERARCHIES_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const loadOptionSetHierarchiesFailure = createAction(
  OptionSetHierarchiesActionTypes.LOAD_OPTION_SET_HIERARCHIES_FAILURE,
  props<{ error: any }>(),
);

export const createOptionSetHierarchy = createAction(
  OptionSetHierarchiesActionTypes.CREATE_OPTION_SET_HIERARCHY,
  props<{ optionSetHierarchy: OptionSetHierarchy }>(),
);

export const createOptionSetHierarchySuccess = createAction(
  OptionSetHierarchiesActionTypes.CREATE_OPTION_SET_HIERARCHY_SUCCESS,
  props<{ optionSetHierarchy: OptionSetHierarchy }>(),
);

export const createOptionSetHierarchyFailure = createAction(
  OptionSetHierarchiesActionTypes.CREATE_OPTION_SET_HIERARCHY_FAILURE,
  props<{ error: any }>(),
);
export const deleteOptionSetHierarchy = createAction(
  OptionSetHierarchiesActionTypes.DELETE_OPTION_SET_HIERARCHY,
  props<{ optionSetHierarchy: OptionSetHierarchy }>(),
);

export const deleteOptionSetHierarchySuccess = createAction(
  OptionSetHierarchiesActionTypes.DELETE_OPTION_SET_HIERARCHY_SUCCESS,
  props<{ optionSetHierarchy: OptionSetHierarchy }>(),
);

export const deleteOptionSetHierarchyFailure = createAction(
  OptionSetHierarchiesActionTypes.DELETE_OPTION_SET_HIERARCHY_FAILURE,
  props<{ error: any }>(),
);

export const updateOptionSetHierarchy = createAction(
  OptionSetHierarchiesActionTypes.UPDATE_OPTION_SET_HIERARCHY,
  props<{ id: string; changes: OptionSetHierarchy }>(),
);

export const updateOptionSetHierarchySuccess = createAction(
  OptionSetHierarchiesActionTypes.UPDATE_OPTION_SET_HIERARCHY_SUCCESS,
  props<{ id: string; changes: OptionSetHierarchy }>(),
);

export const updateOptionSetHierarchyFailure = createAction(
  OptionSetHierarchiesActionTypes.UPDATE_OPTION_SET_HIERARCHY_FAILURE,
  props<{ error: any }>(),
);
