<div class="main">
  <div class="body">
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput [formControl]="nameField" />
    </mat-form-field>
  </div>
  <div class="actions">
    <button mat-flat-button color="cancel" (click)="cancel()">Cancel</button>
    <button mat-flat-button color="primary" [disabled]="!isValid()" (click)="completeEdit()">Add</button>
  </div>
</div>
