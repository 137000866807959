import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HoverActiveDirective } from './hover-active.directive';
import { AutoFocusDirective } from './auto-focus.directive';
import { DebounceClickDirective } from './debounce-click.directive';
import { LimitFractionDigitsDirective } from './limit-fraction-digits.directive';
import { OverflowTooltipDirective } from './overflow-tooltip.directive';
import { MatSelectRemoveAriaOwnsDirective } from './mat-select-remove-aria-owns.directive';

@NgModule({
  declarations: [
    HoverActiveDirective,
    AutoFocusDirective,
    DebounceClickDirective,
    LimitFractionDigitsDirective,
    OverflowTooltipDirective,
    MatSelectRemoveAriaOwnsDirective,
  ],
  imports: [CommonModule],
  exports: [
    HoverActiveDirective,
    AutoFocusDirective,
    DebounceClickDirective,
    LimitFractionDigitsDirective,
    OverflowTooltipDirective,
    MatSelectRemoveAriaOwnsDirective,
  ],
})
export class DirectivesModule {}
