import { createAction, props } from '@ngrx/store';
import { TypePropertyOptionSet } from './option-sets.state';

export enum TypePropertyOptionSetsActionTypes {
  LOAD_TYPE_PROPERTY_OPTION_SETS = '[Type Manager - Type Property Option Sets] Load Type Property Option Sets',
  LOAD_TYPE_PROPERTY_OPTION_SETS_SUCCESS = '[Type Manager - Type Property Option Sets] Load Type Property Option Sets Success',
  LOAD_TYPE_PROPERTY_OPTION_SETS_FAILURE = '[Type Manager - Type Property Option Sets] Load Type Property Option Sets Failure',

  CREATE_TYPE_PROPERTY_OPTION_SET = '[Type Manager - Type Property Option Sets] Create Type Property Option Set',
  CREATE_TYPE_PROPERTY_OPTION_SET_SUCCESS = '[Type Manager - Type Property Option Sets] Create Type Property Option Set Success',
  CREATE_TYPE_PROPERTY_OPTION_SET_FAILURE = '[Type Manager - Type Property Option Sets] Create Type Property Option Set Failure',

  DELETE_TYPE_PROPERTY_OPTION_SET = '[Type Manager - Type Property Option Sets] Delete Type Property Option Set',
  DELETE_TYPE_PROPERTY_OPTION_SET_SUCCESS = '[Type Manager - Type Property Option Sets] Delete Type Property Option Set Success',
  DELETE_TYPE_PROPERTY_OPTION_SET_FAILURE = '[Type Manager - Type Property Option Sets] Delete Type Property Option Set Failure',

  UPDATE_TYPE_PROPERTY_OPTION_SET = '[Type Manager - Type Property Option Sets] Update Type Property Option Set',
  UPDATE_TYPE_PROPERTY_OPTION_SET_SUCCESS = '[Type Manager - Type Property Option Sets] Update Type Property Option Set Success',
  UPDATE_TYPE_PROPERTY_OPTION_SET_FAILURE = '[Type Manager - Type Property Option Sets] Update Type Property Option Set Failure',
}

/// /////////////////////////////////////////////////////////////////////////////

export const loadTypePropertyOptionSets = createAction(
  TypePropertyOptionSetsActionTypes.LOAD_TYPE_PROPERTY_OPTION_SETS,
);

export const loadTypePropertyOptionSetsSuccess = createAction(
  TypePropertyOptionSetsActionTypes.LOAD_TYPE_PROPERTY_OPTION_SETS_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const loadTypePropertyOptionSetsFailure = createAction(
  TypePropertyOptionSetsActionTypes.LOAD_TYPE_PROPERTY_OPTION_SETS_FAILURE,
  props<{ error: any }>(),
);

export const createTypePropertyOptionSet = createAction(
  TypePropertyOptionSetsActionTypes.CREATE_TYPE_PROPERTY_OPTION_SET,
  props<{ typePropertyOptionSet: TypePropertyOptionSet }>(),
);

export const createTypePropertyOptionSetSuccess = createAction(
  TypePropertyOptionSetsActionTypes.CREATE_TYPE_PROPERTY_OPTION_SET_SUCCESS,
  props<{ typePropertyOptionSet: TypePropertyOptionSet }>(),
);

export const createTypePropertyOptionSetFailure = createAction(
  TypePropertyOptionSetsActionTypes.CREATE_TYPE_PROPERTY_OPTION_SET_FAILURE,
  props<{ error: any }>(),
);
export const deleteTypePropertyOptionSet = createAction(
  TypePropertyOptionSetsActionTypes.DELETE_TYPE_PROPERTY_OPTION_SET,
  props<{ typePropertyOptionSet: TypePropertyOptionSet }>(),
);

export const deleteTypePropertyOptionSetSuccess = createAction(
  TypePropertyOptionSetsActionTypes.DELETE_TYPE_PROPERTY_OPTION_SET_SUCCESS,
  props<{ typePropertyOptionSet: TypePropertyOptionSet }>(),
);

export const deleteTypePropertyOptionSetFailure = createAction(
  TypePropertyOptionSetsActionTypes.DELETE_TYPE_PROPERTY_OPTION_SET_FAILURE,
  props<{ error: any }>(),
);

export const updateTypePropertyOptionSet = createAction(
  TypePropertyOptionSetsActionTypes.UPDATE_TYPE_PROPERTY_OPTION_SET,
  props<{ id: string; changes: TypePropertyOptionSet }>(),
);

export const updateTypePropertyOptionSetSuccess = createAction(
  TypePropertyOptionSetsActionTypes.UPDATE_TYPE_PROPERTY_OPTION_SET_SUCCESS,
  props<{ id: string; changes: TypePropertyOptionSet }>(),
);

export const updateTypePropertyOptionSetFailure = createAction(
  TypePropertyOptionSetsActionTypes.UPDATE_TYPE_PROPERTY_OPTION_SET_FAILURE,
  props<{ error: any }>(),
);
