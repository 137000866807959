export const environment = {
  production: false,
  apiBaseUrl: 'https://admin.dev.vibeiq.com/api-dev',
  adminAppHost: 'https://admin.dev.vibeiq.com/',
  userPoolRegion: 'us-east-1',
  userPoolId: 'us-east-1_e2cA0nJWu',
  userPoolWebClientId: 'nnio2uhtq7qfk1tjmi7tgg63p',
  name: 'DEVELOPMENT',
  googleAnalyticsKey: 'UA-179147959-1',
  intercomAppId: 'e2uyp9md',
  domain: '.dev.vibeiq.com',
  websocketService: 'wss://qde1jn70k0.execute-api.us-east-1.amazonaws.com/dev/',
  appName: 'Admin',
  loginUrl: '', // only used by feature branches
  sentryDsn: 'https://80085003875ebf7541efbe8a8b636bb4@o4507029629960192.ingest.us.sentry.io/4508133983453184',
  ngrxHistory: 2,
};
