import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TypePropertiesService } from '../../type-properties/type-properties.service';

@Component({
  selector: 'app-type-property-usage-removal',
  templateUrl: './type-property-usage-removal.component.html',
  styleUrls: ['./type-property-usage-removal.component.scss'],
})
export class TypePropertyUsageRemovalComponent {
  @Input() typeProperty: any;
  @Input() type: any;
  @Output() changes = new EventEmitter();
  public hasInfoLinkFlag = false;
  constructor(private typePropertyService: TypePropertiesService) {}

  async ngOnInit() {
    this.hasInfoLinkFlag = await this.hasInfoLink();
  }

  async getInfoLink() {
    if (this.typeProperty?.infoLinkId) {
      return await this.typePropertyService.getTypePropertyUsageInfo(this.typeProperty.infoLinkId);
    }
    return null;
  }

  async hasInfoLink() {
    const infoLink = await this.getInfoLink();
    if (infoLink) {
      console.log('infoLink : ', infoLink);

      console.log('type: ', this.type);
      return !!infoLink && infoLink.typeId === this.type.id;
    }
    return false;
  }

  deleteUsageLink() {
    const valueChanges = {
      infoLinkId: null,
    };
    this.changes.emit(valueChanges);
  }
}
