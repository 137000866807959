<div class="filter-wrapper">
  <div class="header">
    <div class="title">Filter(s)</div>
    <div class="close" (click)="toggleFilterMenu()"><mat-icon>close</mat-icon></div>
  </div>

  <div class="filter-content">
    <app-filter-entity
      *ngFor="let filter of filters"
      [filter]="filter"
      (filterValueChanged)="emitSelectedFilter()"
      [clearAll]="clearAll"
    >
    </app-filter-entity>
  </div>
</div>
