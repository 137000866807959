import { createAction, props } from '@ngrx/store';
import { LibraryEntity } from './library.state';

export enum LibraryActionTypes {
  SET_LIBRARY_ENTITIES = '[Library] Set Library Entities',
  ADD_LIBRARY_ENTITY = '[Library] Add Library Entity',
  UPDATE_LIBRARY_ENTITY = '[Library] Update Library Entity',
}

export const setLibraryEntities = createAction(
  LibraryActionTypes.SET_LIBRARY_ENTITIES,
  props<{ entities: Array<LibraryEntity> }>(),
);

export const addLibraryEntity = createAction(LibraryActionTypes.ADD_LIBRARY_ENTITY, props<LibraryEntity>());

export const updateLibraryEntity = createAction(
  LibraryActionTypes.UPDATE_LIBRARY_ENTITY,
  props<{ id: string; changes: any }>(),
);
