import { on } from '@ngrx/store';
import { State } from '../type-manager.state';
import * as TypePropertyUserListsAction from './user-lists.actions';
import { typePropertyUserListsEntityAdapter } from './user-lists.state';

const setData = (state: State, { data }) => ({
  ...state,
  typePropertyUserLists: typePropertyUserListsEntityAdapter.setAll(data, state.typePropertyOptionSets),
});
const add = (state: State, { typePropertyUserList }) => ({
  ...state,
  typePropertyUserLists: typePropertyUserListsEntityAdapter.addOne(typePropertyUserList, state.typePropertyUserLists),
});
const update = (state: State, { id, changes }) => ({
  ...state,
  typePropertyUserLists: typePropertyUserListsEntityAdapter.updateOne({ id, changes }, state.typePropertyUserLists),
});
const remove = (state: State, { typePropertyUserList }) => ({
  ...state,
  typePropertyUserLists: typePropertyUserListsEntityAdapter.removeOne(
    typePropertyUserList.id,
    state.typePropertyUserLists,
  ),
});

export const typePropertyUserListsReducer = [
  on(TypePropertyUserListsAction.loadTypePropertyUserListsSuccess, setData),
  on(TypePropertyUserListsAction.createTypePropertyUserListSuccess, add),
  on(TypePropertyUserListsAction.deleteTypePropertyUserListSuccess, remove),
  on(TypePropertyUserListsAction.updateTypePropertyUserList, update),
];
