import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { eventWorkflowTasksReducers } from './event-tasks.reducers';
import { EventWorkflowTasksEffects } from './event-tasks.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('eventWorkflowTasks', eventWorkflowTasksReducers),
    EffectsModule.forFeature([EventWorkflowTasksEffects]),
  ],
})
export class EventWorkflowTasksStoreModule {}
