import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { Auth } from 'aws-amplify';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth.gaurd';
import { TokenInterceptor } from './token.interceptor';
import { AuthService } from './auth.service';
import { UserOrgResolver } from './user-org.resolver';
import { NoOrgsRegisteredComponent } from './no-orgs-registered/no-orgs-registered.component';

Auth.configure({
  region: environment.userPoolRegion,
  userPoolId: environment.userPoolId,
  userPoolWebClientId: environment.userPoolWebClientId,
  mandatorySignIn: true,
  oauth: {},
});

@NgModule({
  declarations: [LoginComponent, NoOrgsRegisteredComponent],
  imports: [CommonModule, AmplifyUIAngularModule],
  providers: [
    AuthGuard,
    AuthService,
    UserOrgResolver,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    HttpClientModule,
  ],
  exports: [LoginComponent],
})
export class AuthModule {}
