import { createSelector } from '@ngrx/store';
import { App } from './store-app';
import { appsEntityAdapter } from './apps.state';
import { Org } from '@common/auth/auth.service';

export const { selectAll, selectEntities, selectIds, selectTotal } = appsEntityAdapter.getSelectors();

const appEntities = (state: any) => state.apps.apps.entities;
export const apps = createSelector(appEntities, (state) => Object.values(state) as Array<App>);

export const currentApp = (state: any) => state.apps.currentApp;

const orgEntities = (state: any) => state.apps.orgs.entities;
export const orgs = createSelector(orgEntities, (state) => Object.values(state) as Array<Org>);
