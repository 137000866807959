import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';
import { TypeManagerActions } from '..';
import { TypePropertiesService } from '../../type-properties/type-properties.service';
import { TypesService } from '../../types.service';

@Injectable()
export class TypePropertiesEffects {
  constructor(
    private actions$: Actions,
    private typePropertiesService: TypePropertiesService,
    public typesService: TypesService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {}

  // loadTypeProperties$ = createEffect(
  //   () => this.actions$.pipe(
  //     ofType(TypeManagerActions.TypePropertiesActionTypes.LOAD_TYPE_PROPERTIES),
  //     withLatestFrom(this.store),
  //     switchMap(([action, store]: [any, RootStoreState.State]) => {
  //       return from(this.typePropertiesService.getTypeProperties())
  //         .pipe(
  //           map(data => TypeManagerActions.loadTypePropertiesSuccess({ data })),
  //           catchError(error => observableOf(TypeManagerActions.loadTypePropertiesFailure({ error })))
  //         );
  //     })
  //   )
  // );
  createTypeProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.TypePropertiesActionTypes.CREATE_TYPE_PROPERTY),
      withLatestFrom(this.store),
      tap(() => {
        this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Creating property.' }));
      }),
      switchMap(([action, store]: [any, RootStoreState.State]) =>
        from(this.typesService.addPropertyToType(store.typeManager.currentType, action.typeProperty)).pipe(
          map((data) => {
            this.snackBar.open('Property Created.', '', { duration: 2000 });
            this.store.dispatch(TypeManagerActions.setCurrentTypeProperty({ currentTypeProperty: data }));
            this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            return TypeManagerActions.createTypePropertySuccess({ typeProperty: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(TypeManagerActions.createTypePropertyFailure({ error }));
          }),
        ),
      ),
    ),
  );
  deleteTypeProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.TypePropertiesActionTypes.DELETE_TYPE_PROPERTY),
      withLatestFrom(this.store),
      tap(() => {
        this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Deleting property.' }));
      }),
      switchMap(([action, store]: [any, RootStoreState.State]) =>
        from(this.typesService.deleteTypeProperty(store.typeManager.currentType, action.typeProperty)).pipe(
          map((data) => {
            this.snackBar.open('Property Deleted.', '', { duration: 2000 });
            this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            this.store.dispatch(TypeManagerActions.setCurrentTypeProperty({ currentTypeProperty: null }));
            return TypeManagerActions.deleteTypePropertySuccess({ typeProperty: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(TypeManagerActions.deleteTypePropertyFailure({ error }));
          }),
        ),
      ),
    ),
  );
  updateTypeProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.TypePropertiesActionTypes.UPDATE_TYPE_PROPERTY),
      withLatestFrom(this.store),
      tap(() => {
        // this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Updating property.' }));
      }),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        const currentTypeId = store.typeManager.currentType.id;
        const typeProperty = store.typeManager.typeProperties.entities[action.id];
        return from(
          this.typePropertiesService.updateTypeProperty(action.id, action.changes, currentTypeId, typeProperty),
        ).pipe(
          map((data) => {
            this.snackBar.open('Property Updated.', '', { duration: 2000 });
            this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            return TypeManagerActions.updateTypePropertySuccess({ id: data.id, changes: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(TypeManagerActions.updateTypePropertyFailure({ error }));
          }),
        );
      }),
    ),
  );
}
