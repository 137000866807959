import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZES,
} from 'src/app/event-workflows/store/event-processes/event-processes.state';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit, OnChanges {
  @Input() pageSize = DEFAULT_PAGE_SIZE;
  @Input() currentPage = DEFAULT_PAGE;
  @Input() hasNextPage = true;

  @Output() pageSizeChange = new EventEmitter();
  @Output() pageChange = new EventEmitter();

  hasPreviousPage = false;

  pageSizes: number[] = DEFAULT_PAGE_SIZES;

  constructor() {}

  ngOnInit(): void {
    this.hasPreviousPage = this.currentPage > 1;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentPage) {
      this.hasPreviousPage = this.currentPage > 1;
    }
  }

  nextPage() {
    this.currentPage++;
    this.hasPreviousPage = true;
    this.pageChange.emit({
      currentPage: this.currentPage,
      pageSize: this.pageSize,
    });
  }

  previousPage() {
    this.currentPage--;
    this.hasPreviousPage = this.currentPage > 1;
    this.hasNextPage = true;
    this.pageChange.emit({
      currentPage: this.currentPage,
      pageSize: this.pageSize,
    });
  }

  onPageSizeChange(event: any) {
    this.pageSizeChange.emit(event.value);
  }

  getNextPageTooltip() {
    return this.hasNextPage ? 'Next page' : 'No more records for selected filters';
  }
}
