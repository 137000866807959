import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isoLocalTimePipe',
})
export class isoLocalTimePipe implements PipeTransform {
  transform(value: any): string {
    const date = new Date(value);
    const offset = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() - offset);

    return date.toISOString().slice(0, -5);
  }
}
