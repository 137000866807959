import { createAction, props } from '@ngrx/store';
import { TypePropertyUserList } from './user-lists.state';

export enum TypePropertyUserListsActionTypes {
  LOAD_TYPE_PROPERTY_USER_LISTS = '[Type Manager - Type Property User Lists] Load Type Property User Lists',
  LOAD_TYPE_PROPERTY_USER_LISTS_SUCCESS = '[Type Manager - Type Property User Lists] Load Type Property User Lists Success',
  LOAD_TYPE_PROPERTY_USER_LISTS_FAILURE = '[Type Manager - Type Property User Lists] Load Type Property User Lists Failure',

  CREATE_TYPE_PROPERTY_USER_LIST = '[Type Manager - Type Property User Lists] Create Type Property User List',
  CREATE_TYPE_PROPERTY_USER_LIST_SUCCESS = '[Type Manager - Type Property User Lists] Create Type Property User List Success',
  CREATE_TYPE_PROPERTY_USER_LIST_FAILURE = '[Type Manager - Type Property User Lists] Create Type Property User List Failure',

  DELETE_TYPE_PROPERTY_USER_LIST = '[Type Manager - Type Property User Lists] Delete Type Property User List',
  DELETE_TYPE_PROPERTY_USER_LIST_SUCCESS = '[Type Manager - Type Property User Lists] Delete Type Property User List Success',
  DELETE_TYPE_PROPERTY_USER_LIST_FAILURE = '[Type Manager - Type Property User Lists] Delete Type Property User List Failure',

  UPDATE_TYPE_PROPERTY_USER_LIST = '[Type Manager - Type Property User Lists] Update Type Property User List',
  UPDATE_TYPE_PROPERTY_USER_LIST_SUCCESS = '[Type Manager - Type Property User Lists] Update Type Property User List Success',
  UPDATE_TYPE_PROPERTY_USER_LIST_FAILURE = '[Type Manager - Type Property User Lists] Update Type Property User List Failure',
}

/// /////////////////////////////////////////////////////////////////////////////

export const loadTypePropertyUserLists = createAction(TypePropertyUserListsActionTypes.LOAD_TYPE_PROPERTY_USER_LISTS);

export const loadTypePropertyUserListsSuccess = createAction(
  TypePropertyUserListsActionTypes.LOAD_TYPE_PROPERTY_USER_LISTS_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const loadTypePropertyUserListsFailure = createAction(
  TypePropertyUserListsActionTypes.LOAD_TYPE_PROPERTY_USER_LISTS_FAILURE,
  props<{ error: any }>(),
);

export const createTypePropertyUserList = createAction(
  TypePropertyUserListsActionTypes.CREATE_TYPE_PROPERTY_USER_LIST,
  props<{ typePropertyUserList: TypePropertyUserList }>(),
);

export const createTypePropertyUserListSuccess = createAction(
  TypePropertyUserListsActionTypes.CREATE_TYPE_PROPERTY_USER_LIST_SUCCESS,
  props<{ typePropertyUserList: TypePropertyUserList }>(),
);

export const createTypePropertyUserListFailure = createAction(
  TypePropertyUserListsActionTypes.CREATE_TYPE_PROPERTY_USER_LIST_FAILURE,
  props<{ error: any }>(),
);
export const deleteTypePropertyUserList = createAction(
  TypePropertyUserListsActionTypes.DELETE_TYPE_PROPERTY_USER_LIST,
  props<{ typePropertyUserList: TypePropertyUserList }>(),
);

export const deleteTypePropertyUserListSuccess = createAction(
  TypePropertyUserListsActionTypes.DELETE_TYPE_PROPERTY_USER_LIST_SUCCESS,
  props<{ typePropertyUserList: TypePropertyUserList }>(),
);

export const deleteTypePropertyUserListFailure = createAction(
  TypePropertyUserListsActionTypes.DELETE_TYPE_PROPERTY_USER_LIST_FAILURE,
  props<{ error: any }>(),
);

export const updateTypePropertyUserList = createAction(
  TypePropertyUserListsActionTypes.UPDATE_TYPE_PROPERTY_USER_LIST,
  props<{ id: string; changes: TypePropertyUserList }>(),
);

export const updateTypePropertyUserListSuccess = createAction(
  TypePropertyUserListsActionTypes.UPDATE_TYPE_PROPERTY_USER_LIST_SUCCESS,
  props<{ id: string; changes: TypePropertyUserList }>(),
);

export const updateTypePropertyUserListFailure = createAction(
  TypePropertyUserListsActionTypes.UPDATE_TYPE_PROPERTY_USER_LIST_FAILURE,
  props<{ error: any }>(),
);
