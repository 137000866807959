import { createAction, props } from '@ngrx/store';
import { EventWorkflowProcess } from './event-process';
import { SortOrderOptions } from '@contrail/sdk';

export enum EventWorkflowProcessesActionTypes {
  LOAD_CURRENT_EVENTWORKFLOWPROCESS = '[EventWorkflowProcesses] Load Current EventWorkflowProcess',
  LOAD_CURRENT_EVENTWORKFLOWPROCESS_SUCCESS = '[EventWorkflowProcesses] Load Current EventWorkflowProcess Success',
  LOAD_CURRENT_EVENTWORKFLOWPROCESS_FAILURE = '[EventWorkflowProcesses] Load Current EventWorkflowProcess Failure',

  LOAD_TEMPLATEID_AND_DATERANGE_EVENTWORKFLOWPROCESSES = '[EventWorkflowProcesses] Load ByTemplateId and DateRange EventWorkflowProcesses',
  LOAD_TEMPLATEID_AND_DATERANGE_EVENTWORKFLOWPROCESSES_SUCCESS = '[EventWorkflowProcesses] Load ByTemplateId and DateRange EventWorkflowProcesses Success',
  LOAD_TEMPLATEID_AND_DATERANGE_EVENTWORKFLOWPROCESSES_FAILURE = '[EventWorkflowProcesses] Load ByTemplateId and DateRange EventWorkflowProcesses Failure',

  CACHE_EVENTWORKFLOWPROCESSES = '[EventWorkflowProcesses] Cache EventWorkflowProcesses',
  CLEAR_CACHE_EVENTWORKFLOWPROCESSES = '[EventWorkflowProcesses] Clear Cache EventWorkflowProcesses',
  SET_CURRENT_PAGE = '[EventWorkflowProcesses] Set Current Page',
  SET_CURRENT_PAGE_SIZE = '[EventWorkflowProcesses] Set Current Page Size',
  LOAD_CURRENT_PAGE_EVENTWORKFLOWPROCESSES = '[EventWorkflowProcesses] Load Current Page EventWorkflowProcesses',
  LOAD_CURRENT_PAGE_EVENTWORKFLOWPROCESSES_SUCCESS = '[EventWorkflowProcesses] Load Current Page EventWorkflowProcesses Success',
  LOAD_CURRENT_PAGE_EVENTWORKFLOWPROCESSES_FAILURE = '[EventWorkflowProcesses] Load Current Page EventWorkflowProcesses Failure',
  LOAD_CURRENT_PAGE_SIZE_EVENTWORKFLOWPROCESSES = '[EventWorkflowProcesses] Load Current Page Size EventWorkflowProcesses',
  LOAD_CURRENT_PAGE_SIZE_EVENTWORKFLOWPROCESSES_SUCCESS = '[EventWorkflowProcesses] Load Current Page Size EventWorkflowProcesses Success',
  LOAD_CURRENT_PAGE_SIZE_EVENTWORKFLOWPROCESSES_FAILURE = '[EventWorkflowProcesses] Load Current Page Size EventWorkflowProcesses Failure',
}

////////////////////////////////////////////////////////////////////////////////
export const loadCurrentEventWorkflowProcess = createAction(
  EventWorkflowProcessesActionTypes.LOAD_CURRENT_EVENTWORKFLOWPROCESS,
  props<{ id: string }>(),
);

export const loadCurrentEventWorkflowProcessSuccess = createAction(
  EventWorkflowProcessesActionTypes.LOAD_CURRENT_EVENTWORKFLOWPROCESS_SUCCESS,
  props<{ eventWorkflowProcess: EventWorkflowProcess }>(),
);

export const loadCurrentEventWorkflowProcessFailure = createAction(
  EventWorkflowProcessesActionTypes.LOAD_CURRENT_EVENTWORKFLOWPROCESS_FAILURE,
  props<{ error: any }>(),
);

export const loadTemplateIdAndDateRangeEventWorkflowProcesses = createAction(
  EventWorkflowProcessesActionTypes.LOAD_TEMPLATEID_AND_DATERANGE_EVENTWORKFLOWPROCESSES,
  props<{ templateId: string; startDate: string; endDate: string; sortOrder: SortOrderOptions; status: string }>(),
);

export const loadTemplateIdAndDateRangeEventWorkflowProcessesFailure = createAction(
  EventWorkflowProcessesActionTypes.LOAD_TEMPLATEID_AND_DATERANGE_EVENTWORKFLOWPROCESSES_FAILURE,
  props<{ error: any }>(),
);

export const cacheEventWorkflowProcesses = createAction(
  EventWorkflowProcessesActionTypes.CACHE_EVENTWORKFLOWPROCESSES,
  props<{ processes: EventWorkflowProcess[]; nextPageKey: string; pageNum: number; pageSize: number }>(),
);

export const setCurrentPage = createAction(
  EventWorkflowProcessesActionTypes.SET_CURRENT_PAGE,
  props<{ pageNum: number }>(),
);

export const setCurrentPageSize = createAction(
  EventWorkflowProcessesActionTypes.SET_CURRENT_PAGE_SIZE,
  props<{ pageSize: number }>(),
);

export const loadCurrentPageEventWorkflowProcesses = createAction(
  EventWorkflowProcessesActionTypes.LOAD_CURRENT_PAGE_EVENTWORKFLOWPROCESSES,
  props<{ templateId: string; startDate: string; endDate: string; sortOrder: SortOrderOptions; status: string }>(),
);

export const loadCurrentPageEventWorkflowProcessesFailure = createAction(
  EventWorkflowProcessesActionTypes.LOAD_CURRENT_PAGE_EVENTWORKFLOWPROCESSES_FAILURE,
  props<{ error: any }>(),
);

export const loadCurrentPageSizeEventWorkflowProcesses = createAction(
  EventWorkflowProcessesActionTypes.LOAD_CURRENT_PAGE_SIZE_EVENTWORKFLOWPROCESSES,
  props<{
    pageSize: number;
    templateId: string;
    startDate: string;
    endDate: string;
    sortOrder: SortOrderOptions;
    status: string;
  }>(),
);

export const loadCurrentPageSizeEventWorkflowProcessesFailure = createAction(
  EventWorkflowProcessesActionTypes.LOAD_CURRENT_PAGE_SIZE_EVENTWORKFLOWPROCESSES_FAILURE,
  props<{ error: any }>(),
);

export const clearCacheEventWorkflowProcesses = createAction(
  EventWorkflowProcessesActionTypes.CLEAR_CACHE_EVENTWORKFLOWPROCESSES,
);
