import { AfterViewInit, Component, Output, ViewChild, EventEmitter } from '@angular/core';
import { TypeProperty } from '@contrail/types';
import { combineLatest, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { map, startWith } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { SearchBarComponent } from 'src/app/common/components/search-bar/search-bar.component';
import { TypeManagerSelectors } from '../../type-manager-store';
import { TypePropertiesService } from '../type-properties.service';

@Component({
  selector: 'app-type-property-chooser',
  templateUrl: './type-property-chooser.component.html',
  styleUrls: ['./type-property-chooser.component.scss'],
})
export class TypePropertyChooserComponent implements AfterViewInit {
  public filteredProperties$: Observable<Array<TypeProperty>>;
  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;
  @Output() propertySelected: EventEmitter<TypeProperty> = new EventEmitter();
  constructor(
    private typePropertyService: TypePropertiesService,
    private store: Store<RootStoreState.State>,
  ) {}

  ngAfterViewInit(): void {
    // this.store.dispatch(TypeManagerActions.loadTypeProperties());
    this.filteredProperties$ = combineLatest([
      this.searchBar.valueChange.pipe(startWith('')),
      this.store.select(TypeManagerSelectors.typeProperties),
    ]).pipe(
      map(([searchTerm, properties]) => {
        const keys = 'label,slug';
        return properties.filter((item) =>
          keys.split(',').some((key) => item.hasOwnProperty(key) && new RegExp(searchTerm, 'gi').test(item[key])),
        );
      }),
    );
  }

  handlePropertySelected(typeProperty: TypeProperty) {
    this.propertySelected.emit(typeProperty);
  }
}
