import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { RootStoreState } from 'src/app/root-store';
import { GroupsActions } from '.';
import { GroupsService } from './groups.service';

@Injectable()
export class GroupsEffects {
  constructor(
    private actions$: Actions,
    private groupsService: GroupsService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {}

  loadGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsActions.GroupsActionTypes.LOAD_GROUPS),
      withLatestFrom(this.store),
      switchMap(([]: [any, RootStoreState.State]) =>
        from(this.groupsService.getGroups()).pipe(
          map((data) => GroupsActions.loadGroupsSuccess({ data })),
          catchError((error) => observableOf(GroupsActions.loadGroupsFailure({ error }))),
        ),
      ),
    ),
  );

  loadCurrentGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsActions.GroupsActionTypes.LOAD_CURRENT_GROUP),
      switchMap((action: any) =>
        from(this.groupsService.getGroupById(action.id)).pipe(
          map((data) => {
            this.store.dispatch(GroupsActions.loadGroups());
            return GroupsActions.loadCurrentGroupSuccess({ group: data });
          }),
          catchError((error) => observableOf(GroupsActions.loadCurrentGroupFailure({ error }))),
        ),
      ),
    ),
  );

  createGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsActions.GroupsActionTypes.CREATE_GROUP),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) => {
        const body = {
          name: action.name,
        };
        return from(this.groupsService.createGroup(body)).pipe(
          map((data) => {
            this.snackBar.open('Group added.', '', { duration: 2000 });
            this.store.dispatch(GroupsActions.loadGroups());
            return GroupsActions.createGroupSuccess({ group: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(GroupsActions.createGroupFailure({ error }));
          }),
        );
      }),
    ),
  );

  deleteGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsActions.GroupsActionTypes.DELETE_GROUP),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.groupsService.deleteGroup(action.group)).pipe(
          map((data) => {
            this.snackBar.open('Group removed.', '', { duration: 2000 });
            return GroupsActions.deleteGroupSuccess({ group: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(GroupsActions.deleteGroupFailure({ error }));
          }),
        ),
      ),
    ),
  );
  deleteGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsActions.GroupsActionTypes.DELETE_GROUPS),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.groupsService.deleteGroups(action.ids)).pipe(
          map((data) => {
            this.snackBar.open(`${action.ids.length} Groups removed.`, '', { duration: 2000 });
            return GroupsActions.deleteGroupsSuccess({ ids: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(GroupsActions.deleteGroupsFailure({ error }));
          }),
        ),
      ),
    ),
  );

  updateGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsActions.GroupsActionTypes.UPDATE_GROUP),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.groupsService.updateGroup(action.id, action.changes)).pipe(
          map((data) => {
            this.snackBar.open('Group updated.', '🎉', { duration: 2000 });
            return GroupsActions.updateGroupSuccess({ id: data.id, changes: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(GroupsActions.updateGroupFailure({ error }));
          }),
        ),
      ),
    ),
  );
}
