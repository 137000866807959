<app-page-section *ngIf="enable">
  <app-page-section-header>
    <app-page-section-title title="Formatting"></app-page-section-title>
  </app-page-section-header>
  <app-page-section-body>
    <div>
      <mat-form-field class="mr-5" [appearance]="'outline'">
        <mat-label>Number Format</mat-label>
        <mat-select [formControl]="numberFormatControl">
          <mat-option *ngFor="let option of numberFormatOptions" [value]="option.slug">{{ option.label }}</mat-option>
        </mat-select>
        <!-- <mat-hint>Which level of the hieararchy does this property apply?</mat-hint> -->
      </mat-form-field>
      <mat-form-field [appearance]="'outline'">
        <mat-label>Decimal Places</mat-label>
        <mat-select [formControl]="decimalPlacesFormatControl">
          <mat-option *ngFor="let option of decimalPlacesOptions" [value]="option.slug">{{ option.label }}</mat-option>
        </mat-select>
        <!-- <mat-hint>Which level of the hieararchy does this property apply?</mat-hint> -->
      </mat-form-field>
    </div>
    <div *ngIf="numberFormatControl.value === 'currency'">
      <mat-form-field [appearance]="'outline'">
        <mat-label>Currency</mat-label>
        <mat-select [formControl]="currencyFormatControl">
          <mat-option *ngFor="let option of currencyOptions" [value]="option">{{ option }}</mat-option>
        </mat-select>
        <!-- <mat-hint>Which level of the hieararchy does this property apply?</mat-hint> -->
      </mat-form-field>
    </div>
  </app-page-section-body>
</app-page-section>
