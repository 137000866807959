import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { EntitiesComponent } from './entities.component';
import { ViewableComponent } from './viewable/viewable.component';
import { ContentManagedComponent } from './content-managed/content-managed.component';
import { ComponentsModule } from '../common/components/components.module';
import { MetaComponent } from './meta/meta.component';
import { EntityDetailsComponent } from './entity-details/entity-details.component';
import { CreateEntityComponent } from './create-entity/create-entity.component';
import { EntitiesSearchComponent } from './entities-search/entities-search.component';
import { EntitiesRoutingModule } from './entities-routing.module';
import { EntitityPropertiesComponent } from './entitity-properties/entitity-properties.component';
import { TypesModule } from '../common/types/types.module';

@NgModule({
  declarations: [
    EntitiesComponent,
    ViewableComponent,
    ContentManagedComponent,
    MetaComponent,
    EntityDetailsComponent,
    CreateEntityComponent,
    EntitiesSearchComponent,
    EntitityPropertiesComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    EntitiesRoutingModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    ComponentsModule,
    TypesModule,
  ],
  exports: [CreateEntityComponent],
})
export class EntitiesModule {}
