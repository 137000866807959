import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerActions } from '..';
import { TypePropertyOptionSetsService } from '../../type-property-option-sets/type-property-option-sets.service';
import { TypesService } from '../../types.service';

@Injectable()
export class TypePropertyOptionSetsEffects {
  constructor(
    private actions$: Actions,
    private typePropertyOptionSetService: TypePropertyOptionSetsService,
    public typesService: TypesService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {}

  loadTypePropertyOptionSets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.TypePropertyOptionSetsActionTypes.LOAD_TYPE_PROPERTY_OPTION_SETS),
      withLatestFrom(this.store),
      switchMap(([]: [any, RootStoreState.State]) =>
        from(this.typePropertyOptionSetService.getOptionSets()).pipe(
          map((data) => TypeManagerActions.loadTypePropertyOptionSetsSuccess({ data })),
          catchError((error) => observableOf(TypeManagerActions.loadTypePropertyOptionSetsFailure({ error }))),
        ),
      ),
    ),
  );
  createTypePropertyOptionSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.TypePropertyOptionSetsActionTypes.CREATE_TYPE_PROPERTY_OPTION_SET),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.typePropertyOptionSetService.createOptionSet(action.typePropertyOptionSet)).pipe(
          map((data) => {
            this.snackBar.open('Option Set Created.', '', { duration: 2000 });
            return TypeManagerActions.createTypePropertyOptionSetSuccess({ typePropertyOptionSet: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(TypeManagerActions.createTypePropertyOptionSetFailure({ error }));
          }),
        ),
      ),
    ),
  );
  deleteTypePropertyOptionSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.TypePropertyOptionSetsActionTypes.DELETE_TYPE_PROPERTY_OPTION_SET),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.typePropertyOptionSetService.deleteOptionSet(action.typePropertyOptionSet)).pipe(
          map((data) => {
            this.snackBar.open('Option Set Deleted.', '', { duration: 2000 });
            return TypeManagerActions.deleteTypePropertyOptionSetSuccess({ typePropertyOptionSet: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(TypeManagerActions.deleteTypePropertyOptionSetFailure({ error }));
          }),
        ),
      ),
    ),
  );
  updateTypePropertyOptionSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.TypePropertyOptionSetsActionTypes.UPDATE_TYPE_PROPERTY_OPTION_SET),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.typePropertyOptionSetService.updateOptionSet(action.id, action.changes)).pipe(
          map((data) => {
            this.snackBar.open('Option Set Updated.', '', { duration: 2000 });
            return TypeManagerActions.updateTypePropertyOptionSetSuccess({ id: data.id, changes: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(TypeManagerActions.updateTypePropertyOptionSetFailure({ error }));
          }),
        ),
      ),
    ),
  );
}
