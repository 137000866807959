import { createAction, props } from '@ngrx/store';
import { EventWorkflowTask } from './event-task';

export enum EventWorkflowTasksActionTypes {
  LOAD_CURRENT_EVENT_WORKFLOW_TASK = '[EventWorkflowTasks] Load Current EventWorkflowTask',
  LOAD_CURRENT_EVENT_WORKFLOW_TASK_SUCCESS = '[EventWorkflowTasks] Load Current EventWorkflowTask Success',
  LOAD_CURRENT_EVENT_WORKFLOW_TASK_FAILURE = '[EventWorkflowTasks] Load Current EventWorkflowTask Failure',

  LOAD_PROCESSID_EVENTWORKFLOWTASKS = '[EventWorkflowTasks] Load ByProcessId EventWorkflowTasks',
  LOAD_PROCESSID_EVENTWORKFLOWTASKS_SUCCESS = '[EventWorkflowTasks] Load ByProcessId EventWorkflowTasks Success',
  LOAD_PROCESSID_EVENTWORKFLOWTASKS_FAILURE = '[EventWorkflowTasks] Load ByProcessId EventWorkflowTasks Failure',

  LOAD_PROCESS_ACTION_ID_EVENTWORKFLOWTASKS = '[EventWorkflowTasks] Load ByProcess_ActionId EventWorkflowTasks',
  LOAD_PROCESS_ACTION_ID_EVENTWORKFLOWTASKS_SUCCESS = '[EventWorkflowTasks] Load ByProcess_ActionId EventWorkflowTasks Success',
  LOAD_PROCESS_ACTION_ID_EVENTWORKFLOWTASKS_FAILURE = '[EventWorkflowTasks] Load ByProcess_ActionId EventWorkflowTasks Failure',

  LOAD_PROCESSID_EVENTWORKFLOWTASK_AND_ACTION_DEFINITION = '[EventWorkflowTasks] Load Process Id EventWorkflowTask And Action Definition',
  LOAD_PROCESSID_EVENTWORKFLOWTASK_AND_ACTION_DEFINITION_SUCCESS = '[EventWorkflowTasks] Load Process Id EventWorkflowTask And Action Definition Success',
  LOAD_PROCESSID_EVENTWORKFLOWTASK_AND_ACTION_DEFINITION_FAILURE = '[EventWorkflowTasks] Load Process Id EventWorkflowTask And Action Definition Failure',
}

/// /////////////////////////////////////////////////////////////////////////////

export const loadCurrentEventWorkflowTask = createAction(
  EventWorkflowTasksActionTypes.LOAD_CURRENT_EVENT_WORKFLOW_TASK,
  props<{ id: string }>(),
);

export const loadCurrentEventWorkflowTaskSuccess = createAction(
  EventWorkflowTasksActionTypes.LOAD_CURRENT_EVENT_WORKFLOW_TASK_SUCCESS,
  props<{ eventWorkflowTask: EventWorkflowTask }>(),
);

export const loadCurrentEventWorkflowTaskFailure = createAction(
  EventWorkflowTasksActionTypes.LOAD_CURRENT_EVENT_WORKFLOW_TASK_FAILURE,
  props<{ error: any }>(),
);

export const loadProcessIdEventWorkflowTasks = createAction(
  EventWorkflowTasksActionTypes.LOAD_PROCESSID_EVENTWORKFLOWTASKS,
  props<{ processId: string }>(),
);

export const loadProcessIdEventWorkflowTasksSuccess = createAction(
  EventWorkflowTasksActionTypes.LOAD_PROCESSID_EVENTWORKFLOWTASKS_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const loadProcessIdEventWorkflowTasksFailure = createAction(
  EventWorkflowTasksActionTypes.LOAD_PROCESSID_EVENTWORKFLOWTASKS_FAILURE,
  props<{ error: any }>(),
);

export const loadProcessActionIdEventWorkflowTasks = createAction(
  EventWorkflowTasksActionTypes.LOAD_PROCESS_ACTION_ID_EVENTWORKFLOWTASKS,
  props<{ processId: string; actionId: string }>(),
);

export const loadProcessActionIdEventWorkflowTasksSuccess = createAction(
  EventWorkflowTasksActionTypes.LOAD_PROCESS_ACTION_ID_EVENTWORKFLOWTASKS_SUCCESS,
  props<{ data: any[] }>(),
);

export const loadProcessActionIdEventWorkflowTasksFailure = createAction(
  EventWorkflowTasksActionTypes.LOAD_PROCESS_ACTION_ID_EVENTWORKFLOWTASKS_FAILURE,
  props<{ error: any }>(),
);

export const loadProcessIdEventWorkflowTaskAndActionDefinition = createAction(
  EventWorkflowTasksActionTypes.LOAD_PROCESSID_EVENTWORKFLOWTASK_AND_ACTION_DEFINITION,
  props<{ id: string }>(),
);

export const loadProcessIdEventWorkflowTaskAndActionDefinitionSuccess = createAction(
  EventWorkflowTasksActionTypes.LOAD_PROCESSID_EVENTWORKFLOWTASK_AND_ACTION_DEFINITION_SUCCESS,
  props<{ data: any[] }>(),
);

export const loadProcessIdEventWorkflowTaskAndActionDefinitionFailure = createAction(
  EventWorkflowTasksActionTypes.LOAD_PROCESSID_EVENTWORKFLOWTASK_AND_ACTION_DEFINITION_FAILURE,
  props<{ error: any }>(),
);
