import { createAction, props } from '@ngrx/store';
import { OrgConfig } from './org-config';

export enum OrgConfigActionTypes {
  LOAD_ORG_CONFIG = '[OrgConfigs] Load OrgConfig',
  LOAD_ORG_CONFIG_SUCCESS = '[OrgConfigs] Load OrgConfig Success',
  LOAD_ORG_CONFIG_FAILURE = '[OrgConfigs] Load OrgConfig Failure',

  UPDATE_ORG_CONFIG = '[OrgConfigs] Update OrgConfig',
  UPDATE_ORG_CONFIG_SUCCESS = '[OrgConfigs] Update OrgConfig Success',
  UPDATE_ORG_CONFIG_FAILURE = '[OrgConfigs] Update OrgConfig Failure',
}

/// /////////////////////////////////////////////////////////////////////////////

export const loadOrgConfig = createAction(OrgConfigActionTypes.LOAD_ORG_CONFIG);

export const loadOrgConfigSuccess = createAction(OrgConfigActionTypes.LOAD_ORG_CONFIG_SUCCESS, props<{ data: any }>());

export const loadOrgConfigFailure = createAction(OrgConfigActionTypes.LOAD_ORG_CONFIG_FAILURE, props<{ error: any }>());

export const updateOrgConfig = createAction(
  OrgConfigActionTypes.UPDATE_ORG_CONFIG,
  props<{ id: string; changes: OrgConfig }>(),
);

export const updateOrgConfigSuccess = createAction(
  OrgConfigActionTypes.UPDATE_ORG_CONFIG_SUCCESS,
  props<{ id: string; changes: OrgConfig }>(),
);

export const updateOrgConfigFailure = createAction(
  OrgConfigActionTypes.UPDATE_ORG_CONFIG_FAILURE,
  props<{ error: any }>(),
);
