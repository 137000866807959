import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TypeProperty } from '@contrail/types';

@Component({
  selector: 'app-type-property-constraints',
  templateUrl: './type-property-constraints.component.html',
  styleUrls: ['./type-property-constraints.component.scss'],
})
export class TypePropertyConstraintsComponent implements OnChanges {
  @Input() typeProperty: TypeProperty;
  enabled = false;
  public validationFunctionControl: UntypedFormControl = new UntypedFormControl();
  @Output() changes = new EventEmitter();
  public editorOptions: any = {
    theme: 'vs',
    language: 'javascript',
    minimap: {
      enabled: false,
    },
    lineNumbers: 'off',
    automaticLayout: true,
  };
  constructor() {}

  ngOnChanges(): void {
    if (!this.typeProperty) {
      return;
    }
    this.enableContraints();
    this.validationFunctionControl.setValue(this.typeProperty.validationFunction);
  }
  enableContraints() {
    this.enabled = true;
  }

  saveValidationFunction() {
    if (!this.validationFunctionControl.valid) {
      return;
    }
    const newValue = this.validationFunctionControl.value;
    const valueChanges = {
      validationFunction: newValue,
    };
    this.changes.emit(valueChanges);
  }
}
