import { Action, createReducer, on } from '@ngrx/store';
import { State, appVersionsEntityAdapter, appVersionsInitialState } from './app-versions.state';
import * as AppVersionsActions from './app-versions.actions';

const setData = (state: State, { appVersions }) => ({
  ...state,
  appVersions: appVersionsEntityAdapter.setAll(appVersions, state.appVersions),
});

export const appVersionsReducers = createReducer(
  appVersionsInitialState,
  on(AppVersionsActions.loadCurrentAppVersionsSuccess, setData),
);

/**
 *
 * @param state
 * @param action
 */
export function reducer(state: State | undefined, action: Action): any {
  return appVersionsReducers(state, action);
}
