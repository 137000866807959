import { on } from '@ngrx/store';
import { State } from '../type-manager.state';
import * as TypePropertyPoliciesAction from './type-property-policies.actions';
import { typePropertyPoliciesEntityAdapter } from './type-property-policies.state';

const setData = (state: State, { data }) => {
  console.log('setData: ', data);
  return {
    ...state,
    typePropertyPolicies: typePropertyPoliciesEntityAdapter.setAll(data, state.typePropertyPolicies),
  };
};
const add = (state: State, { typePropertyPolicy }) => ({
  ...state,
  typePropertyPolicies: typePropertyPoliciesEntityAdapter.addOne(typePropertyPolicy, state.typePropertyPolicies),
});
const update = (state: State, { id, changes }) => ({
  ...state,
  typePropertyPolicies: typePropertyPoliciesEntityAdapter.updateOne({ id, changes }, state.typePropertyPolicies),
});
const remove = (state: State, { typePropertyPolicy }) => ({
  ...state,
  typePropertyPolicies: typePropertyPoliciesEntityAdapter.removeOne(typePropertyPolicy.id, state.typePropertyPolicies),
});

export const typePropertyPoliciesReducer = [
  on(TypePropertyPoliciesAction.loadTypePropertyPoliciesSuccess, setData),
  on(TypePropertyPoliciesAction.createTypePropertyPolicySuccess, add),
  on(TypePropertyPoliciesAction.deleteTypePropertyPolicySuccess, remove),
  on(TypePropertyPoliciesAction.updateTypePropertyPolicy, update),
];
