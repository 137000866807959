import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { OrgConfigActions } from '.';
import { OrgConfigService } from './org-config.service';

@Injectable()
export class OrgConfigEffects {
  constructor(
    private actions$: Actions,
    private orgConfigService: OrgConfigService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {}

  loadOrgConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgConfigActions.OrgConfigActionTypes.LOAD_ORG_CONFIG),
      withLatestFrom(this.store),
      switchMap(([]: [any, RootStoreState.State]) =>
        from(this.orgConfigService.getOrgConfigs()).pipe(
          map((data) => {
            if (!data || data.length !== 1) {
              throw new Error('Could not load Org Config');
            }

            return OrgConfigActions.loadOrgConfigSuccess({ data: data[0] });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(OrgConfigActions.loadOrgConfigFailure({ error }));
          }),
        ),
      ),
    ),
  );

  updateOrgConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgConfigActions.OrgConfigActionTypes.UPDATE_ORG_CONFIG),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.orgConfigService.updateOrgConfig(action.id, action.changes)).pipe(
          map((data) => {
            this.snackBar.open('Org Config updated.', '', { duration: 2000 });
            return OrgConfigActions.updateOrgConfigSuccess({ id: data.id, changes: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(OrgConfigActions.updateOrgConfigFailure({ error }));
          }),
        ),
      ),
    ),
  );
}
