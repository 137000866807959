<app-page-section>
  <app-page-section-header>
    <app-page-section-title title="Access Control"></app-page-section-title>
  </app-page-section-header>
  <app-page-section-body>
    <div class="mb-3">
      <mat-slide-toggle [formControl]="editableFormControl">Allow editing of this property.</mat-slide-toggle>
    </div>

    <div class="font-bold mb-2">Policies</div>
    <div class="mb-2">Assign property policies to control which users groups can see or edit this property.</div>
    <div class="mb-2">
      <mat-chip class="mr-2" *ngFor="let link of assignedPolicyLinks" color="primary">
        {{ link.typePropertyPolicy.label }}
        <mat-icon class="cursor-pointer" (click)="removePolicyFromProperty(link)">close</mat-icon>
      </mat-chip>
    </div>
    <mat-form-field class="example-full-width">
      <mat-label>Add Policy</mat-label>
      <input
        type="text"
        placeholder="Pick one"
        aria-label="Policy"
        matInput
        [formControl]="addPolicyFormControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let policy of typePropertyPolicies$ | async" [value]="policy">
          {{ policy.label }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </app-page-section-body>
</app-page-section>
