<div class="main">
  <div class="body">
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput [formControl]="nameField" />
    </mat-form-field>
  </div>
  <div class="font-bold mb-3">Option Set Hierarchy</div>
  <div class="flex my-3">
    <mat-chip-list>
      <div *ngFor="let optionSet of selectedOptionSets; let last = last">
        <mat-chip color="primary">
          {{ optionSet.name }}
          <mat-icon class="cursor-pointer" (click)="removeOptionSet(optionSet)">close</mat-icon>
        </mat-chip>
        <span *ngIf="!last" class="mx-2">\</span>
      </div>
    </mat-chip-list>
  </div>
  <div class="options-dropdown">
    <mat-form-field class="example-full-width mb-3" appearance="fill">
      <mat-label>Add Option Set</mat-label>
      <input
        type="text"
        placeholder="Pick one"
        aria-label="Option Set"
        matInput
        [formControl]="addOptionSetFormControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let optionSet of availableOptionSets" [value]="optionSet">
          {{ optionSet.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div class="actions">
    <button mat-flat-button color="cancel" (click)="cancel()">Cancel</button>
    <button mat-flat-button color="primary" [disabled]="!isValid()" (click)="completeEdit()">Add</button>
  </div>
</div>
