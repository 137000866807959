import { Statement, Operation } from '@contrail/policies';

export enum CONFIG_VIEW {
  JSON,
  UI,
}

export enum PRINCIPAL_TYPE {
  USER,
  GROUP,
  EVERYONE,
}

export interface Principal {
  id: string;
  name: string;
  type: PRINCIPAL_TYPE;
  email: string;
  reference: string;
}

export interface OperationAction {
  name: string;
  checked: boolean;
}

export interface PolicyStatement extends Statement {
  id: string;
}

export const POLICY_DETAIL_ACTION = [Operation.read, Operation.create, Operation.update, Operation.delete];

export const getUniqueId = () => {
  return Math.random().toString(36);
};
