import { Component, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { StringUtil } from '@contrail/util';
import { ConfirmationBoxService } from 'src/app/common/components/confirmation-box/confirmation-box';
import { MenuComponent } from 'src/app/common/components/menu/menu.component';
import { UpdateDialogService } from '@components/update-dialog/update-dialog-service';
import { RoutingService } from 'src/app/common/routing/routing.service';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerActions, TypeManagerSelectors } from '../type-manager-store';
import { TypePropertyOptionSet } from '../type-manager-store/option-sets/option-sets.state';
@Component({
  selector: 'app-type-property-option-sets',
  templateUrl: './type-property-option-sets.component.html',
  styleUrls: ['./type-property-option-sets.component.scss'],
})
export class TypePropertyOptionSetsComponent {
  searchKey = '';
  public optionSets$: Observable<Array<TypePropertyOptionSet>>;
  constructor(
    public store: Store<RootStoreState.State>,
    private routingService: RoutingService,
    private UpdateDialogService: UpdateDialogService,
    private confirmationBoxService: ConfirmationBoxService,
  ) {
    this.optionSets$ = this.store.select(TypeManagerSelectors.typePropertyOptionSets);
  }
  @ViewChild(MenuComponent) addOptionSetMenu: MenuComponent;

  cancelAdd() {
    this.addOptionSetMenu.panelVisible = false;
  }
  completeAdd(data: any) {
    console.log('complete add: ', data);
    this.addOptionSetMenu.panelVisible = false;

    const slug = StringUtil.convertToCamelCase(data.name);
    this.store.dispatch(
      TypeManagerActions.createTypePropertyOptionSet({ typePropertyOptionSet: { name: data.name, slug } }),
    );
  }
  selectOptionSet(optionSet: TypePropertyOptionSet) {
    this.routingService.go('/type-manager/option-sets/' + optionSet.id);
  }
  async deleteOptionSet(optionSet: TypePropertyOptionSet) {
    const confirm = await this.confirmationBoxService.open(
      'Delete Option Set?',
      'Are you sure you want to delete this option set? Doing so will not delete any data, but data will no longer be accessible via user interfaces and APIs.',
    );
    if (confirm) {
      this.store.dispatch(TypeManagerActions.deleteTypePropertyOptionSet({ typePropertyOptionSet: optionSet }));
      this.routingService.go('/type-manager/option-sets/');
    }
  }

  async editOptionSet(optionSet: TypePropertyOptionSet) {
    const updatedName = await this.UpdateDialogService.openDialog('Rename Option Set', optionSet.name);
    if (updatedName) {
      const id = optionSet.id;
      if (optionSet.name === updatedName) {
        return;
      }
      this.store.dispatch(TypeManagerActions.updateTypePropertyOptionSet({ id, changes: { name: updatedName } }));
    }
  }
}
