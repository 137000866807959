import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthSelectors } from 'src/app/common/auth/auth-store';
import { AuthContext, AuthService } from 'src/app/common/auth/auth.service';
import { RootStoreState } from 'src/app/root-store';

@Component({
  selector: 'app-header-user-menu',
  templateUrl: './header-user-menu.component.html',
  styleUrls: ['./header-user-menu.component.scss'],
})
export class HeaderUserMenuComponent implements OnInit {
  authContext$: Observable<AuthContext>;

  constructor(
    private store: Store<RootStoreState.State>,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.authContext$ = this.store.select(AuthSelectors.selectAuthContext);
  }
  logout() {
    this.authService.logout();
  }
}
