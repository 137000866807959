import { Subject } from 'rxjs';
import { CONFIG_VIEW } from '../loader-helper';
import { Injectable } from '@angular/core';
import { LoaderConfig } from '../loader-interface';

@Injectable({
  providedIn: 'root',
})
export class LoaderConfigService {
  private toggleConfigView = new Subject<CONFIG_VIEW>();
  private onSave = new Subject<boolean>();
  private loaderUpdated = new Subject<LoaderConfig>();

  constructor() {}

  toggleConfigVisibility(view: CONFIG_VIEW): void {
    this.toggleConfigView.next(view);
  }

  getConfigVisibility(): Subject<CONFIG_VIEW> {
    return this.toggleConfigView;
  }

  triggerSave() {
    this.onSave.next(true);
  }

  getOnSave() {
    return this.onSave;
  }

  updatedLoader(loader: LoaderConfig) {
    this.loaderUpdated.next(loader);
  }

  getUpdatedLoader() {
    return this.loaderUpdated;
  }
}
