<div class="text-center flex flex-col gap-4 items-center">
  <mat-card class="w-64 h-80 self-center">
    <mat-calendar
      [minDate]="minDate"
      [maxDate]="maxDate"
      [(selected)]="selectedDateRange"
      (selectedChange)="rangeChanged($event)"
    ></mat-calendar>
  </mat-card>
  <div class="flex gap-4 justify-between">
    <div class="flex flex-col gap-2">
      <div class="flex gap-3 text-left">
        <div class="relative inline-flex flex-col items-center w-24 h-full">
          <div class="absolute top-1 left-0 w-full font-bold">Start</div>
          <div class="absolute top-1/2 left-0 transform -translate-y-1/2">
            {{ newStartDate | date: 'MMM d, y' }}
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <div class="w-28">
            <ngx-mat-timepicker
              class="m-0"
              [(ngModel)]="startDateTimePicker"
              (ngModelChange)="updateDate()"
              [showSeconds]="true"
              [color]="color"
            >
            </ngx-mat-timepicker>
          </div>
        </div>
      </div>
      <div class="flex gap-2"></div>
      <div class="flex-grow text-left">GMT: {{ newStartDate | date: 'MMM d, y HH:mm:ss' : 'GMT' }}</div>
    </div>
    <div class="flex flex-col gap-2">
      <div class="flex gap-2 text-left">
        <div class="relative inline-flex flex-col items-center w-24 h-full">
          <div class="absolute top-1 left-0 w-full font-bold">End</div>
          <div class="absolute top-1/2 left-0 transform -translate-y-1/2">
            {{ newEndDate | date: 'MMM d, y' }}
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <div class="time-picker">
            <ngx-mat-timepicker
              class="m-0"
              [(ngModel)]="endDateTimePicker"
              (ngModelChange)="updateDate()"
              [showSeconds]="true"
              [color]="color"
            >
            </ngx-mat-timepicker>
          </div>
        </div>
      </div>
      <div class="flex gap-2"></div>
      <div class="flex-grow text-left">GMT: {{ newEndDate | date: 'MMM d, y, HH:mm:ss' : 'GMT' }}</div>
    </div>
  </div>
  <div class="flex align-center w-full pt-4">
    <div
      class="text-red-400 justify-self-start w-full text-left"
      [class.invisible]="!isApplyButtonDisabled"
      [attr.aria-hidden]="!isApplyButtonDisabled"
    >
      End must be equal to or after the start.
    </div>
    <div class="flex justify-end w-full justify-self-end">
      <button mat-raised-button color="secondary" (click)="cancel()" data-test="cancel-btn">Cancel</button>&nbsp;&nbsp;
      <button
        mat-raised-button
        [disabled]="isApplyButtonDisabled"
        color="primary"
        (click)="apply()"
        data-test="apply-btn"
      >
        Apply
      </button>
    </div>
  </div>
</div>
