import { createReducer, on } from '@ngrx/store';
import { State, loaderHistoryEntityAdapter, loaderHistoryInitialState } from './loader-history.state';
import * as LoaderHistoryActions from './loader-history.actions';

const setData = (state: State, { data }) => ({
  ...state,
  loading: false,
  hasError: false,
  loaderHistory: loaderHistoryEntityAdapter.setAll(data.results, state.loaderHistory),
  nextPageKey: data.nextPageKey,
  processingStepLoading: false,
});

const startDownloadingLogFile = (state: State) => {
  return {
    ...state,
    loading: false,
    hasError: false,
    processingStepLoading: false,
  };
};

const setLogFileData = (state: State, data: { logText: string }) => ({
  ...state,
  loading: false,
  logs: data.logText,
  hasError: false,
  processingStepLoading: false,
});

const setError = (state: State) => ({
  ...state,
  loading: false,
  hasError: true,
  processingStepLoading: false,
});

const setCurrent = (state: State, { data }) => ({
  ...state,
  currentLoaderHistory: data,
  currentLoaderHistoryDetail: null,
  loading: false,
  hasError: false,
  processingStepLoading: false,
});

const setCurrentDetail = (state: State, { data }) => ({
  ...state,
  currentLoaderHistoryDetail: data,
  loading: false,
  hasError: false,
  processingStepLoading: false,
});

const setLoading = (state: State) => ({
  ...state,
  loading: true,
  hasError: false,
  processingStepLoading: false,
});

const startLoading = (state: State) => {
  const updatedState = setLoading(state);
  return {
    ...state,
    ...updatedState,
    currentLoaderHistory: null,
    processingStepLoading: false,
  };
};

const setDate = (state: State, { startDate, endDate, hardRefresh }) => {
  const updatedState = startLoading(state);
  if (state.startDate === startDate && state.endDate === endDate && !hardRefresh) {
    return {
      ...state,
      ...updatedState,
      startDate,
      endDate,
      loading: false,
      processingStepLoading: false,
      hasError: false,
    };
  } else {
    return {
      ...state,
      ...updatedState,
      loaderHistory: loaderHistoryEntityAdapter.setAll([], state.loaderHistory),
      startDate,
      endDate,
      processingStepLoading: false,
      hasError: false,
    };
  }
};

const setProcessingStepLoading = (state: State) => {
  return {
    ...state,
    processingStepLoading: true,
    loading: false,
    hasError: false,
  };
};

const setPreprocessingFileDetail = (state: State, { data }) => {
  return {
    ...state,
    preprocessingStep: data,
    processingStepLoading: false,
    loading: false,
    hasError: false,
  };
};

export const loaderHistoryReducers = createReducer(
  loaderHistoryInitialState,
  on(LoaderHistoryActions.loadLoaderHistoryActionSuccess, setData),
  on(LoaderHistoryActions.loadLoaderHistoryAction, setDate),
  on(LoaderHistoryActions.loadLoaderHistoryActionFailure, setError),
  on(LoaderHistoryActions.loadS3LogsAction, startDownloadingLogFile),
  on(LoaderHistoryActions.loadS3LogsActionSuccess, setLogFileData),
  on(LoaderHistoryActions.loadCurrentLoaderHistoryAction, startLoading),
  on(LoaderHistoryActions.loadCurrentLoaderHistoryActionSuccess, setCurrent),
  on(LoaderHistoryActions.loadCurrentLoaderHistoryActionFailure, setError),
  on(LoaderHistoryActions.loadCurrentLoaderHistoryDetailAction, setLoading),
  on(LoaderHistoryActions.loadCurrentLoaderHistoryDetailActionSuccess, setCurrentDetail),
  on(LoaderHistoryActions.loadCurrentLoaderHistoryDetailActionFailure, setError),
  on(LoaderHistoryActions.loadPreprocessingPipelineDetailAction, setProcessingStepLoading),
  on(LoaderHistoryActions.loadPreprocessingFileDetailActionSuccess, setPreprocessingFileDetail),
);
