import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { TypeManagerActions } from '..';
import { TypeRootsService } from '../../type-roots.service';

@Injectable()
export class TypeRootsEffects {
  constructor(
    private actions$: Actions,
    private typeRootsService: TypeRootsService,
  ) {}

  loadTypeRoots$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.TypeRootsActionTypes.LOAD_TYPE_ROOTS),
      switchMap(() =>
        from(this.typeRootsService.getTypeRoots()).pipe(
          map((data) => TypeManagerActions.loadTypeRootsSuccess({ data })),
          catchError((error) => observableOf(TypeManagerActions.loadTypeRootsFailure({ error }))),
        ),
      ),
    ),
  );
}
