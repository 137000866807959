import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PropertyType, Type, TypeProperty } from '@contrail/types';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-type-property-federation',
  templateUrl: './type-property-federation.component.html',
  styleUrls: ['./type-property-federation.component.scss'],
})
export class TypePropertyFederationComponent implements OnChanges, OnInit, OnDestroy {
  @Input() typeProperty: TypeProperty;
  @Input() type: Type;
  @Output() changes = new EventEmitter();
  public visible = false;
  public isFederationPropertyFormControl = new UntypedFormControl();
  private destroy$ = new Subject();

  constructor() {}

  ngOnInit() {
    this.isFederationPropertyFormControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value !== this.typeProperty.isFederationProperty) {
        this.changes.emit({ isFederationProperty: value });
      }
    });
  }

  ngOnChanges(): void {
    if (!this.typeProperty && this.type) {
      return;
    }
    this.visible = false;
    const isCustomEntity = this.type.typePath.includes('custom-entity');
    if (isCustomEntity) {
      if (
        [PropertyType.Text, PropertyType.String, PropertyType.Number, PropertyType.Sequence].includes(
          this.typeProperty.propertyType,
        )
      ) {
        this.visible = true;
      }
    }
    this.isFederationPropertyFormControl.setValue(this.typeProperty.isFederationProperty);
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
