import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface LibraryEntity {
  id?: string;
  name?: string;
  [key: string]: any;
}

export interface LibraryState extends EntityState<LibraryEntity> {}

export interface State {
  libraryEntities: LibraryState;
}

export const libraryEntityAdapter: EntityAdapter<LibraryEntity> = createEntityAdapter<LibraryEntity>({});

export const libraryInitialState = {
  libraryEntities: libraryEntityAdapter.getInitialState({}),
};
