import { Component, EventEmitter, Input, Output, OnDestroy, OnInit } from '@angular/core';
import { TypePolicy } from '../../type-manager-store/type-policies/type-policies.state';
import { Router } from '@angular/router';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { TypeManagerActions, TypeManagerSelectors } from '../../type-manager-store';
import { Subject, takeUntil } from 'rxjs';
import { TypeService } from '../../type.service';

@Component({
  selector: 'app-type-policy-list',
  templateUrl: './type-policy-list.component.html',
  styleUrls: ['./type-policy-list.component.scss'],
})
export class TypePolicyListComponent implements OnInit, OnDestroy {
  @Input() policies: Array<TypePolicy>;
  @Input() selectable: boolean;
  @Input() showActions = true;
  @Output() clicked: EventEmitter<TypePolicy> = new EventEmitter();
  @Output() deleted: EventEmitter<TypePolicy> = new EventEmitter();
  @Output() edit: EventEmitter<TypePolicy> = new EventEmitter();

  public isLoading: boolean = true;
  public selectedTypePolicy: string;

  private destroy$ = new Subject<void>();
  public isDetailViewOpen: boolean = false;

  constructor(
    private router: Router,
    private store: Store<RootStoreState.State>,
    private typeService: TypeService,
  ) {
    this.store
      .select(TypeManagerSelectors.loading)
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoading) => {
        this.isLoading = isLoading;
      });
  }

  ngOnInit(): void {
    const url = this.router.url;
    const urlParams = url.split('/');
    const id = urlParams.pop();
    const isValidId = id !== 'type-policies';
    if (isValidId) {
      // getting current loaded config id from URL
      // this is generally the case if page is refresehed
      this.selectedTypePolicy = id;
    }

    this.typeService
      .getDetailView()
      .pipe(takeUntil(this.destroy$))
      .subscribe((open) => {
        this.isDetailViewOpen = open;
        console.log('isDetailViewOpen', open);
      });
  }

  ngOnDestroy() {
    this.store.dispatch(TypeManagerActions.loading({ loading: true }));
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  handleClick(policy: TypePolicy) {
    this.selectedTypePolicy = policy?.id;
    this.clicked.emit(policy);
  }
  handleDelete($event: MouseEvent, policy: TypePolicy) {
    $event.stopPropagation();
    this.deleted.emit(policy);
  }
  handleEdit($event: MouseEvent, policy: TypePolicy) {
    $event.stopPropagation();
    this.edit.emit(policy);
  }
}
