import { createSelector } from '@ngrx/store';
import { TypePropertyUserList, typePropertyUserListsEntityAdapter } from './user-lists.state';

export const { selectAll, selectEntities, selectIds, selectTotal } = typePropertyUserListsEntityAdapter.getSelectors();

const typePropertyUserListsEntities = (state: any) => state.typeManager.typePropertyUserLists.entities;
export const typePropertyUserLists = createSelector(
  typePropertyUserListsEntities,
  (state) => Object.values(state) as Array<TypePropertyUserList>,
);
