import { Component } from '@angular/core';
import { TypePropertyFormFieldBaseComponent } from './type-property-form-field-base.component';

@Component({
  selector: 'app-type-property-form-field-percent',
  template: `
    <mat-form-field
      *ngIf="formControl"
      [attr.data-test]="propertyFormConfiguration.typeProperty.slug + '-form-field'"
      [appearance]="appearance"
    >
      <mat-label>{{ propertyFormConfiguration.typeProperty.label }}</mat-label>
      <input
        matInput
        [(ngModel)]="displayedValue"
        (focusout)="updateFormControlValue()"
        type="number"
        [disabled]="formControl.disabled"
        appLimitFractionDigits
        [maximumFractionDigits]="precision"
      />
      <mat-error *ngIf="formControl.hasError('error')">{{ formControl.getError('error') }}</mat-error>
      <app-type-property-form-validation-error
        *ngIf="formControl.hasError('error') && formControl.disabled"
        [errorMessage]="formControl.getError('error')"
      >
      </app-type-property-form-validation-error>
    </mat-form-field>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
      input {
        border: none;
        font-size: 12px;
        padding: 0px;
        width: 95%;
      }
      mat-form-field {
        width: 100%;
      }
    `,
  ],
})
export class TypePropertyFormFieldPercentComponent extends TypePropertyFormFieldBaseComponent {
  displayedValue: any;

  initComponent() {
    const valueAsPercent = this.getValueAsPercent();
    const shouldSetPrecisionOnValue = Boolean(typeof this.precision === 'number' && typeof this.value === 'number');
    this.displayedValue = shouldSetPrecisionOnValue ? valueAsPercent.toFixed(this.precision) : valueAsPercent;
  }

  updateFormControlValue() {
    const updatedValue = parseFloat(this.displayedValue);
    if (isNaN(updatedValue)) {
      this.formControl.setValue(null);
    } else {
      const decimalAdjustmentForDivideBy100 = 2;
      const numberOfDecimalPlaces = this.getDecimalPlacesInNumber(updatedValue) + decimalAdjustmentForDivideBy100;
      this.formControl.setValue(Number((updatedValue / 100).toFixed(numberOfDecimalPlaces)));
    }
  }

  getValueAsPercent(): number {
    const currentPercentValue = parseFloat(this.value);
    if (isNaN(currentPercentValue)) {
      return null;
    } else {
      const decimalAdjustmentForMultiplyBy100 = 2;
      const numberOfDecimalPlaces = Math.max(
        this.getDecimalPlacesInNumber(currentPercentValue) - decimalAdjustmentForMultiplyBy100,
        0,
      );
      return Number((currentPercentValue * 100).toFixed(numberOfDecimalPlaces));
    }
  }

  getDecimalPlacesInNumber(numberValue: number): number {
    const numberString = numberValue.toString();
    const decimalIndex = numberString.indexOf('.');
    if (decimalIndex === -1) return 0;

    const numberOfDecimalPlaces = numberString.substring(decimalIndex + 1).length;
    return numberOfDecimalPlaces < 0 ? 0 : numberOfDecimalPlaces;
  }
}
