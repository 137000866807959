<div
  class="list-item"
  *ngFor="let optionSet of optionSets"
  [ngClass]="{ selectable: selectable }"
  (click)="handleClick(optionSet)"
>
  <div class="list-value">{{ optionSet.name }}</div>
  <div class="list-action flex flex-row">
    <button mat-button (click)="handleEdit($event, optionSet)"><mat-icon>mode_edit</mat-icon></button>
    <button mat-button (click)="handleDelete($event, optionSet)"><mat-icon>delete</mat-icon></button>
  </div>
</div>
