import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UpdateDialogComponent } from './update-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class UpdateDialogService {
  constructor(public dialog: MatDialog) {}

  public openDialog(
    title: string,
    name: string,
    cancel?: string,
    okBtnLabel: string = 'Save',
    icon: boolean = true,
  ): Promise<string> {
    return new Promise((resolve) => {
      const data = { title, name, cancel, icon, okBtnLabel };
      const dialogRef = this.dialog.open(UpdateDialogComponent, { data });
      dialogRef.afterClosed().subscribe((value) => {
        if (!value) {
          return;
        }
        resolve(data.name);
      });
    });
  }
}
