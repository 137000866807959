import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, FormArray } from '@angular/forms';
import { AdditionFieldConfig, DATA_TYPE } from './additional-fields';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-additional-fields',
  templateUrl: './additional-fields.component.html',
  styleUrls: ['./additional-fields.component.scss'],
})
export class AdditionalFieldsComponent implements OnInit, OnChanges, OnDestroy {
  constructor() {}

  @Input()
  public for: string;

  @Input()
  private config: AdditionFieldConfig[];

  @Input()
  private data: AdditionFieldConfig[] = [];

  @Input()
  private returnType: DATA_TYPE;

  @Input()
  public canDelete: boolean = false;

  @Input()
  public addBtnLabel: string = 'New';

  @Input()
  private allowDuplicateOptions: boolean = true;

  @Input()
  private uniqueKey: string;

  @Output()
  public onChange: EventEmitter<any> = new EventEmitter();

  @Output()
  public onSave: EventEmitter<any> = new EventEmitter();

  public form: FormGroup;

  private destroy$ = new Subject();

  public isAdditionActive: boolean = false;

  public uniqueValues = {};

  private triggerChangeEvent: boolean = true;

  ngOnInit(): void {
    this.initForm();
    this.initSubscriptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.data?.currentValue && !changes?.data?.firstChange && this.form?.get('fields')) {
      this.triggerChangeEvent = false;
      const formArray = this.form.get('fields') as FormArray;
      formArray.clear();

      // fetching fields for current additional data
      const fields = this.getFormFields(changes.data.currentValue);
      // setting value for additional fields
      fields.forEach((f) => {
        formArray.push(f);
      });
      this.triggerChangeEvent = true;
    }
  }

  initForm() {
    const formFields = this.getFormFields();
    this.form = new FormGroup({
      fields: new FormArray(formFields),
    });
  }

  getFormFields(data?) {
    const formFields = [];
    const dataValue = data || this.data;
    let uniqueId = this.generateUniqueID();
    for (let i = 0; i < dataValue.length; i++) {
      const field = dataValue[i];
      const formControl = this.getFieldFormControl(field, uniqueId);
      formFields.push(formControl);

      if (field.key === 'value') {
        uniqueId = this.generateUniqueID();
      }

      if (field.key === 'label') {
        this.uniqueValues[field.value] = true;
      }
    }

    return formFields;
  }

  initSubscriptions() {
    this.form
      ?.get('fields')
      ?.valueChanges?.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (!this.triggerChangeEvent) {
          return;
        }

        if (!this.allowDuplicateOptions) {
          this.uniqueValues = {};
          const uniqueFields = value.filter((f) => f.key === this.uniqueKey);
          uniqueFields.forEach((f) => {
            this.uniqueValues[f.value] = f.value ? true : false;
          });
        }

        if (!this.isAdditionActive) {
          const formdata = this.extractFormData();
          this.onChange.emit(formdata);
        }
      });
  }

  getFieldFormControl(field, uniqueId) {
    return new FormGroup({
      placeholder: new FormControl(field.placeholder || ''),
      key: new FormControl(field.key || ''),
      type: new FormControl(field.type || ''),
      value: new FormControl(field.value || ''),
      options: new FormControl(field.options || []),
      id: new FormControl(uniqueId),
      alias: new FormControl(field.alias || ''),
    });
  }

  generateUniqueID() {
    return Math.random().toString(36);
  }

  extractFormData() {
    const formValue = this.form?.get('fields').value;
    switch (this.returnType) {
      case DATA_TYPE.OBJECT:
        return this.getObjectFromForm(formValue);
      case DATA_TYPE.ARRAY:
        return this.getArrayFromForm(formValue);
      case DATA_TYPE.OBJECT_ARRAY:
        return this.getObjectArrayFromForm(formValue);
      default:
        return formValue;
    }
  }

  getArrayFromForm(formValue) {
    const output = [];
    formValue.forEach((field) => {
      if (field?.value?.trim() !== '') {
        output.push(field.value);
      }
    });

    return output;
  }

  getObjectFromForm(formValue) {
    if (this.config.length !== 2) {
      throw Error(
        `Cannot create return data as object as there are only ${this.config.length} fields to create object, we need 2 fields to return object`,
      );
    }

    const output = {};
    let label = '';
    for (const field of formValue) {
      if (field.key === 'label' && field?.value?.trim() !== '') {
        label = field.value;
      }

      if (label && field.key === 'value' && field?.value?.trim() !== '') {
        output[label] = field.value;
      }

      if (field.key === 'value') {
        label = '';
      }
    }

    return output;
  }

  getObjectArrayFromForm(formValue) {
    const output = [];

    let obj = {};
    for (const field of formValue) {
      const key = field.alias || field.key;
      if (!field.value || field?.value?.trim() === '') {
        continue;
      }

      obj[key] = field.value;
      if (key === 'value') {
        output.push(cloneDeep(obj));
        obj = {};
      }
    }

    return output;
  }

  handleDelete(id: string) {
    const formFields = this.form.get('fields') as FormArray;
    for (let i = 0; i < this.config.length; i++) {
      for (let j = 0; j < formFields['controls'].length; j++) {
        if (formFields.at(j).get('id').value === id) {
          formFields.removeAt(j);
          break;
        }
      }
    }
  }

  addField() {
    const formFields = this.form.get('fields') as FormArray;
    this.isAdditionActive = true;
    const uniqueId = this.generateUniqueID();
    for (const field of this.config) {
      const formControl = this.getFieldFormControl(field, uniqueId);
      formFields.push(formControl);
    }

    this.isAdditionActive = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
