import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PropertyType, TypeProperty } from '@contrail/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { RoutingService } from 'src/app/common/routing/routing.service';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerActions, TypeManagerSelectors } from '../../type-manager-store';
import { TypePropertyUserList } from '../../type-manager-store/user-lists/user-lists.state';

@Component({
  selector: 'app-type-property-user-list',
  templateUrl: './type-property-user-list.component.html',
  styleUrls: ['./type-property-user-list.component.scss'],
})
export class TypePropertyUserListComponent implements OnChanges {
  @Input() typeProperty: TypeProperty;
  public isUserListProperty;
  public userListAssigned = false;
  public userList: TypePropertyUserList;
  public userLists$: Observable<Array<TypePropertyUserList>>;
  public userListFormControl = new UntypedFormControl();
  constructor(
    private store: Store<RootStoreState.State>,
    private routingService: RoutingService,
  ) {
    this.userLists$ = this.store
      .select(TypeManagerSelectors.typePropertyUserLists)
      .pipe(map((sets) => sets.sort((s1, s2) => (s1.name > s2.name ? 1 : -1))));
    this.userListFormControl.valueChanges.subscribe(this.setUserList.bind(this));
  }

  ngOnChanges(): void {
    this.userList = null;
    this.isUserListProperty = [PropertyType.UserList].includes(this.typeProperty.propertyType);

    this.userListFormControl.reset();

    if (this.typeProperty.typePropertyUserListId) {
      this.userLists$
        .pipe(
          take(1),
          tap((userLists) => {
            this.userList = userLists.find((set) => set.id === this.typeProperty.typePropertyUserListId);
          }),
        )
        .subscribe();
    }
    this.userListAssigned = !!this.userList;
  }

  displayFn(userList: TypePropertyUserList): string {
    return userList?.name;
  }

  goToUserList() {
    this.routingService.go('/users/groups');
  }

  setUserList(userList: TypePropertyUserList) {
    if (userList) {
      this.userListAssigned = true;
      this.userList = userList;
      this.store.dispatch(
        TypeManagerActions.updateTypeProperty({
          id: this.typeProperty.id,
          changes: { ...this.typeProperty, typePropertyUserListId: this.userList.id },
        }),
      );
    }
  }
}
