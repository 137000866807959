import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MonacoEditorModule } from '@materia-ui/ngx-monaco-editor';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';

import { TypesModule } from '@common/types/types.module';
import { PipesModule } from '@common/pipes/pipes.module';
import { TypesComponent } from './types.component';
import { TypeManagerRoutingModule } from './type-manager-routing.module';
import { TypeRootComponent } from './type-root/type-root.component';
import { TypeDetailsComponent } from './type-details/type-details.component';
import { ComponentsModule } from '../common/components/components.module';
import { TypePropertiesComponent } from './type-properties/type-properties.component';
import { TypePropertyCreateFormComponent } from './type-properties/type-property-create-form/type-property-create-form.component';
import { TypePropertiesListComponent } from './type-properties/type-properties-list/type-properties-list.component';
import { TypeTypePropertiesComponent } from './type-details/type-type-properties/type-type-properties.component';
import { TypeRelationshipsComponent } from './type-details/type-relationships/type-relationships.component';
import { AddPropertyToTypeComponent } from './type-details/type-type-properties/add-property-to-type/add-property-to-type.component';
import { TypePropertyChooserComponent } from './type-properties/type-property-chooser/type-property-chooser.component';
import { RootTypeSelectorComponent } from './root-type-selector/root-type-selector.component';
import { TypeSelectorComponent } from './type-selector/type-selector.component';
import { TypePropertyDetailsSideBarComponent } from './type-property-details/type-property-details-side-bar/type-property-details-side-bar.component';
import { TypePropertyDetailsComponent } from './type-property-details/type-property-details.component';
import { TypePropertyOptionsComponent } from './type-property-details/type-property-options/type-property-options.component';
import { TypePropertyUserListComponent } from './type-property-details/type-property-user-list/type-property-user-list.component';
import { TypePropertyDefaultsComponent } from './type-property-details/type-property-defaults/type-property-defaults.component';
import { TypePropertyHeaderComponent } from './type-property-details/type-property-header/type-property-header.component';
import { TypePropertyOptionSetsComponent } from './type-property-option-sets/type-property-option-sets.component';
import { TypePropertyOptionSetOptionEditorComponent } from './type-property-option-sets/type-property-option-set-option-editor/type-property-option-set-option-editor.component';
import { TypePropertyOptionSetFormComponent } from './type-property-option-sets/type-property-option-set-form/type-property-option-set-form.component';
import { TypePropertyOptionSetListComponent } from './type-property-option-sets/type-property-option-set-list/type-property-option-set-list.component';
import { TypePropertyOptionSetOptionEditorHeaderComponent } from './type-property-option-sets/type-property-option-set-option-editor/type-property-option-set-option-editor-header/type-property-option-set-option-editor-header.component';
import { TypePropertyOptionSetOptionEditorOptionListComponent } from './type-property-option-sets/type-property-option-set-option-editor/type-property-option-set-option-editor-option-list/type-property-option-set-option-editor-option-list.component';

import { TypePropertyCoreComponent } from './type-property-details/type-property-core/type-property-core.component';
import { TypePropertyFormulaComponent } from './type-property-details/type-property-formula/type-property-formula.component';
import { TypePropertyRequiredComponent } from './type-property-details/type-property-required/type-property-required.component';
import { TypePropertyCarryoverComponent } from './type-property-details/type-property-carryover/type-property-carryover.component';
import { TypePropertyConstraintsComponent } from './type-property-details/type-property-constraints/type-property-constraints.component';
import { TypeSelectorMenuComponent } from './type-selector/type-selector-menu/type-selector-menu.component';
import { CreateSubTypeComponent } from './create-sub-type/create-sub-type.component';
import { TypePropertyLabelComponent } from './type-property-details/type-property-label/type-property-label.component';
import { TypePropertyLevelComponent } from './type-property-details/type-property-level/type-property-level.component';
import { TypePropertyNumberFormatComponent } from './type-property-details/type-property-number-format/type-property-number-format.component';
import { TypePropertyPoliciesComponent } from './type-property-policies/type-property-policies.component';
import { TypePropertyPolicyListComponent } from './type-property-policies/type-property-policy-list/type-property-policy-list.component';
import { TypePropertyPolicyFormComponent } from './type-property-policies/type-property-policy-form/type-property-policy-form.component';
import { TypePropertyPolicyEditorComponent } from './type-property-policies/type-property-policy-editor/type-property-policy-editor.component';
import { TypePropertyPolicyEditorHeaderComponent } from './type-property-policies/type-property-policy-editor/type-property-policy-editor-header/type-property-policy-editor-header.component';
import { TypePropertyPolicyAssignmentComponent } from './type-property-details/type-property-policy-assignment/type-property-policy-assignment.component';
import { TypePropertyObjectReferenceComponent } from './type-property-details/type-property-object-reference/type-property-object-reference.component';
import { TypeRuleSetsComponent } from './type-rule-sets/type-rule-sets.component';
import { TypeRuleSetEditorComponent } from './type-rule-sets/type-rule-set-editor/type-rule-set-editor.component';
import { TypeRuleSetListComponent } from './type-rule-sets/type-rule-set-list/type-rule-set-list.component';
import { TypeRuleSetFormComponent } from './type-rule-sets/type-rule-set-form/type-rule-set-form.component';
import { TypeRuleSetEditorHeaderComponent } from './type-rule-sets/type-rule-set-editor/type-rule-set-editor-header/type-rule-set-editor-header.component';
import { TypeTypeRulesComponent } from './type-details/type-type-rules/type-type-rules.component';
import { OptionSetHierarchiesComponent } from './option-set-hierarchies/option-set-hierarchies.component';
import { OptionSetHierarchyListComponent } from './option-set-hierarchies/option-set-hierarchy-list/option-set-hierarchy-list.component';
import { OptionSetHierarchyEditorComponent } from './option-set-hierarchies/option-set-hierarchy-editor/option-set-hierarchy-editor.component';
import { OptionSetHierarchyFormComponent } from './option-set-hierarchies/option-set-hierarchy-form/option-set-hierarchy-form.component';
import { TypeOptionSetHierarchiesComponent } from './type-details/type-option-set-hierarchies/type-option-set-hierarchies.component';
import { TypePropertySearchableComponent } from './type-property-details/type-property-searchable/type-property-searchable.component';
import { TypePropertyUniquenessComponent } from './type-property-details/type-property-uniqueness/type-property-uniqueness.component';
import { TypePropertyFederationComponent } from './type-property-details/type-property-federation/type-property-federation.component';
import { TypePropertyUsageRemovalComponent } from './type-property-details/type-property-usage-removal/type-property-usage-removal.component';
import { TypePoliciesComponent } from './type-policies/type-policies.component';
import { TypePolicyEditorComponent } from './type-policies/type-policy-editor/type-policy-editor.component';
import { TypePolicyFormComponent } from './type-policies/type-policy-form/type-policy-form.component';
import { TypePolicyListComponent } from './type-policies/type-policy-list/type-policy-list.component';
import { TypePolicyEditorHeaderComponent } from './type-policies/type-policy-editor/type-policy-editor-header/type-policy-editor-header.component';
import { TypeTypePoliciesComponent } from './type-details/type-type-policies/type-type-policies.component';
import { TypePropertyLastModifiedComponent } from './type-property-details/type-property-last-modified/type-property-last-modified.component';
import { TypePropertyCopyBehaviorComponent } from './type-property-details/type-property-copy-behavior/type-property-copy-behavior.component';
import { PolicyDetailComponent } from './components/policy-detail/policy-detail.component';

@NgModule({
  declarations: [
    TypesComponent,
    TypeRootComponent,
    TypeDetailsComponent,
    TypePropertiesComponent,
    TypePropertyCreateFormComponent,
    TypePropertiesListComponent,
    TypeTypePropertiesComponent,
    TypeRelationshipsComponent,
    AddPropertyToTypeComponent,
    TypePropertyChooserComponent,
    RootTypeSelectorComponent,
    TypeSelectorComponent,
    TypePropertyDetailsSideBarComponent,
    TypePropertyDetailsComponent,
    TypePropertyOptionsComponent,
    TypePropertyUserListComponent,
    TypePropertyDefaultsComponent,
    TypePropertyCopyBehaviorComponent,
    TypePropertyHeaderComponent,
    TypePropertyOptionSetsComponent,
    TypePropertyOptionSetOptionEditorComponent,
    TypePropertyOptionSetFormComponent,
    TypePropertyOptionSetListComponent,
    TypePropertyOptionSetOptionEditorHeaderComponent,
    TypePropertyOptionSetOptionEditorOptionListComponent,
    TypePropertyCoreComponent,
    TypePropertyFormulaComponent,
    TypePropertyRequiredComponent,
    TypePropertyCarryoverComponent,
    TypePropertyConstraintsComponent,
    TypePropertyLabelComponent,
    TypeSelectorMenuComponent,
    CreateSubTypeComponent,
    TypePropertyLevelComponent,
    TypePropertyNumberFormatComponent,
    TypePropertyPoliciesComponent,
    TypePropertyPolicyListComponent,
    TypePropertyPolicyFormComponent,
    TypePropertyPolicyEditorComponent,
    TypePropertyPolicyEditorHeaderComponent,
    TypePropertyPolicyAssignmentComponent,
    TypePropertyObjectReferenceComponent,
    TypeRuleSetsComponent,
    TypeRuleSetEditorComponent,
    TypeRuleSetListComponent,
    TypeRuleSetFormComponent,
    TypeRuleSetEditorHeaderComponent,
    TypeTypeRulesComponent,
    OptionSetHierarchiesComponent,
    OptionSetHierarchyListComponent,
    OptionSetHierarchyEditorComponent,
    OptionSetHierarchyFormComponent,
    TypeOptionSetHierarchiesComponent,
    TypePropertySearchableComponent,
    TypePropertyUniquenessComponent,
    TypePropertyFederationComponent,
    TypePropertyUsageRemovalComponent,
    TypePoliciesComponent,
    TypePolicyEditorComponent,
    TypePolicyFormComponent,
    TypePolicyListComponent,
    TypePolicyEditorHeaderComponent,
    TypeTypePoliciesComponent,
    TypePropertyLastModifiedComponent,
    PolicyDetailComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TypeManagerRoutingModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    ComponentsModule,
    MatTooltipModule,
    MatMenuModule,
    MatInputModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatAutocompleteModule,
    DragDropModule,
    TypesModule,
    MonacoEditorModule,
    PipesModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatTableModule,
  ],
  exports: [TypesComponent],
})
export class TypeManagerModule {}
