import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentManagedComponent } from './content-managed/content-managed.component';
import { EntitiesSearchComponent } from './entities-search/entities-search.component';
import { EntitityPropertiesComponent } from './entitity-properties/entitity-properties.component';
import { EntityDetailsComponent } from './entity-details/entity-details.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'list',
    pathMatch: 'full',
  },
  {
    path: 'list',
    component: EntitiesSearchComponent,
  },
  {
    path: 'details/:entityType/:id',
    component: EntityDetailsComponent,
    children: [
      {
        path: '',
        redirectTo: 'properties',
        pathMatch: 'full',
      },
      {
        path: 'content',
        component: ContentManagedComponent,
      },
      {
        path: 'properties',
        component: EntitityPropertiesComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EntitiesRoutingModule {}
