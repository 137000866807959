import { on } from '@ngrx/store';
import { State } from '../type-manager.state';
import * as TypeRuleSetsAction from './type-rule-sets.actions';
import { typeRuleSetsEntityAdapter } from './type-rule-sets.state';

const setData = (state: State, { data }) => {
  console.log('setData: ', data);
  return {
    ...state,
    typeRuleSets: typeRuleSetsEntityAdapter.setAll(data, state.typeRuleSets),
  };
};
const add = (state: State, { typeRuleSet }) => ({
  ...state,
  typeRuleSets: typeRuleSetsEntityAdapter.addOne(typeRuleSet, state.typeRuleSets),
});
const update = (state: State, { id, changes }) => ({
  ...state,
  typeRuleSets: typeRuleSetsEntityAdapter.updateOne({ id, changes }, state.typeRuleSets),
});
const remove = (state: State, { typeRuleSet }) => ({
  ...state,
  typeRuleSets: typeRuleSetsEntityAdapter.removeOne(typeRuleSet.id, state.typeRuleSets),
});

export const typeRuleSetsReducer = [
  on(TypeRuleSetsAction.loadTypeRuleSetsSuccess, setData),
  on(TypeRuleSetsAction.createTypeRuleSetSuccess, add),
  on(TypeRuleSetsAction.deleteTypeRuleSetSuccess, remove),
  on(TypeRuleSetsAction.updateTypeRuleSet, update),
];
