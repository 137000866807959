import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { eventWorkflowTemplatesReducers } from './event-templates.reducers';
import { EventWorkflowTemplatesEffects } from './event-templates.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('eventWorkflowTemplates', eventWorkflowTemplatesReducers),
    EffectsModule.forFeature([EventWorkflowTemplatesEffects]),
  ],
})
export class EventWorkflowTemplatesStoreModule {}
