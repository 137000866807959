<app-page-section>
  <app-page-section-header>
    <app-page-section-title [title]="'Details'"> </app-page-section-title>
  </app-page-section-header>
  <app-page-section-body>
    <app-type-property-form
      [propertyConfigurations]="entity?.getType()?.getAllProperties()"
      [values]="values"
    ></app-type-property-form>
  </app-page-section-body>
</app-page-section>
