<app-page-section *ngIf="isViewable">
  <app-page-section-header>
    <app-page-section-title title="Copy Behavior"></app-page-section-title>
  </app-page-section-header>
  <app-page-section-body>
    <div class="mb-3">
      <mat-form-field>
        <mat-label>Choose Copy Behavior</mat-label>
        <mat-select [formControl]="formControl" [disabled]="isDisabled">
          <ng-container *ngFor="let option of copyOptions">
            <mat-option [value]="option.value" *ngIf="isOptionValueValid(option.value)">
              {{ option.displayValue }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="mb-3" *ngIf="formControl.value === 'default'">
      <app-type-property-form-field [propertyFormConfiguration]="formConfiguration" [value]="getCopyDefaultValue()">
      </app-type-property-form-field>
    </div>
  </app-page-section-body>
</app-page-section>
