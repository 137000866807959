<div
  *ngIf="object && objectName"
  class="object-chip"
  [style.cursor]="clickable ? 'pointer' : 'default'"
  (click)="viewDetails()"
>
  <span class="value" *ngIf="hasStatus" class="status-circle" [style.background-color]="statusHexColor"></span>
  <span class="value" *ngIf="objectNumber">{{ objectNumber }}&nbsp;:&nbsp;</span>
  <span class="value">{{ objectName }}</span>
  <mat-icon *ngIf="edit" class="remove" (click)="remove()">close</mat-icon>
</div>
