import { on } from '@ngrx/store';
import { State } from '../type-manager.state';
import * as TypePropertiesActions from './type-properties.actions';
import { typePropertiesEntityAdapter } from './type-properties.state';

const setData = (state: State, { data }) => ({
  ...state,
  typeProperties: typePropertiesEntityAdapter.setAll(data, state.typeProperties),
});
const add = (state: State, { typeProperty }) => ({
  ...state,
  typeProperties: typePropertiesEntityAdapter.addOne(typeProperty, state.typeProperties),
});
const update = (state: State, { id, changes }) => {
  let currentTypeProperty = state.currentTypeProperty;
  if (state.currentTypeProperty?.id === id) {
    currentTypeProperty = { ...state.currentTypeProperty, ...changes };
  }
  return {
    ...state,
    typeProperties: typePropertiesEntityAdapter.updateOne({ id, changes }, state.typeProperties),
    currentTypeProperty,
  };
};
const remove = (state: State, { typeProperty }) => ({
  ...state,
  typeProperties: typePropertiesEntityAdapter.removeOne(typeProperty.id, state.typeProperties),
});

export const typePropertiesReducer = [
  on(TypePropertiesActions.setTypeProperties, setData),
  on(TypePropertiesActions.loadTypePropertiesSuccess, setData),
  on(TypePropertiesActions.createTypePropertySuccess, add),
  on(TypePropertiesActions.deleteTypePropertySuccess, remove),
  on(TypePropertiesActions.updateTypePropertySuccess, update),
];
