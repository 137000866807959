import { createSelector } from '@ngrx/store';
import { TypePropertyUserList, typePropertyUserListsEntityAdapter } from './user-list.state';

export const { selectAll, selectEntities, selectIds, selectTotal } = typePropertyUserListsEntityAdapter.getSelectors();

const typePropertyUserListsEntities = (state: any) => state.userList?.typePropertyUserLists?.entities ?? [];
export const typePropertyUserLists = createSelector(
  typePropertyUserListsEntities,
  (state) => Object.values(state) as Array<TypePropertyUserList>,
);
