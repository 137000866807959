import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerActions, TypeManagerSelectors } from '../type-manager-store';
import { TypePropertyOptionSet } from '../type-manager-store/option-sets/option-sets.state';

@Injectable({
  providedIn: 'root',
})
export class TypePropertyOptionSetResolver implements Resolve<TypePropertyOptionSet> {
  constructor(private store: Store<RootStoreState.State>) {}
  resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<TypePropertyOptionSet> {
    const optionSetId = route.params.optionSetId;
    this.setCurentOptionSet(optionSetId);
    return this.store.select(TypeManagerSelectors.currentTypePropertyOptionSet).pipe(
      filter((optionSet) => optionSet && optionSet.id === optionSetId),
      take(1),
    );
  }
  setCurentOptionSet(id) {
    this.store
      .select(TypeManagerSelectors.typePropertyOptionSets)
      .pipe(
        filter((sets) => sets && sets.length > 0),
        map((sets) => sets.find((set) => set.id === id)),
        tap((set) => {
          this.store.dispatch(
            TypeManagerActions.setCurrentTypePropertyOptionSet({ currentTypePropertyOptionSet: set }),
          );
        }),
        take(1),
      )
      .subscribe();
  }
}
