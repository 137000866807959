import { Component, Input, OnChanges } from '@angular/core';
import { RoutingService } from 'src/app/common/routing/routing.service';
import { Entity } from '../entity';

@Component({
  selector: 'app-meta',
  templateUrl: './meta.component.html',
  styleUrls: ['./meta.component.scss'],
})
export class MetaComponent implements OnChanges {
  @Input() entity: Entity;
  public relations: Array<any>;
  public interfaces: Array<any>;
  constructor(private routingService: RoutingService) {}

  ngOnChanges(): void {
    if (this.entity && this.entity.getType()) {
      this.relations = Object.values(this.entity.getType().getRelations());
      this.interfaces = this.entity.getType().getInterfaces();
    }
  }

  goToType() {
    const type = this.entity.getType();
    this.routingService.go(`type-manager/types/${type.getEntityType()}/type/${type.getTypeId()}`);
  }
}
