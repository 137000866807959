import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerActions, TypeManagerSelectors } from '../../type-manager-store';
import { TypePropertyOptionSet } from '../../type-manager-store/option-sets/option-sets.state';

@Component({
  selector: 'app-type-property-option-set-option-editor',
  templateUrl: './type-property-option-set-option-editor.component.html',
  styleUrls: ['./type-property-option-set-option-editor.component.scss'],
})
export class TypePropertyOptionSetOptionEditorComponent {
  typePropertyOptionSet$: Observable<TypePropertyOptionSet>;
  constructor(private store: Store<RootStoreState.State>) {
    this.typePropertyOptionSet$ = this.store.select(TypeManagerSelectors.currentTypePropertyOptionSet);
  }

  handledUpdatedOptions(options) {
    console.log('handledUpdatedOptions: ', options);
    this.typePropertyOptionSet$
      .pipe(
        take(1),
        tap((typePropertyOptionSet) => {
          this.store.dispatch(
            TypeManagerActions.updateTypePropertyOptionSet({
              id: typePropertyOptionSet.id,
              changes: { optionSet: options },
            }),
          );
        }),
      )
      .subscribe();
  }
}
