import { Injectable } from '@angular/core';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { RootStoreState } from '@rootstore';
import { OrgAppsActions } from '.';
import { OrgAppsService } from './org-apps.service';
import { AppsActions } from '../apps-store';

@Injectable()
export class OrgAppsEffects {
  constructor(
    private actions$: Actions,
    private orgAppService: OrgAppsService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {}

  loadOrgApps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgAppsActions.OrgAppsActionTypes.LOAD_ORG_APPS),
      withLatestFrom(this.store),
      switchMap(([]: [any, RootStoreState.State]) =>
        from(this.orgAppService.getOrgApps()).pipe(
          map((data) => OrgAppsActions.loadOrgAppsSuccess({ data })),
          catchError((error) => observableOf(OrgAppsActions.loadOrgAppsFailure({ error }))),
        ),
      ),
    ),
  );
  loadCurrentOrgApps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgAppsActions.OrgAppsActionTypes.LOAD_CURRENT_ORG_APP),
      switchMap((action: any) =>
        from(this.orgAppService.getOrgAppById(action.id)).pipe(
          map((data) => OrgAppsActions.loadCurrentOrgAppSuccess({ orgApp: data })),
          catchError((error) => observableOf(OrgAppsActions.loadCurrentOrgAppFailure({ error }))),
        ),
      ),
    ),
  );
  createOrgApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgAppsActions.OrgAppsActionTypes.CREATE_ORG_APP),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) => {
        const appId = action.appId;
        return from(this.orgAppService.createOrgApp(appId)).pipe(
          map((data) => OrgAppsActions.createOrgAppSuccess({ orgApp: data })),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(OrgAppsActions.createOrgAppFailure({ error }));
          }),
        );
      }),
    ),
  );
  createOrgAppSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrgAppsActions.OrgAppsActionTypes.CREATE_ORG_APP_SUCCESS),
        withLatestFrom(this.store),
        tap(([, store]: [any, RootStoreState.State]) => {
          this.store.dispatch(OrgAppsActions.loadOrgApps());
          this.store.dispatch(AppsActions.loadApps({ loadHidden: false }));
          if (store.apps.currentApp.id) {
            this.store.dispatch(AppsActions.loadCurrentApp({ id: store.apps.currentApp.id }));
          }
        }),
      ),
    { dispatch: false },
  );
  deleteOrgApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgAppsActions.OrgAppsActionTypes.DELETE_ORG_APP),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.orgAppService.deleteOrgApp(action.orgApp)).pipe(
          map((data) => OrgAppsActions.deleteOrgAppSuccess({ orgApp: data })),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(OrgAppsActions.deleteOrgAppFailure({ error }));
          }),
        ),
      ),
    ),
  );
  deleteOrgAppSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrgAppsActions.OrgAppsActionTypes.DELETE_ORG_APP_SUCCESS),
        tap(() => {
          this.store.dispatch(AppsActions.loadApps({ loadHidden: false }));
        }),
      ),
    { dispatch: false },
  );

  updateOrgApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrgAppsActions.OrgAppsActionTypes.UPDATE_ORG_APP),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.orgAppService.updateOrgApp(action.id, action.changes)).pipe(
          map((data) => OrgAppsActions.updateOrgAppSuccess({ id: data.id, changes: data })),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(OrgAppsActions.updateOrgAppFailure({ error }));
          }),
        ),
      ),
    ),
  );
}
