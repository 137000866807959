import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateAgoPipe } from './date-ago.pipe';
import { FilterPipe } from './filter.pipe';
import { RoundNumberPipe } from './round-number.pipe';
import { ShortenPipe } from './shorten.pipe';
import { SearchKeyPipe } from './search-key.pipe';
import { SafePipe } from './safe.pipe';
import { UserNamePipe } from './user-name.pipe';
import { TimezonePipe } from './timezone.pipe';
import { isoLocalTimePipe } from './isoLocalTime.pipe';
import { SecureImagePipe } from './secure-image.pipe';
import { ReplacePipe } from './replace.pipe';

@NgModule({
  declarations: [
    DateAgoPipe,
    FilterPipe,
    RoundNumberPipe,
    ShortenPipe,
    SearchKeyPipe,
    SafePipe,
    UserNamePipe,
    TimezonePipe,
    isoLocalTimePipe,
    SecureImagePipe,
    ReplacePipe,
  ],
  imports: [CommonModule],
  exports: [
    DateAgoPipe,
    FilterPipe,
    RoundNumberPipe,
    ShortenPipe,
    SearchKeyPipe,
    SafePipe,
    UserNamePipe,
    TimezonePipe,
    isoLocalTimePipe,
    SecureImagePipe,
    ReplacePipe,
  ],
})
export class PipesModule {}
