import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Type } from '@contrail/types';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerActions, TypeManagerSelectors } from '../type-manager-store';

@Component({
  selector: 'app-type-details',
  templateUrl: './type-details.component.html',
  styleUrls: ['./type-details.component.scss'],
})
export class TypeDetailsComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store<RootStoreState.State>,
    private activeRoute: ActivatedRoute,
  ) {
    this.type$ = this.store.select(TypeManagerSelectors.currentType);
  }

  public type$: Observable<Type>;
  private paramSub: Subscription;

  ngOnInit(): void {
    this.subscribe();
  }
  ngOnDestroy() {
    this.unsubscribe();
  }
  subscribe() {
    this.paramSub = this.activeRoute.params.subscribe((params) => {
      this.store.dispatch(TypeManagerActions.loadCurrentType({ id: params.typeId }));
      this.store.dispatch(TypeManagerActions.setCurrentTypeProperty({ currentTypeProperty: null }));
    });
  }
  unsubscribe() {
    this.paramSub.unsubscribe();
  }
}
