import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Types } from '@contrail/sdk';
import { TypeConstraintsHelper } from '@contrail/type-validation';
import { TypePropertyOption } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { TypePropertyFormFieldBaseComponent } from './type-property-form-field-base.component';

@Component({
  selector: 'app-type-property-form-field-select',
  template: `
    <mat-form-field
      [appearance]="appearance"
      *ngIf="formControl"
      [floatLabel]="propertyFormConfiguration?.isFilter ? 'never' : 'always'"
    >
      <mat-label>{{ propertyFormConfiguration.typeProperty.label }}</mat-label>
      <mat-select [formControl]="formControl">
        <mat-option></mat-option>
        <ng-container *ngFor="let option of propertyFormConfiguration.typeProperty.options">
          <mat-option
            *ngIf="!isInvalidOption(option) || isInvalidOptionButInValue(option)"
            [attr.data-test]="'property-form-option-' + option.value"
            [disabled]="isInvalidOption(option)"
            [value]="option"
          >
            {{ option.display }}
          </mat-option>
        </ng-container>
      </mat-select>
      <mat-error *ngIf="formControl.hasError('error')">{{ formControl.getError('error') }}</mat-error>
      <app-type-property-form-validation-error
        *ngIf="formControl.hasError('error') && formControl.disabled"
        [errorMessage]="formControl.getError('error')"
      >
      </app-type-property-form-validation-error>
    </mat-form-field>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
      .select {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 20px;
        cursor: pointer;
        min-width: 80px;
      }
      mat-form-field {
        width: 100%;
      }
    `,
  ],
})
export class TypePropertyFormFieldSelectComponent extends TypePropertyFormFieldBaseComponent {
  options: TypePropertyOption[];

  async initFormControl() {
    if (!this.propertyFormConfiguration?.typeProperty) {
      return;
    }

    this.options = await this.getOptions();
    const allOptions = this.propertyFormConfiguration.typeProperty?.options ?? [];
    const option = allOptions.find((opt) => opt.value === this.value);
    const disabled = await this.isDisabled();
    const isValueVisible = await this.isValueVisible();

    this.formControl = new UntypedFormControl({ value: isValueVisible ? option : null, disabled });
    this.formControl.valueChanges.subscribe((opt) => {
      console.log('value change opt: ', opt);
      if (!opt) {
        this.value = null;
      } else {
        this.value = opt.value;
      }
      this.handleChange();
    });
  }

  isInvalidOption(selectedOption) {
    return this.options.findIndex((option) => option.value === selectedOption.value) === -1;
  }

  isInvalidOptionButInValue(selectedOption) {
    return (
      !this.options?.map((option) => option.value).includes(selectedOption.value) && this.value === selectedOption.value
    );
  }

  private async getOptions(): Promise<Array<TypePropertyOption>> {
    if (!this.entity?.typeId) {
      if (this.propertyFormConfiguration.typeProperty.options) {
        return ObjectUtil.cloneDeep(this.propertyFormConfiguration.typeProperty.options);
      }

      return [];
    }
    const type = await new Types().getType({ id: this.entity.typeId });
    const eligibleOptions = await TypeConstraintsHelper.getValidOptionSets(
      type,
      this.propertyFormConfiguration.typeProperty,
      this.entity,
    );
    return ObjectUtil.cloneDeep(eligibleOptions);
  }
}
