<div
  class="list-item"
  *ngFor="let optionSetHierarchy of optionSetHierarchies"
  [ngClass]="{ selectable: selectable }"
  (click)="handleClick(optionSetHierarchy)"
>
  <div class="list-value">{{ optionSetHierarchy.name }}</div>
  <div class="list-action">
    <button mat-button (click)="handleEdit($event, optionSetHierarchy)"><mat-icon>mode_edit</mat-icon></button>
    <button mat-button (click)="handleDelete($event, optionSetHierarchy)"><mat-icon>delete</mat-icon></button>
  </div>
</div>
