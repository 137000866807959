import { Action, createReducer, on } from '@ngrx/store';
import { State, groupsEntityAdapter, groupsInitialState } from './groups.state';
import * as GroupsActions from './groups.actions';

const setData = (state: State, { data }) => ({
  ...state,
  groups: groupsEntityAdapter.setAll(data, state.groups),
});
const add = (state: State, { group }) => ({
  ...state,
  groups: groupsEntityAdapter.addOne(group, state.groups),
});
const update = (state: State, { id, changes }) => {
  const updateGroup = { id: id, changes: changes };
  let currentGroup = state?.currentGroup;
  if (state?.currentGroup?.id === id) {
    currentGroup = changes;
  }
  return {
    ...state,
    groups: groupsEntityAdapter.updateOne(updateGroup, state.groups),
    currentGroup: currentGroup,
  };
};

const remove = (state: State, { group }) => ({
  ...state,
  groups: groupsEntityAdapter.removeOne(group.id, state.groups),
});
const batchRemove = (state: State, { ids }) => ({
  ...state,
  groups: groupsEntityAdapter.removeMany(ids, state.groups),
});

const setCurrentGroup = (state: State, { group }) => ({
  ...state,
  currentGroup: group,
});

export const groupsReducers = createReducer(
  groupsInitialState,
  on(GroupsActions.loadGroupsSuccess, setData),
  on(GroupsActions.createGroupSuccess, add),
  on(GroupsActions.deleteGroupSuccess, remove),
  on(GroupsActions.deleteGroupsSuccess, batchRemove),
  on(GroupsActions.updateGroupSuccess, update),
  on(GroupsActions.loadCurrentGroupSuccess, setCurrentGroup),
);

/**
 *
 * @param state
 * @param action
 */
export function reducer(state: State | undefined, action: Action): any {
  return groupsReducers(state, action);
}
