import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  constructor() {}

  public getGroups() {
    return new Entities().get({ entityName: 'group', relations: ['groupPrincipals', 'principal'] });
  }
}
