import { createAction, props } from '@ngrx/store';
import { ChartDataPayload, LineChartData } from './event-dashboard';

export enum EventWorkflowDashboardActionTypes {
  LOAD_TRIGGERED_CHART_DATA = '[Event Workflow Dashboard] Load Triggered Chart Data',
  LOAD_TRIGGERED_CHART_DATA_SUCCESS = '[Event Workflow Dashboard] Load Triggered Chart Data Success',
  LOAD_TRIGGERED_CHART_DATA_FAILURE = '[Event Workflow Dashboard] Load Triggered Chart Data Failure',

  LOAD_FAILED_CHART_DATA = '[Event Workflow Dashboard] Load Failed Chart Data',
  LOAD_FAILED_CHART_DATA_SUCCESS = '[Event Workflow Dashboard] Load Failed Chart Data Success',
  LOAD_FAILED_CHART_DATA_FAILURE = '[Event Workflow Dashboard] Load Failed Chart Data Failure',
}

export const loadTriggeredChartData = createAction(
  EventWorkflowDashboardActionTypes.LOAD_TRIGGERED_CHART_DATA,
  props<ChartDataPayload>(),
);

export const loadTriggeredChartDataSuccess = createAction(
  EventWorkflowDashboardActionTypes.LOAD_TRIGGERED_CHART_DATA_SUCCESS,
  props<{ data: LineChartData }>(),
);

export const loadTriggeredChartDataFailure = createAction(
  EventWorkflowDashboardActionTypes.LOAD_TRIGGERED_CHART_DATA_FAILURE,
  props<{ error: Error }>(),
);

export const loadFailedChartData = createAction(
  EventWorkflowDashboardActionTypes.LOAD_FAILED_CHART_DATA,
  props<ChartDataPayload>(),
);

export const loadFailedChartDataSuccess = createAction(
  EventWorkflowDashboardActionTypes.LOAD_FAILED_CHART_DATA_SUCCESS,
  props<{ data: LineChartData }>(),
);

export const loadFailedChartDataFailure = createAction(
  EventWorkflowDashboardActionTypes.LOAD_FAILED_CHART_DATA_FAILURE,
  props<{ error: Error }>(),
);
