import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { featureKey } from './loader-history.selector';
import { loaderHistoryReducers } from './loader-history.reducer';
import { LoaderHistoryEffects } from './loader-history.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(featureKey, loaderHistoryReducers),
    EffectsModule.forFeature([LoaderHistoryEffects]),
  ],
})
export class LoaderHistoryStoreModule {}
