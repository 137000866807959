import { Component, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ConfirmationBoxService } from 'src/app/common/components/confirmation-box/confirmation-box';
import { MenuComponent } from 'src/app/common/components/menu/menu.component';
import { RoutingService } from 'src/app/common/routing/routing.service';
import { RootStoreState } from 'src/app/root-store';
import { UpdateDialogService } from '@components/update-dialog/update-dialog-service';
import { TypeManagerActions, TypeManagerSelectors } from '../type-manager-store';
import { TypePolicy } from '../type-manager-store/type-policies/type-policies.state';
import { TypeService } from '../type.service';

@Component({
  selector: 'app-type-policies',
  templateUrl: './type-policies.component.html',
  styleUrls: ['./type-policies.component.scss'],
})
export class TypePoliciesComponent {
  public policies$: Observable<Array<TypePolicy>>;
  constructor(
    public store: Store<RootStoreState.State>,
    private routingService: RoutingService,
    private confirmationBoxService: ConfirmationBoxService,
    private UpdateDialogService: UpdateDialogService,
    private typeService: TypeService,
  ) {
    this.policies$ = this.store.select(TypeManagerSelectors.typePolicies);
  }
  @ViewChild(MenuComponent) addPolicyMenu: MenuComponent;

  cancelAdd() {
    this.addPolicyMenu.panelVisible = false;
  }
  completeAdd(data: any) {
    this.addPolicyMenu.panelVisible = false;
    const identifier = data.name.replace(/[\s]/g, '-');
    this.store.dispatch(
      TypeManagerActions.createTypePolicy({ typePolicy: { label: data.name, identifier, policy: { statements: [] } } }),
    );
  }
  select(typePolicy: TypePolicy) {
    this.typeService.toggleDetailView(true);
    this.routingService.go('type-manager/type-policies/' + typePolicy.id);
  }
  async delete(typePolicy: TypePolicy) {
    const confirm = await this.confirmationBoxService.open(
      'Delete Policy?',
      'Are you sure you want to delete this policy?',
    );
    if (confirm) {
      this.store.dispatch(TypeManagerActions.deleteTypePolicy({ typePolicy }));
      this.routingService.go('type-manager/type-policies/');
    }
  }
  async edit(typePolicy: TypePolicy) {
    const updatedLabel = await this.UpdateDialogService.openDialog('Rename Type Policy', typePolicy.label);
    if (updatedLabel) {
      const label = updatedLabel;
      const id = typePolicy.id;
      if (typePolicy.label === updatedLabel) {
        return;
      }
      try {
        this.store.dispatch(TypeManagerActions.updateTypePolicy({ id, changes: { label } }));
      } catch (e) {
        console.error(e);
      }
    }
  }
}
