import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { OrgConfig } from './org-config';

interface OrgConfigState extends EntityState<OrgConfig> {}

export interface State {
  orgConfig: OrgConfigState;
}

export const orgConfigEntityAdapter: EntityAdapter<OrgConfig> = createEntityAdapter<OrgConfig>({});

export const orgConfigInitialState = {
  orgConfig: orgConfigEntityAdapter.getInitialState({}),
};
