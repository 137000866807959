import { Action, createReducer, on } from '@ngrx/store';
import {
  State,
  eventWorkflowTemplatesEntityAdapter,
  eventWorkflowTemplatesInitialState,
  templateActionLinksEntityAdapter,
} from './event-templates.state';
import * as EventWorkflowTemplatesActions from './event-templates.actions';

const setData = (state: State, { data }) => ({
  ...state,
  loading: false,
  eventWorkflowTemplates: eventWorkflowTemplatesEntityAdapter.setAll(data, state.eventWorkflowTemplates),
});
const add = (state: State, { eventWorkflowTemplate }) => ({
  ...state,
  loading: false,
  eventWorkflowTemplates: eventWorkflowTemplatesEntityAdapter.addOne(
    eventWorkflowTemplate,
    state.eventWorkflowTemplates,
  ),
});
const update = (state: State, { id, changes }) => {
  const updateEventWorkflowTemplate = { id: id, changes: changes };
  return {
    ...state,
    loading: false,
    eventWorkflowTemplates: eventWorkflowTemplatesEntityAdapter.updateOne(
      updateEventWorkflowTemplate,
      state.eventWorkflowTemplates,
    ),
    currentEventWorkflowTemplate: changes,
  };
};

const batchUpdate = (state: State, { data }) => {
  const updatedEventWorkflowTemplates = data.map((temp) => ({ id: temp.id, changes: temp }));
  return {
    ...state,
    loading: false,
    eventWorkflowTemplates: eventWorkflowTemplatesEntityAdapter.updateMany(
      updatedEventWorkflowTemplates,
      state.eventWorkflowTemplates,
    ),
  };
};
const remove = (state: State, { eventWorkflowTemplate }) => ({
  ...state,
  loading: false,
  eventWorkflowTemplates: eventWorkflowTemplatesEntityAdapter.removeOne(
    eventWorkflowTemplate.id,
    state.eventWorkflowTemplates,
  ),
});

const batchRemove = (state: State, { ids }) => ({
  ...state,
  loading: false,
  eventWorkflowTemplates: eventWorkflowTemplatesEntityAdapter.removeMany(ids, state.eventWorkflowTemplates),
});
const setCurrentEventWorkflowTemplate = (state: State, { eventWorkflowTemplate }) => ({
  ...state,
  loading: false,
  currentEventWorkflowTemplate: eventWorkflowTemplate,
});
const setTriggerkeyEventWorkflowTemplates = (state: State, { data }) => ({
  ...state,
  loading: false,
  triggerKeyEventWorkflowTemplates: eventWorkflowTemplatesEntityAdapter.setAll(
    data,
    state.triggerKeyEventWorkflowTemplates,
  ),
});
const setTemplateActionLinks = (state: State, { data }) => ({
  ...state,
  loading: false,
  templateActionLinks: templateActionLinksEntityAdapter.setAll(data, state.templateActionLinks),
});

const startLoading = (state: State) => ({
  ...state,
  loading: true,
});

export const eventWorkflowTemplatesReducers = createReducer(
  eventWorkflowTemplatesInitialState,
  on(EventWorkflowTemplatesActions.loadEventWorkflowTemplates, startLoading),
  on(EventWorkflowTemplatesActions.loadEventWorkflowTemplatesSuccess, setData),
  on(EventWorkflowTemplatesActions.createEventWorkflowTemplateSuccess, add),
  on(EventWorkflowTemplatesActions.deleteEventWorkflowTemplateSuccess, remove),
  on(EventWorkflowTemplatesActions.deleteEventWorkflowTemplatesSuccess, batchRemove),
  on(EventWorkflowTemplatesActions.updateEventWorkflowTemplateSuccess, update),
  on(EventWorkflowTemplatesActions.updateEventWorkflowTemplatesSuccess, batchUpdate),
  on(EventWorkflowTemplatesActions.loadCurrentEventWorkflowTemplateSuccess, setCurrentEventWorkflowTemplate),
  on(EventWorkflowTemplatesActions.loadTriggerkeyEventWorkflowTemplatesSuccess, setTriggerkeyEventWorkflowTemplates),
  on(EventWorkflowTemplatesActions.loadAllTemplateActionLinksSuccess, setTemplateActionLinks),
  on(EventWorkflowTemplatesActions.loadTemplateActionLinkForAppSuccess, setTemplateActionLinks),
);

/**
 *
 * @param state
 * @param action
 */
export function reducer(state: State | undefined, action: Action): any {
  return eventWorkflowTemplatesReducers(state, action);
}
