import { Component, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import { OptionSetHierarchy } from '../../type-manager-store/option-set-hierarchies/option-set-hierarchies.state';

@Component({
  selector: 'app-option-set-hierarchy-list',
  templateUrl: './option-set-hierarchy-list.component.html',
  styleUrls: ['./option-set-hierarchy-list.component.scss'],
})
export class OptionSetHierarchyListComponent implements OnDestroy {
  @Input() optionSetHierarchies: Array<OptionSetHierarchy>;
  @Input() selectable: boolean;
  @Input() showActions = true;
  @Output() clicked: EventEmitter<OptionSetHierarchy> = new EventEmitter();
  @Output() deleted: EventEmitter<OptionSetHierarchy> = new EventEmitter();
  @Output() edit: EventEmitter<OptionSetHierarchy> = new EventEmitter();
  constructor() {}

  ngOnDestroy() {
    this.unsubscribe();
  }
  subscribe() {}
  unsubscribe() {}

  handleClick(optionSetHierarchy: OptionSetHierarchy) {
    this.clicked.emit(optionSetHierarchy);
  }
  handleDelete($event: MouseEvent, optionSetHierarchy: OptionSetHierarchy) {
    $event.stopPropagation();
    this.deleted.emit(optionSetHierarchy);
  }
  handleEdit($event: MouseEvent, optionSetHierarchy: OptionSetHierarchy) {
    $event.stopPropagation();
    this.edit.emit(optionSetHierarchy);
  }
}
