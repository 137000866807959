import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { TypePolicy } from './type-policies.state';

@Injectable({
  providedIn: 'root',
})
export class TypePoliciesService {
  constructor() {}

  public async get() {
    let sets = await new Entities().get({ entityName: 'type-policy' });
    sets = sets.sort((s1, s2) => (s1.name > s2.name ? 1 : -1));
    return sets;
  }
  public async create(policy: TypePolicy) {
    return new Entities().create({ entityName: 'type-policy', object: policy });
  }
  public async update(id: string, changes: TypePolicy) {
    return new Entities().update({ entityName: 'type-policy', id, object: changes });
  }
  public async delete(policy: TypePolicy) {
    await new Entities().delete({ entityName: 'type-policy', id: policy.id });
    return policy;
  }
}
