<app-page-section [title]="'Policies / Access Control'">
  <app-page-section-body>
    <div class="mb-2">Assign policies to control who can edit or update this type of entity.</div>
    <mat-chip-list>
      <mat-chip color="primary" *ngFor="let typePolicyLink of typePolicyLinks">
        {{ typePolicyLink.typePolicy.label }}
        <mat-icon class="cursor-pointer" (click)="removeTypePolicy(typePolicyLink)">close</mat-icon>
      </mat-chip>
    </mat-chip-list>

    <div class="mt-3 w-80">
      <mat-form-field class="example-full-width w-full">
        <mat-label>Add Policy</mat-label>
        <input
          type="text"
          placeholder="Pick one"
          aria-label="Type Policies"
          matInput
          [formControl]="addTypePolicyFormControl"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option
            [matTooltip]="typePolicy.label.length > displayTypePolicyLengthLimit ? typePolicy.label : ''"
            *ngFor="let typePolicy of availableTypePolicies$ | async"
            [value]="typePolicy"
          >
            {{ typePolicy.label | shorten: displayTypePolicyLengthLimit }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </app-page-section-body>
</app-page-section>
