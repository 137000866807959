<div class="list-header" *ngIf="!showAdd && optionSet?.length">
  <app-search-bar
    [lgGray]="true"
    [placeholder]="'Search'"
    class="mr-4"
    (valueChange)="searchKey = $event"
  ></app-search-bar>
  <button mat-button (click)="showAdd = true"><mat-icon>add</mat-icon></button>
  <button mat-button (click)="toggleOptionSetImportModal()"><mat-icon>upload</mat-icon> Import</button>
</div>
<div *ngIf="showAdd" class="add-row">
  <div class="option-row">
    <div class="value">
      <mat-form-field appearance="outline">
        <mat-label>Value</mat-label>
        <input id="valueInput" (blur)="setDisplayFromValue()" matInput [formControl]="valueField" />
      </mat-form-field>
    </div>
    <div class="display">
      <mat-form-field appearance="outline">
        <mat-label>Display</mat-label>
        <input matInput [formControl]="displayField" />
      </mat-form-field>
    </div>
    <button [disabled]="!isNewValid()" mat-button (click)="addOption()"><mat-icon>add</mat-icon></button>
    <button mat-button (click)="showAdd = false">Cancel</button>
  </div>
</div>
<app-page-section *ngIf="optionSet && optionSet.length">
  <div class="boundary" cdkDropList (cdkDropListDropped)="drop($event)">
    <div
      class="list-item"
      *ngFor="let option of optionSet | searchKey: searchKey : 'display'"
      cdkDrag
      cdkDragBoundary=".boundary"
    >
      <div class="drag-handle"><mat-icon cdkDragHandle>drag_handle</mat-icon></div>
      <div
        class="list-value value code"
        [matTooltip]="option.value.length > displayTextLengthLimit ? option.value : ''"
      >
        {{ option.value | shorten: displayTextLengthLimit }}
      </div>
      <div
        class="list-value display"
        [matTooltip]="option.display.length > displayTextLengthLimit ? option.display : ''"
      >
        {{ option.display | shorten: displayTextLengthLimit }}
      </div>
      <mat-slide-toggle
        (change)="handleDisableToggle($event, option)"
        [checked]="option.disabled"
        color="primary"
        class="display"
      >
        Disabled
      </mat-slide-toggle>
      <div class="list-action">
        <button mat-button (click)="handleEdit(option)"><mat-icon>edit</mat-icon></button>
      </div>
      <div class="list-action">
        <button mat-button (click)="handleDelete(option)"><mat-icon>delete</mat-icon></button>
      </div>
    </div>
  </div>
</app-page-section>
<div class="empty" *ngIf="!optionSet?.length && !showAdd">
  <div class="message">No options have been defined for this set.</div>
  <button mat-raised-button color="primary" (click)="showAddOption()">Add Option</button>
  <button mat-button class="!px-2" (click)="toggleOptionSetImportModal()"><mat-icon>upload</mat-icon> Import</button>
</div>
<app-import-modal
  *ngIf="isModalOpen"
  (cancel)="toggleOptionSetImportModal()"
  (importOptionSets)="importOptionSets($event)"
  [isUploadProcessed]="importProcessed"
  importType="OPTION_SETS"
  [optionSet]="optionSet"
  [errors]="errors"
></app-import-modal>
