import { Action, createReducer, on } from '@ngrx/store';
import {
  State,
  cachedEventWorkflowProcessesEntityAdapter,
  eventWorkflowProcessesEntityAdapter,
  eventWorkflowProcessesInitialState,
} from './event-processes.state';
import * as EventWorkflowProcessesActions from './event-processes.actions';

const setCurrentEventWorkflowProcess = (state: State, { eventWorkflowProcess }) => {
  return {
    ...state,
    loading: false,
    currentEventWorkflowProcess: eventWorkflowProcess,
  };
};

const startLoading = (state: State) => ({
  ...state,
  loading: true,
  hasError: false,
});

const setCurrentPage = (state: State, { pageNum }) => ({
  ...state,
  currentPage: pageNum,
});

const setCachedProcesses = (state: State, { processes, nextPageKey, pageNum, pageSize }) => ({
  ...state,
  cachedProcesses: cachedEventWorkflowProcessesEntityAdapter.upsertMany(processes, state.cachedProcesses),
  nextPageKey,
  templateIdEventWorkflowProcesses: eventWorkflowProcessesEntityAdapter.setAll(
    processes,
    state.templateIdEventWorkflowProcesses,
  ),
  loading: false,
  currentPage: pageNum,
  currentPageSize: pageSize,
});

const clearCache = (state: State) => ({
  ...state,
  cachedProcesses: cachedEventWorkflowProcessesEntityAdapter.removeAll(state.cachedProcesses),
});

export const eventWorkflowProcessesReducers = createReducer(
  eventWorkflowProcessesInitialState,
  on(EventWorkflowProcessesActions.loadCurrentEventWorkflowProcessSuccess, setCurrentEventWorkflowProcess),
  on(EventWorkflowProcessesActions.loadTemplateIdAndDateRangeEventWorkflowProcesses, startLoading),
  on(EventWorkflowProcessesActions.loadCurrentPageEventWorkflowProcesses, startLoading),
  on(EventWorkflowProcessesActions.loadCurrentPageSizeEventWorkflowProcesses, startLoading),
  on(EventWorkflowProcessesActions.cacheEventWorkflowProcesses, setCachedProcesses),
  on(EventWorkflowProcessesActions.setCurrentPage, setCurrentPage),
  on(EventWorkflowProcessesActions.clearCacheEventWorkflowProcesses, clearCache),
);

/**
 *
 * @param state
 * @param action
 */
export function reducer(state: State | undefined, action: Action): any {
  return eventWorkflowProcessesReducers(state, action);
}
