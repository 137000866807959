import { AuthStoreState } from '../common/auth/auth-store';
import { LoadingIndicatorStoreState } from '../common/loading-indicator/loading-indicator-store';
import { TypeManagerStoreState } from '../type-manager/type-manager-store';
import { UserOrgsStoreState } from '../users/users/user-orgs-store';
import { AppsStoreState } from '../app-store/store/apps-store';
import { OrgAppsStoreState } from '../app-store/store/org-apps-store';
import { EventWorkflowTemplatesStoreState } from '../event-workflows/store/event-templates';
import { EventWorkflowProcessesStoreState } from '../event-workflows/store/event-processes';
import { EventWorkflowTasksStoreState } from '../event-workflows/store/event-tasks';
import { EventWorkflowActionsStoreState } from '../event-workflows/store/event-actions';
import { GroupsStoreState } from '../users/groups/group-store';
import { AppVersionsStoreState } from '../app-store/store/app-versions-store';
import { SystemNotificationState } from '../system-notification/store';
import { ScheduleJobActionState } from '../schedule-job/store';
import { LoaderHistoryState } from '../loader/loader-history/store';
import { LoaderConfigState } from '../loader/loader-configs/store';
import { LibraryStoreState } from '../library/library-store';
import { AppExtensionsStoreState } from '@common/app-extensions/app-extensions-store';

export interface State {
  auth: AuthStoreState.State;
  appExtensions: AppExtensionsStoreState.State;
  loadingIndicator: LoadingIndicatorStoreState.State;
  typeManager: TypeManagerStoreState.State;
  userOrgs: UserOrgsStoreState.State;
  orgApps: OrgAppsStoreState.State;
  apps: AppsStoreState.State;
  appVersions: AppVersionsStoreState.State;
  eventWorkflowTemplates: EventWorkflowTemplatesStoreState.State;
  eventWorkflowActions: EventWorkflowActionsStoreState.State;
  eventWorkflowProcesses: EventWorkflowProcessesStoreState.State;
  eventWorkflowTasks: EventWorkflowTasksStoreState.State;
  groups: GroupsStoreState.State;
  systemNotifications: SystemNotificationState.State;
  scheduleJobs: ScheduleJobActionState.State;
  loaderHistory: LoaderHistoryState.State;
  loadConfigs: LoaderConfigState.State;
  library: LibraryStoreState.State;
}
