import { Component, OnInit } from '@angular/core';
import { TypeRoot } from '@contrail/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerSelectors } from '../type-manager-store';
import { TypesService } from '../types.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-root-type-selector',
  templateUrl: './root-type-selector.component.html',
  styleUrls: ['./root-type-selector.component.scss'],
})
export class RootTypeSelectorComponent implements OnInit {
  public selectedTypeRoot$: Observable<TypeRoot>;
  public typeRoots$: Observable<Array<TypeRoot>>;

  private pinnedTypes = ['item', 'project-item', 'plan-placeholder', 'assortment', 'assortment-item', 'custom-entity'];

  public typeRoots: Array<TypeRoot> = [];
  public prioritisedTypeRoots: Array<TypeRoot> = [];
  constructor(
    public store: Store<RootStoreState.State>,
    private typesService: TypesService,
  ) {
    this.typeRoots$ = this.store.select(TypeManagerSelectors.typeRoots);
    this.selectedTypeRoot$ = this.store.select(TypeManagerSelectors.currentTypeRoot);
  }

  ngOnInit(): void {
    this.setPriorityTypeRoots();
  }

  setPriorityTypeRoots() {
    this.typeRoots$
      .pipe(
        map((typeRoots) => {
          const prioritySelectorsMap = {};
          const priorityTypeRootSelector = [];
          const otherTypeRootSelectors = [];

          typeRoots.forEach((typeRoot) => {
            if (this.pinnedTypes.includes(typeRoot.slug)) {
              prioritySelectorsMap[typeRoot.slug] = typeRoot;
            } else {
              otherTypeRootSelectors.push(typeRoot);
            }
          });

          this.pinnedTypes.forEach((selector) => {
            if (prioritySelectorsMap[selector]) {
              priorityTypeRootSelector.push(prioritySelectorsMap[selector]);
            }
          });

          this.prioritisedTypeRoots = priorityTypeRootSelector;
          otherTypeRootSelectors.sort((a, b) => a.label.localeCompare(b.label));
          this.typeRoots = otherTypeRootSelectors;
        }),
      )
      .subscribe();
  }

  goToRoot(typeRoot) {
    if (!typeRoot) {
      return;
    }
    this.typesService.goToRoot(typeRoot.slug);
  }
}
