import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { ScheduleJobDefinition } from './schedule-job';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ScheduleJobService {
  constructor(private http: HttpClient) {}

  public getUrl() {
    return `${environment.apiBaseUrl}/scheduler`;
  }

  public async createScheduleJob(body): Promise<ScheduleJobDefinition> {
    return await new Entities().create({
      entityName: 'scheduler',
      object: body,
    });
  }

  public async updateScheduleJob(id: string, body): Promise<ScheduleJobDefinition> {
    return await new Entities().update({
      entityName: 'scheduler',
      id,
      object: body,
    });
  }

  public async loadScheduleJobs(): Promise<ScheduleJobDefinition[]> {
    return await new Entities().get({ entityName: 'scheduler' });
  }

  public async loadCurrentScheduleJob(id): Promise<ScheduleJobDefinition> {
    return await new Entities().get({ entityName: 'scheduler', id });
  }
}
