import { Component } from '@angular/core';

@Component({
  selector: 'app-dynamic-load-on-scroll',
  templateUrl: './dynamic-load-on-scroll.component.html',
  styleUrls: ['./dynamic-load-on-scroll.component.scss'],
})
export class DynamicLoadOnScrollComponent {
  constructor() {}
}
