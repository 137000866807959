<div class="flex flex-col h-full">
  <div *ngIf="dimensionCells2?.length > 0" class="flex justify-center">
    <div class="text-[12px] text-gray-600 -rotate-90 origin-center flex items-center font-medium">
      {{ sizeRangeConstraint?.dimension2?.label }}
    </div>
    <div class="flex flex-col items-center mt-[50px]">
      <div
        *ngFor="let dimension2 of dimensionCells2"
        class="flex justify-center w-[32px] h-[32px] text-[12px] text-gray-600"
      >
        {{ dimension2 }}
      </div>
    </div>
    <div class="flex flex-col items-center">
      <div class="text-[12px] text-gray-600 font-medium">{{ sizeRangeConstraint?.dimension1?.label }}</div>
      <div class="flex">
        <div *ngFor="let dimension1 of dimensionCells1" class="flex justify-center w-[32px] text-[12px] text-gray-600">
          {{ dimension1 }}
        </div>
      </div>
      <div class="grid border-t-[1px] border-l-[1px] border-black/10" [style.grid-template-columns]="gridColumnStyle">
        <ng-container *ngFor="let dimension2 of dimensionCells2">
          <app-size-range-cell
            *ngFor="let dimension1 of dimensionCells1"
            [sizes]="(sizeRangeData$ | async)?.sizes"
            [dimension1]="dimension1"
            [dimension2]="dimension2"
            [selectableCells]="selectableCells"
            [selectedSizeRangeCells]="selectedSizeRangeCells$ | async"
            [editMode]="editMode"
            (handleAction)="handleAction($event)"
            class="border-b-[1px] border-r-[1px] border-black/10"
          >
          </app-size-range-cell>
        </ng-container>
      </div>
    </div>
  </div>

  <div *ngIf="dimensionCells2.length === 0" class="flex flex-col w-full">
    <div class="flex flex-col w-full items-center text-[12px] text-gray-600 font-medium">
      {{ sizeRangeConstraint?.dimension1?.label }}
    </div>

    <div class="flex">
      <div *ngFor="let dimension1 of dimensionCells1" class="flex justify-center w-[33px] text-[12px] text-gray-600">
        {{ dimension1 }}
      </div>
    </div>
    <div class="flex border-t-[1px] border-l-[1px] border-black/10">
      <app-size-range-cell
        *ngFor="let dimension1 of dimensionCells1"
        [sizes]="(sizeRangeData$ | async)?.sizes"
        [dimension1]="dimension1"
        [selectedSizeRangeCells]="selectedSizeRangeCells$ | async"
        [editMode]="editMode"
        (handleAction)="handleAction($event)"
        class="border-b-[1px] border-r-[1px] border-black/10"
      >
      </app-size-range-cell>
    </div>
  </div>
</div>
