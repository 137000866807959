import { Types } from '@contrail/sdk';
import { TypeProperty } from '@contrail/types';

export class Type {
  private _type: any;
  private _interfaceMap: Map<string, any> = new Map();
  constructor(
    private id: string = null,
    private entityType = null,
  ) {}

  public async load() {
    if (this.id) {
      this._type = await new Types().getType({ id: this.id, relations: ['typeProperties'] });
    } else if (this.entityType) {
      this._type = await new Types().getByRootAndPath({
        root: this.entityType,
        path: this.entityType,
        relations: ['typeProperties'],
      });
    }
    if (!this._type) {
      return;
    }
    this._type.typeInterfaces.forEach((int) => {
      this._interfaceMap.set(int.slug, int);
    });
  }
  implements(interfaceName: string): boolean {
    return this._interfaceMap.has(interfaceName);
  }
  getTypeName() {
    return this._type?.label || this.entityType;
  }
  getRelations() {
    return this._type.relations;
  }
  getInterfaces() {
    return this._type.typeInterfaces;
  }
  getAllProperties(): Array<TypeProperty> {
    return this._type.typeProperties;
  }

  getTypePath() {
    return this._type?.typePath || this.entityType;
  }
  getEntityType() {
    return this.entityType;
  }
  getTypeId() {
    return this._type.id;
  }
}
