import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { tap } from 'rxjs/operators';
import { TypePropertyFormFieldBaseComponent } from './type-property-form-field-base.component';
@Component({
  selector: 'app-type-property-form-field-date',
  template: `
    <mat-form-field
      *ngIf="formControl"
      [floatLabel]="propertyFormConfiguration.isFilter ? 'never' : 'always'"
      [attr.data-test]="propertyFormConfiguration.typeProperty.slug + '-form-field'"
      class="date"
      [appearance]="appearance"
    >
      <mat-label>{{ propertyFormConfiguration.typeProperty.label }}</mat-label>
      <input
        #inputField
        [formControl]="formControl"
        matInput
        [min]="minDate"
        [max]="maxDate"
        [matDatepicker]="picker"
        placeholder="mm/dd/yyyy"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error *ngIf="formControl.hasError('error')">{{ formControl.getError('error') }}</mat-error>
      <app-type-property-form-validation-error
        *ngIf="formControl.hasError('error') && formControl.disabled"
        [errorMessage]="formControl.getError('error')"
      >
      </app-type-property-form-validation-error>
    </mat-form-field>
  `,
  styles: [
    `
      :host {
        display: block;
        min-height: 40px;
        max-width: 250px;
      }
      mat-form-field.date {
        max-width: 180px;
      }
      mat-form-field {
        width: 100%;
      }
    `,
  ],
})
export class TypePropertyFormFieldDateComponent extends TypePropertyFormFieldBaseComponent {
  minDate: Date;
  maxDate: Date;
  @Input() errorMessage: string;
  @ViewChild('inputField', {
    read: MatInput,
  })
  inputField: MatInput;
  private latestInputValue: string;

  constructor() {
    super();
    this.minDate = new Date(1000, 0, 1);
    this.maxDate = new Date(9999, 11, 31);
  }

  clearValue(): void {
    this.inputField.value = '';
  }

  initFormControlListener() {
    this.formChangeSub = this.formControl.valueChanges
      .pipe(
        tap((val: any) => {
          const updatedDate = this.getCorrectedDateValue(val);
          if (updatedDate !== this.value && this.formControl.valid) {
            this.inputField.value = new Date(updatedDate);
            this.value = updatedDate;

            if (typeof this.value === 'string') {
              this.value = new Date(this.value);
            }
            if (this.value instanceof Date) {
              const isoDatePart = this.getIsoDatePart(this.value);
              const utCIso = isoDatePart + 'T00:00:00.000Z';
              this.value = utCIso;
            }
            this.handleChange();
          }
        }),
      )
      .subscribe();
  }

  private getIsoDatePart(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  private getCorrectedDateValue(val: any) {
    const dateRegex = /^(0?[1-9]|1[0-2])([\/\-.])(0?[1-9]|[12]\d|3[01])$/;
    const isInputDateMissingYear = this.latestInputValue && dateRegex.test(this.latestInputValue);
    if (isInputDateMissingYear) {
      const currentYear = new Date().getFullYear();
      const delimiter = this.latestInputValue.includes('/') ? '/' : this.latestInputValue.includes('-') ? '-' : '.';
      const dateWithCurrentYear = `${this.latestInputValue}${delimiter}${currentYear}`;

      this.latestInputValue = dateWithCurrentYear;
      return dateWithCurrentYear;
    } else {
      return val;
    }
  }

  @HostListener('input', ['$event'])
  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.latestInputValue = input.value;
  }
}
