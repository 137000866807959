import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { SystemNotification } from './system-notification';

interface SystemNotificationState extends EntityState<SystemNotification> {}

export interface State {
  loading: boolean;
  hasError: boolean;
  systemNotifications: SystemNotificationState;
  currentSystemNotification: SystemNotification;
}

export const systemNotificationEntityAdapter: EntityAdapter<SystemNotification> =
  createEntityAdapter<SystemNotification>({});

export const systemNotificationInitialState = {
  loading: false,
  hasError: false,
  systemNotifications: systemNotificationEntityAdapter.getInitialState({}),
  currentSystemNotification: null,
};
