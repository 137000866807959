import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { NumberFormat, PropertyType, TypeProperty } from '@contrail/types';
import { TypeFormChange } from '../type-form-change';
import { TypePropertyFormConfiguration } from '../type-property-form/type-property-form.component';
import { TypePropertyFormFieldBaseComponent } from './property-types/type-property-form-field-base.component';

@Component({
  selector: 'app-type-property-form-field',
  templateUrl: './type-property-form-field.html',
  styleUrls: ['./type-property-form-field.scss'],
})
export class TypePropertyFormFieldComponent implements OnChanges, AfterViewInit {
  @Input() propertyFormConfiguration: TypePropertyFormConfiguration;
  @Input() entity: any;
  @Input() value: any;
  @Input() hint: string;
  @Input() errorMessage: string;
  @Output() valueChange = new EventEmitter<TypeFormChange>();
  @ViewChildren('formField') formFieldQuery: QueryList<any>;
  @Input() appearance = 'fill';
  public formFieldComponent: TypePropertyFormFieldBaseComponent;
  private priorValue;
  public property: TypeProperty;
  public propertyType: PropertyType;

  numericPropertyTypes = [PropertyType.Number, PropertyType.Currency, PropertyType.Sequence];
  stringPropertyTypes = [PropertyType.String, PropertyType.Text];

  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges) {
    if (!this.propertyFormConfiguration) {
      return;
    }
    this.property = this.propertyFormConfiguration?.typeProperty;
    this.propertyType = this.property?.propertyType;
    this.priorValue = this.value;
  }
  ngAfterViewInit(): void {
    if (this.formFieldQuery.length) {
      this.formFieldComponent = this.formFieldQuery.toArray()[0];
    }
  }
  clearValue() {
    this.value = null;
    this.formFieldComponent?.clearValue();
  }

  handleChange($event = null) {
    if ($event) {
      this.value = $event.value;
    }
    if (this.priorValue !== this.value) {
      const change = { value: this.value, propertySlug: this.property.slug };

      this.priorValue = this.value;
      this.valueChange.emit(change);
    }
  }

  isPercentProperty() {
    return Boolean(
      this.propertyType === PropertyType.Percent ||
        (this.propertyType === PropertyType.Formula && this.property.numberFormat?.format === NumberFormat.Percent),
    );
  }

  isNumberProperty() {
    return Boolean(
      this.numericPropertyTypes.includes(this.propertyType) ||
        (this.propertyType === PropertyType.Formula && this.property.numberFormat?.format !== NumberFormat.Percent),
    );
  }
}
