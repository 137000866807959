import { Injectable } from '@angular/core';
import { API_VERSION, Entities, SortOrderOptions } from '@contrail/sdk';

@Injectable({
  providedIn: 'root',
})
export class EventWorkflowProcessesService {
  constructor() {}

  public async getEventWorkflowProcessesById(id: string) {
    return new Entities().get({ entityName: 'event-workflow-process', id });
  }

  public async getEventWorkflowProcessesByTemplateIdForDateRange(
    templateId: string,
    startDate: string,
    endDate: string,
    sortOrder: SortOrderOptions = SortOrderOptions.DESC,
    status: string = null,
    pageSize: number = 25,
    nextPageKey: string = null,
  ) {
    const processes = await new Entities().get({
      entityName: 'event-workflow-process',
      criteria: {
        status,
        eventWorkflowTemplateId: templateId,
        createdOn: `BETWEEN ${startDate} and ${endDate}`,
      },
      apiVersion: API_VERSION.V2,
      take: pageSize,
      nextPageKey,
      order: [{ orderField: 'createdOn', order: sortOrder }],
    });
    return processes;
  }
}
