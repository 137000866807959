<div class="modal-container mb-2 flex flex-col">
  <div class="flex justify-between">
    <h6 data-test="app-confirmation-box-title">{{ data.title }}</h6>
    <mat-icon [mat-dialog-close]="false" class="cursor-pointer -mr-2 ml-3">close</mat-icon>
  </div>
  <mat-form-field class="option-full-width mt-5">
    <mat-label>Name</mat-label>
    <input
      [(ngModel)]="data.name"
      matInput
      placeholder="Enter Option Set Name"
      [formControl]="optionFormControl"
      required
    />
    <mat-error *ngIf="optionFormControl.invalid">{{ getErrorMessage() }}</mat-error>
  </mat-form-field>
  <div mat-dialog-actions class="flex items-center justify-end mt-3">
    <button mat-stroked-button color="primary" [mat-dialog-close]="false" class="!mr-3">
      {{ data.cancel || 'Cancel' }}
    </button>
    <button
      mat-raised-button
      color="primary"
      [mat-dialog-close]="true"
      cdkFocusInitial
      [disabled]="optionFormControl.invalid"
    >
      {{ data.okBtnLabel }}
    </button>
  </div>
</div>
