<div [ngClass]="{ spinShow: loading, spinHide: !loading }">
  <ng-container *ngIf="overlay; else progressSpinner">
    <div class="overlay">
      <div class="center">
        <ng-template [ngTemplateOutlet]="progressSpinner"></ng-template>
      </div>
    </div>
  </ng-container>
  <ng-template #progressSpinner>
    <div class="spinner-bg">
      <div class="message">{{ message }}</div>
      <div class="spinner-svg">
        <mat-progress-spinner
          [diameter]="diameter"
          [mode]="mode"
          [color]="color"
          [strokeWidth]="strokeWidth"
          [value]="value"
        >
        </mat-progress-spinner>
      </div>
    </div>
  </ng-template>
</div>
