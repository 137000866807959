<app-page-section *ngIf="results">
  <app-page-section-header>
    <app-page-section-title title="Results"></app-page-section-title>
  </app-page-section-header>
  <div class="results-count">Found: {{ results?.length }}</div>
  <app-list *ngIf="results?.length">
    <app-list-item *ngFor="let obj of results.slice(0, 300)" (click)="go(obj)">
      <div class="list-value">{{ obj.item?.name }}{{ obj.name || obj.fileName || obj.id }}</div>
    </app-list-item>
  </app-list>

  <div *ngIf="error">
    {{ format(error) }}
  </div>
</app-page-section>
