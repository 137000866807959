import { Component, Input, ViewChild } from '@angular/core';
import { TypesService } from '../../types.service';
import { TypeMenuItem } from './type-menu-item';

@Component({
  selector: 'app-type-selector-menu',
  templateUrl: './type-selector-menu.component.html',
  styleUrls: ['./type-selector-menu.component.scss'],
})
export class TypeSelectorMenuComponent {
  @Input() typeMenu: TypeMenuItem;
  @ViewChild('childMenu', { static: true }) public childMenu;

  constructor(private typesService: TypesService) {}

  goToType(typeMenuItem: TypeMenuItem) {
    this.typesService.goToType(typeMenuItem.id);
  }

  // https://stackblitz.com/edit/dynamic-nested-topnav-menu?file=app%2Fmenu-item%2Fmenu-item.component.html
}
