import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { App } from './store-app';
import { Org } from '@common/auth/auth.service';

interface AppsState extends EntityState<App> {}
interface OrgsState extends EntityState<Org> {}

export interface State {
  apps: AppsState;
  currentApp: App;
  orgs: OrgsState;
}
export const appsEntityAdapter: EntityAdapter<App> = createEntityAdapter<App>({});
export const orgsEntityAdapter: EntityAdapter<Org> = createEntityAdapter<Org>({});

export const appsInitialState = {
  apps: appsEntityAdapter.getInitialState({}),
  currentApp: null,
  orgs: orgsEntityAdapter.getInitialState({}),
};
