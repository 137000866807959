import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { EventWorkflowActionDefinition } from './event-action';

@Injectable({
  providedIn: 'root',
})
export class EventWorkflowActionsService {
  constructor() {}

  public async getEventWorkflowActionsById(id: string) {
    return new Entities().get({ entityName: 'event-workflow-action-definition', id });
  }
  /** Gets all available actions for use. */
  public async getEventWorkflowActions() {
    const orgApps = await new Entities().get({ entityName: 'app-org', relations: ['app'] });
    const actions = [];

    actions.push(
      ...(await new Entities().get({ entityName: 'event-workflow-action-definition', criteria: { ownerKey: 'org' } })),
    );

    const promises = orgApps.map(async (orgApp) => {
      const appActions = await new Entities().get({
        entityName: 'event-workflow-action-definition',
        criteria: { ownerKey: `appVersion:${orgApp.appVersionId}` },
      });
      appActions.forEach((a) => (a.app = orgApp.app));
      actions.push(...appActions);
    });

    await Promise.all(promises);

    return actions;
  }

  public async createEventWorkflowAction({ name, ownerKey, publicStatus, executeFunctionString }) {
    return new Entities().create({
      entityName: 'event-workflow-action-definition',
      object: { name, ownerKey, publicStatus, executeFunctionString },
    });
  }
  public async deleteEventWorkflowAction(eventWorkflowAction: EventWorkflowActionDefinition) {
    await new Entities().delete({ entityName: 'event-workflow-action-definition', id: eventWorkflowAction.id });
    return eventWorkflowAction;
  }
  public async updateEventWorkflowAction(id: string, changes: EventWorkflowActionDefinition) {
    return new Entities().update({ entityName: 'event-workflow-action-definition', id, object: changes });
  }
}
