import { createAction, props } from '@ngrx/store';
import { EventWorkflowTemplate, WorkflowDefinition } from './event-template';

export enum EventWorkflowTemplatesActionTypes {
  LOAD_EVENTWORKFLOWTEMPLATES = '[EventWorkflowTemplates] Load EventWorkflowTemplates',
  LOAD_EVENTWORKFLOWTEMPLATES_SUCCESS = '[EventWorkflowTemplates] Load EventWorkflowTemplates Success',
  LOAD_EVENTWORKFLOWTEMPLATES_FAILURE = '[EventWorkflowTemplates] Load EventWorkflowTemplates Failure',

  CREATE_EVENTWORKFLOWTEMPLATE = '[EventWorkflowTemplates] Create EventWorkflowTemplate',
  CREATE_EVENTWORKFLOWTEMPLATE_SUCCESS = '[EventWorkflowTemplates] Create EventWorkflowTemplate Success',
  CREATE_EVENTWORKFLOWTEMPLATE_FAILURE = '[EventWorkflowTemplates] Create EventWorkflowTemplate Failure',

  DELETE_EVENTWORKFLOWTEMPLATE = '[EventWorkflowTemplates] Delete EventWorkflowTemplate',
  DELETE_EVENTWORKFLOWTEMPLATE_SUCCESS = '[EventWorkflowTemplates] Delete EventWorkflowTemplate Success',
  DELETE_EVENTWORKFLOWTEMPLATE_FAILURE = '[EventWorkflowTemplates] Delete EventWorkflowTemplate Failure',

  DELETE_EVENTWORKFLOWTEMPLATES = '[EventWorkflowTemplates] Delete EventWorkflowTemplates',
  DELETE_EVENTWORKFLOWTEMPLATES_SUCCESS = '[EventWorkflowTemplates] Delete EventWorkflowTemplates Success',
  DELETE_EVENTWORKFLOWTEMPLATES_FAILURE = '[EventWorkflowTemplates] Delete EventWorkflowTemplates Failure',

  UPDATE_EVENTWORKFLOWTEMPLATE = '[EventWorkflowTemplates] Update EventWorkflowTemplate',
  UPDATE_EVENTWORKFLOWTEMPLATE_SUCCESS = '[EventWorkflowTemplates] Update EventWorkflowTemplate Success',
  UPDATE_EVENTWORKFLOWTEMPLATE_FAILURE = '[EventWorkflowTemplates] Update EventWorkflowTemplate Failure',

  UPDATE_EVENTWORKFLOWTEMPLATES = '[EventWorkflowTemplates] Update EventWorkflowTemplates',
  UPDATE_EVENTWORKFLOWTEMPLATES_SUCCESS = '[EventWorkflowTemplates] Update EventWorkflowTemplates Success',
  UPDATE_EVENTWORKFLOWTEMPLATES_FAILURE = '[EventWorkflowTemplates] Update EventWorkflowTemplates Failure',

  LOAD_CURRENT_EVENTWORKFLOWTEMPLATE = '[EventWorkflowTemplates] Load Current EventWorkflowTemplate',
  LOAD_CURRENT_EVENTWORKFLOWTEMPLATE_SUCCESS = '[EventWorkflowTemplates] Load Current EventWorkflowTemplate Success',
  LOAD_CURRENT_EVENTWORKFLOWTEMPLATE_FAILURE = '[EventWorkflowTemplates] Load Current EventWorkflowTemplate Failure',

  LOAD_TRIGGERKEY_EVENTWORKFLOWTEMPLATES = '[EventWorkflowTemplates] Load ByTriggerkey EventWorkflowTemplates',
  LOAD_TRIGGERKEY_EVENTWORKFLOWTEMPLATES_SUCCESS = '[EventWorkflowTemplates] Load ByTriggerkey EventWorkflowTemplates Success',
  LOAD_TRIGGERKEY_EVENTWORKFLOWTEMPLATES_FAILURE = '[EventWorkflowTemplates] Load ByTriggerkey EventWorkflowTemplates Failure',

  LOAD_ALL_TEMPLATE_ACTION_LINKS = '[EventWorkflowTemplates] Load TEMPLATE_ACTION_LINKS',
  LOAD_ALL_TEMPLATE_ACTION_LINKS_SUCCESS = '[EventWorkflowTemplates] Load TEMPLATE_ACTION_LINKS Success',
  LOAD_ALL_TEMPLATE_ACTION_LINKS_FAILURE = '[EventWorkflowTemplates] Load TEMPLATE_ACTION_LINKS Failure',

  LOAD_TEMPLATE_ACTION_LINK_FOR_APP = '[EventWorkflowTemplates] Load TEMPLATE_ACTION_LINK_FOR_APP',
  LOAD_TEMPLATE_ACTION_LINK_FOR_APP_SUCCESS = '[EventWorkflowTemplates] Load TEMPLATE_ACTION_LINK_FOR_APP Success',
  LOAD_TEMPLATE_ACTION_LINK_FOR_APP_FAILURE = '[EventWorkflowTemplates] Load TEMPLATE_ACTION_LINK_FOR_APP Failure',
}

/// /////////////////////////////////////////////////////////////////////////////
export const loadEventWorkflowTemplates = createAction(EventWorkflowTemplatesActionTypes.LOAD_EVENTWORKFLOWTEMPLATES);

export const loadEventWorkflowTemplatesSuccess = createAction(
  EventWorkflowTemplatesActionTypes.LOAD_EVENTWORKFLOWTEMPLATES_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const loadEventWorkflowTemplatesFailure = createAction(
  EventWorkflowTemplatesActionTypes.LOAD_EVENTWORKFLOWTEMPLATES_FAILURE,
  props<{ error: any }>(),
);

export const createEventWorkflowTemplate = createAction(
  EventWorkflowTemplatesActionTypes.CREATE_EVENTWORKFLOWTEMPLATE,
  props<{
    name: string;
    triggerKey: string;
    identifier: string;
    isActive: boolean;
    supportingAppId: string;
    workflowDefinition: WorkflowDefinition;
    ownerKey: string;
    messageGroupId?: string;
    dynamicMessageGroupId?: string;
    messageGroup?: string;
    parallelWorkerCount?: number;
  }>(),
);

export const createEventWorkflowTemplateSuccess = createAction(
  EventWorkflowTemplatesActionTypes.CREATE_EVENTWORKFLOWTEMPLATE_SUCCESS,
  props<{ eventWorkflowTemplate: EventWorkflowTemplate }>(),
);

export const createEventWorkflowTemplateFailure = createAction(
  EventWorkflowTemplatesActionTypes.CREATE_EVENTWORKFLOWTEMPLATE_FAILURE,
  props<{ error: any }>(),
);

export const updateEventWorkflowTemplate = createAction(
  EventWorkflowTemplatesActionTypes.UPDATE_EVENTWORKFLOWTEMPLATE,
  props<{ id: string; changes: Partial<EventWorkflowTemplate> }>(),
);

export const updateEventWorkflowTemplateSuccess = createAction(
  EventWorkflowTemplatesActionTypes.UPDATE_EVENTWORKFLOWTEMPLATE_SUCCESS,
  props<{ id: string; changes: EventWorkflowTemplate }>(),
);

export const updateEventWorkflowTemplateFailure = createAction(
  EventWorkflowTemplatesActionTypes.UPDATE_EVENTWORKFLOWTEMPLATE_FAILURE,
  props<{ error: any }>(),
);

// data: Array<any> ~ [{id: string, changes: EventWorkflowTemplate}]
export const updateEventWorkflowTemplates = createAction(
  EventWorkflowTemplatesActionTypes.UPDATE_EVENTWORKFLOWTEMPLATES,
  props<{ data: Array<any> }>(),
);

export const updateEventWorkflowTemplatesSuccess = createAction(
  EventWorkflowTemplatesActionTypes.UPDATE_EVENTWORKFLOWTEMPLATES_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const updateEventWorkflowTemplatesFailure = createAction(
  EventWorkflowTemplatesActionTypes.UPDATE_EVENTWORKFLOWTEMPLATES_FAILURE,
  props<{ error: any }>(),
);

export const deleteEventWorkflowTemplate = createAction(
  EventWorkflowTemplatesActionTypes.DELETE_EVENTWORKFLOWTEMPLATE,
  props<{ eventWorkflowTemplate: EventWorkflowTemplate }>(),
);

export const deleteEventWorkflowTemplateSuccess = createAction(
  EventWorkflowTemplatesActionTypes.DELETE_EVENTWORKFLOWTEMPLATE_SUCCESS,
  props<{ eventWorkflowTemplate: EventWorkflowTemplate }>(),
);

export const deleteEventWorkflowTemplateFailure = createAction(
  EventWorkflowTemplatesActionTypes.DELETE_EVENTWORKFLOWTEMPLATE_FAILURE,
  props<{ error: any }>(),
);

export const deleteEventWorkflowTemplates = createAction(
  EventWorkflowTemplatesActionTypes.DELETE_EVENTWORKFLOWTEMPLATES,
  props<{ ids: string[] }>(),
);

export const deleteEventWorkflowTemplatesSuccess = createAction(
  EventWorkflowTemplatesActionTypes.DELETE_EVENTWORKFLOWTEMPLATES_SUCCESS,
  props<{ ids: string[] }>(),
);

export const deleteEventWorkflowTemplatesFailure = createAction(
  EventWorkflowTemplatesActionTypes.DELETE_EVENTWORKFLOWTEMPLATES_FAILURE,
  props<{ error: any }>(),
);

export const loadCurrentEventWorkflowTemplate = createAction(
  EventWorkflowTemplatesActionTypes.LOAD_CURRENT_EVENTWORKFLOWTEMPLATE,
  props<{ id: string }>(),
);

export const loadCurrentEventWorkflowTemplateSuccess = createAction(
  EventWorkflowTemplatesActionTypes.LOAD_CURRENT_EVENTWORKFLOWTEMPLATE_SUCCESS,
  props<{ eventWorkflowTemplate: EventWorkflowTemplate }>(),
);

export const loadCurrentEventWorkflowTemplateFailure = createAction(
  EventWorkflowTemplatesActionTypes.LOAD_CURRENT_EVENTWORKFLOWTEMPLATE_FAILURE,
  props<{ error: any }>(),
);

export const loadTriggerkeyEventWorkflowTemplates = createAction(
  EventWorkflowTemplatesActionTypes.LOAD_TRIGGERKEY_EVENTWORKFLOWTEMPLATES,
  props<{ triggerKey: string }>(),
);

export const loadTriggerkeyEventWorkflowTemplatesSuccess = createAction(
  EventWorkflowTemplatesActionTypes.LOAD_TRIGGERKEY_EVENTWORKFLOWTEMPLATES_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const loadTriggerkeyEventWorkflowTemplatesFailure = createAction(
  EventWorkflowTemplatesActionTypes.LOAD_TRIGGERKEY_EVENTWORKFLOWTEMPLATES_FAILURE,
  props<{ error: any }>(),
);

export const loadAllTemplateActionLinks = createAction(
  EventWorkflowTemplatesActionTypes.LOAD_ALL_TEMPLATE_ACTION_LINKS,
);

export const loadAllTemplateActionLinksSuccess = createAction(
  EventWorkflowTemplatesActionTypes.LOAD_ALL_TEMPLATE_ACTION_LINKS_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const loadAllTemplateActionLinksFailure = createAction(
  EventWorkflowTemplatesActionTypes.LOAD_ALL_TEMPLATE_ACTION_LINKS_FAILURE,
  props<{ error: any }>(),
);

export const loadTemplateActionLinkForApp = createAction(
  EventWorkflowTemplatesActionTypes.LOAD_TEMPLATE_ACTION_LINK_FOR_APP,
  props<{ appId: string }>(),
);

export const loadTemplateActionLinkForAppSuccess = createAction(
  EventWorkflowTemplatesActionTypes.LOAD_TEMPLATE_ACTION_LINK_FOR_APP_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const loadTemplateActionLinkForAppFailure = createAction(
  EventWorkflowTemplatesActionTypes.LOAD_TEMPLATE_ACTION_LINK_FOR_APP_FAILURE,
  props<{ error: any }>(),
);
