import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { OptionSetHierarchy } from './option-set-hierarchies.state';

@Injectable({
  providedIn: 'root',
})
export class OptionSetHierarchiesService {
  constructor() {}

  public async get() {
    let sets = await new Entities().get({ entityName: 'option-set-hierarchy' });
    sets = sets.sort((s1, s2) => (s1.name > s2.name ? 1 : -1));
    return sets;
  }
  public async create(ruleSet: OptionSetHierarchy) {
    return new Entities().create({ entityName: 'option-set-hierarchy', object: ruleSet });
  }
  public async update(id: string, changes: OptionSetHierarchy) {
    return new Entities().update({ entityName: 'option-set-hierarchy', id, object: changes });
  }
  public async delete(ruleSet: OptionSetHierarchy) {
    await new Entities().delete({ entityName: 'option-set-hierarchy', id: ruleSet.id });
    return ruleSet;
  }
}
