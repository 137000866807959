import { ChangeDetectorRef, Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appAutoFocus]',
})
export class AutoFocusDirective implements AfterViewInit {
  constructor(
    private elementRef: ElementRef,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.focus();
    this.cdRef.detectChanges();
  }
}
