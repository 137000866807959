import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MatSnackBar } from '@angular/material/snack-bar';
import { from, of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { LoaderHistoryResponse } from './loader-history';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { LoaderHistoryService } from './loader-history.service';
import { LoaderHistoryActions } from '.';
import { loadPreprocessingPipelineDetailAction } from './loader-history.actions';

@Injectable()
export class LoaderHistoryEffects {
  constructor(
    private actions$: Actions,
    private loaderHistoryService: LoaderHistoryService,
    private snackBar: MatSnackBar,
    private store: Store<RootStoreState.State>,
  ) {}

  loadLoaderHistoryActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoaderHistoryActions.LoaderHistoryActionTypes.LOAD_LOADER_HISTORY_ACTION),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        if (store.loaderHistory.loaderHistory.ids.length) {
          return of(
            LoaderHistoryActions.loadLoaderHistoryActionSuccess({
              data: {
                results: Object.values(store.loaderHistory.loaderHistory.entities),
                nextPageKey: store.loaderHistory.nextPageKey,
              },
            }),
          );
        } else {
          return from(this.loaderHistoryService.fetchLoaderProcessBetweenRange(action.startDate, action.endDate)).pipe(
            map((data: LoaderHistoryResponse) => {
              const results = data?.results.sort((a, b) => {
                return a.createdOn < b.createdOn ? 1 : -1;
              });
              return LoaderHistoryActions.loadLoaderHistoryActionSuccess({
                data: {
                  results,
                  nextPageKey: data.nextPageKey,
                },
              });
            }),
            catchError((error) =>
              of(
                LoaderHistoryActions.loadLoaderHistoryActionFailure({
                  error,
                }),
              ),
            ),
          );
        }
      }),
    ),
  );

  loadLogFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoaderHistoryActions.LoaderHistoryActionTypes.LOAD_S3_LOGS_ACTION),
      switchMap((action: any) => {
        if (!action.downloadUrl) {
          return of(
            LoaderHistoryActions.loadS3LogsActionSuccess({
              logText: action.processLogs,
            }),
          );
        } else {
          return from(this.loaderHistoryService.fetchS3Log(action.downloadUrl)).pipe(
            map((data: any) =>
              LoaderHistoryActions.loadS3LogsActionSuccess({
                logText: data,
              }),
            ),
            catchError(() =>
              of(
                LoaderHistoryActions.loadS3LogsActionSuccess({
                  logText: action.processLogs,
                }),
              ),
            ),
          );
        }
      }),
    ),
  );

  loadLoaderCurrentHistoryActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoaderHistoryActions.LoaderHistoryActionTypes.LOAD_CURRENT_LOADER_HISTORY_ACTION),
      switchMap((action: any) =>
        from(this.loaderHistoryService.fetchCurrentLoaderProcess(action.processId)).pipe(
          switchMap((data: any) => {
            const actions = [];
            actions.push(
              LoaderHistoryActions.loadCurrentLoaderHistoryActionSuccess({
                data,
              }),
            );

            if (data?.loadProcessResultDownloadLink) {
              actions.push(
                LoaderHistoryActions.loadCurrentLoaderHistoryDetailAction({
                  downloadUrl: data?.loadProcessResultDownloadLink,
                }),
              );
            }

            return actions;
          }),
          catchError((error) =>
            of(
              LoaderHistoryActions.loadCurrentLoaderHistoryActionFailure({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  loadLoaderCurrentHistoryDetailActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoaderHistoryActions.LoaderHistoryActionTypes.LOAD_CURRENT_LOADER_HISTORY_DETAIL_ACTION),
      switchMap((action: any) =>
        from(this.loaderHistoryService.fetchCurrentLoaderProcessDetail(action.downloadUrl)).pipe(
          map((data: any) =>
            LoaderHistoryActions.loadCurrentLoaderHistoryDetailActionSuccess({
              data,
            }),
          ),
          catchError((error) =>
            of(
              LoaderHistoryActions.loadCurrentLoaderHistoryDetailActionFailure({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  fail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        LoaderHistoryActions.LoaderHistoryActionTypes.LOAD_LOADER_HISTORY_ACTION_FAILURE,
        LoaderHistoryActions.LoaderHistoryActionTypes.LOAD_CURRENT_LOADER_HISTORY_ACTION_FAILURE,
        LoaderHistoryActions.LoaderHistoryActionTypes.LOAD_CURRENT_LOADER_HISTORY_DETAIL_ACTION_FAILURE,
      ),
      map((action: any) => {
        this.snackBar.open(action.error?.statusText, '', { duration: 2000 });
        return null;
      }),
    ),
  );

  loadPreprocessingPipelineDetailActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoaderHistoryActions.LoaderHistoryActionTypes.LOAD_PREPROCESSING_PIPELINE_DETAIL_ACTION),
      switchMap((action: any) =>
        from(this.loaderHistoryService.fetchPreprocessingPipelineDetail(action.fileId)).pipe(
          map((data: any) =>
            LoaderHistoryActions.loadPreprocessingFileDetailAction({
              fileId: data.id,
              downloadUrl: data.downloadUrl,
            }),
          ),
          catchError((error) =>
            of(
              LoaderHistoryActions.loadPreprocessingPipelineDetailActionFailure({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  loadPreprocessingFileDetailActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoaderHistoryActions.LoaderHistoryActionTypes.LOAD_PREPROCESSING_FILE_DETAIL_ACTION),
      switchMap((action: any) =>
        from(this.loaderHistoryService.fetchPreprocessingFileDetail(action.downloadUrl)).pipe(
          map((data: any) =>
            LoaderHistoryActions.loadPreprocessingFileDetailActionSuccess({
              data,
              fileId: action.fileId,
            }),
          ),
          catchError((error) =>
            of(
              LoaderHistoryActions.loadPreprocessingFileDetailActionFailure({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );
}
