import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DateRangeCalendarComponent } from '@components/date-range-time-picker/date-range-time-calendar/date-range-calendar.component';
import { FormGroup, FormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { isoDateAdapter } from '@components/date-range-time-picker/date-adapter/isoDateAdapter';

@Component({
  selector: 'date-range-time-picker',
  templateUrl: './date-range-time-picker.component.html',
  styleUrls: ['./date-range-time-picker.component.scss'],
  providers: [{ provide: DateAdapter, useClass: isoDateAdapter }],
})
export class DateRangeTimePickerComponent implements OnInit {
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Output() urlParamsData = new EventEmitter<object>();
  dateForm: FormGroup;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.dateForm = new FormGroup({
      start: new FormControl(this.startDate),
      end: new FormControl(this.endDate),
    });
  }

  openDatePicker() {
    this.dialog
      .open(DateRangeCalendarComponent, {
        data: {
          startDate: this.startDate,
          endDate: this.endDate,
          minDate: this.minDate,
          maxDate: this.maxDate,
        },
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result) => {
        if (!result) return;
        this.dateForm.controls['start'].setValue(result.startDate);
        this.dateForm.controls['end'].setValue(result.endDate);
        this.urlParamsData.emit({ startDate: result.startDate, endDate: result.endDate, pageSize: result.pageSize });
      });
  }
}
