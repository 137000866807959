import { Component, Input } from '@angular/core';
import { RoutingService } from 'src/app/common/routing/routing.service';
import { TypePropertyOptionSet } from 'src/app/type-manager/type-manager-store/option-sets/option-sets.state';

@Component({
  selector: 'app-type-property-option-set-option-editor-header',
  templateUrl: './type-property-option-set-option-editor-header.component.html',
  styleUrls: ['./type-property-option-set-option-editor-header.component.scss'],
})
export class TypePropertyOptionSetOptionEditorHeaderComponent {
  @Input() typePropertyOptionSet: TypePropertyOptionSet;
  constructor(private routingService: RoutingService) {}

  close() {
    this.routingService.go('/type-manager/option-sets');
  }
}
