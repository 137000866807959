<div class="spinner-bg" *ngIf="isLoading; else loaded">
  <mat-spinner diameter="50" mode="indeterminate"></mat-spinner>
</div>
<ng-template #loaded>
  <ng-container *ngIf="policies?.length; else noPolicies">
    <div
      class="list-item"
      *ngFor="let policy of policies"
      [ngClass]="{ selectable: selectable, selected: selectedTypePolicy === policy.id }"
      (click)="handleClick(policy)"
    >
      <div class="list-value" [ngClass]="{ 'w-half': isDetailViewOpen }">
        {{ policy.label }}
      </div>
      <div class="list-action flex flex-row" [ngClass]="{ 'w-half': isDetailViewOpen }">
        <button mat-button (click)="handleEdit($event, policy)" data-test="type-policy-edit">
          <mat-icon>mode_edit</mat-icon>
        </button>
        <button mat-button (click)="handleDelete($event, policy)" data-test="type-policy-delete">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-template #noPolicies>
    <div class="no-data">No policies found</div>
  </ng-template>
</ng-template>
