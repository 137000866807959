import { Action, createReducer, on } from '@ngrx/store';
import { State, appsEntityAdapter, appsInitialState } from './apps.state';
import * as AppsActions from './apps.actions';

const setData = (state: State, { data }) => {
  return {
    ...state,
    apps: appsEntityAdapter.setAll(data, state.apps),
  };
};
const clearData = (state: State, {}) => {
  return {
    ...state,
    apps: appsEntityAdapter.removeAll(state.apps),
  };
};
const add = (state: State, { app }) => {
  return {
    ...state,
    apps: appsEntityAdapter.addOne(app, state.apps),
  };
};
const update = (state: State, { id, changes }) => {
  return {
    ...state,
    apps: appsEntityAdapter.updateOne({ id, changes }, state.apps),
  };
};
const remove = (state: State, { app }) => {
  return {
    ...state,
    apps: appsEntityAdapter.removeOne(app.id, state.apps),
  };
};
const setCurrentApp = (state: State, { app }) => {
  return {
    ...state,
    currentApp: app,
  };
};

export const appsReducers = createReducer(
  appsInitialState,
  on(AppsActions.loadAppsSuccess, setData),
  on(AppsActions.createAppSuccess, add),
  on(AppsActions.deleteAppSuccess, remove),
  on(AppsActions.updateAppSuccess, update),
  on(AppsActions.loadCurrentAppSuccess, setCurrentApp),
);

/**
 *
 * @param state
 * @param action
 */
export function reducer(state: State | undefined, action: Action): any {
  return appsReducers(state, action);
}
