import { createAction, props } from '@ngrx/store';
import { TypePropertyPolicy } from './type-property-policies.state';

export enum TypePropertyPoliciesActionTypes {
  LOAD_TYPE_PROPERTY_POLICIES = '[Type Manager -Type Property Policies] Load Type Property Policies',
  LOAD_TYPE_PROPERTY_POLICIES_SUCCESS = '[Type Manager -Type Property Policies] Load Type Property Policies Success',
  LOAD_TYPE_PROPERTY_POLICIES_FAILURE = '[Type Manager -Type Property Policies] Load Type Property Policies Failure',

  CREATE_TYPE_PROPERTY_POLICY = '[Type Manager -Type Property Policies] Create Type Property Option Set',
  CREATE_TYPE_PROPERTY_POLICY_SUCCESS = '[Type Manager -Type Property Policies] Create Type Property Option Set Success',
  CREATE_TYPE_PROPERTY_POLICY_FAILURE = '[Type Manager -Type Property Policies] Create Type Property Option Set Failure',

  DELETE_TYPE_PROPERTY_POLICY = '[Type Manager -Type Property Policies] Delete Type Property Option Set',
  DELETE_TYPE_PROPERTY_POLICY_SUCCESS = '[Type Manager -Type Property Policies] Delete Type Property Option Set Success',
  DELETE_TYPE_PROPERTY_POLICY_FAILURE = '[Type Manager -Type Property Policies] Delete Type Property Option Set Failure',

  UPDATE_TYPE_PROPERTY_POLICY = '[Type Manager -Type Property Policies] Update Type Property Option Set',
  UPDATE_TYPE_PROPERTY_POLICY_SUCCESS = '[Type Manager -Type Property Policies] Update Type Property Option Set Success',
  UPDATE_TYPE_PROPERTY_POLICY_FAILURE = '[Type Manager -Type Property Policies] Update Type Property Option Set Failure',
}

/// /////////////////////////////////////////////////////////////////////////////

export const loadTypePropertyPolicies = createAction(TypePropertyPoliciesActionTypes.LOAD_TYPE_PROPERTY_POLICIES);

export const loadTypePropertyPoliciesSuccess = createAction(
  TypePropertyPoliciesActionTypes.LOAD_TYPE_PROPERTY_POLICIES_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const loadTypePropertyPoliciesFailure = createAction(
  TypePropertyPoliciesActionTypes.LOAD_TYPE_PROPERTY_POLICIES_FAILURE,
  props<{ error: any }>(),
);

export const createTypePropertyPolicy = createAction(
  TypePropertyPoliciesActionTypes.CREATE_TYPE_PROPERTY_POLICY,
  props<{ typePropertyPolicy: TypePropertyPolicy }>(),
);

export const createTypePropertyPolicySuccess = createAction(
  TypePropertyPoliciesActionTypes.CREATE_TYPE_PROPERTY_POLICY_SUCCESS,
  props<{ typePropertyPolicy: TypePropertyPolicy }>(),
);

export const createTypePropertyPolicyFailure = createAction(
  TypePropertyPoliciesActionTypes.CREATE_TYPE_PROPERTY_POLICY_FAILURE,
  props<{ error: any }>(),
);
export const deleteTypePropertyPolicy = createAction(
  TypePropertyPoliciesActionTypes.DELETE_TYPE_PROPERTY_POLICY,
  props<{ typePropertyPolicy: TypePropertyPolicy }>(),
);

export const deleteTypePropertyPolicySuccess = createAction(
  TypePropertyPoliciesActionTypes.DELETE_TYPE_PROPERTY_POLICY_SUCCESS,
  props<{ typePropertyPolicy: TypePropertyPolicy }>(),
);

export const deleteTypePropertyPolicyFailure = createAction(
  TypePropertyPoliciesActionTypes.DELETE_TYPE_PROPERTY_POLICY_FAILURE,
  props<{ error: any }>(),
);

export const updateTypePropertyPolicy = createAction(
  TypePropertyPoliciesActionTypes.UPDATE_TYPE_PROPERTY_POLICY,
  props<{ id: string; changes: TypePropertyPolicy }>(),
);

export const updateTypePropertyPolicySuccess = createAction(
  TypePropertyPoliciesActionTypes.UPDATE_TYPE_PROPERTY_POLICY_SUCCESS,
  props<{ id: string; changes: TypePropertyPolicy }>(),
);

export const updateTypePropertyPolicyFailure = createAction(
  TypePropertyPoliciesActionTypes.UPDATE_TYPE_PROPERTY_POLICY_FAILURE,
  props<{ error: any }>(),
);
