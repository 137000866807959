import { Component, Input } from '@angular/core';
import { TypeProperty } from '@contrail/types';

@Component({
  selector: 'app-type-property-core',
  templateUrl: './type-property-core.component.html',
  styleUrls: ['./type-property-core.component.scss'],
})
export class TypePropertyCoreComponent {
  @Input() typeProperty: TypeProperty;
  constructor() {}
}
