<div class="search" [class.lg-gray]="lgGray">
  <mat-icon>search</mat-icon>
  <input
    #inputField
    spellcheck="false"
    type="input"
    [formControl]="searchInput"
    [placeholder]="placeholder"
    [class.text-sm]="!lgGray || textSmall"
    [class.text-title-1]="lgGray && !textSmall"
    (keydown)="preventKeyPropagation($event)"
    data-test="search-bar"
  />

  <span
    *ngIf="showClearButton && searchInput.value"
    data-test="app-search-close-button"
    (click)="clear()"
    class="material-icons text-black24 hover:text-black60 cursor-pointer"
  >
    close
  </span>
</div>
