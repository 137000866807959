export interface FieldOption {
  label: string;
  value: string;
}
export interface AdditionFieldConfig {
  type: 'text' | 'single_select';
  placeholder: string;
  key: string;
  alias?: string;
  value: string;
  options: FieldOption[];
}

export enum DATA_TYPE {
  ARRAY,
  OBJECT,
  OBJECT_ARRAY,
}
