import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { TypeProperty } from '@contrail/types';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfirmationBoxService } from 'src/app/common/components/confirmation-box/confirmation-box';
import { MenuComponent } from 'src/app/common/components/menu/menu.component';
import { SearchBarComponent } from 'src/app/common/components/search-bar/search-bar.component';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerActions, TypeManagerSelectors } from '../type-manager-store';

@Component({
  selector: 'app-type-properties',
  templateUrl: './type-properties.component.html',
  styleUrls: ['./type-properties.component.scss'],
})
export class TypePropertiesComponent implements AfterViewInit {
  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;
  @ViewChild(MenuComponent) addPropertyMenu: MenuComponent;
  public filterdProperties$: Observable<Array<TypeProperty>>;
  constructor(
    private store: Store<RootStoreState.State>,
    private confirmationBoxService: ConfirmationBoxService,
  ) {}
  ngAfterViewInit(): void {
    // this.store.dispatch(TypeManagerActions.loadTypeProperties());
    this.filterdProperties$ = combineLatest([
      this.searchBar.valueChange.pipe(startWith('')),
      this.store.select(TypeManagerSelectors.typeProperties),
    ]).pipe(
      map(([searchTerm, properties]) => {
        const keys = 'label,slug';
        return properties.filter((item) =>
          keys.split(',').some((key) => item.hasOwnProperty(key) && new RegExp(searchTerm, 'gi').test(item[key])),
        );
      }),
    );
    this.store.dispatch(TypeManagerActions.setCurrentTypeProperty({ currentTypeProperty: null }));
  }

  cancelAdd() {
    this.addPropertyMenu.close();
  }
  completeAdd() {
    this.addPropertyMenu.close();
  }
  async deleteProperty(property: TypeProperty) {
    const confirm = await this.confirmationBoxService.open(
      'Delete Property?',
      'Are you sure you want to delete this property?.  The system will ensure that it is not being used.',
    );
    if (confirm) {
      this.store.dispatch(TypeManagerActions.deleteTypeProperty({ typeProperty: property }));
    }
  }

  showPropertyDetails(property: TypeProperty) {
    this.store.dispatch(TypeManagerActions.setCurrentTypeProperty({ currentTypeProperty: property }));
  }
}
