import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { TypePropertyOptionSet } from '../type-manager-store/option-sets/option-sets.state';

@Injectable({
  providedIn: 'root',
})
export class TypePropertyOptionSetsService {
  constructor() {}

  public async getOptionSets() {
    let sets = await new Entities().get({ entityName: 'type-property-option-set' });
    sets = sets.sort((s1, s2) => (s1.name > s2.name ? 1 : -1));
    return sets;
  }
  public async createOptionSet(optionSet: TypePropertyOptionSet) {
    return new Entities().create({ entityName: 'type-property-option-set', object: optionSet });
  }
  public async updateOptionSet(id: string, changes: TypePropertyOptionSet) {
    return new Entities().update({ entityName: 'type-property-option-set', id, object: changes });
  }
  public async deleteOptionSet(optionSet: TypePropertyOptionSet) {
    await new Entities().delete({ entityName: 'type-property-option-set', id: optionSet.id });
    return optionSet;
  }
}
