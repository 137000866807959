import { createSelector } from '@ngrx/store';
import { ScheduleJobDefinition } from './schedule-job';
import { scheduleJobActionsEntityAdapter } from './schedule-job.state';

export const featureKey = 'scheduleJobs';

export const { selectAll, selectEntities, selectIds, selectTotal } = scheduleJobActionsEntityAdapter.getSelectors();

const scheduleJobActionEntities = (state: any) => state[featureKey].scheduleJobActions.entities;

export const scheduleJobs = createSelector(
  scheduleJobActionEntities,
  (state) => Object.values(state) as Array<ScheduleJobDefinition>,
);
export const currentScheduleJobAction = (state: any) => state[featureKey].currentScheduleJobAction;

export const getLoadingState = (state: any) => state[featureKey].loading;
