<app-modal [header]="title" [dialogRef]="dialogRef">
  <div class="spinner-bg" *ngIf="loading; else loaded">
    <mat-spinner diameter="50" mode="indeterminate"></mat-spinner>
  </div>
  <ng-template #loaded>
    <div class="editor">
      <ngx-monaco-editor [options]="editorOptions" [(ngModel)]="code" #editor></ngx-monaco-editor>
    </div>
  </ng-template>
</app-modal>
