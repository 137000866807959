import { EventEmitter, HostListener, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Component, Input } from '@angular/core';
import { SizeRangeAction, SizeRangeCell } from '../size-range-editor-manager';

@Component({
  selector: 'app-size-range-cell',
  template: `
    <div class="relative">
      <div
        class="h-[32px] w-[32px]"
        [ngStyle]="{ 'background-color': isInSizeRange ? '#BBDEFB' : !isSizeSelectable ? '#F5F5F5' : null }"
      ></div>
      <div *ngIf="isSizeSelected" class="h-[32px] w-[32px] absolute bg-neutral-500/[.15] top-0"></div>
    </div>
  `,
  styles: [
    `
      .in-size-range {
        background-color: '#BBDEFB';
      }
      .not-selectable {
        background-color: '#F5F5F5';
      }
    `,
  ],
})
export class SizeRangeCellComponent implements OnChanges {
  @Input() dimension1: string;
  @Input() dimension2: string;
  @Input() sizes: any;
  @Input() selectableCells: Array<SizeRangeCell>;
  @Input() selectedSizeRangeCells: Array<SizeRangeCell>;
  @Input() editMode = false;
  @Output() handleAction = new EventEmitter();
  isInSizeRange = false;
  isSizeSelected = false;
  isSizeSelectable = true;

  constructor() {}

  @HostListener('click', ['$event']) onClick(event) {
    this.handleMouseEvent(event);
  }
  @HostListener('mousedown', ['$event']) onMousedown(event) {
    this.handleMouseEvent(event);
  }
  @HostListener('mouseenter', ['$event']) onMouseenter(event) {
    this.handleMouseEvent(event);
  }
  @HostListener('mouseup', ['$event']) onMouseup(event) {
    this.handleMouseEvent(event);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sizes && changes.sizes.currentValue) {
      if (Array.isArray(this.sizes)) {
        this.isInSizeRange = this.sizes.includes(this.dimension1);
      } else {
        this.isInSizeRange = this.sizes[this.dimension2]?.includes(this.dimension1);
      }
    }

    if (changes.selectableCells && changes.selectableCells.currentValue) {
      if (this.dimension2) {
        this.isSizeSelectable =
          this.selectableCells.findIndex(
            (cell) => cell.dimension1 === this.dimension1 && cell.dimension2 === this.dimension2,
          ) > -1;
      } else {
        this.isSizeSelectable = this.selectableCells.findIndex((cell) => cell.dimension1 === this.dimension1) > -1;
      }
    }

    if (changes.selectedSizeRangeCells && changes.selectedSizeRangeCells.currentValue) {
      if (this.dimension2) {
        this.isSizeSelected =
          this.selectedSizeRangeCells.findIndex((cell) => {
            return cell.dimension1 === this.dimension1 && cell.dimension2 === this.dimension2;
          }) > -1;
      } else {
        this.isSizeSelected = this.selectedSizeRangeCells.findIndex((cell) => cell.dimension1 === this.dimension1) > -1;
      }
    }
  }

  handleMouseEvent(event) {
    if (!this.editMode) {
      return;
    }
    const action: SizeRangeAction = {
      dimension1: this.dimension1,
      actionType: event.type,
    };
    if (this.dimension2) {
      action.dimension2 = this.dimension2;
    }
    this.handleAction.emit(action);
  }
}
