import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface CounterInput {
  title: string;
  name: string;
  cancel?: string;
  icon?: boolean;
  okBtnLabel?: string;
}

@Component({
  selector: 'app-update-dialog',
  templateUrl: './update-dialog.component.html',
  styleUrls: ['./update-dialog.component.scss'],
})
export class UpdateDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<UpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CounterInput,
  ) {}

  optionFormControl = new FormControl('', [Validators.required, Validators.maxLength(40), Validators.minLength(3)]);

  getErrorMessage() {
    if (this.optionFormControl.hasError('required')) {
      return 'name cannot be empty';
    }
    if (this.optionFormControl.hasError('minlength')) {
      return 'name should be at least 3 characters';
    }
    if (this.optionFormControl.hasError('maxlength')) {
      return 'name should not be more than 40 characters';
    }
  }
}
