import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerActions } from '..';
import { TypesService } from '../../types.service';

@Injectable()
export class TypesEffects {
  constructor(
    private actions$: Actions,
    private typesService: TypesService,
    private store: Store<RootStoreState.State>,
  ) {}

  loadTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.TypesActionTypes.LOAD_TYPES),
      withLatestFrom(this.store),
      // tap(() => this.store.dispatch(TypeManagerActions.clearTypes())),
      switchMap(([, store]: [any, RootStoreState.State]) =>
        from(this.typesService.getTypesForRoot(store.typeManager.currentTypeRoot)).pipe(
          map((data) => TypeManagerActions.loadTypesSuccess({ data })),
          catchError((error) => observableOf(TypeManagerActions.loadTypesFailure({ error }))),
        ),
      ),
    ),
  );
  createSubType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.TypesActionTypes.CREATE_SUB_TYPE),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        const type = action.entity;
        const typePath = store.typeManager.currentType.typePath + ':' + type.slug;
        return from(this.typesService.createType({ ...type, typePath })).pipe(
          map((entity) => TypeManagerActions.createSubTypeSuccess({ entity })),
          catchError((error) => observableOf(TypeManagerActions.createSubTypeFailure({ error }))),
        );
      }),
    ),
  );
}
