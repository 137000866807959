import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { PropertyType, TypeProperty } from '@contrail/types';
import { Subscription } from 'rxjs';
import { ObjectUtil } from '@contrail/util';
import { TypePropertyFormFieldComponent } from '@common/types/forms/type-property-form-field/type-property-form-field.component';
import { TypePropertyFormConfiguration } from '@common/types/forms/type-property-form/type-property-form.component';

@Component({
  selector: 'app-type-property-defaults',
  templateUrl: './type-property-defaults.component.html',
  styleUrls: ['./type-property-defaults.component.scss'],
})
export class TypePropertyDefaultsComponent implements OnChanges, AfterViewChecked, OnDestroy {
  @Input() typeProperty: TypeProperty;
  public enabled = false;
  public formConfiguration: TypePropertyFormConfiguration;
  @ViewChild(TypePropertyFormFieldComponent) defaultPropertyFormField: TypePropertyFormFieldComponent;
  @Output() changes = new EventEmitter();
  private formSub: Subscription;
  constructor() {}

  ngOnChanges(): void {
    this.derivedEnabled();
    const propClone = ObjectUtil.cloneDeep(this.typeProperty);
    propClone.editable = true;
    this.formConfiguration = {
      typeProperty: propClone,
    };
  }
  ngAfterViewChecked(): void {
    if (!this.defaultPropertyFormField) {
      return;
    }
    if (this.formSub) {
      this.formSub.unsubscribe();
    }
    this.formSub = this.defaultPropertyFormField.valueChange.subscribe((change) => {
      let val = change.value;
      if (change?.value !== null) {
        val = String(change?.value);
      }
      this.changes.emit({ defaultValue: val });
    });
  }
  ngOnDestroy() {
    if (this.formSub) {
      this.formSub.unsubscribe();
    }
  }
  derivedEnabled() {
    if (
      [
        PropertyType.String,
        PropertyType.Text,
        PropertyType.Number,
        PropertyType.Currency,
        PropertyType.Date,
        PropertyType.SingleSelect,
        PropertyType.MultiSelect,
        PropertyType.Boolean,
        PropertyType.Percent,
      ].includes(this.typeProperty.propertyType)
    ) {
      this.enabled = true;
    } else {
      this.enabled = false;
    }
  }
}
