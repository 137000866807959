import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TypeRoot } from '@contrail/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RootStoreState } from 'src/app/root-store';
import { TypesService } from '../types.service';

@Component({
  selector: 'app-type-root',
  templateUrl: './type-root.component.html',
  styleUrls: ['./type-root.component.scss'],
})
export class TypeRootComponent {
  public rootSlug;
  public typeRoot$: Observable<TypeRoot>;
  constructor(
    private store: Store<RootStoreState.State>,
    private typeService: TypesService,
    private activeRoute: ActivatedRoute,
  ) {
    this.activeRoute.params.subscribe();
  }
}
