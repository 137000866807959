import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-type-property-form-validation-error',
  template: ` <div>{{ errorMessage }}</div> `,
  styles: [
    `
      div {
        font-size: 75%;
        color: #e53935;
        position: absolute;
        letter-spacing: normal;
        margin-top: 1.5em;
        line-height: 11.8125px;
      }
    `,
  ],
})
export class TypePropertyFormValidationErrorComponent {
  @Input() errorMessage: string;
}
