<app-page-section [title]="'Rule Sets'">
  <app-page-section-body>
    <div class="mb-2">
      Assign rule sets to this type to enable more sophisticated constraints, editability rules, etc.
    </div>
    <mat-chip-list>
      <mat-chip color="primary" *ngFor="let ruleSetLink of ruleSetLinks">
        {{ ruleSetLink.typeRuleSet.name }}
        <mat-icon class="cursor-pointer" (click)="removeRuleSet(ruleSetLink)">close</mat-icon>
      </mat-chip>
    </mat-chip-list>

    <div class="mt-3 w-80">
      <mat-form-field class="example-full-width w-full">
        <mat-label>Add Rule Set</mat-label>
        <input
          type="text"
          placeholder="Pick one"
          aria-label="Rules Set"
          matInput
          [formControl]="addRuleSetFormControl"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option
            [matTooltip]="ruleSet.name.length > displayTypeRuleSetLengthLimit ? ruleSet.name : ''"
            *ngFor="let ruleSet of availableTypeRuleSets$ | async"
            [value]="ruleSet"
          >
            {{ ruleSet.name | shorten: displayTypeRuleSetLengthLimit }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </app-page-section-body>
</app-page-section>
