import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnChanges,
  AfterViewChecked,
  OnDestroy,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Type, TypeProperty, PropertyType } from '@contrail/types';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ObjectUtil } from '@contrail/util';
import { TypePropertyFormConfiguration } from '@common/types/forms/type-property-form/type-property-form.component';
import { TypePropertyFormFieldComponent } from '@common/types/forms/type-property-form-field/type-property-form-field.component';

@Component({
  selector: 'app-type-property-carryover',
  templateUrl: './type-property-carryover.component.html',
  styleUrls: ['./type-property-carryover.component.scss'],
})
export class TypePropertyCarryoverComponent implements OnInit, OnChanges, AfterViewChecked, OnDestroy {
  @Input() typeProperty: TypeProperty;
  @Input() type: Type;
  @Output() changes = new EventEmitter();
  @ViewChild(TypePropertyFormFieldComponent) defaultPropertyFormField: TypePropertyFormFieldComponent;
  public formConfiguration: TypePropertyFormConfiguration;
  public formControl = new UntypedFormControl();
  public carryoverOptions: { value: string; displayValue: string }[] = [
    { value: 'clear', displayValue: 'Clear' },
    { value: 'carryover', displayValue: 'Carryover' },
    { value: 'default', displayValue: 'Default' },
  ];
  public enabled = false;
  private destroy$ = new Subject<void>();
  private formSub: Subscription;
  public carryoverOnlyTypes = [
    PropertyType.ObjectReference,
    PropertyType.UserList,
    PropertyType.Sequence,
    PropertyType.TypeReference,
    PropertyType.SizeRange,
  ];

  constructor() {}

  ngOnInit() {
    this.formControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value !== this.typeProperty?.carryOverBehavior) {
        if (value !== 'default') {
          this.changes.emit({ carryOverDefault: null, carryOverBehavior: value });
        } else {
          this.changes.emit({ carryOverBehavior: value });
        }
      }
    });
  }

  ngOnChanges(): void {
    this.derivedEnabled();
    const propClone = ObjectUtil.cloneDeep(this.typeProperty);
    propClone.editable = true;
    this.formConfiguration = {
      typeProperty: propClone,
    };

    if (!this.typeProperty && this.type) {
      return;
    }
    this.formControl.setValue(this.typeProperty?.carryOverBehavior);
  }

  ngAfterViewChecked(): void {
    if (!this.defaultPropertyFormField) {
      return;
    }
    if (this.formSub) {
      this.formSub.unsubscribe();
    }
    this.formSub = this.defaultPropertyFormField.valueChange.subscribe((change) => {
      let val = change.value;
      if (change?.value !== null) {
        val = String(change?.value);
      }
      this.changes.emit({ carryOverDefault: val });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
    if (this.formSub) {
      this.formSub.unsubscribe();
    }
  }

  derivedEnabled() {
    if (!this.type?.typePath?.includes('project-item')) {
      this.enabled = false;
    } else if (
      [
        PropertyType.Text,
        PropertyType.String,
        PropertyType.Number,
        PropertyType.Currency,
        PropertyType.Date,
        PropertyType.SingleSelect,
        PropertyType.MultiSelect,
        PropertyType.Boolean,
        PropertyType.Percent,
        PropertyType.ObjectReference,
        PropertyType.UserList,
        PropertyType.Sequence,
        PropertyType.TypeReference,
        PropertyType.SizeRange,
      ].includes(this.typeProperty.propertyType)
    ) {
      this.enabled = true;
    } else {
      this.enabled = false;
    }
  }
}
