import { createAction, props } from '@ngrx/store';
import { Assortment, LoaderConfig, Workspace } from '../../loader-interface';

export enum LoaderConfigActionTypes {
  LOAD_LOADER_CONFIGS = '[Loader Config] Load Loader Configs',
  LOAD_LOADER_CONFIGS_SUCCESS = '[Loader Config] Loader Configs Success',
  LOAD_LOADER_CONFIGS_FAILURE = '[Loader Config] Loader Configs Failure',

  CREATE_LOADER_CONFIG = '[Loader Config] Create Loader Config',
  CREATE_LOADER_CONFIG_SUCCESS = '[Loader Config] Create Loader Config Success',
  CREATE_LOADER_CONFIG_FAILURE = '[Loader Config] Create Loader Config Failure',

  DELETE_LOADER_CONFIG = '[Loader Config] Delete Loader Config',
  DELETE_LOADER_CONFIG_SUCCESS = '[Loader Config] Delete Loader Config Success',
  DELETE_LOADER_CONFIG_FAILURE = '[Loader Config] Delete Loader Config Failure',

  UPDATE_LOADER_CONFIG = '[Loader Config] Update Loader Config',
  UPDATE_LOADER_CONFIG_SUCCESS = '[Loader Config] Update Loader Config Success',
  UPDATE_LOADER_CONFIG_FAILURE = '[Loader Config] Update Loader Config Failure',

  SET_CURRENT_LOADER_CONFIG = '[Loader Config] Set Current Loader Config',

  LOAD_WORKSPACE = '[Loader Config] Load Workspace',
  LOAD_WORKSPACE_SUCCESS = '[Loader Config] Load Workspace Success',
  LOAD_WORKSPACE_FAILURE = '[Loader Config] Load Workspace Failure',

  LOAD_ASSORTMENT = '[Loader Config] Load Assortment',
  LOAD_ASSORTMENT_SUCCESS = '[Loader Config] Load Assortment Success',
  LOAD_ASSORTMENT_FAILURE = '[Loader Config] Load Assortment Failure',
}

/// /////////////////////////////////////////////////////////////////////////////

export const loadLoaderConfigs = createAction(LoaderConfigActionTypes.LOAD_LOADER_CONFIGS);

export const setCurrentLoaderConfig = createAction(
  LoaderConfigActionTypes.SET_CURRENT_LOADER_CONFIG,
  props<{ currentLoaderConfig: LoaderConfig }>(),
);

export const loadLoaderConfigsSuccess = createAction(
  LoaderConfigActionTypes.LOAD_LOADER_CONFIGS_SUCCESS,
  props<{ data: Array<LoaderConfig> }>(),
);

export const loadLoaderConfigsFailure = createAction(
  LoaderConfigActionTypes.LOAD_LOADER_CONFIGS_FAILURE,
  props<{ error: any }>(),
);

export const createLoaderConfig = createAction(
  LoaderConfigActionTypes.CREATE_LOADER_CONFIG,
  props<{ loaderConfig: LoaderConfig }>(),
);

export const createLoaderConfigSuccess = createAction(
  LoaderConfigActionTypes.CREATE_LOADER_CONFIG_SUCCESS,
  props<{ loaderConfig: LoaderConfig }>(),
);

export const createLoaderConfigFailure = createAction(
  LoaderConfigActionTypes.CREATE_LOADER_CONFIG_FAILURE,
  props<{ error: any }>(),
);
export const deleteLoaderConfig = createAction(
  LoaderConfigActionTypes.DELETE_LOADER_CONFIG,
  props<{ loaderConfig: LoaderConfig }>(),
);

export const deleteLoaderConfigSuccess = createAction(
  LoaderConfigActionTypes.DELETE_LOADER_CONFIG_SUCCESS,
  props<{ loaderConfig: LoaderConfig }>(),
);

export const deleteLoaderConfigFailure = createAction(
  LoaderConfigActionTypes.DELETE_LOADER_CONFIG_FAILURE,
  props<{ error: any }>(),
);

export const updateLoaderConfig = createAction(
  LoaderConfigActionTypes.UPDATE_LOADER_CONFIG,
  props<{ id: string; changes: LoaderConfig }>(),
);

export const updateLoaderConfigSuccess = createAction(
  LoaderConfigActionTypes.UPDATE_LOADER_CONFIG_SUCCESS,
  props<{ id: string; changes: LoaderConfig }>(),
);

export const updateLoaderConfigFailure = createAction(
  LoaderConfigActionTypes.UPDATE_LOADER_CONFIG_FAILURE,
  props<{ error: any }>(),
);

export const loadWorkSpace = createAction(LoaderConfigActionTypes.LOAD_WORKSPACE);

export const loadWorkspaceSuccess = createAction(
  LoaderConfigActionTypes.LOAD_WORKSPACE_SUCCESS,
  props<{ workspaces: Workspace[] }>(),
);

export const loadWorkspaceFailure = createAction(
  LoaderConfigActionTypes.LOAD_WORKSPACE_FAILURE,
  props<{ error: any }>(),
);

export const loadAssortment = createAction(LoaderConfigActionTypes.LOAD_ASSORTMENT);

export const loadAssortmentSuccess = createAction(
  LoaderConfigActionTypes.LOAD_ASSORTMENT_SUCCESS,
  props<{ assortments: Assortment[] }>(),
);

export const loadAssortmentFailure = createAction(
  LoaderConfigActionTypes.LOAD_ASSORTMENT_FAILURE,
  props<{ error: any }>(),
);
