import { Component, EventEmitter, Input, OnInit, Output, OnChanges, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Type, TypeProperty } from '@contrail/types';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-type-property-required',
  templateUrl: './type-property-required.component.html',
  styleUrls: ['./type-property-required.component.scss'],
})
export class TypePropertyRequiredComponent implements OnInit, OnChanges, OnDestroy {
  @Input() typeProperty: TypeProperty;
  @Input() type: Type;
  @Output() changes = new EventEmitter();
  public visible = false;
  public formControl = new UntypedFormControl();
  private destroy$ = new Subject<void>();

  constructor() {}

  ngOnInit() {
    this.formControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value !== this.typeProperty.required) {
        this.changes.emit({ required: value });
      }
    });
  }

  ngOnChanges(): void {
    if (!this.typeProperty && this.type) {
      return;
    }
    this.visible = true;
    this.formControl.setValue(this.typeProperty.required);
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
