<div class="modal-container mb-2 flex flex-col">
  <div class="flex justify-between">
    <h6 data-test="groups-export-dialog-title">Export Groups</h6>
    <mat-icon [mat-dialog-close]="false" class="cursor-pointer -mr-2 ml-3">close</mat-icon>
  </div>
  <div class="option-full-width mt-5 p-2">
    <mat-radio-group [(ngModel)]="selectedOption">
      <mat-radio-button class="p-2" id="option1" name="Groups" value="groups" checked>Groups Only</mat-radio-button
      ><br />
      <mat-radio-button class="p-2" id="option2" name="Group memberships" value="members"
        >Group Memberships</mat-radio-button
      >
    </mat-radio-group>
  </div>
  <div mat-dialog-actions class="flex items-center justify-end mt-3">
    <button mat-stroked-button color="primary" [mat-dialog-close]="false" class="!mr-3">Cancel</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="selectedOption" cdkFocusInitial>Export</button>
  </div>
</div>
