import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AppExtension } from '@contrail/entity-types';

interface AppExtensionState extends EntityState<AppExtension> {}

export interface State {
  appExtensions: AppExtensionState;
}
export const appExtensionsEntityAdapter: EntityAdapter<AppExtension> = createEntityAdapter<AppExtension>({});

export const appExtensionsInitialState: State = {
  appExtensions: appExtensionsEntityAdapter.getInitialState({}),
};
