import { Component, ElementRef, ViewChild } from '@angular/core';
import { TypePropertyFormFieldBaseComponent } from './type-property-form-field-base.component';
@Component({
  selector: 'app-type-property-form-field-text-area',
  template: `
    <mat-form-field
      *ngIf="formControl"
      [appearance]="appearance"
      [attr.data-test]="propertyFormConfiguration.typeProperty.slug + '-form-field'"
    >
      <mat-label>{{ propertyFormConfiguration.typeProperty.label }}</mat-label>
      <!-- <textarea matInput
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="5"></textarea> -->

      <textarea matInput type="text" [formControl]="formControl" style="resize: none;" rows="2" maxRows="2"></textarea>
      <mat-error *ngIf="formControl.hasError('error')">{{ formControl.getError('error') }}</mat-error>
      <app-type-property-form-validation-error
        *ngIf="formControl.hasError('error') && formControl.disabled"
        [errorMessage]="formControl.getError('error')"
      ></app-type-property-form-validation-error>
    </mat-form-field>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
      input {
        border: none;
        font-size: 12px;
        padding: 0px;
        width: 95%;
      }
      mat-form-field {
        width: 100%;
      }
    `,
  ],
})
export class TypePropertyFormFieldTextAreaComponent extends TypePropertyFormFieldBaseComponent {
  @ViewChild('inputField') input: ElementRef;
  constructor() {
    super();
  }
}
