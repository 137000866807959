<app-page-section *ngIf="visible">
  <app-page-section-header>
    <app-page-section-title title="Required"></app-page-section-title>
  </app-page-section-header>
  <app-page-section-body>
    <div class="mb-3">
      <mat-slide-toggle [formControl]="formControl">Should this property be required?</mat-slide-toggle>
    </div>
  </app-page-section-body>
</app-page-section>
