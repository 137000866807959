import { Component, ElementRef, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { TypePropertyOption } from '@contrail/types';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ObjectUtil } from '@contrail/util';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TypePropertyOptionSet } from 'src/app/type-manager/type-manager-store/option-sets/option-sets.state';
import { UpdateDialogService } from '@components/update-dialog/update-dialog-service';
import yaml from 'js-yaml';
import { cloneDeepWith, isArray, isObject } from 'lodash';
import { StringUtil } from '@contrail/util';
import { TypePropertyOptionSetsService } from '../../../type-property-option-sets/type-property-option-sets.service';
@Component({
  selector: 'app-type-property-option-set-option-editor-option-list',
  templateUrl: './type-property-option-set-option-editor-option-list.component.html',
  styleUrls: ['./type-property-option-set-option-editor-option-list.component.scss'],
})
export class TypePropertyOptionSetOptionEditorOptionListComponent implements OnChanges {
  @Input() typePropertyOptionSet: TypePropertyOptionSet;
  @Output() updated: EventEmitter<any> = new EventEmitter();
  @Output() importProcessed = new EventEmitter<void>();
  public optionSet: Array<TypePropertyOption>;
  public edit = false;
  public showAdd = false;
  public isModalOpen = false;
  public displayField = new UntypedFormControl('', Validators.required);
  public valueField = new UntypedFormControl('', Validators.required);
  public searchKey = '';
  public displayTextLengthLimit = 9;
  public errors: any[] = [];
  constructor(
    private el: ElementRef,
    private snackBar: MatSnackBar,
    private typePropertyOptionSetService: TypePropertyOptionSetsService,
    private UpdateDialogService: UpdateDialogService,
  ) {}

  ngOnChanges() {
    this.optionSet = ObjectUtil.cloneDeep(this.typePropertyOptionSet.optionSet || []);
    this.showAdd = false;
  }
  toggleEdit() {
    this.edit = !this.edit;
  }
  showAddOption() {
    this.showAdd = true;
  }
  toggleOptionSetImportModal() {
    this.isModalOpen = !this.isModalOpen;
    this.errors = [];
  }
  isUploadProcessed() {
    this.optionSet = ObjectUtil.cloneDeep(this.typePropertyOptionSet.optionSet || []);
    if (this.isModalOpen) {
      !this.isModalOpen;
    }
  }
  addOption() {
    const existing = this.optionSet.find((o) => o.value === this.valueField.value);
    if (existing) {
      this.snackBar.open(`Duplicate value already exists:  '${this.valueField.value}'.`, null, { duration: 2000 });
      return;
    }
    this.optionSet = this.optionSet.concat([{ value: this.valueField.value, display: this.displayField.value }]);
    this.valueField.reset();
    this.displayField.reset();
    const valueInput = document.getElementById('valueInput');
    valueInput.focus();
    this.updated.emit(this.optionSet);
  }
  removeOption() {}
  modifyOption() {}
  isNewValid() {
    return !(this.displayField.invalid || this.valueField.invalid);
  }
  setDisplayFromValue() {
    const value = this.valueField.value;
    if (!value) {
      return;
    }
    let labelForSlug = value.replace(/([A-Z])/g, ' $1');
    labelForSlug = labelForSlug.charAt(0).toUpperCase() + labelForSlug.slice(1);
    this.displayField.setValue(labelForSlug);
  }
  handleDisableToggle(event, updatedOption) {
    const isDisabled = event.checked;
    this.optionSet = this.optionSet.map((option) => {
      if (option.value === updatedOption.value) {
        return { ...option, disabled: isDisabled };
      }
      return option;
    });
    this.updated.emit(this.optionSet);
  }
  async handleEdit(option) {
    const updatedDisplay = await this.UpdateDialogService.openDialog('Rename Value Label', option.display);
    if (updatedDisplay !== option.display) {
      const updatedOption = { ...option, display: updatedDisplay };
      const updatedOptionSet = this.optionSet.map((o) => (o.value === option.value ? updatedOption : o));
      this.optionSet = updatedOptionSet;
      this.updated.emit(updatedOptionSet);
    }
  }
  handleDelete(option) {
    this.optionSet = this.optionSet.filter((o) => o.value !== option.value);
    this.updated.emit(this.optionSet);
  }
  drop(event) {
    this.optionSet = ObjectUtil.cloneDeep(this.optionSet || []);
    moveItemInArray(this.optionSet, event.previousIndex, event.currentIndex);
    this.updated.emit(ObjectUtil.cloneDeep(this.optionSet));
  }
  async importOptionSets(uploadedOptionData: any): Promise<void> {
    try {
      this.errors = [];
      const optionSetName = this.typePropertyOptionSet.slug;
      const parsedOptionSets = this.parseNameAndLabelAndKey(uploadedOptionData, optionSetName);
      const yamlStr = yaml.dump(parsedOptionSets);
      this.saveOptionSets(yamlStr);
    } catch (e) {
      console.error('Error while parsing Option Set', e);
    }
  }
  async saveOptionSets(yamlStr: string): Promise<void> {
    try {
      const yamlData = yaml.load(yamlStr);
      const { optionSet, slug } = yamlData[0];
      const currentOptionSets = await this.typePropertyOptionSetService.getOptionSets();
      const curOptionSet = currentOptionSets.find((optionSet) => optionSet.slug === slug);
      const parsedOptionSet = this.parseOptionSetValuesToString(curOptionSet?.optionSet || '');
      if (this.errors.length) {
        return;
      }
      let optionstoUpdate = [];
      if (parsedOptionSet.length > 0) {
        const { updatedOptions, existingSkippedOptions } = this.mergeOptionSets(parsedOptionSet, optionSet);
        optionstoUpdate = updatedOptions;
      } else {
        console.log('No existing options found. Adding all options');
        optionstoUpdate = optionSet;
      }

      const object = {
        name: curOptionSet.name,
        slug: curOptionSet.slug,
        optionSet: optionstoUpdate,
        isArchived: curOptionSet.isArchived,
      };

      await this.typePropertyOptionSetService.updateOptionSet(curOptionSet.id, object);

      this.optionSet = optionstoUpdate;
      this.updated.emit(optionstoUpdate);
      this.errors = [];
      this.importProcessed.emit();
    } catch (e) {
      this.errors = [e.message];
      this.snackBar.open(e.message, '', { duration: 2000 });
    }
  }
  parseOptionSetValuesToString(optionSet): any {
    return cloneDeepWith(optionSet, (value) => {
      // If the value is an object or array, let cloneDeepWith handle it
      if (isObject(value) || isArray(value)) {
        return undefined;
      }
      return String(value);
    });
  }
  /*Parse the options
   * @param uploadedOptionData
   * @param optionSetName
   * @returns any[]
   */
  parseNameAndLabelAndKey(uploadedOptionData: any[], optionSetName: string): any[] {
    const optionSets: Record<string, any> = {};
    optionSets[optionSetName] = {};
    //Check row length and non empty or falsy values
    const isValidRow = (row: any[]): boolean => {
      return (row.length === 3 || row.length === 2) && !row.some((x) => !x);
    };
    for (const csvRow of uploadedOptionData) {
      const row = Object.values(csvRow);
      if (!isValidRow(row)) {
        this.errors.push(`${JSON.stringify(row, null, 2)}`);
      }
      const [key, label] = row;
      let disabled = false;
      // Check if the third column exists and parse the disabled value
      if (row.length === 3) {
        const isDisabled = row[2];
        disabled = this.parseDisabledValue(isDisabled as string);
      }
      optionSets[optionSetName][key as string] = { label, disabled };
    }
    if (this.errors.length) {
      throw new Error(`${JSON.stringify(this.errors, null, 2)}`);
    }
    // Helper function to transform the option set into the desired structure
    const transformOptionSet = (optionSetName: string, options: any) => {
      const optionSet = Object.entries(options).map(([key, { label, disabled }]: [string, any]) => ({
        value: key.toString(),
        display: label,
        disabled: !!disabled,
      }));
      return {
        name: optionSetName,
        slug: StringUtil.convertToCamelCase(optionSetName),
        isArchived: false,
        optionSet,
      };
    };
    const result = Object.keys(optionSets).map((name) => transformOptionSet(name, optionSets[name]));
    return result;
  }

  parseDisabledValue(stringValue: string): boolean {
    const testString = /^true$/i.test(stringValue?.trim());
    const isDisabledFalse = testString === false;
    const isDisabledTrue = testString === true;
    if (!isDisabledFalse && !isDisabledTrue) {
      console.log(`Invalid value (${stringValue}) for disabled attribute. Must be either "true" or "false"`);
    }
    return isDisabledTrue;
  }

  mergeOptionSets(
    existingOptionsData: any[],
    newOptionData: any[],
  ): { updatedOptions: any[]; existingSkippedOptions: any[] } {
    const existingSkippedOptions = [];
    // Iterate through newOptionData and add non-skipped elements to existingOptionsData
    newOptionData.forEach((newOption) => {
      const optionExists = existingOptionsData.some((option) => option.value === newOption.value);
      if (optionExists) {
        existingSkippedOptions.push(newOption);
      } else {
        existingOptionsData.push(newOption);
      }
    });
    return { updatedOptions: existingOptionsData, existingSkippedOptions };
  }
}
