import { Component, ElementRef, HostListener, Input, OnChanges } from '@angular/core';
import { Entity } from '../entity';

@Component({
  selector: 'app-viewable',
  templateUrl: './viewable.component.html',
  styleUrls: ['./viewable.component.scss'],
})
export class ViewableComponent implements OnChanges {
  @Input() entity: Entity;
  public url: string;
  constructor(private eRef: ElementRef) {}

  ngOnChanges(): void {
    this.setUrl();
  }
  setUrl() {
    if (this.entity) {
      this.url =
        this.entity.getValue('primaryViewable.mediumViewable.downloadUrl') ||
        this.entity.getValue('primaryViewable.largeViewable.downloadUrl') ||
        this.entity.getValue('primaryViewable.primaryFile.downloadUrl');
    }
  }
  @HostListener('dragover', ['$event']) public stdDragOver(evt) {
    evt.preventDefault();
    const group = this.eRef.nativeElement.querySelector('.viewable');
    group.classList.add('hover');
    evt.stopPropagation();
  }
  @HostListener('drop', ['$event']) async stdDrop(evt) {
    evt.preventDefault();
    const listItemDiv = this.eRef.nativeElement.querySelector('.viewable');
    listItemDiv.classList.remove('hover');
    const entityString = evt.dataTransfer.getData('entity');
    const entity = JSON.parse(entityString);
    if (entity && entity.entityType === 'content') {
      this.entity.setValue('primaryViewableId', entity.id);
      this.entity.update();
      this.entity.setValue('primaryViewable', entity);
      this.setUrl();
    }
    evt.stopPropagation();
  }
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    const listItem = this.eRef.nativeElement.querySelector('.viewable');
    listItem.classList.remove('hover');
    evt.stopPropagation();
  }
}
