import { TypeRoot, Type, TypeProperty, OptionSetHierarchy } from '@contrail/types';
import { typePropertiesInitialState, TypePropertiesState } from './type-properties/type-properties.state';
import {
  TypePropertyOptionSet,
  typePropertyOptionSetsInitialState,
  TypePropertyOptionSetsState,
} from './option-sets/option-sets.state';
import {
  TypePropertyUserList,
  typePropertyUserListsInitialState,
  TypePropertyUserListsState,
} from './user-lists/user-lists.state';
import { typeRootsInitialState, TypeRootsState } from './type-roots/type-roots.state';
import { typesInitialState, TypesState } from './types/types.state';
import {
  typePropertyPoliciesInitialState,
  TypePropertyPoliciesState,
  TypePropertyPolicy,
} from './type-property-policies/type-property-policies.state';
import { TypeRuleSet, typeRuleSetsInitialState, TypeRuleSetsState } from './type-rule-sets/type-rule-sets.state';
import {
  OptionSetHierarchiesState,
  optionSetHierarchiesInitialState,
} from './option-set-hierarchies/option-set-hierarchies.state';
import { TypePoliciesState, TypePolicy, typePoliciesInitialState } from './type-policies/type-policies.state';

export interface State {
  typeRoots: TypeRootsState;
  currentTypeRoot: TypeRoot;
  types: TypesState;
  currentType: Type;
  typeProperties: TypePropertiesState;
  currentTypeProperty: TypeProperty;
  typePropertyOptionSets: TypePropertyOptionSetsState;
  currentTypePropertyOptionSet: TypePropertyOptionSet;
  typePropertyUserLists: TypePropertyUserListsState;
  currentTypePropertyUserList: TypePropertyUserList;
  typePropertyPolicies: TypePropertyPoliciesState;
  typePolicies: TypePoliciesState;
  currentTypePropertyPolicy: TypePropertyPolicy;
  currentTypePolicy: TypePolicy;
  typeRuleSets: TypeRuleSetsState;
  currentTypeRuleSet: TypeRuleSet;
  optionSetHierarchies: OptionSetHierarchiesState;
  currentOptionSetHierarchy: OptionSetHierarchy;
  loading: boolean;
  updating: boolean;
}
export const initialState: State = {
  typeRoots: typeRootsInitialState,
  currentTypeRoot: null,
  types: typesInitialState,
  currentType: null,
  typeProperties: typePropertiesInitialState,
  currentTypeProperty: null,
  typePropertyOptionSets: typePropertyOptionSetsInitialState,
  currentTypePropertyOptionSet: null,
  typePropertyUserLists: typePropertyUserListsInitialState,
  currentTypePropertyUserList: null,
  typePropertyPolicies: typePropertyPoliciesInitialState,
  typePolicies: typePoliciesInitialState,
  currentTypePropertyPolicy: null,
  currentTypePolicy: null,
  typeRuleSets: typeRuleSetsInitialState,
  currentTypeRuleSet: null,
  optionSetHierarchies: optionSetHierarchiesInitialState,
  currentOptionSetHierarchy: null,
  loading: true,
  updating: false,
};
