import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainModule } from './main/main.module';
import { EntitiesModule } from './entities/entities.module';
import { ComponentsModule } from './common/components/components.module';
import { TypeManagerModule } from './type-manager/type-manager.module';
import { AuthModule } from './common/auth/auth.module';
import { RootStoreModule } from './root-store';
import { ClearCacheComponent } from './cache/clear-cache/clear-cache.component';
import { WebhooksModule } from './webhooks/webhooks.module';
import { storeDevTools } from './build-specifics';
import { DynamicLoadOnScrollComponent } from './common/components/dynamic-load-on-scroll/dynamic-load-on-scroll.component';
import { FilesComponent } from './files/files.component';
import { AppExtensionsModule } from '@common/app-extensions/app-extensions.module';
import { UtilityExtensionsComponent } from './utility-extensions/utility-extension.component';
@NgModule({
  declarations: [
    AppComponent,
    ClearCacheComponent,
    DynamicLoadOnScrollComponent,
    FilesComponent,
    UtilityExtensionsComponent,
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    MainModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    EntitiesModule,
    ComponentsModule,
    TypeManagerModule,
    AuthModule,
    RootStoreModule,
    WebhooksModule,
    storeDevTools,
    MatProgressSpinnerModule,
    NgxMatNativeDateModule,
    AppExtensionsModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({ showDialog: false }),
    },
  ],
})
export class AppModule {}
