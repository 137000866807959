import { createAction, props } from '@ngrx/store';
import { EventWorkflowActionDefinition } from './event-action';

export enum EventWorkflowActionsActionTypes {
  CREATE_EVENTWORKFLOWACTION = '[EventWorkflowActions] Create EventWorkflowAction',
  CREATE_EVENTWORKFLOWACTION_SUCCESS = '[EventWorkflowActions] Create EventWorkflowAction Success',
  CREATE_EVENTWORKFLOWACTION_FAILURE = '[EventWorkflowActions] Create EventWorkflowAction Failure',

  DELETE_EVENTWORKFLOWACTION = '[EventWorkflowActions] Delete EventWorkflowAction',
  DELETE_EVENTWORKFLOWACTION_SUCCESS = '[EventWorkflowActions] Delete EventWorkflowAction Success',
  DELETE_EVENTWORKFLOWACTION_FAILURE = '[EventWorkflowActions] Delete EventWorkflowAction Failure',

  UPDATE_EVENTWORKFLOWACTION = '[EventWorkflowActions] Update EventWorkflowAction',
  UPDATE_EVENTWORKFLOWACTION_SUCCESS = '[EventWorkflowActions] Update EventWorkflowAction Success',
  UPDATE_EVENTWORKFLOWACTION_FAILURE = '[EventWorkflowActions] Update EventWorkflowAction Failure',

  LOAD_CURRENT_EVENTWORKFLOWACTION = '[EventWorkflowActions] Load Current EventWorkflowAction',
  LOAD_CURRENT_EVENTWORKFLOWACTION_SUCCESS = '[EventWorkflowActions] Load Current EventWorkflowAction Success',
  LOAD_CURRENT_EVENTWORKFLOWACTION_FAILURE = '[EventWorkflowActions] Load Current EventWorkflowAction Failure',

  LOAD_EVENTWORKFLOWACTIONS = '[EventWorkflowActions] Load EventWorkflowActions',
  LOAD_EVENTWORKFLOWACTIONS_SUCCESS = '[EventWorkflowActions] Load EventWorkflowActions Success',
  LOAD_EVENTWORKFLOWACTIONS_FAILURE = '[EventWorkflowActions] Load EventWorkflowActions Failure',
}

/// /////////////////////////////////////////////////////////////////////////////

export const createEventWorkflowAction = createAction(
  EventWorkflowActionsActionTypes.CREATE_EVENTWORKFLOWACTION,
  props<{ name: string; ownerKey: string; publicStatus: boolean; executeFunctionString?: any }>(),
);

export const createEventWorkflowActionSuccess = createAction(
  EventWorkflowActionsActionTypes.CREATE_EVENTWORKFLOWACTION_SUCCESS,
  props<{ eventWorkflowAction: EventWorkflowActionDefinition }>(),
);

export const createEventWorkflowActionFailure = createAction(
  EventWorkflowActionsActionTypes.CREATE_EVENTWORKFLOWACTION_FAILURE,
  props<{ error: any }>(),
);

export const updateEventWorkflowAction = createAction(
  EventWorkflowActionsActionTypes.UPDATE_EVENTWORKFLOWACTION,
  props<{ id: string; changes: EventWorkflowActionDefinition }>(),
);

export const updateEventWorkflowActionSuccess = createAction(
  EventWorkflowActionsActionTypes.UPDATE_EVENTWORKFLOWACTION_SUCCESS,
  props<{ id: string; changes: EventWorkflowActionDefinition }>(),
);

export const updateEventWorkflowActionFailure = createAction(
  EventWorkflowActionsActionTypes.UPDATE_EVENTWORKFLOWACTION_FAILURE,
  props<{ error: any }>(),
);

export const deleteEventWorkflowAction = createAction(
  EventWorkflowActionsActionTypes.DELETE_EVENTWORKFLOWACTION,
  props<{ eventWorkflowAction: EventWorkflowActionDefinition }>(),
);

export const deleteEventWorkflowActionSuccess = createAction(
  EventWorkflowActionsActionTypes.DELETE_EVENTWORKFLOWACTION_SUCCESS,
  props<{ eventWorkflowAction: EventWorkflowActionDefinition }>(),
);

export const deleteEventWorkflowActionFailure = createAction(
  EventWorkflowActionsActionTypes.DELETE_EVENTWORKFLOWACTION_FAILURE,
  props<{ error: any }>(),
);

export const loadCurrentEventWorkflowAction = createAction(
  EventWorkflowActionsActionTypes.LOAD_CURRENT_EVENTWORKFLOWACTION,
  props<{ id: string }>(),
);

export const loadCurrentEventWorkflowActionSuccess = createAction(
  EventWorkflowActionsActionTypes.LOAD_CURRENT_EVENTWORKFLOWACTION_SUCCESS,
  props<{ eventWorkflowAction: EventWorkflowActionDefinition }>(),
);

export const loadCurrentEventWorkflowActionFailure = createAction(
  EventWorkflowActionsActionTypes.LOAD_CURRENT_EVENTWORKFLOWACTION_FAILURE,
  props<{ error: any }>(),
);

export const loadOwnerkeyEventWorkflowActions = createAction(
  EventWorkflowActionsActionTypes.LOAD_EVENTWORKFLOWACTIONS,
  props<{ ownerKey: string }>(),
);

export const loadOwnerkeyEventWorkflowActionsSuccess = createAction(
  EventWorkflowActionsActionTypes.LOAD_EVENTWORKFLOWACTIONS_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const loadOwnerkeyEventWorkflowActionsFailure = createAction(
  EventWorkflowActionsActionTypes.LOAD_EVENTWORKFLOWACTIONS_FAILURE,
  props<{ error: any }>(),
);
