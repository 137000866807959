import { Component } from '@angular/core';

@Component({
  selector: 'app-page-section-actions',
  templateUrl: './page-section-actions.component.html',
  styleUrls: ['./page-section-actions.component.scss'],
})
export class PageSectionActionsComponent {
  constructor() {}
}
