import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { filter, map, take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Request } from '@contrail/sdk';
import { ActivatedRoute } from '@angular/router';
import { RootStoreState } from '../root-store';
import { TypeManagerActions, TypeManagerSelectors } from './type-manager-store';
import { TypesService } from './types.service';
import { RoutingService } from '../common/routing/routing.service';
import { CreateEntityComponent } from '../entities/create-entity/create-entity.component';
import { MenuComponent } from '../common/components/menu/menu.component';

@Component({
  selector: 'app-types',
  templateUrl: './types.component.html',
  styleUrls: ['./types.component.scss'],
})
export class TypesComponent implements OnInit {
  @ViewChild(MenuComponent) addSubTypeComponent: MenuComponent;
  public subTypeable$: Observable<boolean>;
  public tab = 'details';
  constructor(
    private matDialog: MatDialog,
    private routingService: RoutingService,
    private typesService: TypesService,
    private store: Store<RootStoreState.State>,
    private route: ActivatedRoute,
  ) {
    this.subTypeable$ = this.store.select(TypeManagerSelectors.currentTypeRoot).pipe(
      filter((typeRoot) => !!typeRoot),
      map((typeRoot) => typeRoot.subtypeable),
    );
  }
  ngOnInit() {
    this.route.url.subscribe(() => {
      if (this.routingService.isRoute('type-manager/types')) {
        console.log('TypesComponent : goto slug');
        this.store
          .select(TypeManagerSelectors.typeRoots)
          .pipe(
            filter((roots) => roots?.length > 0),
            tap((roots) => console.log('TypesComponent: TAP: ', roots)),
            take(1),
            tap((roots) => {
              console.log('TypesComponent: GO!: ', roots);
              this.typesService.goToRoot(roots[0].slug);
            }),
          )
          .subscribe();
      }
    });
  }
  list() {
    this.routingService.go('/entities/list', {
      entityType: this.typesService.selectedTypeRoot.slug,
      typeId: this.typesService.selectedType.id,
    });
  }

  async init() {
    // Call types service to init the root types for this org.
    // Note: Call will be invoked as the user / org combination just established
    // Note: This might be a good canidate for a job and/or step function use case.
    await Request.request('/type-roots/initialize', {
      method: 'POST',
      body: JSON.stringify({}),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }
  create(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';
    dialogConfig.data = {
      entityType: this.typesService.selectedTypeRoot.slug,
      typeId: this.typesService.selectedType.id,
    };
    const dialogRef = this.matDialog.open(CreateEntityComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(() => {
      console.log('The Edit profile dialog was closed');
    });
  }

  cancelAddSubType() {
    this.addSubTypeComponent.close();
  }

  completeAddSubType(subType) {
    this.store.dispatch(TypeManagerActions.createSubType({ entity: subType }));
    this.addSubTypeComponent.close();
  }
}
