import { createAction, props } from '@ngrx/store';
import { LOG_LEVEL, NOTIFICATION_SOURCE, SystemNotification } from './system-notification';

export enum SystemNotificationActionTypes {
  LOAD_SYSTEM_NOTIFICATIONS = '[SystemNotification] Load System Notification',
  LOAD_SYSTEM_NOTIFICATIONS_SUCCESS = '[SystemNotification] Load System Notification Success',
  LOAD_SYSTEM_NOTIFICATIONS_FAILURE = '[SystemNotification] Load System Notification Failure',

  LOAD_CURRENT_SYSTEM_NOTIFICATION = '[SystemNotification] Load Current System Notification',
  LOAD_CURRENT_SYSTEM_NOTIFICATION_SUCCESS = '[SystemNotification] Load Current System Notification Success',
  LOAD_CURRENT_SYSTEM_NOTIFICATION_FAILURE = '[SystemNotification] Load Current System Notification Failure',

  CLEAR_CURRENT_SYSTEM_NOTIFICATION = '[SystemNotification] Clear Current System Notification',
}

/// /////////////////////////////////////////////////////////////////////////////
export const loadSystemNotification = createAction(
  SystemNotificationActionTypes.LOAD_SYSTEM_NOTIFICATIONS,
  props<{ logLevel: LOG_LEVEL; source: NOTIFICATION_SOURCE; startDate: string; endDate: string }>(),
);

export const loadSystemNotificationSuccess = createAction(
  SystemNotificationActionTypes.LOAD_SYSTEM_NOTIFICATIONS_SUCCESS,
  props<{ data: Array<SystemNotification> }>(),
);

export const loadSystemNotificationFailure = createAction(
  SystemNotificationActionTypes.LOAD_SYSTEM_NOTIFICATIONS_FAILURE,
  props<{ error: any }>(),
);

export const loadCurrentSystemNotification = createAction(
  SystemNotificationActionTypes.LOAD_CURRENT_SYSTEM_NOTIFICATION,
  props<{ id: string }>(),
);

export const loadCurrentSystemNotificationSuccess = createAction(
  SystemNotificationActionTypes.LOAD_CURRENT_SYSTEM_NOTIFICATION_SUCCESS,
  props<{ systemNotification: SystemNotification }>(),
);

export const loadCurrentSystemNotificationFailure = createAction(
  SystemNotificationActionTypes.LOAD_CURRENT_SYSTEM_NOTIFICATION_FAILURE,
  props<{ error: any }>(),
);

export const clearCurrentSystemNotification = createAction(
  SystemNotificationActionTypes.CLEAR_CURRENT_SYSTEM_NOTIFICATION,
);
