<div class="filter-column">
  <div class="filter-name">
    {{ filter?.label }}
  </div>
  <div class="filter-values">
    <ul class="adjustFilterValues">
      <li *ngFor="let option of filter?.options">
        <mat-checkbox color="primary" (click)="applyFilter(option.value)" [value]="option.value" #filterSelectionBoxes>
          {{ option.display }}
        </mat-checkbox>
      </li>
    </ul>
  </div>
</div>
