import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { EventWorkflowTemplate, TemplateActionLink } from './event-template';

interface EventWorkflowTemplatesState extends EntityState<EventWorkflowTemplate> {}
interface TemplateActionLinkState extends EntityState<TemplateActionLink> {}

export interface State {
  eventWorkflowTemplates: EventWorkflowTemplatesState;
  triggerKeyEventWorkflowTemplates: EventWorkflowTemplatesState;
  currentEventWorkflowTemplate: EventWorkflowTemplate;
  templateActionLinks: TemplateActionLinkState;
  loading: false;
}
export const eventWorkflowTemplatesEntityAdapter: EntityAdapter<EventWorkflowTemplate> =
  createEntityAdapter<EventWorkflowTemplate>({});
export const templateActionLinksEntityAdapter: EntityAdapter<TemplateActionLink> =
  createEntityAdapter<TemplateActionLink>({});

export const eventWorkflowTemplatesInitialState = {
  eventWorkflowTemplates: eventWorkflowTemplatesEntityAdapter.getInitialState({}),
  triggerKeyEventWorkflowTemplates: eventWorkflowTemplatesEntityAdapter.getInitialState({}),
  currentEventWorkflowTemplate: null,
  templateActionLinks: templateActionLinksEntityAdapter.getInitialState({}),
};
