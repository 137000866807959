import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { Group } from '@contrail/entity-types';

@Injectable({
  providedIn: 'root',
})
export class GroupsService {
  constructor() {}

  public async getGroups() {
    return new Entities().get({ entityName: 'group', relations: ['groupPrincipals'] });
  }
  public async getGroupById(id: string) {
    return new Entities().get({ entityName: 'group', id, relations: ['groupPrincipals', 'groupPrincipals.principal'] });
  }
  public async createGroup({ name }) {
    return new Entities().create({ entityName: 'group', object: { name } });
  }
  public async deleteGroup(group: Group) {
    await new Entities().delete({ entityName: 'group', id: group?.id });
    return group;
  }
  public async deleteGroups(ids: string[]) {
    await new Entities().batchDelete({ entityName: 'group', ids });
    return ids;
  }
  public async updateGroup(id: string, changes: Group) {
    return new Entities().update({ entityName: 'group', id, object: changes });
  }
  public async groupPrincipalBatchCreate(objects: any) {
    return new Entities().batchCreate({ entityName: 'group-principal', objects });
  }
}
