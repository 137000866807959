<div class="main" (click)="$event.stopPropagation()">
  <div class="body">
    <mat-form-field appearance="outline">
      <mat-label>Key</mat-label>
      <input #keyInput matInput [(ngModel)]="slug" (change)="setLabel()" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Label</mat-label>
      <input matInput [(ngModel)]="label" />
    </mat-form-field>
    <div class="property-types-list" *ngIf="!propertyTypeMeta">
      <div *ngFor="let propMetaOption of propertyTypes">
        <div class="property-type" *ngIf="propMetaOption.createable" (click)="propertyTypeMeta = propMetaOption">
          <app-type-property-icon [propertyType]="propMetaOption.propertyType"></app-type-property-icon
          >{{ propMetaOption.label }}
        </div>
      </div>
    </div>
    <div *ngIf="propertyTypeMeta">
      <div class="selected-option" (click)="propertyTypeMeta = null">
        <app-type-property-icon [propertyType]="propertyTypeMeta.propertyType"></app-type-property-icon
        >{{ propertyTypeMeta.label }}
      </div>
    </div>
    <div *ngIf="['sequence'].includes(propertyTypeMeta?.propertyType)">
      <mat-form-field [appearance]="'outline'">
        <mat-label>Seed Number</mat-label>
        <input matInput [formControl]="sequenceSeedNumberFormControl" type="number" />

        <!-- <mat-hint>Which level of the hieararchy does this property apply?</mat-hint> -->
      </mat-form-field>
    </div>
    <div *ngIf="['object_reference', 'type_reference'].includes(propertyTypeMeta?.propertyType)">
      <div class="reference-root-list" *ngIf="!selectedObjectReferenceTarget">
        <div *ngFor="let target of objectReferenceTargets$ | async">
          <div class="property-type" (click)="selectedObjectReferenceTarget = target">
            {{ target.label }}
          </div>
        </div>
      </div>
      <div *ngIf="selectedObjectReferenceTarget">
        <div class="selected-option" (click)="selectedObjectReferenceTarget = null">
          {{ selectedObjectReferenceTarget.label }}
        </div>
      </div>
      <div *ngIf="isItemReference()">
        <mat-form-field [appearance]="'outline'">
          <mat-label>Item Subtype</mat-label>
          <mat-select [formControl]="selectedObjectReferenceSubTypeFormControl">
            <mat-option *ngFor="let typeOption of itemTypePathOptions$ | async" [value]="typeOption.value">
              {{ typeOption.display }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field [appearance]="'outline'">
          <mat-label>Item Role</mat-label>
          <mat-select [formControl]="selectedObjectReferenceRoleFormControl">
            <mat-option [value]="'family'">Family</mat-option>
            <mat-option [value]="'option'">Option</mat-option>
          </mat-select>
          <!-- <mat-hint>Which level of the hieararchy does this property apply?</mat-hint> -->
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="actions">
    <button mat-flat-button color="cancel" (click)="cancel()">Cancel</button>
    <button mat-flat-button color="primary" [disabled]="!isValid()" (click)="addProperty()">Add</button>
  </div>
</div>
