<div *ngIf="typePropertyOptionSet$ | async" class="px-8 py-3 overflow-auto h-full" data-test="option-set-editor">
  <app-type-property-option-set-option-editor-header
    [typePropertyOptionSet]="typePropertyOptionSet$ | async"
  ></app-type-property-option-set-option-editor-header>
  <app-type-property-option-set-option-editor-option-list
    (updated)="handledUpdatedOptions($event)"
    [typePropertyOptionSet]="typePropertyOptionSet$ | async"
  >
  </app-type-property-option-set-option-editor-option-list>
</div>
