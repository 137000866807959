import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppExtensionsStoreModule } from '@common/app-extensions/app-extensions-store';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppVersionsStoreModule } from '../app-store/store/app-versions-store';
import { AppsStoreModule } from '../app-store/store/apps-store/apps-store.module';
import { OrgAppsStoreModule } from '../app-store/store/org-apps-store/org-apps-store.module';
import { AuthStoreModule } from '../common/auth/auth-store';
import { LoadingIndicatorStoreModule } from '../common/loading-indicator/loading-indicator-store';
import { EventWorkflowActionsStoreModule } from '../event-workflows/store/event-actions';
import { EventWorkflowProcessesStoreModule } from '../event-workflows/store/event-processes/event-processes.module';
import { EventWorkflowTasksStoreModule } from '../event-workflows/store/event-tasks/event-tasks.module';
import { EventWorkflowTemplatesStoreModule } from '../event-workflows/store/event-templates/event-templates.module';
import { LibraryStoreModule } from '../library/library-store';
import { EventWorkflowDashboardStoreModule } from '../event-workflows/store/event-dashboard';
import { LoaderConfigStoreModule } from '../loader/loader-configs/store/loader-config.store.module';
import { LoaderHistoryStoreModule } from '../loader/loader-history/store/loader-history.store.module';
import { OrgConfigStoreModule } from '../org-setup/org-config-store';
import { ScheduleJobStoreModule } from '../schedule-job/store';
import { SystemNotificationStoreModule } from '../system-notification/store';
import { TypeManagerStoreModule } from '../type-manager/type-manager-store';
import { GroupsStoreModule } from '../users/groups/group-store';
import { UserOrgsStoreModule } from '../users/users/user-orgs-store';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    AuthStoreModule,
    LoadingIndicatorStoreModule,
    TypeManagerStoreModule,
    UserOrgsStoreModule,
    AppsStoreModule,
    AppVersionsStoreModule,
    OrgAppsStoreModule,
    EventWorkflowTemplatesStoreModule,
    EventWorkflowActionsStoreModule,
    EventWorkflowTasksStoreModule,
    EventWorkflowProcessesStoreModule,
    GroupsStoreModule,
    OrgConfigStoreModule,
    SystemNotificationStoreModule,
    LoaderConfigStoreModule,
    ScheduleJobStoreModule,
    LoaderHistoryStoreModule,
    LibraryStoreModule,
    EventWorkflowDashboardStoreModule,
    AppExtensionsStoreModule,
  ],
})
export class RootStoreModule {}
