import { NativeDateAdapter } from '@angular/material/core';

export class isoDateAdapter extends NativeDateAdapter {
  format(date: Date, _displayFormat: object): string {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return `${year}-${this.pad(month)}-${this.pad(day)} ${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
  }
  // Helper function to pad single digit numbers with a leading zero
  private pad(n: number): string {
    return ('00' + n).slice(-2);
  }
}
