import { createSelector } from '@ngrx/store';
import { OptionSetHierarchy, optionSetHierarchiesEntityAdapter } from './option-set-hierarchies.state';

export const { selectAll, selectEntities, selectIds, selectTotal } = optionSetHierarchiesEntityAdapter.getSelectors();

const optionSetHierarchiesEntities = (state: any) => state.typeManager.optionSetHierarchies.entities;
export const optionSetHierarchies = createSelector(
  optionSetHierarchiesEntities,
  (state) => Object.values(state) as Array<OptionSetHierarchy>,
);
