import { Action, createReducer, on } from '@ngrx/store';
import { State, eventWorkflowTasksEntityAdapter, eventWorkflowTasksInitialState } from './event-tasks.state';
import * as EventWorkflowTasksActions from './event-tasks.actions';

const setCurrentEventWorkflowTask = (state: State, { eventWorkflowTask }) => ({
  ...state,
  currentEventWorkflowTask: eventWorkflowTask,
});
const setProcessIdEventWorkflowTasks = (state: State, { data }) => ({
  ...state,
  processIdEventWorkflowTasks: eventWorkflowTasksEntityAdapter.setAll(data, state.processIdEventWorkflowTasks),
});
const setProcessActionIdEventWorkflowTasks = (state: State, { data }) => ({
  ...state,
  processActionIdEventWorkflowTasks: eventWorkflowTasksEntityAdapter.setAll(
    data,
    state.processActionIdEventWorkflowTasks,
  ),
});

const resetCurrentEventWorkflowTask = (state: State) => ({
  ...state,
  currentEventWorkflowTask: null,
});

export const eventWorkflowTasksReducers = createReducer(
  eventWorkflowTasksInitialState,
  on(EventWorkflowTasksActions.loadCurrentEventWorkflowTask, resetCurrentEventWorkflowTask),
  on(EventWorkflowTasksActions.loadCurrentEventWorkflowTaskSuccess, setCurrentEventWorkflowTask),
  on(EventWorkflowTasksActions.loadProcessIdEventWorkflowTasksSuccess, setProcessIdEventWorkflowTasks),
  on(EventWorkflowTasksActions.loadProcessIdEventWorkflowTasksSuccess, setProcessActionIdEventWorkflowTasks),
  on(
    EventWorkflowTasksActions.loadProcessIdEventWorkflowTaskAndActionDefinitionSuccess,
    setProcessIdEventWorkflowTasks,
  ),
);

/**
 *
 * @param state
 * @param action
 */
export function reducer(state: State | undefined, action: Action): any {
  return eventWorkflowTasksReducers(state, action);
}
