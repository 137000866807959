<div *ngIf="typeRuleSet" class="px-8 py-3 overflow-auto h-full">
  <app-type-rule-set-editor-header [typeRuleSet]="typeRuleSet$ | async"> </app-type-rule-set-editor-header>

  <app-page-section>
    <app-page-section-header>
      <app-page-section-title title="Rule Set"></app-page-section-title>
    </app-page-section-header>
    <app-page-section-body>
      <ngx-monaco-editor
        class="border p-4 block h-96 w-full mb-2"
        [formControl]="ruleSetControl"
        [options]="editorOptions"
        #editor
      >
      </ngx-monaco-editor>
      <button mat-raised-button [disabled]="!ruleSetControl?.valid" (click)="save()">Save</button>
    </app-page-section-body>
  </app-page-section>
</div>
