import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { UserOrg } from './user-org';

@Injectable({
  providedIn: 'root',
})
export class UserOrgsService {
  constructor() {}

  public async getUserOrgs() {
    return new Entities().get({ entityName: 'user-org', relations: ['groupPrincipals', 'group', 'principal'] });
  }
  public async getUserOrgById(id: string) {
    return new Entities().get({ entityName: 'user-org', id });
  }
  public async createUserOrg(email: string) {
    return new Entities().create({ entityName: 'user-org', object: { userEmail: email, role: 'MEMBER' } });
  }
  public async deleteUserOrg(userOrg: UserOrg) {
    await new Entities().delete({ entityName: 'user-org', id: userOrg.user?.id });
    return userOrg;
  }
  public async deleteUserOrgs(ids: string[]) {
    await new Entities().batchDelete({ entityName: 'user-org', ids });
    return ids;
  }
  public async updateUserOrg(id: string, changes: UserOrg) {
    return new Entities().update({
      entityName: 'user-org',
      id,
      object: { userEmail: changes.user.email, role: changes.role, ...changes },
    });
  }
  public async createUser(user: any) {
    return new Entities().create({
      entityName: 'user-org',
      object: { role: user.role, userEmail: user.email, ...user },
    });
  }
}
