import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerActions } from '.';
import { TypesService } from '../types.service';

@Injectable()
export class TypeManagerEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private typesService: TypesService,
    private snackBar: MatSnackBar,
  ) {}

  setCurrentTypeRoot$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TypeManagerActions.TypeManagerActionTypes.SET_CURRENT_TYPE_ROOT),
        tap(() => {
          this.store.dispatch(TypeManagerActions.loadTypes());
        }),
      ),
    { dispatch: false },
  );
  // defaultCurentRoot$ = createEffect(
  //   () => this.actions$.pipe(
  //     ofType(TypeManagerActions.TypeRootsActionTypes.LOAD_TYPE_ROOTS_SUCCESS),
  //     tap((action: any) => {
  //       const root = action.data[0];
  //       if (root) {
  //         this.typesService.goToRoot(root);
  //       }
  //     }),
  //   ),
  //   { dispatch: false }
  // );
  defaultCurentType$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TypeManagerActions.TypesActionTypes.LOAD_TYPES_SUCCESS),
        tap((action: any) => {
          const type = action.data[0];
          if (type) {
            const url = window.location.href;
            if (url.indexOf('/type/') < 0) {
              this.typesService.goToType(type.id);
            }
          }
        }),
      ),
    { dispatch: false },
  );
  loadCurrentType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.TypeManagerActionTypes.LOAD_CURRENT_TYPE),
      switchMap((action: any) =>
        from(this.typesService.getTypeAndAllPropertiesById(action.id)).pipe(
          map((data) => {
            this.store.dispatch(TypeManagerActions.setTypeProperties({ data: data.typeProperties }));
            return TypeManagerActions.loadCurrentTypeSuccess({ currentType: data });
          }),
          catchError((error) => observableOf(TypeManagerActions.loadTypeRootsFailure({ error }))),
        ),
      ),
    ),
  );
  addPropertyToType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.TypeManagerActionTypes.ADD_PROPERTY_TO_TYPE),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) =>
        from(this.typesService.addPropertyToType(store.typeManager.currentType, action.typeProperty)).pipe(
          map((data) => {
            this.snackBar.open('Property added to type.', '', { duration: 2000 });
            return TypeManagerActions.addPropertyToTypeSuccess({ typeProperty: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(TypeManagerActions.addPropertyToTypeFailure({ error }));
          }),
        ),
      ),
    ),
  );
  removePropertyFromType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.TypeManagerActionTypes.REMOVE_PROPERTY_FROM_TYPE),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) =>
        from(this.typesService.deleteTypeProperty(store.typeManager.currentType, action.typeProperty)).pipe(
          map((data) => {
            this.snackBar.open('Property removed from type.', '', { duration: 2000 });
            return TypeManagerActions.removePropertyFromTypeSuccess({ typeProperty: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(TypeManagerActions.removePropertyFromTypeFailure({ error }));
          }),
        ),
      ),
    ),
  );
}
