import { createAction, props } from '@ngrx/store';
import { AppVersion } from '@contrail/entity-types';

export enum AppVersionsActionTypes {
  LOAD_CURRENT_APP_VERSIONS = '[Apps] Load Current App Versions',
  LOAD_CURRENT_APP_VERSIONS_SUCCESS = '[Apps] Load Current App Versions Success',
  LOAD_CURRENT_APP_VERSIONS_FAILURE = '[Apps] Load Current App Versions Failure',
}

/// /////////////////////////////////////////////////////////////////////////////

export const loadCurrentAppVersions = createAction(
  AppVersionsActionTypes.LOAD_CURRENT_APP_VERSIONS,
  props<{ appId: string }>(),
);

export const loadCurrentAppVersionsSuccess = createAction(
  AppVersionsActionTypes.LOAD_CURRENT_APP_VERSIONS_SUCCESS,
  props<{ appVersions: Array<AppVersion> }>(),
);

export const loadCurrentAppVersionsFailure = createAction(
  AppVersionsActionTypes.LOAD_CURRENT_APP_VERSIONS_FAILURE,
  props<{ error: any }>(),
);
