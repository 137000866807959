import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'TimezonePipe',
})
export class TimezonePipe implements PipeTransform {
  transform(value: any): string {
    const date = new Date(value);
    const offset = date.getTimezoneOffset();
    const hours = Math.abs(Math.round(offset / 60))
      .toString()
      .padStart(2, '0');
    const minutes = Math.abs(offset % 60)
      .toString()
      .padStart(2, '0');
    const sign = offset < 0 ? '+' : '-';
    return `GMT${sign}${hours}:${minutes}`;
  }
}
