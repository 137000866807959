import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DateRange, DefaultMatCalendarRangeStrategy, MatRangeDateSelectionModel } from '@angular/material/datepicker';

@Component({
  selector: 'date-range-calendar',
  templateUrl: './date-range-calendar.component.html',
  styleUrls: ['./date-range-calendar.component.scss'],
  providers: [DefaultMatCalendarRangeStrategy, MatRangeDateSelectionModel],
})
export class DateRangeCalendarComponent implements OnInit {
  minDate: Date;
  maxDate: Date;
  newStartDate: Date;
  newEndDate: Date;
  startDateTimePicker: Date;
  endDateTimePicker: Date;
  selectedDateRange: DateRange<Date>;
  isApplyButtonDisabled = false;
  color: 'primary';

  constructor(
    public dialogRef: MatDialogRef<DateRangeCalendarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private selectionModel: MatRangeDateSelectionModel<Date>,
    private selectionStrategy: DefaultMatCalendarRangeStrategy<Date>,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.minDate = this.data.minDate;
    this.maxDate = this.data.maxDate;
    this.initTimePickers(this.data.startDate, this.data.endDate);
    this.newStartDate = this.startDateTimePicker;
    this.newEndDate = this.endDateTimePicker;
    this.initDateRange(this.data.startDate, this.data.endDate);
  }

  initDateRange(startDate: Date, endDate: Date) {
    this.selectedDateRange = new DateRange<Date>(startDate, endDate);
  }

  initTimePickers(startDate: Date, endDate: Date) {
    this.startDateTimePicker = new Date(startDate);
    this.endDateTimePicker = new Date(endDate);
  }

  rangeChanged(selectedDate: Date) {
    const selection = this.selectionModel.selection;
    const newSelection = this.selectionStrategy.selectionFinished(selectedDate, selection);

    this.selectionModel.updateSelection(newSelection, this);
    this.selectedDateRange = new DateRange<Date>(newSelection.start, newSelection.end);
    if (newSelection.start) {
      newSelection.start.setHours(this.startDateTimePicker.getHours());
      newSelection.start.setMinutes(this.startDateTimePicker.getMinutes());
      newSelection.start.setSeconds(this.startDateTimePicker.getSeconds());
      newSelection.start.setMilliseconds(0);
      this.startDateTimePicker = newSelection.start;
    }
    if (newSelection.start && newSelection.end) {
      newSelection.end.setHours(this.endDateTimePicker.getHours());
      newSelection.end.setMinutes(this.endDateTimePicker.getMinutes());
      newSelection.end.setSeconds(this.endDateTimePicker.getSeconds());
      newSelection.end.setMilliseconds(999);
      this.startDateTimePicker = newSelection.start;
      this.endDateTimePicker = newSelection.end;
    }
  }

  updateDate() {
    this.newStartDate = new Date(this.startDateTimePicker.getTime());
    this.newEndDate = new Date(this.endDateTimePicker.getTime());
    this.isApplyButtonDisabled = this.newEndDate < this.newStartDate;
  }

  apply() {
    this.route.queryParams.subscribe((params) => {
      this.dialogRef.close({ startDate: this.newStartDate, endDate: this.newEndDate, pageSize: params.pageSize });
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}
