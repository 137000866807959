import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { RootStoreState } from '../../root-store';
import { TypeManagerActions, TypeManagerSelectors } from '../type-manager-store';
import { TypePolicy } from '../type-manager-store/type-policies/type-policies.state';

@Injectable({
  providedIn: 'root',
})
export class TypePoliciesResolver implements Resolve<Array<TypePolicy>> {
  constructor(private store: Store<RootStoreState.State>) {}
  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<Array<TypePolicy>> {
    this.loadTypePolicies();
    return this.store.select(TypeManagerSelectors.typePropertyPolicies).pipe(take(1));
  }

  loadTypePolicies() {
    this.store.dispatch(TypeManagerActions.loadTypePolicies());
  }
}
