import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Entities } from '@contrail/sdk';
import { Type } from '@contrail/types';
import { Observable, Subscription, from } from 'rxjs';
import { TypePoliciesService } from '../../type-manager-store/type-policies/type-policies.service';

@Component({
  selector: 'app-type-type-policies',
  templateUrl: './type-type-policies.component.html',
  styleUrls: ['./type-type-policies.component.scss'],
})
export class TypeTypePoliciesComponent implements OnChanges, OnDestroy, OnInit {
  @Input() type: Type;
  public availableTypePolicies$: Observable<Array<any>>;
  public addTypePolicyFormControl = new UntypedFormControl();
  private addTypePolicyFormControlSub: Subscription;
  public typePolicyLinks: Array<any>;
  public displayTypePolicyLengthLimit = 35;

  constructor(private typePoliciesService: TypePoliciesService) {}

  ngOnInit() {
    this.addTypePolicyFormControlSub = this.addTypePolicyFormControl.valueChanges.subscribe((typePolicy) => {
      if (typePolicy) {
        this.addTypePolicy(typePolicy);
        this.addTypePolicyFormControl.reset();
      }
    });
  }

  async ngOnChanges() {
    if (!this.type) {
      return;
    }
    this.typePolicyLinks = await new Entities().get({
      entityName: 'type-policy-link',
      criteria: { typeId: this.type.id },
      relations: ['typePolicy'],
    });
    this.availableTypePolicies$ = from(this.typePoliciesService.get());
  }
  async removeTypePolicy(typePolicyLink) {
    // PREEMPT THE DELETE.
    const filteredAssigned = this.typePolicyLinks.filter((l) => l.id !== typePolicyLink.id);
    this.typePolicyLinks = filteredAssigned;

    await new Entities().delete({
      entityName: 'type-policy-link',
      id: typePolicyLink.id,
    });
  }

  async addTypePolicy(typePolicy: any) {
    const existing = this.typePolicyLinks.find((p) => p.typePolicyId === typePolicy.id);
    if (existing) {
      return;
    }
    console.log('Adding type policy link: ', typePolicy, this.typePolicyLinks.length);
    const link = await new Entities().create({
      entityName: 'type-policy-link',
      object: { typePolicyId: typePolicy.id, typeId: this.type.id },
    });
    link.typePolicy = typePolicy;
    this.typePolicyLinks.push(link);
    console.log('Added!!!! type policy: ', typePolicy, this.typePolicyLinks.length);
  }
  displayFn(policy: any): string {
    return policy?.label;
  }

  ngOnDestroy(): void {
    this.addTypePolicyFormControlSub?.unsubscribe();
  }
}
