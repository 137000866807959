import { Action, createReducer, on } from '@ngrx/store';
import { OrgAppsActions } from '.';
import { State, orgAppsEntityAdapter, orgAppsInitialState } from './org-apps.state';

const setData = (state: State, { data }) => ({
  ...state,
  orgApps: orgAppsEntityAdapter.setAll(data, state.orgApps),
});
const add = (state: State, { orgApp }) => ({
  ...state,
  orgApps: orgAppsEntityAdapter.addOne(orgApp, state.orgApps),
});
const update = (state: State, { id, changes }) => ({
  ...state,
  orgApps: orgAppsEntityAdapter.updateOne({ id, changes }, state.orgApps),
});
const remove = (state: State, { orgApp }) => ({
  ...state,
  orgApps: orgAppsEntityAdapter.removeOne(orgApp.id, state.orgApps),
});

const setCurrentOrgApp = (state: State, { orgApp }) => ({
  ...state,
  currentOrgApp: orgApp,
});

export const orgAppsReducers = createReducer(
  orgAppsInitialState,
  on(OrgAppsActions.loadOrgAppsSuccess, setData),
  on(OrgAppsActions.createOrgAppSuccess, add),
  on(OrgAppsActions.deleteOrgAppSuccess, remove),
  on(OrgAppsActions.updateOrgAppSuccess, update),
  on(OrgAppsActions.loadCurrentOrgAppSuccess, setCurrentOrgApp),
);

/**
 *
 * @param state
 * @param action
 */
export function reducer(state: State | undefined, action: Action): any {
  return orgAppsReducers(state, action);
}
