import { on } from '@ngrx/store';
import { State } from '../type-manager.state';
import * as OptionSetHierarchiesAction from './option-set-hierarchies.actions';
import { optionSetHierarchiesEntityAdapter } from './option-set-hierarchies.state';

const setData = (state: State, { data }) => {
  console.log('setData: ', data);
  return {
    ...state,
    optionSetHierarchies: optionSetHierarchiesEntityAdapter.setAll(data, state.optionSetHierarchies),
  };
};
const add = (state: State, { optionSetHierarchy }) => ({
  ...state,
  optionSetHierarchies: optionSetHierarchiesEntityAdapter.addOne(optionSetHierarchy, state.optionSetHierarchies),
});
const update = (state: State, { id, changes }) => ({
  ...state,
  optionSetHierarchies: optionSetHierarchiesEntityAdapter.updateOne({ id, changes }, state.optionSetHierarchies),
});
const remove = (state: State, { optionSetHierarchy }) => ({
  ...state,
  optionSetHierarchies: optionSetHierarchiesEntityAdapter.removeOne(optionSetHierarchy.id, state.optionSetHierarchies),
});

export const optionSetHierarchiesReducer = [
  on(OptionSetHierarchiesAction.loadOptionSetHierarchiesSuccess, setData),
  on(OptionSetHierarchiesAction.createOptionSetHierarchySuccess, add),
  on(OptionSetHierarchiesAction.deleteOptionSetHierarchySuccess, remove),
  on(OptionSetHierarchiesAction.updateOptionSetHierarchy, update),
];
