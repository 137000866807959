import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Entity } from '../entity';

@Component({
  selector: 'app-entity-details',
  templateUrl: './entity-details.component.html',
  styleUrls: ['./entity-details.component.scss'],
})
export class EntityDetailsComponent {
  public entity: Entity;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe((params) => {
      console.log('params: ', params);
      const entityType = params.entityType;
      const id = params.id;

      this.entity = new Entity(entityType, id, [
        'primaryViewable',
        'primaryViewable.primaryFile',
        'primaryViewable.largeViewable',
        'createdBy',
        'updatedBy',
      ]);
      this.entity.load();
    });
  }

  go(route) {
    this.router.navigate([`${route}`], { relativeTo: this.route });
  }

  format(json) {
    return JSON.stringify(json, null, 4);
  }
}
