<app-page-section [title]="'Relationships'">
  <div class="list-item" *ngFor="let relation of relations$ | async" data-test="type-relationship">
    <mat-icon class="list-value">{{ getIconForCardinality(relation.cardinality) }}</mat-icon>
    <div class="list-value code">{{ relation.name }}</div>
    <div class="list-value center">
      <mat-chip color="primary">
        {{ getLabelForEntitySlug(relation.entityType) }}
      </mat-chip>
    </div>
  </div>
</app-page-section>
