import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { appExtensionsReducer } from './app-extensions.reducers';
import { EffectsModule } from '@ngrx/effects';
import { AppExtensionsEffects } from './app-extensions.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('appExtensions', appExtensionsReducer),
    EffectsModule.forFeature([AppExtensionsEffects]),
  ],
})
export class AppExtensionsStoreModule {}
