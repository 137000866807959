import { createAction, props } from '@ngrx/store';
import { TypePropertyUserList } from './user-list.state';

export enum TypePropertyUserListsActionTypes {
  LOAD_TYPE_PROPERTY_USER_LISTS = '[Type Property User Lists] Load Type Property User Lists',
  LOAD_TYPE_PROPERTY_USER_LISTS_SUCCESS = '[Type Property User Lists] Load Type Property User Lists Success',
  LOAD_TYPE_PROPERTY_USER_LISTS_FAILURE = '[Type Property User Lists] Load Type Property User Lists Failure',
  ADD_TYPE_PROPERTY_USER_LIST = '[Type Property User Lists] Add Type Property User List',
}

////////////////////////////////////////////////////////////////////////////////

export const loadTypePropertyUserLists = createAction(TypePropertyUserListsActionTypes.LOAD_TYPE_PROPERTY_USER_LISTS);

export const loadTypePropertyUserListsSuccess = createAction(
  TypePropertyUserListsActionTypes.LOAD_TYPE_PROPERTY_USER_LISTS_SUCCESS,
  props<{ data: Array<TypePropertyUserList> }>(),
);

export const loadTypePropertyUserListsFailure = createAction(
  TypePropertyUserListsActionTypes.LOAD_TYPE_PROPERTY_USER_LISTS_FAILURE,
  props<{ error: any }>(),
);

export const addTypePropertyUserList = createAction(
  TypePropertyUserListsActionTypes.ADD_TYPE_PROPERTY_USER_LIST,
  props<{ typePropertyUserList: TypePropertyUserList }>(),
);
