import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Entities } from '@contrail/sdk';
import { File } from '@contrail/entity-types';
import { MatSnackBar } from '@angular/material/snack-bar';

interface FileData extends File {
  size: number;
  createdBy: {
    email: string;
  };
}

const KiloBytesThreshold = 1000;
const MegaBytesThreshold = 1000000;

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss'],
})
export class FilesComponent implements OnInit {
  file: FileData;
  fileId: string;
  email: string;
  createdOn: Date;
  fileName: string;
  updatedById: string;
  size: string;
  downloadUrl: string;
  found: boolean;
  isLoading = true;

  constructor(
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      const urlSegments = this.route.snapshot.url;
      this.fileId = this.route.snapshot.params.fileId;
      await this.getFile();
      this.setAttributes();

      if (urlSegments[urlSegments.length - 1].path === 'download') {
        this.downloadFile();
      }
      this.found = !!this.file;
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  }

  async getFile(): Promise<void> {
    try {
      this.file = await new Entities().get({ entityName: 'file', id: this.fileId, relations: ['createdBy'] });
    } catch (error) {
      console.log(error);
    }
  }

  setAttributes(): void {
    this.email = this.file.createdBy.email;
    this.createdOn = new Date(this.file.createdOn);
    this.fileName = this.file.fileName;
    this.updatedById = this.file.updatedById;
    this.size = this.convertSize(this.file.size);
    this.downloadUrl = this.file.downloadUrl;
  }

  convertSize(size: number): string {
    if (size < KiloBytesThreshold) {
      return size + ' Bs';
    } else if (size < MegaBytesThreshold) {
      return (size / 1000).toFixed(2) + ' KBs';
    } else if (size >= MegaBytesThreshold) {
      return (size / 1000000).toFixed(2) + ' MBs';
    }
  }

  downloadFile(): void {
    window.location.href = this.downloadUrl;
    const message = `Downloaded ${this.fileName}`;
    this.snackBar.open(message, '', { duration: 2000 });
  }
}
