import { Component, ViewChild } from '@angular/core';
import { TypeProperty } from '@contrail/types';
import { Store } from '@ngrx/store';
import { MenuComponent } from 'src/app/common/components/menu/menu.component';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerActions } from 'src/app/type-manager/type-manager-store';

const enum Mode {
  ADD_EXISTING = 'ADD_EXISTING',
  ADD_NEW = 'ADD_NEW',
}
@Component({
  selector: 'app-add-property-to-type',
  templateUrl: './add-property-to-type.component.html',
  styleUrls: ['./add-property-to-type.component.scss'],
})
export class AddPropertyToTypeComponent {
  public mode: Mode = Mode.ADD_NEW;
  @ViewChild(MenuComponent) menu: MenuComponent;
  constructor(private store: Store<RootStoreState.State>) {}

  toggleAdd() {}
  cancelAdd() {
    this.menu.close();
  }
  completeAdd(typeProperty: TypeProperty) {
    this.store.dispatch(TypeManagerActions.createTypeProperty({ typeProperty }));
    this.menu.close();
  }
  handlePropertySelected(typeProperty: TypeProperty) {
    console.log('AddPropertyToTypeComponent: typeSelected:', typeProperty);
    this.store.dispatch(TypeManagerActions.addPropertyToType({ typeProperty }));
  }
}
