import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TypeRoot } from '@contrail/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { RootStoreState } from '../../root-store';
import { TypeManagerActions, TypeManagerSelectors } from '../type-manager-store';

@Injectable({
  providedIn: 'root',
})
export class TypeRootsResolver implements Resolve<Array<TypeRoot>> {
  constructor(private store: Store<RootStoreState.State>) {}
  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<Array<TypeRoot>> {
    this.loadTypeRoots();
    return this.store.select(TypeManagerSelectors.typeRoots).pipe(take(1));
  }

  loadTypeRoots() {
    this.store.dispatch(TypeManagerActions.loadTypeRoots());
  }
}
