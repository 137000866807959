import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerActions, TypeManagerSelectors } from '../type-manager-store';
import { TypeRuleSet } from '../type-manager-store/type-rule-sets/type-rule-sets.state';

@Injectable({
  providedIn: 'root',
})
export class TypeRuleSetResolver implements Resolve<TypeRuleSet> {
  constructor(private store: Store<RootStoreState.State>) {}
  resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<TypeRuleSet> {
    const typeRuleSetId = route.params.typeRuleSetId;
    console.log('typeRuleSetId: ', typeRuleSetId);
    this.setCurrentTypeRuleSet(typeRuleSetId);
    return this.store.select(TypeManagerSelectors.currentTypeRuleSet).pipe(
      filter((ruleSet) => ruleSet && ruleSet.id === typeRuleSetId),
      take(1),
    );
  }
  setCurrentTypeRuleSet(id) {
    this.store
      .select(TypeManagerSelectors.typeRuleSets)
      .pipe(
        filter((ruleSets) => ruleSets && ruleSets.length > 0),
        map((ruleSets) => ruleSets.find((ruleSet) => ruleSet.id === id)),
        tap((ruleSet) => {
          console.log('ruleSet: ', ruleSet);
          this.store.dispatch(TypeManagerActions.setCurrentTypeRuleSet({ currentTypeRuleSet: ruleSet }));
        }),
        take(1),
      )
      .subscribe();
  }
}
