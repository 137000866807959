import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OptionSetHierarchiesResolver } from './option-set-hierarchies/option-set-hierarchies-resolver';
import { OptionSetHierarchiesComponent } from './option-set-hierarchies/option-set-hierarchies.component';
import { OptionSetHierarchyEditorComponent } from './option-set-hierarchies/option-set-hierarchy-editor/option-set-hierarchy-editor.component';
import { OptionSetHierarchyResolver } from './option-set-hierarchies/option-set-hierarchy-resolver';
import { TypeDetailsComponent } from './type-details/type-details.component';
import { TypePropertiesComponent } from './type-properties/type-properties.component';
import { TypePropertyOptionSetOptionEditorComponent } from './type-property-option-sets/type-property-option-set-option-editor/type-property-option-set-option-editor.component';
import { TypePropertyOptionSetResolver } from './type-property-option-sets/type-property-option-set-resolver';
import { TypePropertyOptionSetsResolver } from './type-property-option-sets/type-property-option-sets-resolver';
import { TypePropertyOptionSetsComponent } from './type-property-option-sets/type-property-option-sets.component';
import { TypePropertyUserListsResolver } from './type-property-user-lists/type-property-user-lists-resolver';

import { TypePropertyPoliciesResolver } from './type-property-policies/type-property-policies-resolver';
import { TypePropertyPoliciesComponent } from './type-property-policies/type-property-policies.component';
import { TypePropertyPolicyEditorComponent } from './type-property-policies/type-property-policy-editor/type-property-policy-editor.component';
import { TypePropertyPolicyResolver } from './type-property-policies/type-property-policy-resolver';
import { TypeRootResolver } from './type-root/type-root-resolver';
import { TypeRootComponent } from './type-root/type-root.component';
import { TypeRootsResolver } from './type-root/type-roots-resolver';
import { TypeRuleSetEditorComponent } from './type-rule-sets/type-rule-set-editor/type-rule-set-editor.component';
import { TypeRuleSetResolver } from './type-rule-sets/type-rule-set-resolver';
import { TypeRuleSetsResolver } from './type-rule-sets/type-rule-sets-resolver';
import { TypeRuleSetsComponent } from './type-rule-sets/type-rule-sets.component';
import { TypesComponent } from './types.component';
import { TypePoliciesComponent } from './type-policies/type-policies.component';
import { TypePolicyEditorComponent } from './type-policies/type-policy-editor/type-policy-editor.component';
import { TypePolicyResolver } from './type-policies/type-policy-resolver';
import { TypePoliciesResolver } from './type-policies/type-policies-resolver';

const routes: Routes = [
  { path: '', redirectTo: 'types', pathMatch: 'full' },
  { path: 'properties', component: TypePropertiesComponent },
  {
    path: 'option-sets',
    resolve: { typePropertyOptionSets: TypePropertyOptionSetsResolver },
    component: TypePropertyOptionSetsComponent,
    children: [
      {
        path: ':optionSetId',
        resolve: { optionSet: TypePropertyOptionSetResolver },
        component: TypePropertyOptionSetOptionEditorComponent,
      },
    ],
  },
  {
    path: 'type-policies',
    resolve: { typePolicies: TypePoliciesResolver },
    component: TypePoliciesComponent,
    children: [
      {
        path: ':typePolicyId',
        resolve: { typePolicy: TypePolicyResolver },
        component: TypePolicyEditorComponent,
      },
    ],
  },
  {
    path: 'type-property-policies',
    resolve: { typePropertyPolicies: TypePropertyPoliciesResolver },
    component: TypePropertyPoliciesComponent,
    children: [
      {
        path: ':typePropertyPolicyId',
        resolve: { typePropertyPolicy: TypePropertyPolicyResolver },
        component: TypePropertyPolicyEditorComponent,
      },
    ],
  },
  {
    path: 'option-set-hierarchies',
    resolve: { optionSetHierarchies: OptionSetHierarchiesResolver },
    component: OptionSetHierarchiesComponent,
    children: [
      {
        path: ':optionSetHierarchyId',
        resolve: { optionSetHierarchy: OptionSetHierarchyResolver },
        component: OptionSetHierarchyEditorComponent,
      },
    ],
  },
  {
    path: 'rule-sets',
    resolve: { typeRuleSets: TypeRuleSetsResolver },
    component: TypeRuleSetsComponent,
    children: [
      {
        path: ':typeRuleSetId',
        resolve: { typeRuleSet: TypeRuleSetResolver },
        component: TypeRuleSetEditorComponent,
      },
    ],
  },
  {
    path: 'types',
    resolve: {
      typeRoots: TypeRootsResolver,
      typePropertyOptionSets: TypePropertyOptionSetsResolver,
      typePropertyUserLists: TypePropertyUserListsResolver,
    },
    component: TypesComponent,
    children: [
      {
        path: ':rootSlug',
        resolve: {
          typeRoot: TypeRootResolver,
          typePropertyPolicies: TypePropertyPoliciesResolver,
        },
        component: TypeRootComponent,
        children: [{ path: 'type/:typeId', component: TypeDetailsComponent }],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TypeManagerRoutingModule {}
