import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, Pipe, PipeTransform } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DomSanitizer } from '@angular/platform-browser';
import { AuthSelectors } from '@common/auth/auth-store';
import { AuthService } from '@common/auth/auth.service';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { filter, take, tap } from 'rxjs';
import {
  AppExtensionHost,
  AppExtensionMessageHandler,
  AppContext,
  AppExtensionMessage,
} from '@contrail/extensions-sdk';

@Pipe({ name: 'safeIframeURL' })
export class SafeIFramePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-app-extension-modal-launcher',
  templateUrl: './app-extension-modal-launcher.component.html',
  styleUrls: ['./app-extension-modal-launcher.component.scss'],
})
export class AppExtensionModalLauncherComponent implements OnInit, OnDestroy {
  public loading = true;
  public iframeUrl;
  @Output() closeWidget = new EventEmitter();
  public selectedIds: Array<any>;
  public iframeData: any;
  public authToken: string;
  public authOrg: string;
  public user;
  private messageHandler: AppExtensionMessageHandler;
  private messageListener: any;

  constructor(
    private store: Store<RootStoreState.State>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
  ) {}

  async ngOnInit() {
    this.authToken = await this.authService.getToken();
    this.store
      .select(AuthSelectors.selectAuthContext)
      .pipe(
        filter((res) => res !== null),
        take(1),
        tap((context) => {
          this.authOrg = context.currentOrg?.orgSlug;
          this.user = context.user;
        }),
      )
      .subscribe();

    // Bind to the message handler provided on launch
    this.messageHandler = this.data.messageHandler;

    /** Once the web app has loaded into the iframe, we can securely send the authentication information */
    document.getElementById('localFrame').onload = () => {
      this.loading = false;
    };

    await this.initFrameConfig();
  }

  ngOnDestroy(): void {
    console.log('onDestroy.. removing listener');
    window.removeEventListener('message', this.messageListener);
  }

  async initFrameConfig() {
    const iframe = document.getElementById('localFrame') as HTMLIFrameElement;
    this.iframeUrl = this.data.app?.iframeUrl;
    iframe.src = this.iframeUrl;

    const context: AppContext = {
      appContext: this.data?.context,
      user: this.user,
    };

    const appExtensionMessageHandlerWrapper = {
      handleMessage: async (data: AppExtensionMessage) => {
        if (data.command === 'close') {
          this.closeWidget.emit();
        } else {
          this.messageHandler.handleMessage(data);
        }
      },
    };

    await AppExtensionHost.registerHostWithAppExtension(iframe, context, appExtensionMessageHandlerWrapper);
  }
}
