import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface TypeRuleSet {
  id?: string;
  name?: string;
  identifier?: string;
  ruleSet?: any;
}

export interface TypeRuleSetsState extends EntityState<TypeRuleSet> {}
export const typeRuleSetsEntityAdapter: EntityAdapter<TypeRuleSet> = createEntityAdapter<TypeRuleSet>({});

export const typeRuleSetsInitialState = typeRuleSetsEntityAdapter.getInitialState({});
