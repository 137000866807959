<app-page-section *ngIf="isUserListProperty">
  <app-page-section-header>
    <app-page-section-title title="User List"></app-page-section-title>
  </app-page-section-header>
  <app-page-section-body>
    <div *ngIf="!userListAssigned && typeProperty.typePropertyUserListId" class="bg-amber-100 p-3 m-2 mb-4">
      WARNING: Previously referenced User List no longer exists.
    </div>
    <div *ngIf="!userListAssigned" class="user-list-select">
      <mat-form-field class="example-full-width">
        <mat-label>Choose a User List</mat-label>
        <input
          type="text"
          placeholder="Pick one"
          aria-label="User List"
          matInput
          [formControl]="userListFormControl"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let userList of userLists$ | async" [value]="userList">
            {{ userList.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div>
        or <span class="cursor-pointer underline text-primary" (click)="goToUserList()">Create New User List</span>
      </div>
    </div>
    <div *ngIf="userListAssigned" class="user-list-details">
      Using user list:
      <span class="cursor-pointer underline text-primary" (click)="goToUserList()">{{ userList?.name }}</span>
      <div class="user-list-list">
        <div class="option-row" *ngFor="let option of userList?.userList">
          {{ option.display }}
        </div>
      </div>
    </div>
  </app-page-section-body>
</app-page-section>
