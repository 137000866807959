import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UiTestComponent } from '@common/components/ui-test/ui-test.component';
import { AuthGuard } from './common/auth/auth.gaurd';
import { LoginComponent } from './common/auth/login/login.component';
import { MainComponent } from './main/main.component';
import { NoOrgsRegisteredComponent } from './common/auth/no-orgs-registered/no-orgs-registered.component';
import { SelectOrgComponent } from './main/select-org/select-org.component';
import { UserOrgResolver } from './common/auth/user-org.resolver';
import { DefaultOrgResolver } from './common/auth/default-org.resolver';
import { ClearCacheComponent } from './cache/clear-cache/clear-cache.component';
import { FilesComponent } from './files/files.component';
import { UtilityExtensionsComponent } from './utility-extensions/utility-extension.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    resolve: { defaultOrg: DefaultOrgResolver },
    children: [
      { path: '', component: SelectOrgComponent },
      {
        path: 'org/:org',
        resolve: { user: UserOrgResolver },
        children: [
          { path: '', redirectTo: 'org-setup', pathMatch: 'full' },
          { path: 'entities', loadChildren: () => import('./entities/entities.module').then((m) => m.EntitiesModule) },
          {
            path: 'type-manager',
            loadChildren: () => import('./type-manager/type-manager.module').then((m) => m.TypeManagerModule),
          },
          {
            path: 'library',
            loadChildren: () => import('./library/library.module').then((m) => m.LibraryModule),
          },
          { path: 'users', loadChildren: () => import('./users/users.module').then((m) => m.UsersModule) },
          {
            path: 'app-store',
            loadChildren: () => import('./app-store/app-store.module').then((m) => m.AppStoreModule),
          },
          {
            path: 'org-setup',
            loadChildren: () => import('./org-setup/org-setup.module').then((m) => m.OrgSetupModule),
          },
          { path: 'loader', loadChildren: () => import('./loader/loader.module').then((m) => m.LoaderModule) },
          {
            path: 'event-workflows',
            loadChildren: () => import('./event-workflows/event-workflows.module').then((m) => m.EventWorkflowsModule),
          },
          {
            path: 'schedules',
            loadChildren: () => import('./schedule-job/schedule-job.module').then((m) => m.ScheduleJobModule),
          },
          { path: 'webhooks', loadChildren: () => import('./webhooks/webhooks.module').then((m) => m.WebhooksModule) },
          { path: 'api', loadChildren: () => import('./api/api.module').then((m) => m.ApiModule) },
          { path: 'cache', component: ClearCacheComponent },
          { path: 'ui-test', component: UiTestComponent },
          {
            path: 'system-notifications',
            loadChildren: () =>
              import('./system-notification/system-notification.module').then((m) => m.SystemNotificationModule),
          },
          { path: 'files/:fileId', component: FilesComponent },
          { path: 'files/:fileId/download', component: FilesComponent },
          { path: 'utility-extensions/:extensionIdentifier', component: UtilityExtensionsComponent },
        ],
      },
    ],
  },

  { path: 'login', component: LoginComponent },
  { path: 'no-org-found', component: NoOrgsRegisteredComponent },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
