import { Action, createReducer, on } from '@ngrx/store';
import { typeRootsReducers } from './type-roots/type-roots.reducers';
import { typesReducers } from './types/types.reducers';
import { typePropertiesReducer } from './type-properties/type-properties.reducers';
import { typePropertyOptionSetsReducer } from './option-sets/option-sets.reducers';
import { typePropertyUserListsReducer } from './user-lists/user-lists.reducers';
import { initialState, State } from './type-manager.state';
import * as TypeManagerActions from './type-manager.actions';
import { typePropertyPoliciesReducer } from './type-property-policies/type-property-policies.reducers';
import { typeRuleSetsReducer } from './type-rule-sets/type-rule-sets.reducers';
import { optionSetHierarchiesReducer } from './option-set-hierarchies/option-set-hierarchies.reducers';
import { typePoliciesReducer } from './type-policies/type-policies.reducers';

const setCurrentTypeRoot = (state: State, { typeRoot }) => ({
  ...state,
  currentTypeRoot: typeRoot,
});

const setCurrentType = (state: State, { currentType }) => ({
  ...state,
  currentType,
});
const setCurrentTypeProperty = (state: State, { currentTypeProperty }) => ({
  ...state,
  currentTypeProperty,
});
const setCurrentTypePropertyOptionSet = (state: State, { currentTypePropertyOptionSet }) => ({
  ...state,
  currentTypePropertyOptionSet,
});
const setCurrentTypePropertyUserList = (state: State, { currentTypePropertyUserList }) => ({
  ...state,
  currentTypePropertyUserList,
});
const removePropertyFromCurrentType = (state: State, { typeProperty }) => ({
  ...state,
  currentType: {
    ...state.currentType,
    typeProperties: state.currentType.typeProperties.filter((prop) => prop.id !== typeProperty.id),
  },
});
const addPropertyToCurrentType = (state: State, { typeProperty }) => {
  const typeProperties = [
    ...state.currentType.typeProperties.filter((prop) => prop.id !== typeProperty.id),
    typeProperty,
  ];
  return {
    ...state,
    currentType: {
      ...state.currentType,
      typeProperties,
    },
  };
};
const setCurrentTypePropertyPolicy = (state: State, { currentTypePropertyPolicy }) => ({
  ...state,
  currentTypePropertyPolicy,
});
const setCurrentTypePolicy = (state: State, { currentTypePolicy }) => ({
  ...state,
  currentTypePolicy,
});
const setCurrentTypeRuleSet = (state: State, { currentTypeRuleSet }) => ({
  ...state,
  currentTypeRuleSet,
});
const setCurrentOptionSetHierarchy = (state: State, { currentOptionSetHierarchy }) => ({
  ...state,
  currentOptionSetHierarchy,
});

const isLoading = (state: State, { loading }) => ({
  ...state,
  loading,
});

const isUpdating = (state: State, { updating }) => ({
  ...state,
  updating,
});

export const typeManagerReducer = createReducer(
  initialState,
  ...typeRootsReducers,
  ...typesReducers,
  ...typePropertiesReducer,
  ...typePropertyOptionSetsReducer,
  ...typePropertyUserListsReducer,
  ...typePropertyPoliciesReducer,
  ...typePoliciesReducer,
  ...typeRuleSetsReducer,
  ...optionSetHierarchiesReducer,
  on(TypeManagerActions.setCurrentTypeRoot, setCurrentTypeRoot),
  on(TypeManagerActions.loadCurrentTypeSuccess, setCurrentType),
  on(TypeManagerActions.setCurrentTypeProperty, setCurrentTypeProperty),
  on(TypeManagerActions.removePropertyFromTypeSuccess, removePropertyFromCurrentType),
  on(TypeManagerActions.addPropertyToTypeSuccess, addPropertyToCurrentType),
  on(TypeManagerActions.setCurrentTypePropertyOptionSet, setCurrentTypePropertyOptionSet),
  on(TypeManagerActions.setCurrentTypePropertyUserList, setCurrentTypePropertyUserList),
  on(TypeManagerActions.setCurrentTypePropertyPolicy, setCurrentTypePropertyPolicy),
  on(TypeManagerActions.setCurrentTypePolicy, setCurrentTypePolicy),
  on(TypeManagerActions.setCurrentTypeRuleSet, setCurrentTypeRuleSet),
  on(TypeManagerActions.setCurrentOptionSetHierarchy, setCurrentOptionSetHierarchy),
  on(TypeManagerActions.loading, isLoading),
  on(TypeManagerActions.updating, isUpdating),
);

/**
 *
 * @param state
 * @param action
 */
export function reducer(state: State | undefined, action: Action): any {
  return typeManagerReducer(state, action);
}
