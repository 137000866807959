import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { RootStoreState } from '../../root-store';
import { TypeManagerActions, TypeManagerSelectors } from '../type-manager-store';
import { OptionSetHierarchy } from '../type-manager-store/option-set-hierarchies/option-set-hierarchies.state';

@Injectable({
  providedIn: 'root',
})
export class OptionSetHierarchiesResolver implements Resolve<Array<OptionSetHierarchy>> {
  constructor(private store: Store<RootStoreState.State>) {}
  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<Array<OptionSetHierarchy>> {
    this.load();
    return this.store.select(TypeManagerSelectors.optionSetHierarchies).pipe(take(1));
  }

  load() {
    this.store.dispatch(TypeManagerActions.loadOptionSetHierarchies());
  }
}
