export const currentTypeRoot = (state: any) => state.typeManager.currentTypeRoot;
export const currentType = (state: any) => state.typeManager.currentType;
export const currentTypeProperty = (state: any) => state.typeManager.currentTypeProperty;
export const currentTypePropertyOptionSet = (state: any) => state.typeManager.currentTypePropertyOptionSet;
export const currentTypePropertyUserList = (state: any) => state.typeManager.currentTypePropertyUserList;
export const currentTypePropertyPolicy = (state: any) => state.typeManager.currentTypePropertyPolicy;
export const currentTypePolicy = (state: any) => state.typeManager.currentTypePolicy;
export const currentTypeRuleSet = (state: any) => state.typeManager.currentTypeRuleSet;
export const currentOptionSetHierarchy = (state: any) => state.typeManager.currentOptionSetHierarchy;

export const updating = (state: any) => state.typeManager.updating;
export const loading = (state: any) => state.typeManager.loading;
