import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { LineChartData } from './event-dashboard';

export interface ChartsState {
  triggeredChartData: LineChartData | null;
  failedChartData: LineChartData | null;
  triggeredChartLoading: boolean;
  failedChartLoading: boolean;
  error: Error;
}

export const initialState: ChartsState = {
  triggeredChartData: null,
  failedChartData: null,
  triggeredChartLoading: false,
  failedChartLoading: false,
  error: null,
};

export const chartEntityAdapter: EntityAdapter<LineChartData> = createEntityAdapter<LineChartData>();
