<div class="header flex-between-center">
  <div class="left-header flex justify-center items-center">
    <h6>{{ typePolicy?.label }}</h6>
  </div>
  <div class="right-header flex-center mb-3 config-header">
    <mat-button-toggle-group [value]="activeView" (change)="selectView($event)">
      <mat-button-toggle [value]="view.UI" class="view-selection-tabs cursor-pointer" data-test="ui-view"
        >UI</mat-button-toggle
      >
      <mat-button-toggle [value]="view.JSON" class="view-selection-tabs cursor-pointer" data-test="json-view"
        >JSON</mat-button-toggle
      >
    </mat-button-toggle-group>
    <mat-icon class="cursor-pointer ml-3" (click)="close()">close</mat-icon>
  </div>
</div>
