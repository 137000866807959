import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TypePropertyFormComponent } from 'src/app/common/types/forms/type-property-form/type-property-form.component';
import { Entity } from '../entity';

@Component({
  selector: 'app-entitity-properties',
  templateUrl: './entitity-properties.component.html',
  styleUrls: ['./entitity-properties.component.scss'],
})
export class EntitityPropertiesComponent implements AfterViewInit {
  public entity: Entity;
  public values;
  @ViewChild(TypePropertyFormComponent) typePropertyForm: TypePropertyFormComponent;
  constructor(private route: ActivatedRoute) {
    this.init();
  }
  async init() {
    this.route.parent.params.subscribe(async (params) => {
      console.log('params: ', params);
      if (params.entityType) {
        const entityType = params.entityType;
        const id = params.id;

        this.entity = new Entity(entityType, id, []);
        await this.entity.load();
        const rawEntity = this.entity.getRaw();
        const atts = rawEntity.atts || {};
        this.values = { ...rawEntity, ...atts };
      }
    });
  }
  ngAfterViewInit() {
    this.typePropertyForm.formChanges.subscribe((change) => {
      this.entity.setValue(change.propertySlug, change.value);
      this.entity.update();
    });
  }
}
