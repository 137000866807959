import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { AppExtensionsActions } from '.';
import { AppExtensionService } from '../app-extension.service';

@Injectable()
export class AppExtensionsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private appExtensionService: AppExtensionService,
  ) {}

  loadAppExtensions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppExtensionsActions.AppExtensionsActionTypes.LOAD_APP_EXTENSIONS),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.appExtensionService.getExtensions()).pipe(
          map((data) => AppExtensionsActions.loadAppExtensionsSuccess({ data })),
          catchError((error) => observableOf(AppExtensionsActions.loadAppExtensionsFailure({ error }))),
        );
      }),
    ),
  );
}
