<div class="content">
  <h6 class="py-2 border-b px-8">Type Policies</h6>
  <div class="wrap">
    <div class="option-set-list w-full px-8 py-3 overflow-auto square-scroll">
      <div class="header">
        <app-search-bar [lgGray]="true" [placeholder]="'Search'" class="mr-4"></app-search-bar>
        <app-menu name="" icon="add">
          <app-type-policy-form (cancelEdit)="cancelAdd()" (done)="completeAdd($event)"></app-type-policy-form>
        </app-menu>
      </div>
      <div class="flex justify-between border">
        <app-type-policy-list
          class="w-full"
          [policies]="policies$ | async"
          (clicked)="select($event)"
          (deleted)="delete($event)"
          (edit)="edit($event)"
        >
        </app-type-policy-list>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
