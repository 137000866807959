import { Action, createReducer, on } from '@ngrx/store';
import * as CommentsActions from './loading-indicator.actions';
import { initialState, State } from './loading-indicator.state';

const setLoading = (state: State, { loading, message = 'Loading' }) => ({
  ...state,
  loading,
  message,
});
const setMessage = (state: State, { message }) => ({
  ...state,
  message,
});

export const loadingIndicatorReducer = createReducer(
  initialState,
  on(CommentsActions.setLoading, setLoading),
  on(CommentsActions.setMessage, setMessage),
);

/**
 *
 * @param state
 * @param action
 */
export function reducer(state: State | undefined, action: Action): any {
  return loadingIndicatorReducer(state, action);
}
