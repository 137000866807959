import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerActions, TypeManagerSelectors } from '../type-manager-store';
import { TypePolicy } from '../type-manager-store/type-policies/type-policies.state';

@Injectable({
  providedIn: 'root',
})
export class TypePolicyResolver implements Resolve<TypePolicy> {
  constructor(private store: Store<RootStoreState.State>) {}
  resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<TypePolicy> {
    const typePolicyId = route.params.typePolicyId;
    this.setCurrentTypePolicy(typePolicyId);
    return this.store.select(TypeManagerSelectors.currentTypePolicy).pipe(
      filter((policy) => policy && policy.id === typePolicyId),
      take(1),
    );
  }
  setCurrentTypePolicy(id) {
    this.store
      .select(TypeManagerSelectors.typePolicies)
      .pipe(
        filter((policies) => policies && policies.length > 0),
        map((policies) => policies.find((policy) => policy.id === id)),
        tap((policy) => {
          this.store.dispatch(TypeManagerActions.setCurrentTypePolicy({ currentTypePolicy: policy }));
        }),
        take(1),
      )
      .subscribe();
  }
}
