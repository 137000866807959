import { Action, createReducer, on } from '@ngrx/store';
import * as ChartActions from './event-dashboard.actions';
import { ChartsState, initialState } from './event-dashboard.state';

const setTriggeredChartData = (state: ChartsState, { data }) => {
  return {
    ...state,
    triggeredChartData: data,
    triggeredChartLoading: false,
    error: null,
  };
};

const setFailedChartData = (state: ChartsState, { data }) => {
  return {
    ...state,
    failedChartData: data,
    failedChartLoading: false,
    error: null,
  };
};

const startTriggeredChartLoading = (state: ChartsState) => ({
  ...state,
  triggeredChartLoading: true,
  hasError: false,
});

const startFailedChartLoading = (state: ChartsState) => ({
  ...state,
  failedChartLoading: true,
  hasError: false,
});

export const chartReducer = createReducer(
  initialState,
  on(ChartActions.loadTriggeredChartDataSuccess, setTriggeredChartData),
  on(ChartActions.loadFailedChartDataSuccess, setFailedChartData),
  on(ChartActions.loadTriggeredChartData, startTriggeredChartLoading),
  on(ChartActions.loadFailedChartData, startFailedChartLoading),
);

export function reducer(state: ChartsState | undefined, action: Action): ChartsState {
  return chartReducer(state, action);
}
