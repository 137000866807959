<app-page-section [title]="'Content'">
  <div
    draggable="true"
    [id]="contentEntity.id"
    (dragstart)="startDrag($event)"
    class="content-item"
    *ngFor="let contentEntity of content"
  >
    <img [src]="contentEntity?.primaryFile?.downloadUrl" />
    <!-- <img [src]="contentEntity?.mediumViewable?.downloadUrl">
    <img [src]="contentEntity?.smallViewable?.downloadUrl">
    <img [src]="contentEntity?.tinyViewable?.downloadUrl"> -->
  </div>
  <div *ngIf="!content?.length" class="empty-state">No content available. Drop file to upload.</div>
</app-page-section>
