<mat-menu #childMenu="matMenu" [overlapTrigger]="false">
  <span *ngFor="let typeMenuItem of typeMenu.children">
    <!-- Handle branch node menu items -->
    <ng-container *ngIf="typeMenuItem.children && typeMenuItem.children.length > 0">
      <button
        (click)="goToType(typeMenuItem)"
        mat-menu-item
        color="primary"
        [matMenuTriggerFor]="menu.childMenu"
        #menuTrigger="matMenuTrigger"
        (mouseenter)="menuTrigger.openMenu()"
      >
        <span>{{ typeMenuItem.label }}</span>
      </button>
      <app-type-selector-menu #menu [typeMenu]="typeMenuItem"></app-type-selector-menu>
    </ng-container>
    <!-- Handle leaf node menu items -->
    <ng-container *ngIf="!typeMenuItem.children || typeMenuItem.children.length === 0">
      <button mat-menu-item (click)="goToType(typeMenuItem)" id="btn-type-second">
        {{ typeMenuItem.label }}
      </button>
    </ng-container>
  </span>
</mat-menu>
