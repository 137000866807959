import { Component, Input, OnChanges } from '@angular/core';
import { Types } from '@contrail/sdk';
import { PropertyType, TypeProperty, Type } from '@contrail/types';

@Component({
  selector: 'app-type-property-object-reference',
  templateUrl: './type-property-object-reference.component.html',
  styleUrls: ['./type-property-object-reference.component.scss'],
})
export class TypePropertyObjectReferenceComponent implements OnChanges {
  @Input() typeProperty: TypeProperty;
  public isObjectReferenceProperty = false;
  public referencedType: Type;
  public referencedTypeRoot: Type;
  constructor() {}

  async ngOnChanges() {
    this.isObjectReferenceProperty = false;
    if (this.typeProperty) {
      this.isObjectReferenceProperty = [PropertyType.ObjectReference, PropertyType.TypeReference].includes(
        this.typeProperty?.propertyType,
      );
      if (this.isObjectReferenceProperty && this.typeProperty.referencedTypePath) {
        this.referencedTypeRoot = await new Types().getType({ path: this.typeProperty.referencedTypeRootSlug });
        this.referencedType = await new Types().getType({ path: this.typeProperty.referencedTypePath });
      }
    }
  }
}
