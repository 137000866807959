import { createSelector } from '@ngrx/store';
import { EventWorkflowTemplate, TemplateActionLink } from './event-template';
import { eventWorkflowTemplatesEntityAdapter } from './event-templates.state';

export const { selectAll, selectEntities, selectIds, selectTotal } = eventWorkflowTemplatesEntityAdapter.getSelectors();

const eventWorkflowTemplateEntities = (state: any) => state.eventWorkflowTemplates.eventWorkflowTemplates.entities;
const triggerKeyEventWorkflowTemplateEntities = (state: any) =>
  state.eventWorkflowTemplates.triggerKeyEventWorkflowTemplates.entities;
const templateActionLinkEntities = (state: any) => state.eventWorkflowTemplates.templateActionLinks.entities;
export const eventWorkflowTemplates = createSelector(
  eventWorkflowTemplateEntities,
  (state) => Object.values(state) as Array<EventWorkflowTemplate>,
);
export const triggerKeyEventWorkflowTemplates = createSelector(
  triggerKeyEventWorkflowTemplateEntities,
  (state) => Object.values(state) as Array<EventWorkflowTemplate>,
);
export const templateActionLinks = createSelector(
  templateActionLinkEntities,
  (state) => Object.values(state) as Array<TemplateActionLink>,
);
export const currentEventWorkflowTemplate = (state: any) => state.eventWorkflowTemplates.currentEventWorkflowTemplate;
export const getLoadingState = (state: any) => state.eventWorkflowTemplates.loading;
