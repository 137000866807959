import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appLimitFractionDigits]',
})
export class LimitFractionDigitsDirective {
  @Input() maximumFractionDigits: number;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: KeyboardEvent) {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const inputValue: string = inputElement.value;

    if (inputValue.includes('.') && typeof this.maximumFractionDigits === 'number') {
      const decimalPart = inputValue.split('.')[1];
      if (decimalPart && decimalPart.length > this.maximumFractionDigits) {
        const truncatedDecimalPart = decimalPart.slice(0, this.maximumFractionDigits);
        if (this.maximumFractionDigits === 0) {
          inputElement.type = 'text';
        }

        inputElement.value = inputValue.split('.')[0] + '.' + truncatedDecimalPart;
        inputElement.dispatchEvent(new Event('input'));
      }
    }
  }
}
