import { Injectable } from '@angular/core';
import { of as observableOf, from, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { RootStoreState } from 'src/app/root-store';
import { EventWorkflowProcessesActions } from '.';
import { EventWorkflowProcessesService } from './event-processes.service';
import { EventWorkflowProcess } from './event-process';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from './event-processes.state';

@Injectable()
export class EventWorkflowProcessesEffects {
  constructor(
    private actions$: Actions,
    private eventWorkflowProcessesService: EventWorkflowProcessesService,
    private store: Store<RootStoreState.State>,
  ) {}

  loadCurrentEventWorkflowProcesses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventWorkflowProcessesActions.EventWorkflowProcessesActionTypes.LOAD_CURRENT_EVENTWORKFLOWPROCESS),
      switchMap((action: any) => {
        return from(this.eventWorkflowProcessesService.getEventWorkflowProcessesById(action.id)).pipe(
          map((data) =>
            EventWorkflowProcessesActions.loadCurrentEventWorkflowProcessSuccess({ eventWorkflowProcess: data }),
          ),
          catchError((error) =>
            observableOf(EventWorkflowProcessesActions.loadCurrentEventWorkflowProcessFailure({ error })),
          ),
        );
      }),
    ),
  );

  loadEventWorkflowProcessesByTemplateIdAndDateRange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        EventWorkflowProcessesActions.EventWorkflowProcessesActionTypes
          .LOAD_TEMPLATEID_AND_DATERANGE_EVENTWORKFLOWPROCESSES,
      ),
      switchMap((action: any) =>
        from(
          this.eventWorkflowProcessesService.getEventWorkflowProcessesByTemplateIdForDateRange(
            action.templateId,
            action.startDate,
            action.endDate,
            action.sortOrder,
            action.status,
          ),
        ).pipe(
          map((data) =>
            EventWorkflowProcessesActions.cacheEventWorkflowProcesses({
              processes: data.results as EventWorkflowProcess[],
              nextPageKey: data.nextPageKey,
              pageNum: DEFAULT_PAGE,
              pageSize: DEFAULT_PAGE_SIZE,
            }),
          ),
          catchError((error) =>
            observableOf(
              EventWorkflowProcessesActions.loadTemplateIdAndDateRangeEventWorkflowProcessesFailure({ error }),
            ),
          ),
        ),
      ),
    ),
  );

  loadEventWorkflowProcessesByPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventWorkflowProcessesActions.EventWorkflowProcessesActionTypes.LOAD_CURRENT_PAGE_EVENTWORKFLOWPROCESSES),
      withLatestFrom(this.store.pipe(select((state) => state.eventWorkflowProcesses))),
      switchMap(([action, eventWorkflowProcesses]) => {
        const pageNum = eventWorkflowProcesses.currentPage;
        const pageSize = eventWorkflowProcesses.currentPageSize;
        const cachedRecordLength = eventWorkflowProcesses.cachedProcesses.ids.length;
        const nextPageKey = eventWorkflowProcesses.nextPageKey;
        const lastPageNum = pageNum - 1;
        const neededNumberOfRecords = lastPageNum * pageSize;
        const areRecordsCached = cachedRecordLength > neededNumberOfRecords;

        // checking if the cached records are enough to display the current page
        if (areRecordsCached) {
          const cachedRecords = Object.values(eventWorkflowProcesses.cachedProcesses.entities);
          const currectProcesses = cachedRecords.slice(lastPageNum * pageSize, pageNum * pageSize);
          return of(
            EventWorkflowProcessesActions.cacheEventWorkflowProcesses({
              processes: currectProcesses as EventWorkflowProcess[],
              nextPageKey,
              pageNum,
              pageSize,
            }),
          );
        } else {
          // if the cached records are not enough, fetch the next page
          return from(
            this.eventWorkflowProcessesService.getEventWorkflowProcessesByTemplateIdForDateRange(
              (action as any)?.templateId,
              (action as any)?.startDate,
              (action as any)?.endDate,
              (action as any)?.sortOrder,
              (action as any)?.status,
              pageSize,
              nextPageKey,
            ),
          ).pipe(
            map((data) =>
              EventWorkflowProcessesActions.cacheEventWorkflowProcesses({
                processes: data.results as EventWorkflowProcess[],
                nextPageKey: data.nextPageKey,
                pageNum,
                pageSize,
              }),
            ),
            catchError((error) => {
              console.log('error', error);
              return of(EventWorkflowProcessesActions.loadCurrentPageEventWorkflowProcessesFailure({ error }));
            }),
          );
        }
      }),
    ),
  );

  loadEventWorkflowProcessesByPageSize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        EventWorkflowProcessesActions.EventWorkflowProcessesActionTypes.LOAD_CURRENT_PAGE_SIZE_EVENTWORKFLOWPROCESSES,
      ),
      switchMap((action: any) => {
        return from(
          this.eventWorkflowProcessesService.getEventWorkflowProcessesByTemplateIdForDateRange(
            action.templateId,
            action.startDate,
            action.endDate,
            action.sortOrder,
            action.status,
            action.pageSize,
          ),
        ).pipe(
          map((data) =>
            EventWorkflowProcessesActions.cacheEventWorkflowProcesses({
              processes: data.results as EventWorkflowProcess[],
              nextPageKey: data.nextPageKey,
              pageNum: DEFAULT_PAGE,
              pageSize: action.pageSize,
            }),
          ),
          catchError((error) => {
            console.log('error', error);
            return of(EventWorkflowProcessesActions.loadCurrentPageSizeEventWorkflowProcessesFailure({ error }));
          }),
        );
      }),
    ),
  );
}
