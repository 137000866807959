import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';

@Injectable({
  providedIn: 'root',
})
export class TypePropertyUserListsService {
  constructor() {}

  public async getUserLists() {
    let sets = await new Entities().get({ entityName: 'user-list' });
    sets = sets.sort((s1, s2) => (s1.name > s2.name ? 1 : -1));
    return sets;
  }
}
