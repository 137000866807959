<div class="pagination">
  <mat-paginator
    #ItemPagination
    [length]="data?.length"
    [pageSize]="currentPageSize"
    [pageSizeOptions]="pageSizeOptions"
    [pageIndex]="pageIndex"
    (page)="handlePageChangeEvent($event)"
    [showFirstLastButtons]="showFirstLastButtons"
    [hidePageSize]="hidePageSize"
  >
  </mat-paginator>
</div>
