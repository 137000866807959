import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { AppOrg } from './org-app';

@Injectable({
  providedIn: 'root',
})
export class OrgAppsService {
  constructor() {}

  public async getOrgApps() {
    return new Entities().get({ entityName: 'app-org', relations: ['app', 'appApiKey'] });
  }
  public async getOrgAppById(id: string) {
    return new Entities().get({ entityName: 'app-org', relations: ['app', 'appApiKey'], id });
  }
  public async createOrgApp(appId: string) {
    return new Entities().create({ entityName: 'app-org', object: { appId } });
  }
  public async deleteOrgApp(orgApp: AppOrg) {
    await new Entities().delete({ entityName: 'app-org', id: orgApp?.id });
    return orgApp;
  }
  // TODO: payload object
  public async updateOrgApp(id: string, changes: AppOrg) {
    return new Entities().update({ entityName: 'app-org', id, object: changes });
  }
}
