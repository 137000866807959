import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PageHeaderService, PageHeaderConfig } from './page-header.service';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {
  pageHeaderConfig: Observable<PageHeaderConfig>;
  constructor(private pageHeaderService: PageHeaderService) {}

  ngOnInit(): void {
    this.pageHeaderConfig = this.pageHeaderService.pageHeaderConfig;
  }
}
