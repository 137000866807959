import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { typeManagerReducer } from './type-manager.reducer';
import { TypeManagerEffects } from './type-manager.effects';
import { TypeRootsEffects } from './type-roots/type-roots.effects';
import { TypesEffects } from './types/types.effects';
import { TypePropertiesEffects } from './type-properties/type-properties.effects';
import { TypePropertyOptionSetsEffects } from './option-sets/option-sets.effects';
import { TypePropertyUserListsEffects } from './user-lists/user-lists.effects';
import { TypePropertyPoliciesEffects } from './type-property-policies/type-property-policies.effects';
import { TypeRuleSetsEffects } from './type-rule-sets/type-rule-sets.effects';
import { OptionSetHierarchiesEffects } from './option-set-hierarchies/option-set-hierarchies.effects';
import { TypePoliciesEffects } from './type-policies/type-policies.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('typeManager', typeManagerReducer),
    EffectsModule.forFeature([
      TypeManagerEffects,
      TypeRootsEffects,
      TypesEffects,
      TypePropertiesEffects,
      TypePropertyOptionSetsEffects,
      TypePropertyUserListsEffects,
      TypePropertyPoliciesEffects,
      TypePoliciesEffects,
      TypeRuleSetsEffects,
      OptionSetHierarchiesEffects,
    ]),
  ],
})
export class TypeManagerStoreModule {
  constructor() {}
}
