<div *ngIf="typePolicy" class="px-8 py-3 overflow-auto h-full">
  <app-type-policy-editor-header [typePolicy]="typePolicy$ | async" [activeView]="activeConfigView">
  </app-type-policy-editor-header>

  <div class="config-view-container" data-test="config-view">
    <ng-container *ngIf="this.activeConfigView === view.JSON">
      <ngx-monaco-editor
        class="border p-4 block w-full mb-2 json-editor"
        [formControl]="policyControl"
        [options]="editorOptions"
        data-test="json-editor"
        #editor
      >
      </ngx-monaco-editor>
    </ng-container>
    <ng-container *ngIf="this.activeConfigView === view.UI">
      <div class="policy-ui-editor" data-test="config-ui-page">
        <app-policy-detail
          *ngFor="let statement of statements"
          [policyStatement]="statement"
          [principals]="principals$ | async"
          (onDelete)="deletePolicyStatement($event)"
          data-test="policy-detail"
        >
        </app-policy-detail>
      </div>
    </ng-container>
    <div class="button container">
      <button
        mat-raised-button
        color="primary"
        [disabled]="!policyControl?.valid || updating"
        class="mt-2"
        (click)="save()"
        data-test="btn-save-field"
      >
        <div class="save-btn flex" data-test="save-btn">
          <mat-spinner diameter="20" class="spinner" mode="indeterminate" *ngIf="updating"></mat-spinner>
          <span>Save</span>
        </div>
      </button>

      <button
        *ngIf="this.activeConfigView === view.UI"
        mat-stroked-button
        color="primary"
        class="btn add-field mt-3 !ml-3"
        data-test="btn-add-field"
        (click)="addField()"
      >
        <mat-icon>add</mat-icon>Add Principal
      </button>
    </div>
  </div>
</div>
