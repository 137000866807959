import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface TypePropertyPolicy {
  id?: string;
  label?: string;
  identifier?: string;
  policy?: any;
  createdOn?: Date;
}

export interface TypePropertyPoliciesState extends EntityState<TypePropertyPolicy> {}
export const typePropertyPoliciesEntityAdapter: EntityAdapter<TypePropertyPolicy> =
  createEntityAdapter<TypePropertyPolicy>({});

export const typePropertyPoliciesInitialState = typePropertyPoliciesEntityAdapter.getInitialState({});
