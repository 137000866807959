import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ChartService } from './event-dashboard.service';
import { EventWorkflowDashboardActions } from '.';
import { ChartDataPayload } from './event-dashboard';

@Injectable()
export class ChartEffects {
  constructor(
    private actions$: Actions,
    private chartService: ChartService,
  ) {}

  loadTriggeredChartData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventWorkflowDashboardActions.loadTriggeredChartData),
      switchMap((action: ChartDataPayload) =>
        from(this.chartService.getTriggeredChartData(action.workflowId, action.startDate, action.endDate)).pipe(
          map((data) => EventWorkflowDashboardActions.loadTriggeredChartDataSuccess({ data })),
          catchError((error) => observableOf(EventWorkflowDashboardActions.loadTriggeredChartDataFailure({ error }))),
        ),
      ),
    ),
  );

  loadFailedChartData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventWorkflowDashboardActions.loadFailedChartData),
      switchMap((action: ChartDataPayload) =>
        from(this.chartService.getFailedChartData(action.workflowId, action.startDate, action.endDate)).pipe(
          map((data) => EventWorkflowDashboardActions.loadFailedChartDataSuccess({ data })),
          catchError((error) => observableOf(EventWorkflowDashboardActions.loadFailedChartDataFailure({ error }))),
        ),
      ),
    ),
  );
}
