<div [ngClass]="{ spinShow: (loading$ | async), spinHide: !(loading$ | async) }">
  <ng-container *ngIf="loading$ | async; else progressSpinner">
    <div class="overlay">
      <div class="center">
        <ng-template [ngTemplateOutlet]="progressSpinner"></ng-template>
      </div>
    </div>
  </ng-container>
  <ng-template #progressSpinner>
    <div class="spinner-bg loading-indicator" data-test="loading-indicator">
      <div class="message">{{ message$ | async }}</div>
      <div class="spinner-svg">
        <mat-progress-spinner diameter="50" mode="indeterminate"> </mat-progress-spinner>
      </div>
    </div>
  </ng-template>
</div>
