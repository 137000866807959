import { Type } from '@contrail/types';

export interface TypeMenuItem {
  label: string;
  id: string;
  children: Array<TypeMenuItem>;
}

/**
 *
 * @param types
 */
export function buildTypeMenu(types: Array<Type>): TypeMenuItem {
  const pathMap: Map<string, TypeMenuItem> = new Map();
  types.forEach((type) => {
    pathMap.set(type.typePath, { id: type.id, label: type.label, children: [] });
  });
  types.forEach((type) => {
    const parentPath = getParentPathFromTypePath(type.typePath);
    if (parentPath) {
      const parentTypeMenuItem = pathMap.get(parentPath);
      const thisTypeMenuItem = pathMap.get(type.typePath);
      parentTypeMenuItem.children.push(thisTypeMenuItem);
    }
  });

  types.forEach((type) => {
    const thisTypeMenuItem = pathMap.get(type.typePath);
    thisTypeMenuItem.children.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  });

  const rootPath = Array.from(pathMap.keys()).find((key) => key.indexOf(':') < 0);
  return pathMap.get(rootPath);
}

/**
 *
 * @param typePath
 */
function getParentPathFromTypePath(typePath: string) {
  if (!typePath) {
    return null;
  }
  if (typePath.indexOf(':') < 0) {
    return null;
  }
  const parentPath = typePath.substring(0, typePath.lastIndexOf(':'));
  return parentPath;
}
