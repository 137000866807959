import { Component, Input, OnInit } from '@angular/core';
import { RoutingService } from '@common/routing/routing.service';
import { TypeRuleSet } from 'src/app/type-manager/type-manager-store/type-rule-sets/type-rule-sets.state';
import { TypeService } from 'src/app/type-manager/type.service';

@Component({
  selector: 'app-type-rule-set-editor-header',
  templateUrl: './type-rule-set-editor-header.component.html',
  styleUrls: ['./type-rule-set-editor-header.component.scss'],
})
export class TypeRuleSetEditorHeaderComponent implements OnInit {
  @Input() typeRuleSet: TypeRuleSet;
  constructor(
    private routingService: RoutingService,
    private typeService: TypeService,
  ) {}

  ngOnInit(): void {
    this.typeService.getEntityUpdate().subscribe((data) => {
      if (data.id === this.typeRuleSet.id) {
        this.typeRuleSet = {
          ...this.typeRuleSet,
          ...data,
        };
      }
    });
  }

  close() {
    this.routingService.go('/type-manager/rule-sets');
  }
}
