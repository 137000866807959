import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { appVersionsReducers } from './app-versions.reducers';
import { AppVersionsEffects } from './app-versions.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('appVersions', appVersionsReducers),
    EffectsModule.forFeature([AppVersionsEffects]),
  ],
})
export class AppVersionsStoreModule {}
