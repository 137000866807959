import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { RootStoreState } from '../../root-store';
import { TypeManagerActions, TypeManagerSelectors } from '../type-manager-store';
import { TypePropertyPolicy } from '../type-manager-store/type-property-policies/type-property-policies.state';

@Injectable({
  providedIn: 'root',
})
export class TypePropertyPoliciesResolver implements Resolve<Array<TypePropertyPolicy>> {
  constructor(private store: Store<RootStoreState.State>) {}
  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<Array<TypePropertyPolicy>> {
    this.loadTypePropertyPolicies();
    return this.store.select(TypeManagerSelectors.typePropertyPolicies).pipe(take(1));
  }

  loadTypePropertyPolicies() {
    this.store.dispatch(TypeManagerActions.loadTypePropertyPolicies());
  }
}
