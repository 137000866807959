import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { featureKey } from '../store/system-notification.selectors';
import { systemNotificationReducers } from '../store/system-notification.reducers';
import { SystemNotificationEffects } from '../store/system-notification.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(featureKey, systemNotificationReducers),
    EffectsModule.forFeature([SystemNotificationEffects]),
  ],
})
export class SystemNotificationStoreModule {}
