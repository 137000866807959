import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { groupsReducers } from './groups.reducers';
import { GroupsEffects } from './groups.effects';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('groups', groupsReducers), EffectsModule.forFeature([GroupsEffects])],
})
export class GroupsStoreModule {}
