import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerActions, TypeManagerSelectors } from '../type-manager-store';
import { TypePropertyPolicy } from '../type-manager-store/type-property-policies/type-property-policies.state';

@Injectable({
  providedIn: 'root',
})
export class TypePropertyPolicyResolver implements Resolve<TypePropertyPolicy> {
  constructor(private store: Store<RootStoreState.State>) {}
  resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<TypePropertyPolicy> {
    const typePropertyPolicyId = route.params.typePropertyPolicyId;
    this.setCurrentTypePropertyPolicy(typePropertyPolicyId);
    return this.store.select(TypeManagerSelectors.currentTypePropertyPolicy).pipe(
      filter((policy) => policy && policy.id === typePropertyPolicyId),
      take(1),
    );
  }
  setCurrentTypePropertyPolicy(id) {
    this.store
      .select(TypeManagerSelectors.typePropertyPolicies)
      .pipe(
        filter((policies) => policies && policies.length > 0),
        map((policies) => policies.find((policy) => policy.id === id)),
        tap((policy) => {
          this.store.dispatch(TypeManagerActions.setCurrentTypePropertyPolicy({ currentTypePropertyPolicy: policy }));
        }),
        take(1),
      )
      .subscribe();
  }
}
