import { on } from '@ngrx/store';
import { State } from '../type-manager.state';
import * as TypePoliciesAction from './type-policies.actions';
import { typePoliciesEntityAdapter } from './type-policies.state';

const setData = (state: State, { data }) => {
  console.log('setData: ', data);
  return {
    ...state,
    typePolicies: typePoliciesEntityAdapter.setAll(data, state.typePolicies),
  };
};

const add = (state: State, { typePolicy }) => ({
  ...state,
  typePolicies: typePoliciesEntityAdapter.addOne(typePolicy, state.typePolicies),
});

const update = (state: State, { id, changes }) => ({
  ...state,
  typePolicies: typePoliciesEntityAdapter.updateOne({ id, changes }, state.typePolicies),
});

const remove = (state: State, { typePolicy }) => ({
  ...state,
  typePolicies: typePoliciesEntityAdapter.removeOne(typePolicy.id, state.typePolicies),
});

export const typePoliciesReducer = [
  on(TypePoliciesAction.loadTypePoliciesSuccess, setData),
  on(TypePoliciesAction.createTypePolicySuccess, add),
  on(TypePoliciesAction.deleteTypePolicySuccess, remove),
  on(TypePoliciesAction.updateTypePolicySuccess, update),
];
