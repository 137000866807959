import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PropertyTypeMeta, TypeProperty } from '@contrail/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthSelectors } from '@common/auth/auth-store';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerActions } from '../../type-manager-store';

@Component({
  selector: 'app-type-property-header',
  templateUrl: './type-property-header.component.html',
  styleUrls: ['./type-property-header.component.scss'],
})
export class TypePropertyHeaderComponent implements OnChanges {
  @Input() typeProperty: TypeProperty;
  labelField: UntypedFormControl;
  propertyTypeLabel: string;
  authOrgId$: Observable<string>;
  constructor(private store: Store<RootStoreState.State>) {
    this.authOrgId$ = store.select(AuthSelectors.selectAuthContext).pipe(map((ctx) => ctx.currentOrg.orgId));
  }

  ngOnChanges(): void {
    this.labelField = new UntypedFormControl(this.typeProperty.label);
    this.propertyTypeLabel = PropertyTypeMeta.get(this.typeProperty.propertyType).label;
  }

  close() {
    this.store.dispatch(TypeManagerActions.setCurrentTypeProperty({ currentTypeProperty: null }));
  }
}
