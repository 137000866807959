import { createSelector } from '@ngrx/store';
import { EventWorkflowActionDefinition } from './event-action';
import { eventWorkflowActionsEntityAdapter } from './event-actions.state';

export const { selectAll, selectEntities, selectIds, selectTotal } = eventWorkflowActionsEntityAdapter.getSelectors();

const eventWorkflowActionEntities = (state: any) => state.eventWorkflowActions.eventWorkflowActions.entities;
const ownerKeyEventWorkflowActionEntities = (state: any) =>
  state.eventWorkflowActions.ownerKeyEventWorkflowActions.entities;
export const eventWorkflowActions = createSelector(
  eventWorkflowActionEntities,
  (state) => Object.values(state) as Array<EventWorkflowActionDefinition>,
);
export const ownerKeyEventWorkflowActions = createSelector(
  ownerKeyEventWorkflowActionEntities,
  (state) => Object.values(state) as Array<EventWorkflowActionDefinition>,
);
export const currentEventWorkflowAction = (state: any) => state.eventWorkflowActions.currentEventWorkflowAction;
