import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PropertyType, TypeProperty } from '@contrail/types';

@Component({
  selector: 'app-type-property-formula',
  templateUrl: './type-property-formula.component.html',
  styleUrls: ['./type-property-formula.component.scss'],
})
export class TypePropertyFormulaComponent implements OnChanges {
  @Input() typeProperty: TypeProperty;
  public isFormulaProperty: boolean;
  public formulaFunctionControl: UntypedFormControl = new UntypedFormControl();
  @Output() changes = new EventEmitter();
  public editorOptions: any = {
    theme: 'vs',
    language: 'javascript',
    minimap: {
      enabled: false,
    },
    lineNumbers: 'off',
    automaticLayout: true,
  };
  constructor() {}

  ngOnChanges(): void {
    if (!this.typeProperty) {
      return;
    }
    this.isFormulaProperty = [
      PropertyType.Formula,
      PropertyType.String,
      PropertyType.Text,
      PropertyType.SingleSelect,
      PropertyType.Number,
      PropertyType.Currency,
      PropertyType.Percent,
      PropertyType.MultiSelect,
      PropertyType.Boolean,
      PropertyType.Date,
      PropertyType.UserList,
      PropertyType.SizeRange,
      PropertyType.ObjectReference,
      PropertyType.TypeReference,
    ].includes(this.typeProperty.propertyType);

    this.formulaFunctionControl.setValue(this.typeProperty.formulaFunction);
  }

  saveFormulaFunction() {
    if (!this.formulaFunctionControl.valid) {
      return;
    }
    const newValue = this.formulaFunctionControl.value;
    const valueChanges = {
      formulaFunction: newValue,
    };
    this.changes.emit(valueChanges);
  }
}
