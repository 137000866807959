import { createSelector } from '@ngrx/store';
import { TypePolicy, typePoliciesEntityAdapter } from './type-policies.state';

export const { selectAll, selectEntities, selectIds, selectTotal } = typePoliciesEntityAdapter.getSelectors();

const typePoliciesEntities = (state: any) => state.typeManager.typePolicies.entities;
export const typePolicies = createSelector(typePoliciesEntities, (state) => Object.values(state) as Array<TypePolicy>);

export const selectTypePolicyById = (id: string) =>
  createSelector(typePoliciesEntities, (state) => selectEntities(state)[id]);
