import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MonacoEditorModule } from '@materia-ui/ngx-monaco-editor';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { ComponentsModule } from '@components/components.module';
import { DirectivesModule } from '@directives/directives.module';
import { SizeRangeEditorModule } from '@components/size-range-editor/size-range-editor.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { TypePropertyFormFieldComponent } from './forms/type-property-form-field/type-property-form-field.component';
import { TypePropertyFormFieldTextComponent } from './forms/type-property-form-field/property-types/type-property-form-field-text.component';
import { TypePropertyFormFieldSelectComponent } from './forms/type-property-form-field/property-types/type-property-form-field-select.component';
import { TypePropertyFormFieldDateComponent } from './forms/type-property-form-field/property-types/type-property-form-field-date.component';
import { TypePropertyFormFieldFormulaComponent } from './forms/type-property-form-field/property-types/type-property-form-field-formula.component';
import { TypePropertyFormComponent } from './forms/type-property-form/type-property-form.component';
import { TypePropertyFormFieldMultiSelectComponent } from './forms/type-property-form-field/property-types/type-property-form-field-multi-select.component';
import { TypePropertyIconComponent } from './type-property-icon/type-property-icon.component';
import { TypePropertyFormFieldNumberComponent } from './forms/type-property-form-field/property-types/type-property-form-field-number.component';
import { TypePropertyFormFieldBooleanComponent } from './forms/type-property-form-field/property-types/type-property-form-field-boolean.component';
import { TypePropertyFormFieldPercentComponent } from './forms/type-property-form-field/property-types/type-property-form-field-percent.component';
import { TypePropertyFormFieldObjectReferenceComponent } from './forms/type-property-form-field/property-types/type-property-form-field-object-reference.component';
import { TypePropertyFormFieldUserListComponent } from './forms/type-property-form-field/property-types/type-property-form-field-user-list.component';
import { TypePropertyFormValidationErrorComponent } from './forms/type-property-form-validation-error/type-property-form-validation-error.component';
import { TypePropertyFormFieldTextAreaComponent } from './forms/type-property-form-field/property-types/type-property-form-field-text-area.component';
import { PropertyValueComponent } from './property-value/property-value.component';

@NgModule({
  declarations: [
    PropertyValueComponent,
    TypePropertyFormFieldComponent,
    TypePropertyFormFieldTextComponent,
    TypePropertyFormFieldNumberComponent,
    TypePropertyFormFieldBooleanComponent,
    TypePropertyFormFieldDateComponent,
    TypePropertyFormFieldFormulaComponent,
    TypePropertyFormFieldSelectComponent,
    TypePropertyFormFieldMultiSelectComponent,
    TypePropertyFormComponent,
    TypePropertyIconComponent,
    TypePropertyFormFieldPercentComponent,
    TypePropertyFormFieldObjectReferenceComponent,
    TypePropertyFormFieldUserListComponent,
    TypePropertyFormValidationErrorComponent,
    TypePropertyFormFieldTextAreaComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatIconModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MonacoEditorModule,
    MatChipsModule,
    MatButtonModule,
    ComponentsModule,
    DirectivesModule,
    SizeRangeEditorModule,
  ],
  exports: [
    PropertyValueComponent,
    TypePropertyFormFieldComponent,
    TypePropertyFormComponent,
    TypePropertyFormFieldTextComponent,
    TypePropertyFormFieldNumberComponent,
    TypePropertyFormFieldPercentComponent,
    TypePropertyFormFieldBooleanComponent,
    TypePropertyFormFieldDateComponent,
    TypePropertyFormFieldFormulaComponent,
    TypePropertyFormFieldSelectComponent,
    TypePropertyFormFieldMultiSelectComponent,
    TypePropertyFormFieldObjectReferenceComponent,
    TypePropertyFormValidationErrorComponent,
    TypePropertyFormFieldTextAreaComponent,
    TypePropertyFormFieldUserListComponent,
    TypePropertyIconComponent,
  ],
})
export class TypesModule {}
