import { Component, Input, OnInit } from '@angular/core';
import { TypePolicy } from '@contrail/types';
import { RoutingService } from '@common/routing/routing.service';
import { CONFIG_VIEW } from 'src/app/type-manager/types-helper';
import { TypeService } from '../../../type.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { selectTypePolicyById } from 'src/app/type-manager/type-manager-store/type-policies/type-policies.selectors';

@Component({
  selector: 'app-type-policy-editor-header',
  templateUrl: './type-policy-editor-header.component.html',
  styleUrls: ['./type-policy-editor-header.component.scss'],
})
export class TypePolicyEditorHeaderComponent implements OnInit {
  @Input() typePolicy: TypePolicy;
  @Input() public activeView: CONFIG_VIEW = CONFIG_VIEW.UI;

  private typePolicy$: Observable<any>;
  public view = CONFIG_VIEW;

  constructor(
    private routingService: RoutingService,
    private typeService: TypeService,
    private store: Store<RootStoreState.State>,
  ) {}

  ngOnInit(): void {
    this.typeService.getEntityUpdate().subscribe((data) => {
      if (data.id === this.typePolicy.id) {
        this.typePolicy = {
          ...this.typePolicy,
          ...data,
        };
      }
    });
  }

  close() {
    this.typeService.toggleDetailView(false);
    this.routingService.go('type-manager/type-policies');
    this.typePolicy$ = this.store.select(selectTypePolicyById(this.typePolicy.id));

    this.typePolicy$.subscribe((typePolicy) => {
      this.typePolicy = typePolicy;
    });
  }

  selectView(event: MatButtonToggleChange) {
    this.activeView = event.value;
    this.typeService.toggleConfigVisibility(event.value);
  }
}
