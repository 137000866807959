import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';

@Injectable({
  providedIn: 'root',
})
export class FileLoaderService {
  constructor() {}

  public async getLoaderConfigurations(): Promise<any> {
    return await new Entities().get({ entityName: 'loader-configuration' });
  }

  public async addLoaderConfiguration(data): Promise<any> {
    return await new Entities().create({ entityName: 'loader-configuration', object: data });
  }

  public async deleteLoaderConfiguration(data): Promise<any> {
    const id = data?.id;
    return await new Entities().delete({ entityName: 'loader-configuration', id });
  }

  public async updateLoaderConfiguration(id, data): Promise<any> {
    return await new Entities().update({ entityName: 'loader-configuration', id, object: data });
  }
}
