import { on } from '@ngrx/store';
import { State } from '../type-manager.state';
import * as TypesActions from './types.actions';
import { typesEntityAdapter } from './types.state';

const setTypes = (state: State, { data }) => ({
  ...state,
  types: typesEntityAdapter.setAll(data, state.types),
});
const clearData = (state: State, {}) => ({
  ...state,
  types: typesEntityAdapter.removeAll(state.types),
});
const addType = (state: State, { entity }) => ({
  ...state,
  types: typesEntityAdapter.addOne(entity, state.types),
});

export const typesReducers = [
  on(TypesActions.loadTypesSuccess, setTypes),
  on(TypesActions.createSubTypeSuccess, addType),
  on(TypesActions.clearTypes, clearData),
];
