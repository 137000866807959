import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { RootStoreState } from '@rootstore';
import { TypeManagerActions, TypeManagerSelectors } from '../../type-manager-store';
import { TypeRuleSet } from '../../type-manager-store/type-rule-sets/type-rule-sets.state';

@Component({
  selector: 'app-type-rule-set-editor',
  templateUrl: './type-rule-set-editor.component.html',
  styleUrls: ['./type-rule-set-editor.component.scss'],
})
export class TypeRuleSetEditorComponent implements OnDestroy {
  public ruleSetControl: UntypedFormControl = new UntypedFormControl();
  private ruleSetSub: Subscription;
  @Output() changes = new EventEmitter();
  public editorOptions: any = {
    theme: 'vs',
    language: 'json',
    minimap: {
      enabled: false,
    },
    lineNumbers: 'off',
    automaticLayout: true,
  };
  typeRuleSet$: Observable<TypeRuleSet>;
  typeRuleSet: TypeRuleSet;
  constructor(private store: Store<RootStoreState.State>) {
    this.typeRuleSet$ = this.store.select(TypeManagerSelectors.currentTypeRuleSet);
    this.ruleSetSub = this.typeRuleSet$.subscribe((ruleSet) => {
      this.typeRuleSet = ruleSet;
      this.ruleSetControl.setValue(JSON.stringify(this.typeRuleSet.ruleSet, null, 4));
    });
  }

  ngOnDestroy() {
    this.ruleSetSub.unsubscribe();
  }

  save() {
    const ruleSetValue = JSON.parse(this.ruleSetControl.value);
    this.store.dispatch(
      TypeManagerActions.updateTypeRuleSet({ id: this.typeRuleSet.id, changes: { ruleSet: ruleSetValue } }),
    );
  }
}
