import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Input() dialogRef: MatDialogRef<any>;
  @Input() header: string;
  @Input() showHeaderSeparator = false;
  @Input() showHide = true;
  @Input() closeData: any = null;

  constructor() {}
}
