import { Component, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import { TypePropertyOptionSet } from '../../type-manager-store/option-sets/option-sets.state';

@Component({
  selector: 'app-type-property-option-set-list',
  templateUrl: './type-property-option-set-list.component.html',
  styleUrls: ['./type-property-option-set-list.component.scss'],
})
export class TypePropertyOptionSetListComponent implements OnDestroy {
  @Input() optionSets: Array<TypePropertyOptionSet>;
  @Input() selectable: boolean;
  @Input() showActions = true;
  @Output() clicked: EventEmitter<TypePropertyOptionSet> = new EventEmitter();
  @Output() deleted: EventEmitter<TypePropertyOptionSet> = new EventEmitter();
  @Output() edit: EventEmitter<TypePropertyOptionSet> = new EventEmitter();
  constructor() {}

  ngOnDestroy() {
    this.unsubscribe();
  }
  subscribe() {}
  unsubscribe() {}

  handleClick(property: TypePropertyOptionSet) {
    this.clicked.emit(property);
  }
  handleDelete($event: MouseEvent, propertyOptionSet: TypePropertyOptionSet) {
    $event.stopPropagation();
    this.deleted.emit(propertyOptionSet);
  }
  handleEdit($event: MouseEvent, propertyOptionSet: TypePropertyOptionSet) {
    $event.stopPropagation();
    this.edit.emit(propertyOptionSet);
  }
}
