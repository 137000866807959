import { Component, EventEmitter, Input, Output, OnDestroy, OnInit } from '@angular/core';
import { TypePropertyPolicy } from '../../type-manager-store/type-property-policies/type-property-policies.state';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { TypeManagerActions, TypeManagerSelectors } from '../../type-manager-store';
import { Subject, takeUntil } from 'rxjs';
import { TypeService } from '../../type.service';

@Component({
  selector: 'app-type-property-policy-list',
  templateUrl: './type-property-policy-list.component.html',
  styleUrls: ['./type-property-policy-list.component.scss'],
})
export class TypePropertyPolicyListComponent implements OnInit, OnDestroy {
  @Input() policies: Array<TypePropertyPolicy>;
  @Input() showActions = true;
  @Input() isDetailViewOpen: boolean = false;
  @Output() clicked: EventEmitter<TypePropertyPolicy> = new EventEmitter();
  @Output() deleted: EventEmitter<TypePropertyPolicy> = new EventEmitter();
  @Output() edit: EventEmitter<TypePropertyPolicy> = new EventEmitter();

  public selectedTypePropertyPolicy: string;
  public isLoading: boolean = true;

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private store: Store<RootStoreState.State>,
    private typeService: TypeService,
  ) {
    this.store
      .select(TypeManagerSelectors.loading)
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoading) => {
        this.isLoading = isLoading;
      });
  }

  ngOnInit(): void {
    const url = this.router.url;
    const urlParams = url.split('/');
    const id = urlParams.pop();
    const isValidId = id !== 'type-policies';
    if (isValidId) {
      // getting current loaded config id from URL
      // this is generally the case if page is refresehed
      this.selectedTypePropertyPolicy = id;
    }

    this.typeService
      .getDetailView()
      .pipe(takeUntil(this.destroy$))
      .subscribe((open) => (this.isDetailViewOpen = open));
  }

  ngOnDestroy() {
    this.store.dispatch(TypeManagerActions.loading({ loading: true }));
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  handleClick(policy: TypePropertyPolicy) {
    this.selectedTypePropertyPolicy = policy.id;
    this.clicked.emit(policy);
  }
  handleDelete($event: MouseEvent, policy: TypePropertyPolicy) {
    $event.stopPropagation();
    this.deleted.emit(policy);
  }
  handleEdit($event: MouseEvent, policy: TypePropertyPolicy) {
    $event.stopPropagation();
    this.edit.emit(policy);
  }
}
