import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { OrgConfig } from './org-config';

@Injectable({
  providedIn: 'root',
})
export class OrgConfigService {
  constructor() {}

  public async getOrgConfigs() {
    return await new Entities().get({ entityName: 'org-config' });
  }

  public async updateOrgConfig(id: string, changes: OrgConfig) {
    return new Entities().update({ entityName: 'org-config', id, object: changes });
  }
}
