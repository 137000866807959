import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

console.log('main...');

if (environment.production || environment.name === 'DEVELOPMENT') {
  Sentry.init({
    dsn: environment.sentryDsn,
    environment: environment.name,
    ignoreErrors: [
      'Error: NG0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.', //TODO: Remove this line after we resolve these console errors
    ],
  });
}
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
