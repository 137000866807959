import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerActions, TypeManagerSelectors } from '../type-manager-store';
import { OptionSetHierarchy } from '../type-manager-store/option-set-hierarchies/option-set-hierarchies.state';

@Injectable({
  providedIn: 'root',
})
export class OptionSetHierarchyResolver implements Resolve<OptionSetHierarchy> {
  constructor(private store: Store<RootStoreState.State>) {}
  resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<OptionSetHierarchy> {
    const optionSetHierarchyId = route.params.optionSetHierarchyId;
    this.load(optionSetHierarchyId);
    return this.store.select(TypeManagerSelectors.currentOptionSetHierarchy).pipe(
      filter((optionSetHiearchy) => optionSetHiearchy && optionSetHiearchy.id === optionSetHierarchyId),
      take(1),
    );
  }
  load(id) {
    this.store
      .select(TypeManagerSelectors.optionSetHierarchies)
      .pipe(
        filter((optionSetHierarchies) => optionSetHierarchies && optionSetHierarchies.length > 0),
        map((optionSetHierarchies) => optionSetHierarchies.find((optionSetHiearchy) => optionSetHiearchy.id === id)),
        tap((optionSetHiearchy) => {
          this.store.dispatch(
            TypeManagerActions.setCurrentOptionSetHierarchy({ currentOptionSetHierarchy: optionSetHiearchy }),
          );
        }),
        take(1),
      )
      .subscribe();
  }
}
