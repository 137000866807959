import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { RootStoreState } from 'src/app/root-store';
import { EventWorkflowActionsActions } from '.';
import { EventWorkflowActionsService } from './event-actions.service';

@Injectable()
export class EventWorkflowActionsEffects {
  constructor(
    private actions$: Actions,
    private eventWorkflowActionsService: EventWorkflowActionsService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {}

  loadCurrentEventWorkflowActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventWorkflowActionsActions.EventWorkflowActionsActionTypes.LOAD_CURRENT_EVENTWORKFLOWACTION),
      switchMap((action: any) =>
        from(this.eventWorkflowActionsService.getEventWorkflowActionsById(action.id)).pipe(
          map((data) =>
            EventWorkflowActionsActions.loadCurrentEventWorkflowActionSuccess({ eventWorkflowAction: data }),
          ),
          catchError((error) =>
            observableOf(EventWorkflowActionsActions.loadCurrentEventWorkflowActionFailure({ error })),
          ),
        ),
      ),
    ),
  );
  loadEventWorkflowActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventWorkflowActionsActions.EventWorkflowActionsActionTypes.LOAD_EVENTWORKFLOWACTIONS),
      withLatestFrom(this.store),
      switchMap(([]: [any, RootStoreState.State]) =>
        from(this.eventWorkflowActionsService.getEventWorkflowActions()).pipe(
          map((data) => EventWorkflowActionsActions.loadOwnerkeyEventWorkflowActionsSuccess({ data })),
          catchError((error) =>
            observableOf(EventWorkflowActionsActions.loadOwnerkeyEventWorkflowActionsFailure({ error })),
          ),
        ),
      ),
    ),
  );
  createEventWorkflowAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventWorkflowActionsActions.EventWorkflowActionsActionTypes.CREATE_EVENTWORKFLOWACTION),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) => {
        const body = {
          name: action.name,
          ownerKey: action.ownerKey,
          publicStatus: action.publicStatus,
          executeFunctionString: action.executeFunctionString,
        };
        return from(this.eventWorkflowActionsService.createEventWorkflowAction(body)).pipe(
          map((data) => {
            this.snackBar.open('EventWorkflowAction added.', '', { duration: 2000 });
            return EventWorkflowActionsActions.createEventWorkflowActionSuccess({ eventWorkflowAction: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(EventWorkflowActionsActions.createEventWorkflowActionFailure({ error }));
          }),
        );
      }),
    ),
  );
  deleteEventWorkflowAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventWorkflowActionsActions.EventWorkflowActionsActionTypes.DELETE_EVENTWORKFLOWACTION),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.eventWorkflowActionsService.deleteEventWorkflowAction(action.eventWorkflowAction)).pipe(
          map((data) => {
            this.snackBar.open('EventWorkflowAction removed.', '', { duration: 2000 });
            return EventWorkflowActionsActions.deleteEventWorkflowActionSuccess({ eventWorkflowAction: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(EventWorkflowActionsActions.deleteEventWorkflowActionFailure({ error }));
          }),
        ),
      ),
    ),
  );
  updateEventWorkflowAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventWorkflowActionsActions.EventWorkflowActionsActionTypes.UPDATE_EVENTWORKFLOWACTION),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.eventWorkflowActionsService.updateEventWorkflowAction(action.id, action.changes)).pipe(
          map((data) => {
            this.snackBar.open('EventWorkflowAction updated.', '', { duration: 2000 });
            return EventWorkflowActionsActions.updateEventWorkflowActionSuccess({ id: data.id, changes: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(EventWorkflowActionsActions.updateEventWorkflowActionFailure({ error }));
          }),
        ),
      ),
    ),
  );
}
