<div *ngIf="type$ | async">
  <app-page-section>
    <app-page-section-header>
      <app-page-section-title title="Details"></app-page-section-title>
      <app-page-section-actions> </app-page-section-actions>
    </app-page-section-header>
    <app-page-section-body>
      <table>
        <tr>
          <td style="width: 100px">Name:</td>
          <td>
            <span data-test="type-name">{{ (type$ | async).label }}</span>
          </td>
        </tr>
        <tr>
          <td>Id:</td>
          <td>
            <span class="code">{{ (type$ | async).id }}</span>
          </td>
        </tr>
        <tr>
          <td>Path:</td>
          <td>
            <span class="code">{{ (type$ | async).typePath }}</span>
          </td>
        </tr>
      </table>
    </app-page-section-body>
  </app-page-section>
  <app-page-section [title]="'Interfaces'">
    <app-page-section-body>
      <mat-chip-list>
        <mat-chip color="primary" selected="true" *ngFor="let interface of (type$ | async)?.typeInterfaces">
          {{ interface.label }}
        </mat-chip>
      </mat-chip-list>
    </app-page-section-body>
  </app-page-section>

  <app-type-type-properties></app-type-type-properties>
  <app-type-type-policies [type]="type$ | async"></app-type-type-policies>
  <app-type-type-rules [type]="type$ | async"></app-type-type-rules>
  <app-type-option-set-hierarchies [type]="type$ | async"></app-type-option-set-hierarchies>
  <app-type-relationships></app-type-relationships>
</div>
