import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-section-header',
  templateUrl: './page-section-header.component.html',
  styleUrls: ['./page-section-header.component.scss'],
})
export class PageSectionHeaderComponent {
  @Input() title: string;
  constructor() {}
}
