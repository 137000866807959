import { Component, Input } from '@angular/core';
import markdownIt from 'markdown-it';
import * as markdownItMultimdTable from 'markdown-it-multimd-table';
import * as syntaxHiglighting from 'markdown-it-highlightjs';
import * as mdKatex from 'markdown-it-katex';
import * as katex from 'katex';
import hljs from 'highlight.js/lib/core';

@Component({
  selector: 'markdown',
  template: `<div
      class="mb-10 markdown-body hljs"
      [innerHTML]="isContentExpanded ? originalContent : truncatedContent"
    ></div>
    <div *ngIf="!isContentExpanded && originalContent && showSeeMore">
      <div class="font-medium mb-4 flex justify-center cursor-pointer text-blue-400">
        <a href="javascript:void(0);" (click)="seeMore()">Show more</a>
      </div>
    </div>
    <div *ngIf="isContentExpanded">
      <div class="font-medium mb-4 flex justify-center cursor-pointer text-blue-400">
        <a href="javascript:void(0);" (click)="seeLess()">Show less</a>
      </div>
    </div> `,
  styleUrls: ['markdown.scss'],
})
export class MarkdownComponent {
  parsedHtml;
  maxLength = 400;
  showSeeMore = false;
  isContentExpanded = false;
  truncatedContent = '';
  originalContent = '';

  @Input() set markdown(value: string) {
    const md = markdownIt({
      highlight: function (str, lang) {
        if (lang && hljs.getLanguage(lang)) {
          try {
            return hljs.highlight(str, { language: lang }).value;
          } catch (__) {
            // Handle the error or log it
          }
        }
        return '';
      },
    });
    md.use(syntaxHiglighting, { hljs });
    md.use(markdownItMultimdTable);
    md.use(mdKatex, { katex });
    this.parsedHtml = md.render(value);
    this.truncateContent();
  }

  constructor() {}

  truncateContent(): void {
    this.originalContent = this.parsedHtml;
    this.showSeeMore = this.parsedHtml?.length > this.maxLength;
    this.truncatedContent = this.parsedHtml.substring(0, this.maxLength);
  }

  seeMore(): void {
    this.isContentExpanded = true;
  }

  seeLess(): void {
    this.isContentExpanded = false;
  }
}
