import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PropertyType, TypeProperty } from '@contrail/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { StringUtil } from '@contrail/util';
import { RoutingService } from 'src/app/common/routing/routing.service';
import { RootStoreState } from 'src/app/root-store';
import { MenuComponent } from '@components/menu/menu.component';
import { TypeManagerActions, TypeManagerSelectors } from '../../type-manager-store';
import { TypePropertyOptionSet } from '../../type-manager-store/option-sets/option-sets.state';

@Component({
  selector: 'app-type-property-options',
  templateUrl: './type-property-options.component.html',
  styleUrls: ['./type-property-options.component.scss'],
})
export class TypePropertyOptionsComponent implements OnChanges {
  @Input() typeProperty: TypeProperty;
  public isOptionProperty;
  public optionSetAssigned = false;
  public optionSet: TypePropertyOptionSet;
  public optionSets$: Observable<Array<TypePropertyOptionSet>>;
  public optionSetFormControl = new UntypedFormControl();
  constructor(
    private store: Store<RootStoreState.State>,
    private routingService: RoutingService,
  ) {
    this.optionSets$ = this.store
      .select(TypeManagerSelectors.typePropertyOptionSets)
      .pipe(map((sets) => sets.sort((s1, s2) => (s1.name > s2.name ? 1 : -1))));
    this.optionSetFormControl.valueChanges.subscribe(this.setOptionSet.bind(this));
  }
  @ViewChild(MenuComponent) addOptionSetMenu: MenuComponent;
  ngOnChanges(): void {
    this.optionSet = null;
    this.isOptionProperty = [PropertyType.SingleSelect, PropertyType.MultiSelect].includes(
      this.typeProperty.propertyType,
    );

    this.optionSetFormControl.reset();

    if (this.typeProperty.typePropertyOptionSetId) {
      this.optionSets$
        .pipe(
          take(1),
          tap((optionSets) => {
            this.optionSet = optionSets.find((set) => set.id === this.typeProperty.typePropertyOptionSetId);
          }),
        )
        .subscribe();
    }
    this.optionSetAssigned = !!this.optionSet;
  }

  displayFn(optionSet: TypePropertyOptionSet): string {
    return optionSet?.name;
  }

  createNewOptionSet() {}

  goToOptionSet(optionSet) {
    this.routingService.go('/type-manager/option-sets/' + optionSet.id);
  }

  setOptionSet(optionSet: TypePropertyOptionSet) {
    if (optionSet) {
      this.optionSetAssigned = true;
      this.optionSet = optionSet;
      this.store.dispatch(
        TypeManagerActions.updateTypeProperty({
          id: this.typeProperty.id,
          changes: { ...this.typeProperty, typePropertyOptionSetId: this.optionSet.id },
        }),
      );
    }
  }

  cancelAdd() {
    this.addOptionSetMenu.panelVisible = false;
  }
  completeAdd(data: any) {
    this.addOptionSetMenu.panelVisible = false;

    const slug = StringUtil.convertToCamelCase(data.name);
    this.store.dispatch(
      TypeManagerActions.createTypePropertyOptionSet({ typePropertyOptionSet: { name: data.name, slug } }),
    );

    const subscription = this.optionSets$
      .pipe(map((ops) => ops.find((option) => option.name === data.name)))
      .subscribe((option) => {
        if (option) {
          this.setOptionSet(option);
          subscription.unsubscribe();
        }
      });
  }
}
