import { TypePropertyOption } from '@contrail/types';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface TypePropertyUserList {
  id?: string;
  name?: string;
  groupId?: string;
  userList?: Array<TypePropertyOption>;
}

interface TypePropertyUserListsState extends EntityState<TypePropertyUserList> {}

export interface State {
  typePropertyUserLists: TypePropertyUserListsState;
}
export const typePropertyUserListsEntityAdapter: EntityAdapter<TypePropertyUserList> =
  createEntityAdapter<TypePropertyUserList>({});

export const typePropertyUserListsInitialState = {
  typePropertyUserLists: typePropertyUserListsEntityAdapter.getInitialState({}),
};
