<app-page-section *ngIf="isOptionProperty" data-test="property-options">
  <app-page-section-header>
    <app-page-section-title title="Options"></app-page-section-title>
  </app-page-section-header>
  <app-page-section-body>
    <div *ngIf="!optionSetAssigned && typeProperty.typePropertyOptionSetId" class="bg-amber-100 p-3 m-2 mb-4">
      WARNING: Previously referenced Option Set no longer exists.
    </div>
    <div *ngIf="!optionSetAssigned" class="option-set-select">
      <mat-form-field class="example-full-width">
        <mat-label>Choose an Option Set</mat-label>
        <input
          type="text"
          placeholder="Pick one"
          aria-label="Option Set"
          matInput
          [formControl]="optionSetFormControl"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let optionSet of optionSets$ | async" [value]="optionSet">
            {{ optionSet.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div style="display: flex">
        <span> or </span>
        <span class="cursor-pointer create-option-set" data-test="create-option-set">
          <app-menu name="Create New Set" position="left">
            <app-type-property-option-set-form
              (cancelEdit)="cancelAdd()"
              (done)="completeAdd($event)"
              data-test="add-property-form"
            ></app-type-property-option-set-form>
          </app-menu>
        </span>
      </div>
    </div>
    <div *ngIf="optionSetAssigned" class="option-set-details">
      Using option set:
      <span class="cursor-pointer underline text-primary" (click)="goToOptionSet(optionSet)">{{
        optionSet?.name
      }}</span>
      <div class="option-set-list">
        <div class="option-row" *ngFor="let option of optionSet?.optionSet">
          {{ option.display }}
        </div>
      </div>
    </div>
  </app-page-section-body>
</app-page-section>
