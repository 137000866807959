import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { userOrgsReducers } from './user-orgs.reducers';
import { UserOrgsEffects } from './user-orgs.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('userOrgs', userOrgsReducers),
    EffectsModule.forFeature([UserOrgsEffects]),
  ],
})
export class UserOrgsStoreModule {}
