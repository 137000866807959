import { createAction, props } from '@ngrx/store';
import { TypePolicy } from './type-policies.state';

export enum TypePoliciesActionTypes {
  LOAD_TYPE_POLICIES = '[Type Manager -Type  Policies] Load Type  Policies',
  LOAD_TYPE_POLICIES_SUCCESS = '[Type Manager -Type  Policies] Load Type  Policies Success',
  LOAD_TYPE_POLICIES_FAILURE = '[Type Manager -Type  Policies] Load Type  Policies Failure',

  CREATE_TYPE_POLICY = '[Type Manager -Type  Policies] Create Type  Option Set',
  CREATE_TYPE_POLICY_SUCCESS = '[Type Manager -Type  Policies] Create Type  Option Set Success',
  CREATE_TYPE_POLICY_FAILURE = '[Type Manager -Type  Policies] Create Type  Option Set Failure',

  DELETE_TYPE_POLICY = '[Type Manager -Type  Policies] Delete Type  Option Set',
  DELETE_TYPE_POLICY_SUCCESS = '[Type Manager -Type  Policies] Delete Type  Option Set Success',
  DELETE_TYPE_POLICY_FAILURE = '[Type Manager -Type  Policies] Delete Type  Option Set Failure',

  UPDATE_TYPE_POLICY = '[Type Manager -Type  Policies] Update Type  Option Set',
  UPDATE_TYPE_POLICY_SUCCESS = '[Type Manager -Type  Policies] Update Type  Option Set Success',
  UPDATE_TYPE_POLICY_FAILURE = '[Type Manager -Type  Policies] Update Type  Option Set Failure',
}

/// /////////////////////////////////////////////////////////////////////////////

export const loadTypePolicies = createAction(TypePoliciesActionTypes.LOAD_TYPE_POLICIES);

export const loadTypePoliciesSuccess = createAction(
  TypePoliciesActionTypes.LOAD_TYPE_POLICIES_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const loadTypePoliciesFailure = createAction(
  TypePoliciesActionTypes.LOAD_TYPE_POLICIES_FAILURE,
  props<{ error: any }>(),
);

export const createTypePolicy = createAction(
  TypePoliciesActionTypes.CREATE_TYPE_POLICY,
  props<{ typePolicy: TypePolicy }>(),
);

export const createTypePolicySuccess = createAction(
  TypePoliciesActionTypes.CREATE_TYPE_POLICY_SUCCESS,
  props<{ typePolicy: TypePolicy }>(),
);

export const createTypePolicyFailure = createAction(
  TypePoliciesActionTypes.CREATE_TYPE_POLICY_FAILURE,
  props<{ error: any }>(),
);
export const deleteTypePolicy = createAction(
  TypePoliciesActionTypes.DELETE_TYPE_POLICY,
  props<{ typePolicy: TypePolicy }>(),
);

export const deleteTypePolicySuccess = createAction(
  TypePoliciesActionTypes.DELETE_TYPE_POLICY_SUCCESS,
  props<{ typePolicy: TypePolicy }>(),
);

export const deleteTypePolicyFailure = createAction(
  TypePoliciesActionTypes.DELETE_TYPE_POLICY_FAILURE,
  props<{ error: any }>(),
);

export const updateTypePolicy = createAction(
  TypePoliciesActionTypes.UPDATE_TYPE_POLICY,
  props<{ id: string; changes: TypePolicy }>(),
);

export const updateTypePolicySuccess = createAction(
  TypePoliciesActionTypes.UPDATE_TYPE_POLICY_SUCCESS,
  props<{ id: string; changes: TypePolicy }>(),
);

export const updateTypePolicyFailure = createAction(
  TypePoliciesActionTypes.UPDATE_TYPE_POLICY_FAILURE,
  props<{ error: any }>(),
);
