<app-page-section *ngIf="visible">
  <app-page-section-header>
    <app-page-section-title title="Search"></app-page-section-title>
  </app-page-section-header>
  <app-page-section-body>
    <div class="mb-3">
      <mat-slide-toggle [formControl]="searchableFormControl"
        >Include this property's values in text searches.</mat-slide-toggle
      >
    </div>
  </app-page-section-body>
</app-page-section>
