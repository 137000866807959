import { Component } from '@angular/core';
import { TypeProperty } from '@contrail/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfirmationBoxService } from 'src/app/common/components/confirmation-box/confirmation-box';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerActions, TypeManagerSelectors } from '../../type-manager-store';

@Component({
  selector: 'app-type-type-properties',
  templateUrl: './type-type-properties.component.html',
  styleUrls: ['./type-type-properties.component.scss'],
})
export class TypeTypePropertiesComponent {
  public typeProperties$: Observable<Array<TypeProperty>>;
  constructor(
    private store: Store<RootStoreState.State>,
    private confirmationBoxService: ConfirmationBoxService,
  ) {
    this.typeProperties$ = this.store
      .select(TypeManagerSelectors.typeProperties)
      .pipe(map((properties) => properties.sort((a, b) => (a.label < b.label ? -1 : 1))));
  }

  async removeProperty(property: TypeProperty) {
    const confirm = await this.confirmationBoxService.open(
      'Remove Property?',
      'Are you sure you want to remove this property?  No data will be deleted, but the property will no longer be accessible via the user experience.',
    );
    if (confirm) {
      this.store.dispatch(TypeManagerActions.deleteTypeProperty({ typeProperty: property }));
    }
  }

  showPropertyDetails(property) {
    this.store.dispatch(TypeManagerActions.setCurrentTypeProperty({ currentTypeProperty: property }));
  }

  toggleArchivedProperty(event: { property: TypeProperty; changes: { isArchived: boolean } }) {
    const isArchived = Boolean(event?.changes?.isArchived);

    this.store.dispatch(
      TypeManagerActions.updateTypeProperty({
        id: event.property.id,
        changes: { isArchived },
      }),
    );
  }
}
