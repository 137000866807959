<app-page-section>
  <app-page-section-header>
    <app-page-section-title title="History"></app-page-section-title>
  </app-page-section-header>
  <app-page-section-body>
    <div *ngIf="typeProperty['updatedOn']" class="flex">
      <div class="ml-2 mr-5">Updated On:</div>
      <div class="font-medium">
        {{ properties?.updatedOn | date: 'medium' }}
      </div>
    </div>
    <div *ngIf="typeProperty['updatedById']" class="flex">
      <div class="ml-2 mr-5">Updated By:</div>
      <div class="font-medium">{{ user?.email }}</div>
    </div>
  </app-page-section-body>
</app-page-section>
