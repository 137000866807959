import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-load-file',
  templateUrl: './load-file.component.html',
  styleUrls: ['./load-file.component.scss'],
})
export class LoadFileComponent implements OnInit {
  @Input() accept = '.csv';
  @Input() showInstructions: boolean = true;

  @Output() onFileLoad = new EventEmitter<File>();
  constructor() {}

  ngOnInit(): void {}

  @HostListener('dragover', ['$event']) async onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  @HostListener('drop', ['$event']) async onDrop(event) {
    event.stopPropagation();
    event.preventDefault();
    if (event.dataTransfer?.files?.length) {
      const files: FileList = event.dataTransfer?.files;
      const file = files[0];
      this.onFileLoad.emit(file);
    }
  }

  handleFileSelection($event: any) {
    //const files = $event?.target?.files;
    const file = $event.target.files[0];
    this.onFileLoad.emit(file);
  }
}
