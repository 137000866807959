<div class="content">
  <h6 class="py-2 border-b px-8">Files</h6>
  <div class="px-4 py-3">
    <div class="spinner-bg" *ngIf="isLoading">
      <mat-spinner diameter="50" mode="indeterminate"></mat-spinner>
    </div>

    <div *ngIf="!isLoading" class="block">
      <div>
        <span *ngIf="!found && !isLoading; else loaded" class="m-auto" data-test="not-found"
          >File with Id <span data-test="file-id" class="code">{{ fileId }}</span> was not found</span
        >
        <div class="spinner-bg" *ngIf="isLoading">
          <mat-spinner diameter="50" mode="indeterminate"></mat-spinner>
        </div>
        <ng-template #loaded>
          <div class="file-container">
            <div class="file-header">
              <div class="file-name" data-test="type-name">{{ fileName }}</div>
              <span class="separator">-</span>
              <div class="file-id" data-test="file-id">{{ fileId }}</div>
            </div>
            <div class="secondary-details">
              <div class="row">
                <div class="col-label">Size</div>
                <div data-test="size">{{ size }}</div>
              </div>
              <div class="row">
                <div class="col-label">Created On</div>
                <div data-test="createdOn">{{ createdOn | date: 'medium' }}</div>
              </div>
              <div class="row">
                <div class="col-label">Created By</div>
                <div data-test="email">{{ email }}</div>
              </div>
            </div>
            <button class="download-button" (click)="downloadFile()" data-test="download-data">
              <mat-icon>file_download</mat-icon>
              Download
            </button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
