import { AfterViewInit, Component, ElementRef, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { AllPropertyTypes, PropertyType, PropertyTypeMetaData } from '@contrail/types';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SubTypesHelper, Types } from '@contrail/sdk';
import { UntypedFormControl } from '@angular/forms';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerSelectors } from '../../type-manager-store';

interface ObjectReferenceTarget {
  typePath: string;
  label: string;
  typeRootSlug: string;
}

@Component({
  selector: 'app-type-property-create-form',
  templateUrl: './type-property-create-form.component.html',
  styleUrls: ['./type-property-create-form.component.scss'],
})
export class TypePropertyCreateFormComponent implements OnInit, AfterViewInit {
  @Output() cancelEdit = new EventEmitter();
  @Output() done = new EventEmitter();
  @ViewChild('keyInput') keyInput: ElementRef;
  public label: string;
  public slug: string;
  public itemTypePathOptions$: Subject<Array<{ value: string; display: string }>> = new BehaviorSubject(null);
  public propertyTypeMeta: PropertyTypeMetaData;
  public objectReferenceTargets$: Observable<Array<ObjectReferenceTarget>>;
  public selectedObjectReferenceTarget: ObjectReferenceTarget;
  public selectedObjectReferenceRoleFormControl = new UntypedFormControl();
  public selectedObjectReferenceSubTypeFormControl = new UntypedFormControl();
  public sequenceSeedNumberFormControl = new UntypedFormControl();

  constructor(private store: Store<RootStoreState.State>) {}

  public propertyTypes = AllPropertyTypes;

  async ngOnInit() {
    const customEntityRoot = await new Types().getType({ path: 'custom-entity', relations: ['children'] });
    const customEntitySubTypes = customEntityRoot.children;
    this.selectedObjectReferenceRoleFormControl.setValue('family');
    this.sequenceSeedNumberFormControl.setValue(1);

    this.initializeItemTypePathOptions();

    this.objectReferenceTargets$ = this.store.select(TypeManagerSelectors.typeRoots).pipe(
      filter((roots) => roots.length > 0),
      map((roots) => {
        const objectReferenceTargets = [];

        // Add all subtypes of custom entity
        objectReferenceTargets.push(
          ...customEntitySubTypes.map((customEntityType) => ({
            label: customEntityType.label,
            typePath: customEntityType.typePath,
            typeRootSlug: 'custom-entity',
          })),
        );

        // Add referencable roots
        roots
          .filter((root) => root.typeInterfaceSlugs.includes('referenceable'))
          .map((root) => {
            objectReferenceTargets.push({
              label: root.label,
              typePath: root.slug,
              typeRootSlug: root.slug,
            });
          });
        objectReferenceTargets.sort((o1, o2) => (o1.label > o2.label ? 1 : -1));
        return objectReferenceTargets;
      }),
    );
  }

  ngAfterViewInit() {
    this.keyInput.nativeElement.focus();
  }

  async addProperty() {
    const property: any = {
      propertyType: this.propertyTypeMeta.propertyType,
      label: this.label,
      slug: this.slug,
    };
    if ([PropertyType.ObjectReference, PropertyType.TypeReference].includes(this.propertyTypeMeta.propertyType)) {
      property.referencedTypePath = this.selectedObjectReferenceTarget?.typePath;
      property.referencedTypeRootSlug = this.selectedObjectReferenceTarget.typeRootSlug;
    }

    // Set referenced item role (for item object refs only)
    if (PropertyType.ObjectReference === this.propertyTypeMeta.propertyType && this.isItemReference()) {
      property.referencedTypeRole = this.selectedObjectReferenceRoleFormControl.value;
      property.referencedTypePath = this.selectedObjectReferenceSubTypeFormControl.value;
    } else {
      delete property.referencedTypeRole;
    }

    // Handle seed value for sequences
    if (PropertyType.Sequence === this.propertyTypeMeta.propertyType) {
      property.sequenceSeedValue = Math.round(this.sequenceSeedNumberFormControl.value || 1);
    } else {
      delete property.sequenceSeedValue;
    }

    this.done.emit(property);
    this.label = null;
    this.slug = null;
    this.propertyTypeMeta = null;
    this.keyInput.nativeElement.focus();
  }

  setLabel() {
    let labelForSlug = this.slug.replace(/([A-Z])/g, ' $1');
    labelForSlug = labelForSlug.charAt(0).toUpperCase() + labelForSlug.slice(1);
    this.label = labelForSlug;
  }
  cancel() {
    this.cancelEdit.emit(null);
  }

  isValid() {
    let valid: boolean = !!this.label && !!this.propertyTypeMeta && !!this.slug;
    if (valid && this.propertyTypeMeta.propertyType === PropertyType.ObjectReference) {
      valid = !!this.selectedObjectReferenceTarget;
    }

    if (this.isItemReference() && !this.selectedObjectReferenceSubTypeFormControl.value) {
      valid = false;
    }

    return valid;
  }

  isItemReference() {
    if (this.selectedObjectReferenceTarget?.typeRootSlug === 'item') {
      return true;
    }
  }

  async initializeItemTypePathOptions() {
    const typeOptions = await this.getTypeOptionsForRootTypePath('item');
    this.itemTypePathOptions$.next(typeOptions);
  }

  async getTypeOptionsForRootTypePath(typePath: string): Promise<Array<{ value: string; display: string }>> {
    const typesList = await SubTypesHelper.getTypeListFromPath(typePath, { includeParentTypes: false });
    const options = [{ display: 'Item', value: 'item' }];

    options.push(
      ...typesList.map((type) => {
        return {
          display: type.pathLabel ?? type.label,
          value: type.typePath,
        };
      }),
    );

    return options;
  }
}
