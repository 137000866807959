import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { TypeRuleSet } from './type-rule-sets.state';

@Injectable({
  providedIn: 'root',
})
export class TypeRuleSetsService {
  constructor() {}

  public async get() {
    let sets = await new Entities().get({ entityName: 'type-rule-set' });
    sets = sets.sort((s1, s2) => (s1.name > s2.name ? 1 : -1));
    return sets;
  }
  public async create(ruleSet: TypeRuleSet) {
    return new Entities().create({ entityName: 'type-rule-set', object: ruleSet });
  }
  public async update(id: string, changes: TypeRuleSet) {
    return new Entities().update({ entityName: 'type-rule-set', id, object: changes });
  }
  public async delete(ruleSet: TypeRuleSet) {
    await new Entities().delete({ entityName: 'type-rule-set', id: ruleSet.id });
    return ruleSet;
  }
}
