import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerActions } from '..';
import { TypePropertyUserListsService } from '../../type-property-user-lists/type-property-user-lists.service';
import { TypesService } from '../../types.service';

@Injectable()
export class TypePropertyUserListsEffects {
  constructor(
    private actions$: Actions,
    private typePropertyUserListService: TypePropertyUserListsService,
    public typesService: TypesService,
    private store: Store<RootStoreState.State>,
  ) {}

  loadTypePropertyUserLists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.TypePropertyUserListsActionTypes.LOAD_TYPE_PROPERTY_USER_LISTS),
      withLatestFrom(this.store),
      switchMap(() =>
        from(this.typePropertyUserListService.getUserLists()).pipe(
          map((data) => TypeManagerActions.loadTypePropertyUserListsSuccess({ data })),
          catchError((error) => observableOf(TypeManagerActions.loadTypePropertyUserListsFailure({ error }))),
        ),
      ),
    ),
  );
}
