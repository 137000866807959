import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';

@Injectable({
  providedIn: 'root',
})
export class AssortmentService {
  public async getAssortments() {
    return await new Entities().get({ entityName: 'assortment' });
  }
}
