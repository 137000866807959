import { Action, createReducer, on } from '@ngrx/store';
import { State, scheduleJobActionsEntityAdapter, scheduleJobActionsInitialState } from './schedule-job.state';
import * as ScheduleJobActions from './schedule-job.actions';

const add = (state: State, { scheduleJobAction }) => ({
  ...state,
  loading: false,
  scheduleJobActions: scheduleJobActionsEntityAdapter.addOne(scheduleJobAction, state.scheduleJobActions),
});

const update = (state: State, { id, changes }) => ({
  ...state,
  loading: false,
  scheduleJobActions: scheduleJobActionsEntityAdapter.updateOne({ id, changes }, state.scheduleJobActions),
});

const setData = (state: State, { data }) => ({
  ...state,
  loading: false,
  scheduleJobActions: scheduleJobActionsEntityAdapter.setAll(data, state.scheduleJobActions),
});

const setCurrent = (state: State, { scheduleJobAction }) => ({
  ...state,
  loading: false,
  currentScheduleJobAction: scheduleJobAction,
});

const startLoading = (state: State) => ({
  ...state,
  loading: true,
});

const clearData = (state: State) => ({
  ...state,
  loading: false,
  currentScheduleJobAction: null,
});

export const scheduleJobActionsReducers = createReducer(
  scheduleJobActionsInitialState,
  on(ScheduleJobActions.updateScheduleJobActionSuccess, update),
  on(ScheduleJobActions.createScheduleJobActionSuccess, add),
  on(ScheduleJobActions.loadScheduleJobActionSuccess, setData),
  on(ScheduleJobActions.loadCurrentScheduleJobActionSuccess, setCurrent),
  on(ScheduleJobActions.loadScheduleJobAction, startLoading),
  on(ScheduleJobActions.clearCurrentScheduleJobAction, clearData),
);

/**
 *
 * @param state
 * @param action
 */
export function reducer(state: State | undefined, action: Action): any {
  return scheduleJobActionsReducers(state, action);
}
