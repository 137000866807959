import { Injectable } from '@angular/core';
import { API_VERSION, Entities } from '@contrail/sdk';
import { SystemNotification } from './system-notification';

@Injectable({
  providedIn: 'root',
})
export class SystemNotificationService {
  constructor() {}

  public async loadSystemNotificationsByDateRange(startDate: string, endDate: string): Promise<SystemNotification[]> {
    const allResults = [];
    let nextPageKey;
    do {
      const page = await new Entities().get({
        entityName: 'system-notification',
        apiVersion: API_VERSION.V2,
        criteria: {
          createdOn: `BETWEEN ${startDate} and ${endDate}`,
        },
        nextPageKey,
      });
      nextPageKey = page.nextPageKey;
      allResults.push(...page.results);
    } while (nextPageKey);

    return allResults;
  }

  public async loadCurrentSystemNotification(id): Promise<SystemNotification> {
    return await new Entities().get({ entityName: 'system-notification', id });
  }
}
