import { createSelector } from '@ngrx/store';
import { EventWorkflowTask } from './event-task';
import { eventWorkflowTasksEntityAdapter } from './event-tasks.state';

export const { selectAll, selectEntities, selectIds, selectTotal } = eventWorkflowTasksEntityAdapter.getSelectors();

const processActionIdEventWorkflowTaskEntities = (state: any) =>
  state.eventWorkflowTasks.processActionIdEventWorkflowTasks.entities;
const processIdEventWorkflowTaskEntities = (state: any) =>
  state.eventWorkflowTasks.processIdEventWorkflowTasks.entities;
export const processActionIdeventWorkflowTasks = createSelector(
  processActionIdEventWorkflowTaskEntities,
  (state) => Object.values(state) as Array<EventWorkflowTask>,
);
export const processIdEventWorkflowTasks = createSelector(
  processIdEventWorkflowTaskEntities,
  (state) => Object.values(state) as Array<EventWorkflowTask>,
);
export const currentEventWorkflowTask = (state: any) => state.eventWorkflowTasks.currentEventWorkflowTask;
