import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { withLatestFrom, switchMap, map, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { RootStoreState } from '@rootstore';
import { ScheduleJobService } from './schedule-job.service';
import * as ScheduleJobActions from './schedule-job.actions';

@Injectable()
export class ScheduleJobEffects {
  constructor(
    private actions$: Actions,
    private scheduleJobService: ScheduleJobService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {}

  loadScheduleJobActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScheduleJobActions.ScheduleJobActionTypes.LOAD_SCHEDULE_JOB_ACTION),
      switchMap(() =>
        from(this.scheduleJobService.loadScheduleJobs()).pipe(
          map((data: any) =>
            ScheduleJobActions.loadScheduleJobActionSuccess({
              data,
            }),
          ),
          catchError((error) =>
            of(
              ScheduleJobActions.loadScheduleJobActionFailure({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  loadCurrentEventWorkflowActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScheduleJobActions.ScheduleJobActionTypes.LOAD_CURRENT_SCHEDULE_JOB_ACTION),
      switchMap((action: any) =>
        from(this.scheduleJobService.loadCurrentScheduleJob(action.id)).pipe(
          map((data: any) =>
            ScheduleJobActions.loadCurrentScheduleJobActionSuccess({
              scheduleJobAction: data,
            }),
          ),
          catchError((error) => of(ScheduleJobActions.loadCurrentScheduleJobActionFailure({ error }))),
        ),
      ),
    ),
  );

  createScheduleJobAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScheduleJobActions.ScheduleJobActionTypes.CREATE_SCHEDULE_JOB_ACTION),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) => {
        const body = {
          cron: action.cron,
          requestMethod: 'POST',
          isActive: action.isActive,
          payload: {
            workflowId: action.workflowId,
            event: action.event,
          },
          entityName: 'event-workflow-request',
          suffix: 'run-workflow',
          name: action.name,
        };
        return from(this.scheduleJobService.createScheduleJob(body)).pipe(
          map((data: any) => {
            this.snackBar.open('Event Workflow Schedule Created', '', {
              duration: 2000,
            });
            return ScheduleJobActions.createScheduleJobActionSuccess({
              scheduleJobAction: data,
            });
          }),
          catchError((error) => {
            this.snackBar.open(`Failed to Create Event Workflow Schedule. ${error}`, '', { duration: 2000 });
            return of(
              ScheduleJobActions.createScheduleJobActionFailure({
                error,
              }),
            );
          }),
        );
      }),
    ),
  );

  updateScheduleJobAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScheduleJobActions.ScheduleJobActionTypes.UPDATE_SCHEDULE_JOB_ACTION),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) => {
        const body = {
          requestMethod: 'POST',
          entityName: 'event-workflow-request',
          cron: action.changes.cron,
          isActive: action.changes.isActive,
          payload: {
            workflowId: action.changes.workflowId,
            event: action.changes.event,
          },
          suffix: 'run-workflow',
          name: action.changes.name,
        };

        return from(this.scheduleJobService.updateScheduleJob(action.id, body)).pipe(
          map((data: any) => {
            this.snackBar.open('Event Workflow Schedule Updated.', '', {
              duration: 2000,
            });
            return ScheduleJobActions.updateScheduleJobActionSuccess({
              id: data.id,
              changes: data,
            });
          }),
          catchError((error) => {
            this.snackBar.open(`Failed to Update Event Workflow Schedule. ${error}`, '', { duration: 2000 });
            return of(
              ScheduleJobActions.updateScheduleJobActionFailure({
                error,
              }),
            );
          }),
        );
      }),
    ),
  );
}
