import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ObjectUtil } from '@contrail/util';
import { Type, TypeProperty, PropertyType, CopyBehavior } from '@contrail/types';
import { TypePropertyFormConfiguration } from '@common/types/forms/type-property-form/type-property-form.component';
import { TypePropertyFormFieldComponent } from '@common/types/forms/type-property-form-field/type-property-form-field.component';

@Component({
  selector: 'app-type-property-copy-behavior',
  templateUrl: './type-property-copy-behavior.component.html',
  styleUrls: ['./type-property-copy-behavior.component.scss'],
})
export class TypePropertyCopyBehaviorComponent implements OnInit, OnChanges, AfterViewChecked, OnDestroy {
  @Input() typeProperty: TypeProperty;
  @Input() type: Type;
  @Output() changes = new EventEmitter();
  @ViewChild(TypePropertyFormFieldComponent) defaultPropertyFormField: TypePropertyFormFieldComponent;
  public formConfiguration: TypePropertyFormConfiguration;
  public formControl = new UntypedFormControl();
  private formSubscription: Subscription;
  private destroy$ = new Subject<void>();
  private currentDefaultValue: any = null;
  public copyOptions: { value: string; displayValue: string }[] = [
    { value: CopyBehavior.Clear, displayValue: 'Clear' },
    { value: CopyBehavior.Copy, displayValue: 'Copy' },
    { value: CopyBehavior.Default, displayValue: 'Default' },
  ];

  public isViewable = false;
  public isDisabled = false;
  private validCopyTypePaths = ['item', 'project-item', 'plan-placeholder'];
  private validCopyPropertyTypes = [
    PropertyType.Text,
    PropertyType.String,
    PropertyType.Number,
    PropertyType.Currency,
    PropertyType.Percent,
    PropertyType.Date,
    PropertyType.Boolean,
    PropertyType.MultiSelect,
    PropertyType.SingleSelect,
    PropertyType.ObjectReference,
    PropertyType.TypeReference,
    PropertyType.UserList,
    PropertyType.SizeRange,
  ];

  private invalidCopyDefaultPropertyTypes = [
    PropertyType.ObjectReference,
    PropertyType.UserList,
    PropertyType.TypeReference,
    PropertyType.SizeRange,
  ];

  private invalidCopyPropertySlugs = ['createdOn', 'updatedOn'];
  private invalidItemCopyPropertySlugs = ['name', 'optionName'];
  private invalidPlanPlaceholderCopyPropertySlugs = ['name', 'optionName', 'itemFamily', 'itemOption', 'itemNumber'];

  constructor() {}

  ngOnInit() {
    this.formControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value !== this.getCopyBehavior()) {
        if (value !== CopyBehavior.Default) {
          this.currentDefaultValue = null;
          this.changes.emit({ copyDefault: null, copyBehavior: value });
        } else {
          this.changes.emit({ copyBehavior: value });
        }
      }
    });
  }

  ngOnChanges(): void {
    this.determineIfCopyIsViewable();
    this.determineIfCopyIsDisabled();

    if (!this.isViewable || (!this.typeProperty && this.type)) {
      return;
    }

    const copyBehavior = this.getCopyBehavior();
    this.formConfiguration = {
      typeProperty: {
        ...ObjectUtil.cloneDeep(this.typeProperty),
        editable: true,
        copyBehavior,
      },
    };

    this.formControl.setValue(copyBehavior);
  }

  ngAfterViewChecked(): void {
    if (!this.defaultPropertyFormField) {
      return;
    }

    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }

    this.currentDefaultValue = this.getCopyDefaultValue();

    this.formSubscription = this.defaultPropertyFormField.valueChange.subscribe((change) => {
      const updatedCopyDefault = change?.value ? String(change.value) : null;
      if (this.currentDefaultValue !== updatedCopyDefault) {
        this.currentDefaultValue = updatedCopyDefault;
        this.changes.emit({ copyDefault: updatedCopyDefault });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    console.log('running on destroy');
  }

  determineIfCopyIsViewable() {
    const isTypePathViewable = this.validCopyTypePaths.includes(this.type?.typePath);
    const isPropertyTypeViewable = this.validCopyPropertyTypes.includes(this.typeProperty?.propertyType);

    let isPropertySlugViewable = !this.invalidCopyPropertySlugs.includes(this.typeProperty?.slug);

    if (isPropertySlugViewable && this.type?.typePath === 'plan-placeholder') {
      isPropertySlugViewable = !this.invalidPlanPlaceholderCopyPropertySlugs.includes(this.typeProperty?.slug);
    }

    if (isPropertySlugViewable && this.type?.typePath === 'item') {
      isPropertySlugViewable = !this.invalidItemCopyPropertySlugs.includes(this.typeProperty?.slug);
    }

    if (isTypePathViewable && isPropertyTypeViewable && isPropertySlugViewable) {
      this.isViewable = true;
    } else {
      this.isViewable = false;
    }
  }

  determineIfCopyIsDisabled() {
    if (this.typeProperty.isUniqueProperty) {
      this.isDisabled = true;
      if (this.typeProperty.copyBehavior !== CopyBehavior.Clear) {
        this.changes.emit({ copyDefault: null, copyBehavior: CopyBehavior.Clear });
      }
    } else {
      this.isDisabled = false;
    }
  }

  isOptionValueValid(optionValue: string): boolean {
    if (
      optionValue === CopyBehavior.Default &&
      this.invalidCopyDefaultPropertyTypes.includes(this.typeProperty?.propertyType)
    ) {
      return false;
    }

    return true;
  }

  getCopyDefaultValue() {
    if (this.typeProperty.copyDefault === 'true') {
      return true;
    }

    if (this.typeProperty.copyDefault === 'false') {
      return false;
    }

    return this.typeProperty.copyDefault ?? null;
  }

  getCopyBehavior() {
    if (!this.typeProperty?.copyBehavior) {
      return CopyBehavior.Copy;
    }

    return this.typeProperty?.copyBehavior;
  }
}
