import { Action, createReducer, on } from '@ngrx/store';
import { State, eventWorkflowActionsEntityAdapter, eventWorkflowActionsInitialState } from './event-actions.state';
import * as EventWorkflowActionsActions from './event-actions.actions';

const add = (state: State, { eventWorkflowAction }) => ({
  ...state,
  eventWorkflowActions: eventWorkflowActionsEntityAdapter.addOne(eventWorkflowAction, state.eventWorkflowActions),
});

const update = (state: State, { id, changes }) => ({
  ...state,
  eventWorkflowActions: eventWorkflowActionsEntityAdapter.updateOne({ id, changes }, state.eventWorkflowActions),
});
const remove = (state: State, { eventWorkflowAction }) => ({
  ...state,
  eventWorkflowActions: eventWorkflowActionsEntityAdapter.removeOne(eventWorkflowAction.id, state.eventWorkflowActions),
});
const setCurrentEventWorkflowAction = (state: State, { eventWorkflowAction }) => ({
  ...state,
  currentEventWorkflowAction: eventWorkflowAction,
});
const setOwnerkeyEventWorkflowActions = (state: State, { data }) => ({
  ...state,
  ownerKeyEventWorkflowActions: eventWorkflowActionsEntityAdapter.setAll(data, state.ownerKeyEventWorkflowActions),
});

export const eventWorkflowActionsReducers = createReducer(
  eventWorkflowActionsInitialState,
  on(EventWorkflowActionsActions.createEventWorkflowActionSuccess, add),
  on(EventWorkflowActionsActions.deleteEventWorkflowActionSuccess, remove),
  on(EventWorkflowActionsActions.updateEventWorkflowActionSuccess, update),
  on(EventWorkflowActionsActions.loadCurrentEventWorkflowActionSuccess, setCurrentEventWorkflowAction),
  on(EventWorkflowActionsActions.loadOwnerkeyEventWorkflowActionsSuccess, setOwnerkeyEventWorkflowActions),
);

/**
 *
 * @param state
 * @param action
 */
export function reducer(state: State | undefined, action: Action): any {
  return eventWorkflowActionsReducers(state, action);
}
