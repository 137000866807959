import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { TypeProperty } from '@contrail/types';

@Injectable({
  providedIn: 'root',
})
export class TypePropertiesService {
  constructor() {}

  public async getTypeProperties() {
    return new Entities().get({ entityName: 'type-property' });
  }
  public async createTypeProperty(property: TypeProperty) {
    return new Entities().create({ entityName: 'type-property', object: property });
  }
  public async deleteTypeProperty(property: TypeProperty) {
    await new Entities().delete({ entityName: 'type-property', id: property.id });
    return property;
  }
  public async updateTypeProperty(id: string, changes: TypeProperty, typeId: string, fullTypeProperty) {
    // Create a copy of fullTypeProperty to avoid direct mutation
    const localFullTypeProperty = { ...fullTypeProperty };

    // Create a new instance of the Entities class
    const entity = new Entities();

    // Non-inherited changes are handled separately
    if (!localFullTypeProperty.inherited && !localFullTypeProperty.core) {
      return entity.update({ entityName: 'type-property', id, object: changes });
    }

    let infoLink = await this.getTypePropertyUsageInfo(localFullTypeProperty.infoLinkId);

    // If there is a linked info and changes doesn't nullify it, update it
    if (localFullTypeProperty.infoLinkId && infoLink.typeId === typeId && (changes as any).infoLinkId !== null) {
      infoLink = await entity.update({
        entityName: 'type-property-usage-info',
        id: localFullTypeProperty.infoLinkId,
        object: changes,
      });
    }
    // If there is a linked info and changes nullifies it, delete it and fetch the updated type-property
    else if (localFullTypeProperty.infoLinkId && infoLink.typeId === typeId && (changes as any).infoLinkId === null) {
      await entity.delete({ entityName: 'type-property-usage-info', id: localFullTypeProperty.infoLinkId });
      if (!localFullTypeProperty.inherited && !localFullTypeProperty.core) {
        return entity.update({ entityName: 'type-property', id, object: { infoLinkId: null } });
      }
    }
    // If there is no linked info, create it
    else {
      infoLink = await entity.create({
        entityName: 'type-property-usage-info',
        object: { typeId, typePropertyId: id, ...changes },
      });
    }

    // Assign infoLink's id to localFullTypeProperty's infoLinkId property and apply changes
    localFullTypeProperty.infoLinkId = infoLink?.id;
    Object.assign(localFullTypeProperty, changes);

    return localFullTypeProperty;
  }

  public async getTypePropertyUsageInfo(id: string) {
    return new Entities().get({ entityName: 'type-property-usage-info', id });
  }
}
