<app-page-section *ngIf="visible">
  <app-page-section-header>
    <app-page-section-title title="Federation Property"></app-page-section-title>
  </app-page-section-header>
  <app-page-section-body>
    <div class="mb-3">
      <mat-slide-toggle [formControl]="isFederationPropertyFormControl"
        >Is this property used in federation mappings?</mat-slide-toggle
      >
    </div>
  </app-page-section-body>
</app-page-section>
