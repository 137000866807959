import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PropertyType, Type, TypeProperty } from '@contrail/types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-type-property-searchable',
  templateUrl: './type-property-searchable.component.html',
  styleUrls: ['./type-property-searchable.component.scss'],
})
export class TypePropertySearchableComponent implements OnChanges, OnInit, OnDestroy {
  @Input() typeProperty: TypeProperty;
  @Input() type: Type;
  @Output() changes = new EventEmitter();
  public visible = false;
  public searchableFormControl = new UntypedFormControl();
  private subscriptions: Array<Subscription> = [];
  constructor() {}

  ngOnInit() {
    this.subscriptions.push(
      this.searchableFormControl.valueChanges.subscribe((value) => {
        if (value !== this.typeProperty.searchable) {
          this.changes.emit({ searchable: value });
        }
      }),
    );
  }
  ngOnChanges(): void {
    if (!this.typeProperty && this.type) {
      return;
    }
    this.visible = false;
    const searchableInterface = this.type.typeInterfaces.find((ti) => ti.slug === 'searchable');
    if (searchableInterface) {
      if (
        [
          PropertyType.Text,
          PropertyType.String,
          PropertyType.Number,
          PropertyType.Date,
          PropertyType.SingleSelect,
          PropertyType.Currency,
          PropertyType.Formula,
          PropertyType.Sequence,
        ].includes(this.typeProperty.propertyType)
      ) {
        this.visible = true;
      }
    }
    this.searchableFormControl.setValue(this.typeProperty.searchable);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub?.unsubscribe());
  }
}
