<div class="content">
  <h6 class="py-2 border-b px-8">Option Set Hierarchies</h6>
  <div class="wrap">
    <div class="option-set-list w-1/2 border-r py-3 overflow-auto">
      <div class="header">
        <app-search-bar [lgGray]="true" [placeholder]="'Search'" class="mr-4 px-4"></app-search-bar>
        <app-menu name="" icon="add">
          <app-option-set-hierarchy-form
            (cancelEdit)="cancelAdd()"
            (done)="completeAdd($event)"
          ></app-option-set-hierarchy-form>
        </app-menu>
      </div>
      <app-option-set-hierarchy-list
        class="w-full"
        [optionSetHierarchies]="optionSetHierarchies$ | async"
        (clicked)="select($event)"
        (deleted)="delete($event)"
        (edit)="edit($event)"
      >
      </app-option-set-hierarchy-list>
    </div>
    <div class="w-1/2 py-3">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
