import { createAction, props } from '@ngrx/store';

export enum TypeRootsActionTypes {
  LOAD_TYPE_ROOTS = '[Type Manager - Roots] Load Type Roots',
  LOAD_TYPE_ROOTS_SUCCESS = '[Type Manager - Roots] Load Type Roots Success',
  LOAD_TYPE_ROOTS_FAILURE = '[Type Manager - Roots] Load Type Roots Failure',
}

/// /////////////////////////////////////////////////////////////////////////////

export const loadTypeRoots = createAction(TypeRootsActionTypes.LOAD_TYPE_ROOTS);

export const loadTypeRootsSuccess = createAction(
  TypeRootsActionTypes.LOAD_TYPE_ROOTS_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const loadTypeRootsFailure = createAction(TypeRootsActionTypes.LOAD_TYPE_ROOTS_FAILURE, props<{ error: any }>());
