<app-page-section [title]="'Info'">
  <app-page-section-body>
    <div *ngIf="entity.implements('base_managed')">
      <div class="property">
        Created:<br />
        {{ entity.properties.createdOn | date: 'short' }}<br />{{ entity.properties.createdBy?.email }}
      </div>
      <div class="property">
        Updated:<br />
        {{ entity.properties.updatedOn | date: 'short' }}<br />{{ entity.properties.updatedBy?.email }}
      </div>
      <div class="header"><b>Type</b></div>
      <div class="property cursor-pointer underline text-primary" (click)="goToType()">
        {{ entity.getType().getTypePath() }}
      </div>
      <div class="header"><b>Relations</b></div>
      <div class="list" *ngFor="let relation of relations">
        <div>{{ relation.name }}</div>
      </div>
      <div class="header"><b>Interfaces</b></div>
      <div class="list" *ngFor="let int of interfaces">
        <div>{{ int.label }}</div>
      </div>
    </div>
  </app-page-section-body>
</app-page-section>
