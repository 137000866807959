import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { orgAppsReducers } from './org-apps.reducers';
import { OrgAppsEffects } from './org-apps.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('orgApps', orgAppsReducers),
    EffectsModule.forFeature([OrgAppsEffects]),
  ],
})
export class OrgAppsStoreModule {}
