import { createSelector } from '@ngrx/store';
import { ChartsState } from './event-dashboard.state';
import { DashboardState } from './event-dashboard';

export const selectEventWorkflowDashboardState = (state: DashboardState) => state.eventWorkflowDashboard;

export const selectTriggeredChartData = createSelector(
  selectEventWorkflowDashboardState,
  (state: ChartsState) => state.triggeredChartData,
);

export const selectFailedChartData = createSelector(
  selectEventWorkflowDashboardState,
  (state: ChartsState) => state.failedChartData,
);

export const selectTriggeredChartLoading = createSelector(
  selectEventWorkflowDashboardState,
  (state: ChartsState) => state.triggeredChartLoading,
);

export const selectFailedChartLoading = createSelector(
  selectEventWorkflowDashboardState,
  (state: ChartsState) => state.failedChartLoading,
);

export const selectError = createSelector(selectEventWorkflowDashboardState, (state: ChartsState) => state.error);
