<div class="upload-container">
  <app-page-section *ngIf="showInstructions">
    <app-page-section-header> Instructions </app-page-section-header>
    <app-page-section-body>
      <ng-content></ng-content>
    </app-page-section-body>
  </app-page-section>

  <div class="drop-zone">
    <div class="flex flex-col justify-center items-center pt-5">
      <mat-icon class="upload-icon"> file_upload </mat-icon>
      <div class="message py-2">
        Drag files here
        <span class="underline cursor-pointer" (click)="fileUpload.click()">or select.</span>
      </div>
      <input #fileUpload type="file" style="display: none" (change)="handleFileSelection($event)" [accept]="accept" />
    </div>
  </div>
</div>
