<h6 class="py-2 border-b px-8">Webhooks</h6>
<div class="px-8 py-3">
  <div class="page-description">
    Define webhooks that can fire on entity events, such as create, update, & delete. Specify conditions for the
    webhook, such as property value changes.
  </div>
  <div class="empty-state">
    <img style="width: 85%; max-width: 800px; margin-bottom: 40px" src="assets/images/webhook_diagram.png" />
    <button mat-raised-button color="primary">Create Webhook</button>
  </div>
</div>
