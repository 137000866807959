<app-page-section [title]="'Option Set Hierarchies'">
  <app-page-section-body>
    <div class="mb-2">Assign option set hierarchies to this type to apply a hierarchies option value constraint.</div>
    <mat-chip-list>
      <mat-chip color="primary" *ngFor="let optionSetHierarchyLink of optionSetHierarchyLinks">
        {{ optionSetHierarchyLink.optionSetHierarchy.name }}
        <mat-icon class="cursor-pointer" (click)="removeOptionSetHierarchy(optionSetHierarchyLink)">close</mat-icon>
      </mat-chip>
    </mat-chip-list>

    <div class="mt-3 w-80">
      <mat-form-field class="example-full-width w-full">
        <mat-label>Add Option Set Hierarchy Set</mat-label>
        <input
          type="text"
          placeholder="Pick one"
          aria-label="Option Set Hierarchies"
          matInput
          [formControl]="addHierachyFormControl"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option
            [matTooltip]="optionSetHierarchy.name.length > displayTypeOSHLengthLimit ? optionSetHierarchy.name : ''"
            *ngFor="let optionSetHierarchy of availableOptionSetHieararchies$ | async"
            [value]="optionSetHierarchy"
          >
            {{ optionSetHierarchy.name | shorten: displayTypeOSHLengthLimit }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </app-page-section-body>
</app-page-section>
