import { Component } from '@angular/core';
import { RoutingService } from 'src/app/common/routing/routing.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public orgSlug;
  constructor(private routingService: RoutingService) {}
  go(route) {
    this.routingService.go(route);
  }
}
