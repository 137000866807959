<div class="content w-full">
  <div class="px-8 py-3 w-full">
    <div class="header">
      <div class="flex items-center mb-4">
        <app-root-type-selector></app-root-type-selector>
        <ng-container *ngIf="subTypeable$ | async">
          <app-type-selector></app-type-selector>
          <app-menu name="" icon="add">
            <app-create-sub-type
              (cancelEdit)="cancelAddSubType()"
              (done)="completeAddSubType($event)"
            ></app-create-sub-type>
          </app-menu>
        </ng-container>
      </div>
      <div class="flex items-center">
        <div>
          <button mat-button (click)="init()">Init</button>
        </div>
      </div>
    </div>
    <div class="scrollable-content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-type-property-details-side-bar></app-type-property-details-side-bar>
</div>
