import { Component, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ConfirmationBoxService } from 'src/app/common/components/confirmation-box/confirmation-box';
import { MenuComponent } from 'src/app/common/components/menu/menu.component';
import { UpdateDialogService } from '@components/update-dialog/update-dialog-service';
import { RoutingService } from 'src/app/common/routing/routing.service';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerActions, TypeManagerSelectors } from '../type-manager-store';
import { OptionSetHierarchy } from '../type-manager-store/option-set-hierarchies/option-set-hierarchies.state';

@Component({
  selector: 'app-option-set-hierarchies',
  templateUrl: './option-set-hierarchies.component.html',
  styleUrls: ['./option-set-hierarchies.component.scss'],
})
export class OptionSetHierarchiesComponent {
  public optionSetHierarchies$: Observable<Array<OptionSetHierarchy>>;
  constructor(
    public store: Store<RootStoreState.State>,
    private routingService: RoutingService,
    private UpdateDialogService: UpdateDialogService,
    private confirmationBoxService: ConfirmationBoxService,
  ) {
    this.optionSetHierarchies$ = this.store.select(TypeManagerSelectors.optionSetHierarchies);
  }
  @ViewChild(MenuComponent) addEntityMenu: MenuComponent;

  cancelAdd() {
    this.addEntityMenu.panelVisible = false;
  }
  completeAdd(data: any) {
    this.addEntityMenu.panelVisible = false;
    const optionSetHierarchy = {
      ...data,
      identifier: data.name.replace(/[\s]/g, '-'),
    };
    this.store.dispatch(TypeManagerActions.createOptionSetHierarchy({ optionSetHierarchy }));
  }

  select(entity: OptionSetHierarchy) {
    this.routingService.go('type-manager/option-set-hierarchies/' + entity.id);
  }
  async delete(optionSetHierarchy: OptionSetHierarchy) {
    const confirm = await this.confirmationBoxService.open(
      'Delete Option Hierarchy?',
      'Are you sure you want to delete this option set hierarchy?',
    );
    if (confirm) {
      this.store.dispatch(TypeManagerActions.deleteOptionSetHierarchy({ optionSetHierarchy }));
      this.routingService.go('type-manager/option-set-hierarchies/');
    }
  }

  async edit(optionSetHierarchy: OptionSetHierarchy) {
    const updatedName = await this.UpdateDialogService.openDialog(
      'Rename Option Set Hierarchy',
      optionSetHierarchy.name,
    );
    if (updatedName) {
      const id = optionSetHierarchy.id;
      if (optionSetHierarchy.name === updatedName) {
        return;
      }
      this.store.dispatch(TypeManagerActions.updateOptionSetHierarchy({ id, changes: { name: updatedName } }));
    }
  }
}
