import { Component, EventEmitter, Input, OnChanges, OnInit, Output, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TypeProperty, Type, PropertyType, NumberFormatingOptions, NumberFormat } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-type-property-number-format',
  templateUrl: './type-property-number-format.component.html',
  styleUrls: ['./type-property-number-format.component.scss'],
})
export class TypePropertyNumberFormatComponent implements OnInit, OnChanges, OnDestroy {
  @Input() typeProperty: TypeProperty;
  @Input() type: Type;
  public enable = false;
  @Output() changes = new EventEmitter();
  public numberFormatControl = new UntypedFormControl();
  public decimalPlacesFormatControl = new UntypedFormControl();
  public currencyFormatControl = new UntypedFormControl();

  public numberFormatOptions = [];
  public decimalPlacesOptions = [];
  public currencyOptions = [
    'CAD',
    'CHF',
    'CNY',
    'DKK',
    'EUR',
    'GBP',
    'HKD',
    'JPY',
    'KRW',
    'NOK',
    'RUB',
    'SEK',
    'TWD',
    'USD',
  ];
  private subs: Array<Subscription> = [];
  constructor() {}
  ngOnInit() {
    this.subs.push(
      this.numberFormatControl.valueChanges.subscribe(() => {
        this.handleNumberFormatChange();
      }),
    );
    this.subs.push(
      this.decimalPlacesFormatControl.valueChanges.subscribe(() => {
        this.handleDecimaPlacesFormatChange();
      }),
    );
    this.subs.push(
      this.currencyFormatControl.valueChanges.subscribe(() => {
        this.handleCurrencyFormatChange();
      }),
    );
    // this.initNumberFormat();
    // this.initDecimalPlaces(this.numberFormatControl.value);
  }
  ngOnChanges() {
    this.initNumberFormat();
    this.initDecimalPlaces(this.numberFormatControl.value);
    this.initCurrency();
  }
  initDecimalPlaces(numberFormat) {
    if (
      [PropertyType.Formula, PropertyType.Currency, PropertyType.Number, PropertyType.Percent].includes(
        this.typeProperty?.propertyType,
      )
    ) {
      const numberFormatConfig: NumberFormatingOptions = this.typeProperty.numberFormat || {
        format: this.getDefaultFormat(),
      };
      this.decimalPlacesFormatControl.setValue(numberFormatConfig.precision);
    }

    if (numberFormat === NumberFormat.Decimal) {
      this.decimalPlacesOptions = [
        { label: '1', slug: 0 },
        { label: '1.0', slug: 1 },
        { label: '1.00', slug: 2 },
        { label: '1.000', slug: 3 },
        { label: '1.0000', slug: 4 },
      ];
    } else if (numberFormat === NumberFormat.Currency) {
      this.decimalPlacesOptions = [
        { label: '$1', slug: 0 },
        { label: '$1.0', slug: 1 },
        { label: '$1.00', slug: 2 },
        { label: '$1.000', slug: 3 },
        { label: '$1.0000', slug: 4 },
      ];
    } else if (numberFormat === NumberFormat.Percent) {
      this.decimalPlacesOptions = [
        { label: '1%', slug: 0 },
        { label: '1.0%', slug: 1 },
        { label: '1.00%', slug: 2 },
        { label: '1.000%', slug: 3 },
        { label: '1.0000%', slug: 4 },
      ];
    }
  }
  initNumberFormat() {
    this.enable = false;
    console.log('NumberFormatComponent: ', this.typeProperty);

    if (
      [PropertyType.Formula, PropertyType.Currency, PropertyType.Number, PropertyType.Percent].includes(
        this.typeProperty?.propertyType,
      )
    ) {
      this.enable = true;
      const numberFormatConfig: NumberFormatingOptions = this.typeProperty.numberFormat || {
        format: this.getDefaultFormat(),
      };
      this.numberFormatControl.setValue(numberFormatConfig.format);
    }
    if ([PropertyType.Formula].includes(this.typeProperty?.propertyType)) {
      this.numberFormatOptions = [
        { label: 'Currency', slug: NumberFormat.Currency },
        { label: 'Number', slug: NumberFormat.Decimal },
        { label: 'Percent', slug: NumberFormat.Percent },
      ];
    }
    if ([PropertyType.Currency].includes(this.typeProperty?.propertyType)) {
      this.numberFormatOptions = [{ label: 'Currency', slug: NumberFormat.Currency }];
    }
    if ([PropertyType.Number].includes(this.typeProperty?.propertyType)) {
      this.numberFormatOptions = [{ label: 'Number', slug: NumberFormat.Decimal }];
    }
    if ([PropertyType.Percent].includes(this.typeProperty?.propertyType)) {
      this.numberFormatOptions = [{ label: 'Percent', slug: NumberFormat.Percent }];
    }
  }

  initCurrency() {
    if ([PropertyType.Currency].includes(this.typeProperty?.propertyType)) {
      const currentCurrency = this.typeProperty.numberFormat?.currency || 'USD';
      this.currencyFormatControl.setValue(currentCurrency);
    }
  }
  getDefaultFormat() {
    switch (this.typeProperty?.propertyType) {
      case PropertyType.Formula:
        return NumberFormat.Decimal;
      case PropertyType.Currency:
        return NumberFormat.Currency;
      case PropertyType.Percent:
        return NumberFormat.Percent;
      case PropertyType.Number:
        return NumberFormat.Decimal;
      default:
        return NumberFormat.Decimal;
    }
  }
  ngOnDestroy() {
    this.subs?.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  handleNumberFormatChange() {
    const newValue = this.numberFormatControl?.value;
    const numberFormat = ObjectUtil.cloneDeep(this.typeProperty.numberFormat) || { format: null };
    console.log('newValue: ', newValue);
    console.log('numberFormat: ', numberFormat);
    console.log('this.typeProperty.numberFormat: ', this.typeProperty.numberFormat);
    if (!newValue || newValue === numberFormat?.format) {
      return;
    }

    numberFormat.format = newValue;
    const valueChanges = {
      numberFormat,
    };
    this.changes.emit(valueChanges);
  }

  handleDecimaPlacesFormatChange() {
    const newValue = this.decimalPlacesFormatControl?.value;
    const numberFormat = ObjectUtil.cloneDeep(this.typeProperty.numberFormat) || { format: null };
    if (newValue === numberFormat?.precision) {
      return;
    }

    numberFormat.precision = newValue;
    const valueChanges = {
      numberFormat,
    };
    this.changes.emit(valueChanges);
  }

  handleCurrencyFormatChange() {
    const newValue = this.currencyFormatControl?.value;
    const numberFormat = ObjectUtil.cloneDeep(this.typeProperty.numberFormat) || { format: null };
    if (newValue === numberFormat?.currency) {
      return;
    }

    numberFormat.currency = newValue;
    const valueChanges = {
      numberFormat,
    };
    this.changes.emit(valueChanges);
  }
}
