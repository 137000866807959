import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TypeProperty } from '@contrail/types';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerSelectors } from '../../type-manager-store';

@Component({
  selector: 'app-type-property-details-side-bar',
  templateUrl: './type-property-details-side-bar.component.html',
  styleUrls: ['./type-property-details-side-bar.component.scss'],
})
export class TypePropertyDetailsSideBarComponent {
  public visible = false;
  public currentProperty$: Observable<TypeProperty>;
  constructor(private store: Store<RootStoreState.State>) {
    this.currentProperty$ = this.store.select(TypeManagerSelectors.currentTypeProperty);
  }
}
