import { createAction, props } from '@ngrx/store';
import { TypeRuleSet } from './type-rule-sets.state';

export enum TypeRuleSetsActionTypes {
  LOAD_TYPE_RULE_SETS = '[Type Manager -Type Rule Sets] Load Type Rule Sets',
  LOAD_TYPE_RULE_SETS_SUCCESS = '[Type Manager -Type Rule Sets] Load Type Rule Sets Success',
  LOAD_TYPE_RULE_SETS_FAILURE = '[Type Manager -Type Rule Sets] Load Type Rule Sets Failure',

  CREATE_TYPE_RULE_SET = '[Type Manager -Type Rule Sets] Create Type Rule Set',
  CREATE_TYPE_RULE_SET_SUCCESS = '[Type Manager -Type Rule Sets] Create Type Rule Set Success',
  CREATE_TYPE_RULE_SET_FAILURE = '[Type Manager -Type Rule Sets] Create Type Rule Set Failure',

  DELETE_TYPE_RULE_SET = '[Type Manager -Type Rule Sets] Delete Type Rule Set',
  DELETE_TYPE_RULE_SET_SUCCESS = '[Type Manager -Type Rule Sets] Delete Type Rule Set Success',
  DELETE_TYPE_RULE_SET_FAILURE = '[Type Manager -Type Rule Sets] Delete Type Rule Set Failure',

  UPDATE_TYPE_RULE_SET = '[Type Manager -Type Rule Sets] Update Type Rule Set',
  UPDATE_TYPE_RULE_SET_SUCCESS = '[Type Manager -Type Rule Sets] Update Type Rule Set Success',
  UPDATE_TYPE_RULE_SET_FAILURE = '[Type Manager -Type Rule Sets] Update Type Rule Set Failure',
}

/// /////////////////////////////////////////////////////////////////////////////

export const loadTypeRuleSets = createAction(TypeRuleSetsActionTypes.LOAD_TYPE_RULE_SETS);

export const loadTypeRuleSetsSuccess = createAction(
  TypeRuleSetsActionTypes.LOAD_TYPE_RULE_SETS_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const loadTypeRuleSetsFailure = createAction(
  TypeRuleSetsActionTypes.LOAD_TYPE_RULE_SETS_FAILURE,
  props<{ error: any }>(),
);

export const createTypeRuleSet = createAction(
  TypeRuleSetsActionTypes.CREATE_TYPE_RULE_SET,
  props<{ typeRuleSet: TypeRuleSet }>(),
);

export const createTypeRuleSetSuccess = createAction(
  TypeRuleSetsActionTypes.CREATE_TYPE_RULE_SET_SUCCESS,
  props<{ typeRuleSet: TypeRuleSet }>(),
);

export const createTypeRuleSetFailure = createAction(
  TypeRuleSetsActionTypes.CREATE_TYPE_RULE_SET_FAILURE,
  props<{ error: any }>(),
);
export const deleteTypeRuleSet = createAction(
  TypeRuleSetsActionTypes.DELETE_TYPE_RULE_SET,
  props<{ typeRuleSet: TypeRuleSet }>(),
);

export const deleteTypeRuleSetSuccess = createAction(
  TypeRuleSetsActionTypes.DELETE_TYPE_RULE_SET_SUCCESS,
  props<{ typeRuleSet: TypeRuleSet }>(),
);

export const deleteTypeRuleSetFailure = createAction(
  TypeRuleSetsActionTypes.DELETE_TYPE_RULE_SET_FAILURE,
  props<{ error: any }>(),
);

export const updateTypeRuleSet = createAction(
  TypeRuleSetsActionTypes.UPDATE_TYPE_RULE_SET,
  props<{ id: string; changes: TypeRuleSet }>(),
);

export const updateTypeRuleSetSuccess = createAction(
  TypeRuleSetsActionTypes.UPDATE_TYPE_RULE_SET_SUCCESS,
  props<{ id: string; changes: TypeRuleSet }>(),
);

export const updateTypeRuleSetFailure = createAction(
  TypeRuleSetsActionTypes.UPDATE_TYPE_RULE_SET_FAILURE,
  props<{ error: any }>(),
);
