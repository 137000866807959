import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { typePropertyUserListsReducers } from './user-list.reducers';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('userList', typePropertyUserListsReducers)],
})
export class TypePropertyUserListsStoreModule {}
