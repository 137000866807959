import { Entities } from '@contrail/sdk';
import { ObjectUtil } from '@contrail/util';
import { Type } from './type';

export class Entity {
  private _entity: any;
  private _type: Type;
  public properties: any;
  public loaded = false;
  public _changes: Array<any> = [];
  constructor(
    private entityType: string,
    private id: string,
    private relations = [],
  ) {}

  async load() {
    this._entity = await new Entities().get({ entityName: this.entityType, id: this.id, relations: this.relations });

    this.properties = Object.assign({}, this._entity);
    this._type = new Type(this._entity.typeId, this.entityType);
    await this._type.load();
    this.loaded = true;
    return this;
  }
  public async update() {
    const changes: any = {};
    this._changes.forEach((change) => {
      ObjectUtil.setByPath(changes, change.key, change.value);
    });
    console.log('Entity: update: changes ', changes);
    this._entity = await new Entities().update({ entityName: this.entityType, id: this.id, object: changes });
    this._changes = [];
  }
  public getEntityType() {
    return this.entityType;
  }
  public getId() {
    return this.id;
  }
  public getEntityReference() {
    return this.entityType + ':' + this.id;
  }
  public getType(): Type {
    return this._type;
  }

  public getRaw() {
    return this._entity;
  }

  public implements(interfaceName: string): boolean {
    if (!this._type) {
      return;
    }
    return this._type.implements(interfaceName);
  }

  public save() {
    this._entity = new Entities().update({ entityName: this.entityType, id: this.id, object: this._entity });
  }

  public setValue(key, value) {
    const currentValue = ObjectUtil.getByPath(this._entity, key);
    if (currentValue === value) {
      return;
    }
    ObjectUtil.setByPath(this._entity, key, value);
    this._changes.push({ key, value });
  }
  public getValue(key, defaultValue = null) {
    return ObjectUtil.getByPath(this._entity, key, defaultValue);
  }

  public async loadRelationships(relationship: string, subrelationships: Array<string> = []) {
    const allRelations = subrelationships.concat(relationship);
    const reload = await new Entities().get({ entityName: this.entityType, id: this.id, relations: allRelations });
    this._entity[relationship] = reload[relationship];
  }
}
