import { Component } from '@angular/core';

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss'],
})
export class LeftSidebarComponent {
  expanded = true;
  constructor() {}

  changeNavView() {
    this.expanded = !this.expanded;
  }
}
