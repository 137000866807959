import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerActions } from '..';

import { TypesService } from '../../types.service';
import { TypeRuleSetsService } from './type-rule-sets.service';
import { TypeService } from '../../type.service';

@Injectable()
export class TypeRuleSetsEffects {
  constructor(
    private actions$: Actions,
    private typeRuleSetService: TypeRuleSetsService,
    public typesService: TypesService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
    private typeService: TypeService,
  ) {}

  loadTypeRuleSets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.TypeRuleSetsActionTypes.LOAD_TYPE_RULE_SETS),
      withLatestFrom(this.store),
      switchMap(([]: [any, RootStoreState.State]) =>
        from(this.typeRuleSetService.get()).pipe(
          map((data) => TypeManagerActions.loadTypeRuleSetsSuccess({ data })),
          catchError((error) => observableOf(TypeManagerActions.loadTypeRuleSetsFailure({ error }))),
        ),
      ),
    ),
  );
  createTypeRuleSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.TypeRuleSetsActionTypes.CREATE_TYPE_RULE_SET),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.typeRuleSetService.create(action.typeRuleSet)).pipe(
          map((data) => {
            this.snackBar.open('Rule Set created.', '', { duration: 2000 });
            this.store.dispatch(TypeManagerActions.setCurrentTypeRuleSet({ currentTypeRuleSet: data }));
            return TypeManagerActions.createTypeRuleSetSuccess({ typeRuleSet: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(TypeManagerActions.createTypeRuleSetFailure({ error }));
          }),
        ),
      ),
    ),
  );
  deleteTypeRuleSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.TypeRuleSetsActionTypes.DELETE_TYPE_RULE_SET),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.typeRuleSetService.delete(action.typeRuleSet)).pipe(
          map((data) => {
            this.snackBar.open('Rule Set deleted.', '', { duration: 2000 });
            return TypeManagerActions.deleteTypeRuleSetSuccess({ typeRuleSet: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(TypeManagerActions.deleteTypeRuleSetFailure({ error }));
          }),
        ),
      ),
    ),
  );
  updateTypeRuleSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TypeManagerActions.TypeRuleSetsActionTypes.UPDATE_TYPE_RULE_SET),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.typeRuleSetService.update(action.id, action.changes)).pipe(
          map((data) => {
            this.snackBar.open('Rule Set updated.', '', { duration: 2000 });
            this.typeService.updateEntity(data);
            return TypeManagerActions.updateTypeRuleSetSuccess({ id: data.id, changes: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(TypeManagerActions.updateTypeRuleSetFailure({ error }));
          }),
        ),
      ),
    ),
  );
}
