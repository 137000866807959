import { Action, createReducer, on } from '@ngrx/store';
import { LibraryEntity, State, libraryEntityAdapter, libraryInitialState } from './library.state';
import * as LibraryActions from './library.actions';

const setData = (state: State, { entities }) => {
  return {
    ...state,
    libraryEntities: libraryEntityAdapter.setAll(entities, state.libraryEntities),
  };
};

const add = (state: State, entity: LibraryEntity) => {
  return {
    ...state,
    libraryEntities: libraryEntityAdapter.addOne(entity, state.libraryEntities),
  };
};

const update = (state: State, { id, changes }) => {
  return {
    ...state,
    libraryEntities: libraryEntityAdapter.updateOne({ id, changes }, state.libraryEntities),
  };
};

export const libraryReducers = createReducer(
  libraryInitialState,
  on(LibraryActions.setLibraryEntities, setData),
  on(LibraryActions.addLibraryEntity, add),
  on(LibraryActions.updateLibraryEntity, update),
);

export function reducer(state: State | undefined, action: Action): any {
  return libraryReducers(state, action);
}
