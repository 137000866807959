import { on } from '@ngrx/store';
import { State } from '../type-manager.state';
import * as TypePropertyOptionSetsAction from './option-sets.actions';
import { typePropertyOptionSetsEntityAdapter } from './option-sets.state';

const setData = (state: State, { data }) => ({
  ...state,
  typePropertyOptionSets: typePropertyOptionSetsEntityAdapter.setAll(data, state.typePropertyOptionSets),
});
const add = (state: State, { typePropertyOptionSet }) => ({
  ...state,
  typePropertyOptionSets: typePropertyOptionSetsEntityAdapter.addOne(
    typePropertyOptionSet,
    state.typePropertyOptionSets,
  ),
});
const update = (state: State, { id, changes }) => ({
  ...state,
  typePropertyOptionSets: typePropertyOptionSetsEntityAdapter.updateOne({ id, changes }, state.typePropertyOptionSets),
});
const remove = (state: State, { typePropertyOptionSet }) => ({
  ...state,
  typePropertyOptionSets: typePropertyOptionSetsEntityAdapter.removeOne(
    typePropertyOptionSet.id,
    state.typePropertyOptionSets,
  ),
});

export const typePropertyOptionSetsReducer = [
  on(TypePropertyOptionSetsAction.loadTypePropertyOptionSetsSuccess, setData),
  on(TypePropertyOptionSetsAction.createTypePropertyOptionSetSuccess, add),
  on(TypePropertyOptionSetsAction.deleteTypePropertyOptionSetSuccess, remove),
  on(TypePropertyOptionSetsAction.updateTypePropertyOptionSet, update),
];
