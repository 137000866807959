import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PropertyType, Type, TypeProperty } from '@contrail/types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-type-property-uniqueness',
  templateUrl: './type-property-uniqueness.component.html',
  styleUrls: ['./type-property-uniqueness.component.scss'],
})
export class TypePropertyUniquenessComponent implements OnChanges, OnInit, OnDestroy {
  @Input() typeProperty: TypeProperty;
  @Input() type: Type;
  @Output() changes = new EventEmitter();
  public visible = false;
  public isUniqueInFamilyDisabled = false;
  public uniquenessFormControl = new UntypedFormControl();
  public familyUniquenessFormControl = new UntypedFormControl();
  private subscriptions: Array<Subscription> = [];
  constructor() {}

  ngOnInit() {
    this.subscriptions.push(
      this.uniquenessFormControl.valueChanges.subscribe((value) => {
        if (value !== this.typeProperty.isUniqueProperty) {
          if (value === true && this.typeProperty.isUniquePropertyInFamily === true) {
            this.changes.emit({ isUniqueProperty: value, isUniquePropertyInFamily: false });
          } else {
            this.changes.emit({ isUniqueProperty: value });
          }
        }
      }),
    );

    this.subscriptions.push(
      this.familyUniquenessFormControl.valueChanges.subscribe((value) => {
        if (value !== this.typeProperty.isUniquePropertyInFamily) {
          if (value === true && this.typeProperty.isUniqueProperty === true) {
            this.changes.emit({ isUniquePropertyInFamily: value, isUniqueProperty: false });
          } else {
            this.changes.emit({ isUniquePropertyInFamily: value });
          }
        }
      }),
    );

    this.visible = false;
    const canBeUnique = this.type.typePath.startsWith('item') || this.type.typePath.startsWith('project-item');
    if (
      [
        PropertyType.Text,
        PropertyType.String,
        PropertyType.Number,
        PropertyType.Formula,
        PropertyType.ObjectReference,
      ].includes(this.typeProperty.propertyType) &&
      canBeUnique
    ) {
      this.visible = true;
    }
  }

  ngOnChanges(): void {
    this.visible = false;
    const canBeUnique = this.type.typePath.startsWith('item') || this.type.typePath.startsWith('project-item');
    if (
      [
        PropertyType.Text,
        PropertyType.String,
        PropertyType.Number,
        PropertyType.Formula,
        PropertyType.ObjectReference,
      ].includes(this.typeProperty.propertyType) &&
      canBeUnique
    ) {
      this.visible = true;
    }

    this.isUniqueInFamilyDisabled = ['family', 'overridable'].includes(this.typeProperty.propertyLevel);
    this.uniquenessFormControl.setValue(this.typeProperty.isUniqueProperty);

    if (this.isUniqueInFamilyDisabled && this.typeProperty.isUniquePropertyInFamily) {
      this.changes.emit({ isUniquePropertyInFamily: false, isUniqueProperty: this.typeProperty.isUniqueProperty });
    } else {
      this.familyUniquenessFormControl.setValue(this.typeProperty.isUniquePropertyInFamily);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub?.unsubscribe());
  }
}
