import { SizeRange, TwoDimensionSizeRange } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { Subject, BehaviorSubject, Observable } from 'rxjs';

export interface SizeRangeAction {
  dimension1: string;
  dimension2?: string;
  actionType: 'click' | 'mousedown' | 'mouseup' | 'mouseenter';
}

export interface SizeRangeCell {
  dimension1: string;
  dimension2?: string;
}

export class SizeRangeEditorManager {
  startColumnIndex = -1;
  startRowIndex = -1;
  dimensionCells1: string[];
  dimensionCells2: string[];
  selectableCells: Array<SizeRangeCell>;
  selectingCellInProgress = false;
  selectedCellRange: any; // cell range selected by dragging
  selectedSizeRangeCells: Array<SizeRangeCell>;
  sizeRangeData: SizeRange; // current Size Range value
  private sizeRangeDataSubject: Subject<SizeRange> = new BehaviorSubject(null);
  public sizeRangeData$: Observable<SizeRange> = this.sizeRangeDataSubject.asObservable();
  private selectedSizeRangeCellsSubject: Subject<Array<SizeRangeCell>> = new BehaviorSubject(null);
  public selectedSizeRangeCells$: Observable<Array<SizeRangeCell>> = this.selectedSizeRangeCellsSubject.asObservable();
  constructor() {}

  constructGrid(sizeRangeTemplate: SizeRange, sizeRange: SizeRange) {
    if (sizeRangeTemplate) {
      this.selectableCells = [];
      this.sizeRangeData = ObjectUtil.cloneDeep(sizeRange);
      if (Array.isArray(sizeRangeTemplate.sizes)) {
        this.dimensionCells1 = ObjectUtil.cloneDeep(sizeRangeTemplate.sizes);
        this.dimensionCells1.forEach((size) => {
          this.selectableCells.push({ dimension1: size });
        });
      } else {
        const dimensionCellSet1: Set<string> = new Set();
        this.dimensionCells2 = Object.keys(sizeRangeTemplate.sizes);
        this.dimensionCells2.forEach((size) => {
          sizeRangeTemplate.sizes[size].forEach((innerSize) => {
            dimensionCellSet1.add(innerSize);
            this.selectableCells.push({ dimension1: innerSize, dimension2: size });
          });
        });
        this.dimensionCells1 = Array.from(dimensionCellSet1);
      }
      this.sizeRangeDataSubject.next(ObjectUtil.cloneDeep(this.sizeRangeData));
    }
  }

  setSizeRangeData(sizeRangeData: SizeRange) {
    this.sizeRangeData = ObjectUtil.cloneDeep(sizeRangeData);
    this.sizeRangeDataSubject.next(ObjectUtil.cloneDeep(this.sizeRangeData));
  }

  handleAction(action: SizeRangeAction) {
    if (action.actionType === 'mousedown') {
      this.selectingCellInProgress = true;
      this.startColumnIndex = this.dimensionCells1.findIndex((cell) => cell === action.dimension1);
      if (action.dimension2) {
        this.startRowIndex = this.dimensionCells2.findIndex((cell) => cell === action.dimension2);
      }
    } else if (action.actionType === 'mouseenter') {
      this.handleSelectCells(action.dimension1, action.dimension2);
    } else if (action.actionType === 'mouseup') {
      setTimeout(() => {
        // setTimeout to process after 'click' event
        if (this.selectingCellInProgress) {
          this.selectingCellInProgress = false;
          this.selectedCellRange = [];
          this.selectedSizeRangeCellsSubject.next([]);
          this.calculateSizeRange();
        }
      }, 1);
    } else if (action.actionType === 'click') {
      this.handleClick(action);
    }
  }

  handleSelectCells(dimension1: string, dimension2?: string) {
    if (!this.selectingCellInProgress) {
      return;
    }
    const columnIndex = this.dimensionCells1.findIndex((cell) => cell === dimension1);
    let rowIndex = -1;
    if (dimension2) {
      rowIndex = this.dimensionCells2.findIndex((cell) => cell === dimension2);
    }

    this.selectedCellRange = {
      columnStartIndex: Math.min(columnIndex, this.startColumnIndex),
      columnEndIndex: Math.max(columnIndex, this.startColumnIndex),
      rowStartIndex: Math.min(rowIndex, this.startRowIndex),
      rowEndIndex: Math.max(rowIndex, this.startRowIndex),
    };
    this.selectedSizeRangeCells = [];
    if (this.selectedCellRange.rowStartIndex > -1) {
      for (let j = this.selectedCellRange.rowStartIndex; j <= this.selectedCellRange.rowEndIndex; j++) {
        for (let i = this.selectedCellRange.columnStartIndex; i <= this.selectedCellRange.columnEndIndex; i++) {
          if (
            this.selectableCells.findIndex(
              (cell) => cell.dimension1 === this.dimensionCells1[i] && cell.dimension2 === this.dimensionCells2[j],
            ) > -1
          ) {
            this.selectedSizeRangeCells.push({
              dimension1: this.dimensionCells1[i],
              dimension2: this.dimensionCells2[j],
            });
          }
        }
      }
    } else {
      for (let i = this.selectedCellRange.columnStartIndex; i <= this.selectedCellRange.columnEndIndex; i++) {
        this.selectedSizeRangeCells.push({ dimension1: this.dimensionCells1[i] });
      }
    }
    this.selectedSizeRangeCellsSubject.next(ObjectUtil.cloneDeep(this.selectedSizeRangeCells));
  }

  private calculateSizeRange() {
    let newSizes: any;
    const selectedSizeRangeCells: Array<SizeRangeCell> = ObjectUtil.cloneDeep(this.selectedSizeRangeCells);
    if (Array.isArray(this.sizeRangeData.sizes)) {
      this.sizeRangeData.sizes.forEach((dimension1) => {
        const index = selectedSizeRangeCells.findIndex((cell) => cell.dimension1 === dimension1);
        if (index === -1) {
          selectedSizeRangeCells.push({ dimension1 });
        } else {
          selectedSizeRangeCells.splice(index, 1); // remove previously selected sizes
        }
      });
    } else {
      Object.keys(this.sizeRangeData.sizes).forEach((dimension2) => {
        this.sizeRangeData.sizes[dimension2].forEach((dimension1) => {
          const index = selectedSizeRangeCells.findIndex(
            (cell) => cell.dimension1 === dimension1 && cell.dimension2 === dimension2,
          );
          if (index === -1) {
            selectedSizeRangeCells.push({ dimension1, dimension2 });
          } else {
            selectedSizeRangeCells.splice(index, 1); // remove previously selected sizes
          }
        });
      });
    }
    if (this.dimensionCells2) {
      // 2-dimensional
      newSizes = {};
      this.dimensionCells2.forEach((dimensionCell2) => {
        newSizes[dimensionCell2] = [];
        if (selectedSizeRangeCells.findIndex((cell) => cell.dimension2 === dimensionCell2) > -1) {
          this.dimensionCells1.forEach((dimensionCell1) => {
            if (
              selectedSizeRangeCells.findIndex(
                (cell) => cell.dimension1 === dimensionCell1 && cell.dimension2 === dimensionCell2,
              ) > -1
            ) {
              newSizes[dimensionCell2].push(dimensionCell1);
            }
          });
        }
      });
    } else {
      // 1-dimensional
      newSizes = [];
      this.dimensionCells1.forEach((dimensionCell1) => {
        if (selectedSizeRangeCells.findIndex((cell) => cell.dimension1 === dimensionCell1) > -1) {
          newSizes.push(dimensionCell1);
        }
      });
    }
    this.sizeRangeData.sizes = newSizes;
    this.sizeRangeDataSubject.next(ObjectUtil.cloneDeep(this.sizeRangeData));
  }

  private handleClick(action: SizeRangeAction) {
    this.selectingCellInProgress = false;
    if (
      this.selectableCells.findIndex(
        (cell) => cell.dimension1 === action.dimension1 && cell.dimension2 === action.dimension2,
      ) === -1
    ) {
      return;
    }
    if (Array.isArray(this.sizeRangeData.sizes)) {
      // 1-dimensional
      const sizes: string[] = ObjectUtil.cloneDeep(this.sizeRangeData.sizes);
      if (sizes?.includes(action.dimension1)) {
        const index = this.sizeRangeData.sizes.indexOf(action.dimension1);
        this.sizeRangeData.sizes.splice(index, 1);
      } else {
        if (!sizes) {
          this.sizeRangeData.sizes = [];
        }
        this.sizeRangeData.sizes.push(action.dimension1);
        this.sortDimension1(this.sizeRangeData.sizes, this.dimensionCells1);
      }
    } else {
      // 2-dimensional
      if (!this.sizeRangeData.sizes[action.dimension2] || this.sizeRangeData.sizes[action.dimension2].length === 0) {
        this.sizeRangeData.sizes[action.dimension2] = [action.dimension1];
        this.sortDimension2();
      } else {
        if (this.sizeRangeData.sizes[action.dimension2].includes(action.dimension1)) {
          const index = this.sizeRangeData.sizes[action.dimension2].indexOf(action.dimension1);
          this.sizeRangeData.sizes[action.dimension2].splice(index, 1);
        } else {
          this.sizeRangeData.sizes[action.dimension2].push(action.dimension1);
          this.sortDimension1(this.sizeRangeData.sizes[action.dimension2], this.dimensionCells1);
        }
      }
    }
    this.sizeRangeDataSubject.next(ObjectUtil.cloneDeep(this.sizeRangeData));
  }

  private sortDimension1(array: string[], sortByArray: string[]) {
    array.sort((a, b) => {
      return sortByArray.indexOf(a) - sortByArray.indexOf(b);
    });
  }

  private sortDimension2() {
    const sortedSizes: TwoDimensionSizeRange = {};
    this.dimensionCells2.forEach((size) => {
      sortedSizes[size] = ObjectUtil.cloneDeep(this.sizeRangeData.sizes[size]);
    });
    this.sizeRangeData.sizes = sortedSizes;
  }
}
