import { Component } from '@angular/core';
import { Relation } from '@contrail/types/lib/relations/relation';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerSelectors } from '../../type-manager-store';
import { TypesService } from '../../types.service';

@Component({
  selector: 'app-type-relationships',
  templateUrl: './type-relationships.component.html',
  styleUrls: ['./type-relationships.component.scss'],
})
export class TypeRelationshipsComponent {
  public relations$: Observable<Array<Relation>>;
  constructor(
    private typeService: TypesService,
    private store: Store<RootStoreState.State>,
  ) {
    this.relations$ = this.store
      .select(TypeManagerSelectors.currentType)
      .pipe(map((type) => Object.values(type?.relations)));
  }
  getIconForCardinality(cardinality: string) {
    switch (cardinality) {
      case 'N': {
        return 'filter_none';
      }
      case '1': {
        return 'arrow_right_alt';
      }
      case '0_1': {
        return 'arrow_right_alt';
      }
    }
    return;
  }
  getLabelForEntitySlug(slug): string {
    return this.typeService.getLabelForEntitySlug(slug);
  }
}
