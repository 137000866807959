import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shorten',
})
export class ShortenPipe implements PipeTransform {
  transform(value: any, limit: number) {
    if (value !== null && value !== 'undefined' && value?.length > 0 && value?.length > limit) {
      return value.length < limit ? value : value.slice(0, limit) + '...';
    }
    return value;
  }
}
