import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TypePropertyFormConfiguration } from '../../type-property-form/type-property-form.component';
import { TypePropertyFormHelper } from './type-property-form-helper';
import { NumberFormat, PropertyType } from '@contrail/types';

@Component({ template: '' })
export class TypePropertyFormFieldBaseComponent implements OnInit, OnChanges {
  @Input() propertyFormConfiguration: TypePropertyFormConfiguration;
  @Input() value: any;
  @Input() entity: any;
  @Input() errorMessage: any;
  @Input() hint: string;
  @Input() appearance = 'fill';
  @Input() showSearchBar: boolean = false;
  @Input() showSelectAll: boolean = false;
  @Output() valueChange = new EventEmitter<{ value }>();

  public formControl: UntypedFormControl;
  protected formChangeSub: Subscription;
  public precision: number;

  constructor() {}

  ngOnInit() {
    if (this.propertyFormConfiguration.isFilter) {
      this.init();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.errorMessage || changes.entity || changes.propertyFormConfiguration) {
      this.init();
    }
  }

  handleChange() {
    if (!this.propertyFormConfiguration) {
      return;
    }
    this.valueChange.emit({ value: this.value });
  }

  async init() {
    await this.initFormControl();
    this.showErrorsIfExist();
    this.initComponent();
  }

  async initFormControl() {
    const disabled = await this.isDisabled();
    const isValueVisible = await this.isValueVisible();
    const validators = [];
    if (this.propertyFormConfiguration.typeProperty.required) {
      validators.push(Validators.required);
    }
    if (this.propertyFormConfiguration.typeProperty.propertyType === PropertyType.Date) {
      if (this.value instanceof Date) {
        this.value = this.value.toISOString();
      }
      // NEUTRALIZE TIMEZONE
      if (typeof this.value === 'string') {
        if (this.value.endsWith('Z')) {
          this.value = this.value.slice(0, -1);
        }
      }
    }

    this.precision = this.propertyFormConfiguration.typeProperty.numberFormat?.precision;

    const isNumericProperty = [PropertyType.Number, PropertyType.Currency].includes(
      this.propertyFormConfiguration.typeProperty.propertyType,
    );
    const isNumericFormula = Boolean(
      this.propertyFormConfiguration?.typeProperty.propertyType === PropertyType.Formula &&
        this.propertyFormConfiguration?.typeProperty.numberFormat?.format !== NumberFormat.Percent,
    );

    if (isNumericProperty || isNumericFormula) {
      const shouldSetPrecisionOnValue = Boolean(typeof this.precision === 'number' && typeof this.value === 'number');
      this.value = shouldSetPrecisionOnValue ? this.value.toFixed(this.precision) : this.value;
    }

    this.formControl = new UntypedFormControl(
      {
        value: isValueVisible ? this.value : null,
        disabled,
      },
      {
        validators,
        updateOn: 'blur',
      },
    );
    this.initFormControlListener();
  }

  initFormControlListener() {
    this.formChangeSub = this.formControl.valueChanges
      .pipe(
        tap((val: any) => {
          if (val !== this.value) {
            this.value = val;
            this.handleChange();
          }
        }),
      )
      .subscribe();
  }

  showErrorsIfExist() {
    if (!this.formControl) {
      return;
    }
    if (this.errorMessage) {
      setTimeout(() => {
        this.formControl.setErrors({ error: this.errorMessage });
        this.formControl.markAsTouched();
      }, 1);
    }
  }

  onDestroy() {
    this.formChangeSub?.unsubscribe();
  }

  clearValue() {
    this.formControl?.setValue(this.value);
  }

  initComponent() {}

  async isDisabled() {
    const disabled = await TypePropertyFormHelper.isDisabled(this.propertyFormConfiguration, this.entity);
    return disabled;
  }

  async isValueVisible() {
    const visible = await TypePropertyFormHelper.isValueVisible(this.propertyFormConfiguration, this.entity);
    return visible;
  }

  /** Answers if this form element is invalid.  Checks for the state of
   * the formContol and also checks for error messages.
   */
  isInvalid(): boolean {
    return this.formControl.invalid || this.errorMessage;
  }
}
