import { TypePropertyOption } from '@contrail/types';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface TypePropertyOptionSet {
  id?: string;
  name?: string;
  slug?: string;
  optionSet?: Array<TypePropertyOption>;
}

export interface TypePropertyOptionSetsState extends EntityState<TypePropertyOptionSet> {}
export const typePropertyOptionSetsEntityAdapter: EntityAdapter<TypePropertyOptionSet> =
  createEntityAdapter<TypePropertyOptionSet>({});

export const typePropertyOptionSetsInitialState = typePropertyOptionSetsEntityAdapter.getInitialState({});
