import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Entities } from '@contrail/sdk';
import { Type } from '@contrail/types';
import { Observable, Subscription, from } from 'rxjs';
import { TypeRuleSetsService } from '../../type-manager-store/type-rule-sets/type-rule-sets.service';

@Component({
  selector: 'app-type-type-rules',
  templateUrl: './type-type-rules.component.html',
  styleUrls: ['./type-type-rules.component.scss'],
})
export class TypeTypeRulesComponent implements OnChanges, OnDestroy, OnInit {
  @Input() type: Type;
  public availableTypeRuleSets$: Observable<Array<any>>;
  public addRuleSetFormControl = new UntypedFormControl();
  private typeRuleSetFormControlSub: Subscription;
  public ruleSetLinks: Array<any>;
  public displayTypeRuleSetLengthLimit = 35;

  constructor(private typeRuleSetService: TypeRuleSetsService) {}

  ngOnInit() {
    this.typeRuleSetFormControlSub = this.addRuleSetFormControl.valueChanges.subscribe((ruleSet) => {
      if (ruleSet) {
        this.addRuleSet(ruleSet);
        this.addRuleSetFormControl.reset();
      }
    });
  }
  async ngOnChanges() {
    if (!this.type) {
      return;
    }
    this.ruleSetLinks = await new Entities().get({
      entityName: 'type-rule-set-link',
      criteria: { typeId: this.type.id },
      relations: ['typeRuleSet'],
    });
    this.availableTypeRuleSets$ = from(this.typeRuleSetService.get());
  }
  async removeRuleSet(ruleSetLink) {
    // PREEMPT THE DELETE.
    const filteredAssigned = this.ruleSetLinks.filter((l) => l.id !== ruleSetLink.id);
    this.ruleSetLinks = filteredAssigned;

    await new Entities().delete({
      entityName: 'type-rule-set-link',
      id: ruleSetLink.id,
    });
  }

  async addRuleSet(ruleSet: any) {
    const existing = this.ruleSetLinks.find((p) => p.typeRuleSetId === ruleSet.id);
    if (existing) {
      return;
    }
    console.log('Adding rule set: ', ruleSet, this.ruleSetLinks.length);
    const link = await new Entities().create({
      entityName: 'type-rule-set-link',
      object: { typeRuleSetId: ruleSet.id, typeId: this.type.id },
    });
    link.typeRuleSet = ruleSet;
    this.ruleSetLinks.push(link);
    console.log('Added!!!! rule set: ', ruleSet, this.ruleSetLinks.length);
  }
  displayFn(policy: any): string {
    return policy?.label;
  }

  ngOnDestroy(): void {
    this.typeRuleSetFormControlSub?.unsubscribe();
  }
}
