<div class="property-form">
  <app-menu name="" icon="add" position="left">
    <app-type-property-chooser
      *ngIf="mode === 'ADD_EXISTING'"
      (propertySelected)="handlePropertySelected($event)"
    ></app-type-property-chooser>
    <app-type-property-create-form
      *ngIf="mode === 'ADD_NEW'"
      (cancelEdit)="cancelAdd()"
      (done)="completeAdd($event)"
    ></app-type-property-create-form>
  </app-menu>
</div>
