import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { OptionSetHierarchy, OptionSetHierarchyHelper, TypePropertyOptionSet } from '@contrail/types';
import { Subscription } from 'rxjs';
import { TypePropertyOptionSetsService } from '../../type-property-option-sets/type-property-option-sets.service';

@Component({
  selector: 'app-option-set-hierarchy-form',
  templateUrl: './option-set-hierarchy-form.component.html',
  styleUrls: ['./option-set-hierarchy-form.component.scss'],
})
export class OptionSetHierarchyFormComponent implements OnInit {
  @Output() cancelEdit = new EventEmitter();
  @Output() done = new EventEmitter();
  public nameField = new UntypedFormControl('', Validators.required);
  public allOptionSets: Array<TypePropertyOptionSet>;
  public availableOptionSets: Array<TypePropertyOptionSet>;
  public selectedOptionSets = [];
  public addOptionSetFormControl = new UntypedFormControl();
  private addOptionSetFormControlSub: Subscription;
  private optionSetHierarchy: OptionSetHierarchy;

  constructor(private optionSetsService: TypePropertyOptionSetsService) {}
  async ngOnInit() {
    this.allOptionSets = await this.optionSetsService.getOptionSets();
    this.availableOptionSets = [...this.allOptionSets];
    this.addOptionSetFormControlSub = this.addOptionSetFormControl.valueChanges.subscribe((set) => {
      if (set) {
        this.addOptionSet(set);
        this.addOptionSetFormControl.reset();
      }
    });
  }

  async completeEdit() {
    this.optionSetHierarchy.name = this.nameField.value;
    this.done.emit(this.optionSetHierarchy);
    this.nameField.reset();
  }

  cancel() {
    this.cancelEdit.emit({});
  }

  isValid() {
    const valid = !this.nameField.invalid;
    return valid;
  }

  addOptionSet(optionSet: any) {
    this.selectedOptionSets.push(optionSet);
    this.optionSetHierarchy = OptionSetHierarchyHelper.computeFullOptionSetHierchy(this.selectedOptionSets, 2);
    console.log(this.optionSetHierarchy);
    this.computeAvailableOptionSets();
  }
  removeOptionSet(optionSet: any) {
    this.selectedOptionSets = this.selectedOptionSets.filter((o) => o.id !== optionSet.id);
    this.computeAvailableOptionSets();
  }
  computeAvailableOptionSets() {
    this.availableOptionSets = this.allOptionSets.filter((os) => !this.selectedOptionSets.find((o) => o.id === os.id));
  }

  displayFn(policy: any): string {
    return policy?.label;
  }
}
