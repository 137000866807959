<div *ngIf="optionSetHierarchy" class="px-4 overflow-auto h-full">
  <div class="header flex-between-center">
    <div class="flex items-center">
      <h6>{{ optionSetHierarchy?.name }} -</h6>
      <div class="slug code ml-2">{{ optionSetHierarchy.id }}</div>
    </div>
    <div class="right-header flex-center">
      <button mat-button class="px-2 mx-2" (click)="toggleOptionSetHierarchyImportModal()" data-test="import-button">
        <mat-icon>cloud_upload</mat-icon> Import
      </button>
      <button mat-button class="!px-2" (click)="exportOptionSetHierarchy()" data-test="export-button">
        <mat-icon>cloud_download</mat-icon> Export
      </button>
      <mat-icon class="cursor-pointer ml-3" (click)="close()">close</mat-icon>
    </div>
  </div>

  <div>
    <div class="py-4" data-test="option-set-hierarchy-editor">
      <ngx-monaco-editor
        class="border block h-96 w-full mb-2"
        [formControl]="editorFormControl"
        [options]="editorOptions"
        #editor
      >
      </ngx-monaco-editor>
    </div>
    <div class="btn-actions flex justify-end items-center">
      <button color="primary" mat-raised-button [disabled]="!editorFormControl?.valid" (click)="save()">Save</button>
    </div>
  </div>
  <app-import-modal
    *ngIf="isModalOpen"
    (cancel)="toggleOptionSetHierarchyImportModal()"
    (importOptionSetHierarchies)="importOptionSetHierarchies($event)"
    [isUploadProcessed]="importProcessed"
    importType="OPTION_SET_HIERARCHY"
    [errors]="optionSetHierarchyImportErrors"
  ></app-import-modal>
</div>
