import {
  State,
  loadAssortmentEntityAdapter,
  loadWorkspacesEntityAdapter,
  loaderConfigEntityAdapter,
  loaderConfigInitialState,
} from './loader-config.state';

import * as LoaderConfigActions from './loader-config.actions';
import { Action, createReducer, on } from '@ngrx/store';

const setData = (state: State, { data }) => {
  return {
    ...state,
    loading: false,
    hasError: false,
    updating: false,
    loaderConfigs: loaderConfigEntityAdapter.setAll(data, state.loaderConfigs),
  };
};
const setCurrentLoaderConfig = (state: State, { currentLoaderConfig }) => {
  return {
    ...state,
    loading: false,
    hasError: false,
    updating: false,
    currentLoaderConfig,
  };
};

const startLoading = (state: State) => {
  return {
    ...state,
    loading: true,
    hasError: false,
    updating: false,
  };
};

const setError = (state: State) => {
  return {
    ...state,
    loading: false,
    hasError: true,
    updating: false,
  };
};

const clearData = (state: State, {}) => {
  return {
    ...state,
    loading: false,
    hasError: false,
    updating: false,
    loaderConfigs: loaderConfigEntityAdapter.removeAll(state.loaderConfigs),
  };
};

const add = (state: State, { loaderConfig }) => {
  return {
    ...state,
    loading: false,
    hasError: false,
    updating: false,
    loaderConfigs: loaderConfigEntityAdapter.addOne(loaderConfig, state.loaderConfigs),
  };
};
const update = (state: State, { id, changes }) => {
  return {
    ...state,
    loading: false,
    hasError: false,
    updating: false,
    loaderConfigs: loaderConfigEntityAdapter.updateOne({ id, changes }, state.loaderConfigs),
  };
};
const remove = (state: State, { loaderConfig }) => {
  return {
    ...state,
    loading: false,
    hasError: false,
    updating: false,
    loaderConfigs: loaderConfigEntityAdapter.removeOne(loaderConfig.id, state.loaderConfigs),
  };
};

const setWorkspaces = (state: State, { workspaces }) => {
  return {
    ...state,
    updating: false,
    loadWorkspaces: loadWorkspacesEntityAdapter.setAll(workspaces, state.loadWorkspaces),
  };
};

const setAssortments = (state: State, { assortments }) => {
  return {
    ...state,
    updating: false,
    loadAssortments: loadAssortmentEntityAdapter.setAll(assortments, state.loadAssortments),
  };
};

const startUpdating = (state: State) => {
  return {
    ...state,
    updating: true,
  };
};

export const loaderConfigReducer = createReducer(
  loaderConfigInitialState,
  on(LoaderConfigActions.loadLoaderConfigsSuccess, setData),
  on(LoaderConfigActions.loadLoaderConfigs, startLoading),
  on(LoaderConfigActions.updateLoaderConfig, startUpdating),
  on(LoaderConfigActions.createLoaderConfigSuccess, add),
  on(LoaderConfigActions.deleteLoaderConfigSuccess, remove),
  on(LoaderConfigActions.updateLoaderConfigSuccess, update),
  on(LoaderConfigActions.setCurrentLoaderConfig, setCurrentLoaderConfig),
  on(LoaderConfigActions.loadWorkspaceSuccess, setWorkspaces),
  on(LoaderConfigActions.loadAssortmentSuccess, setAssortments),
);

/**
 *
 * @param state
 * @param action
 */
export function reducer(state: State | undefined, action: Action): any {
  return loaderConfigReducer(state, action);
}
