import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { merge, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TypeFormChange } from '../type-form-change';
import { TypePropertyFormFieldComponent } from '../type-property-form-field/type-property-form-field.component';

export interface TypePropertyFormConfiguration {
  editable?: boolean;
  isFilter?: boolean;
  typeProperty: TypeProperty;
}

@Component({
  selector: 'app-type-property-form',
  templateUrl: './type-property-form.html',
  styleUrls: ['./type-property-form.scss'],
})
export class TypePropertyFormComponent implements OnChanges, AfterViewInit {
  @Input() propertyConfigurations: Array<TypePropertyFormConfiguration>;
  @Input() values: any;
  @Input() formFieldAppearance = 'fill';
  @Input() errors: any = [];
  @Output() changes = new EventEmitter<{ changes }>();
  @ViewChildren(TypePropertyFormFieldComponent) formFieldQuery: QueryList<TypePropertyFormFieldComponent>;
  public formFields: Array<TypePropertyFormFieldComponent>;
  public formChanges: Subject<TypeFormChange> = new Subject();

  constructor() {}
  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges) {}

  ngAfterViewInit(): void {
    if (this.formFieldQuery.toArray().length) {
      this.subscribeToFormFields(this.formFieldQuery.toArray());
    }
    this.formFieldQuery.changes.subscribe((fieldsQuery: QueryList<TypePropertyFormFieldComponent>) => {
      this.subscribeToFormFields(fieldsQuery.toArray());
    });
  }

  private subscribeToFormFields(fields: Array<TypePropertyFormFieldComponent>) {
    this.formFields = fields;
    const formFieldChanges: Array<Observable<any>> = this.formFields.map((formField) => formField.valueChange);
    merge(...formFieldChanges)
      .pipe(
        tap((change) => {
          console.log('TypePropertyFormComponent: change detected', change);
          this.formChanges.next(change);
          this.changes.next({ changes: [change] });
        }),
      )
      .subscribe();
  }
  propertyTrackByFn(index, obj: any) {
    return obj?.id;
  }
}
