import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { RootStoreState } from 'src/app/root-store';
import { AppsActions } from '.';
import { AppsService } from './apps.service';

@Injectable()
export class AppsEffects {
  constructor(
    private actions$: Actions,
    private appService: AppsService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {}

  loadApps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsActions.AppsActionTypes.LOAD_APPS),
      withLatestFrom(this.store),
      switchMap(([_action, _store]: [any, RootStoreState.State]) => {
        return from(this.appService.getApps()).pipe(
          map((data) => AppsActions.loadAppsSuccess({ data })),
          catchError((error) => observableOf(AppsActions.loadAppsFailure({ error }))),
        );
      }),
    ),
  );
  loadCurrentApps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsActions.AppsActionTypes.LOAD_CURRENT_APP),
      switchMap((action: any) =>
        from(this.appService.getAppById(action.id)).pipe(
          map((data) => AppsActions.loadCurrentAppSuccess({ app: data })),
          tap(() => {
            this.store.dispatch(AppsActions.loadCurrentAppSuccess(null));
          }),
          catchError((error) => observableOf(AppsActions.loadCurrentAppFailure({ error }))),
        ),
      ),
    ),
  );

  createApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsActions.AppsActionTypes.CREATE_APP),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) => {
        const id = action.id;
        return from(this.appService.createApp(id)).pipe(
          map((data) => {
            this.snackBar.open('App added.', '', { duration: 2000 });
            return AppsActions.createAppSuccess({ app: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(AppsActions.createAppFailure({ error }));
          }),
        );
      }),
    ),
  );
  deleteApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsActions.AppsActionTypes.DELETE_APP),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.appService.deleteApp(action.app)).pipe(
          map((data) => {
            this.snackBar.open('App removed.', '', { duration: 2000 });
            return AppsActions.deleteAppSuccess({ app: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(AppsActions.deleteAppFailure({ error }));
          }),
        ),
      ),
    ),
  );
  updateApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppsActions.AppsActionTypes.UPDATE_APP),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.appService.updateApp(action.id, action.changes)).pipe(
          map((data) => {
            this.snackBar.open('App updated.', '', { duration: 2000 });
            return AppsActions.updateAppSuccess({ id: data.id, changes: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(AppsActions.updateAppFailure({ error }));
          }),
        ),
      ),
    ),
  );
}
