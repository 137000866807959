import { Component } from '@angular/core';
import { Type } from '@contrail/types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { TypeManagerSelectors } from '../type-manager-store';
import { TypesService } from '../types.service';
import { buildTypeMenu, TypeMenuItem } from './type-selector-menu/type-menu-item';

@Component({
  selector: 'app-type-selector',
  templateUrl: './type-selector.component.html',
  styleUrls: ['./type-selector.component.scss'],
})
export class TypeSelectorComponent {
  public typeMenu$: Observable<TypeMenuItem>;
  public selectedType$: Observable<Type>;
  public rootId;
  constructor(
    private store: Store<RootStoreState.State>,
    private typesService: TypesService,
  ) {
    this.typeMenu$ = this.store.select(TypeManagerSelectors.types).pipe(map((types) => this.buildTypeMenuItems(types)));
    this.selectedType$ = this.store.select(TypeManagerSelectors.currentType);
  }
  goToType(type) {
    this.typesService.goToType(type.id);
  }

  buildTypeMenuItems(types: Array<Type>) {
    if (!types?.length) {
      return null;
    }
    console.log('buildTypeMenuItems: types: ', types);
    const menuItem = buildTypeMenu(types);
    console.log('menuItem: ', menuItem);
    this.rootId = menuItem.id;
    return menuItem;
  }
  goToRoot() {
    this.typesService.goToType(this.rootId);
  }
}
