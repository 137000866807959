<div class="header flex-between-center">
  <div class="left-header flex-center">
    <app-type-property-icon class="h-6" [propertyType]="typeProperty.propertyType"></app-type-property-icon>
    <h6 class="ml-2">{{ typeProperty?.label }}</h6>
  </div>
  <div class="right-header flex-center">
    <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
  </div>
</div>
<div class="detail">
  <div class="property-type">({{ propertyTypeLabel }})</div>
  <div class="slug code">{{ typeProperty.slug }}</div>
</div>
<div class="flex">
  <div class="ml-2 mr-5">Id:</div>
  <div class="code">{{ typeProperty.id }}</div>
</div>
<div
  class="bg-rose-300 p-3 m-2 rounded-md"
  *ngIf="typeProperty['orgId'] && (authOrgId$ | async) !== typeProperty['orgId']"
>
  WARNING: Org mismatch
</div>
