import { Component, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { TypeProperty } from '@contrail/types';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { TypeManagerSelectors } from '../../type-manager-store';

@Component({
  selector: 'app-type-properties-list',
  templateUrl: './type-properties-list.component.html',
  styleUrls: ['./type-properties-list.component.scss'],
})
export class TypePropertiesListComponent implements OnDestroy {
  @Input() properties: Array<TypeProperty> = [];
  @Input() selectable = false;
  @Input() showActions = true;

  @Output() propertyClicked = new EventEmitter<TypeProperty>();
  @Output() propertyDelete = new EventEmitter<TypeProperty>();
  @Output() propertyToggled = new EventEmitter();

  private currentRootType;

  constructor(public store: Store<RootStoreState.State>) {
    this.store.select(TypeManagerSelectors.currentTypeRoot).subscribe((root) => (this.currentRootType = root));
  }

  ngOnDestroy() {
    this.unsubscribe();
  }
  subscribe() {}
  unsubscribe() {}

  handleClick(property: TypeProperty) {
    this.propertyClicked.emit(property);
  }

  toggleEnableProperty(property: TypeProperty, isEnabled: boolean) {
    const isArchived = !isEnabled; // Invert for backend compatibility
    this.propertyToggled.emit({ property, changes: { isArchived } });
  }

  handleDelete(property: TypeProperty) {
    this.propertyDelete.emit(property);
  }

  public isEnabledButtonDisabled(property: TypeProperty): boolean {
    const alwaysEnabledTypeSlugs = ['name', 'createdOn', 'updatedOn'];

    if (this.currentRootType?.slug === 'item') {
      const alwaysEnabledItemTypeSlugs = ['itemNumber', 'optionName', 'lifecycleStage'];
      alwaysEnabledTypeSlugs.push(...alwaysEnabledItemTypeSlugs);
    }

    if (this.currentRootType?.slug === 'plan-placeholder') {
      const alwaysEnabledPlanPlaceholderTypeSlugs = [
        'optionName',
        'itemType',
        'lifecycleStage',
        'itemFamily',
        'itemOption',
        'isDropped',
        'isProjectDropped',
      ];
      alwaysEnabledTypeSlugs.push(...alwaysEnabledPlanPlaceholderTypeSlugs);
    }

    if (this.currentRootType?.slug === 'project-item') {
      const alwaysEnabledProjectItemTypeSlugs = ['isProjectDropped'];
      alwaysEnabledTypeSlugs.push(...alwaysEnabledProjectItemTypeSlugs);
    }

    if (this.currentRootType?.slug === 'assortment-item') {
      const alwaysEnabledAssortmentItemTypeSlugs = ['itemFamily', 'itemOption'];
      alwaysEnabledTypeSlugs.push(...alwaysEnabledAssortmentItemTypeSlugs);
    }

    console.log('TEST - Ran validation on property', property.slug);
    const isButtonDisabled = alwaysEnabledTypeSlugs.includes(property.slug);
    return isButtonDisabled;
  }
}
