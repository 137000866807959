import { Component, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ConfirmationBoxService } from 'src/app/common/components/confirmation-box/confirmation-box';
import { MenuComponent } from 'src/app/common/components/menu/menu.component';
import { RoutingService } from 'src/app/common/routing/routing.service';
import { RootStoreState } from 'src/app/root-store';
import { UpdateDialogService } from '@components/update-dialog/update-dialog-service';
import { TypeManagerActions, TypeManagerSelectors } from '../type-manager-store';
import { TypeRuleSet } from '../type-manager-store/type-rule-sets/type-rule-sets.state';

@Component({
  selector: 'app-type-rule-sets',
  templateUrl: './type-rule-sets.component.html',
  styleUrls: ['./type-rule-sets.component.scss'],
})
export class TypeRuleSetsComponent {
  public ruleSets$: Observable<Array<TypeRuleSet>>;
  constructor(
    public store: Store<RootStoreState.State>,
    private routingService: RoutingService,
    private confirmationBoxService: ConfirmationBoxService,
    private UpdateDialogService: UpdateDialogService,
  ) {
    this.ruleSets$ = this.store.select(TypeManagerSelectors.typeRuleSets);
  }
  @ViewChild(MenuComponent) addRuleSetMenu: MenuComponent;

  cancelAdd() {
    this.addRuleSetMenu.panelVisible = false;
  }
  completeAdd(data: any) {
    const template = [
      {
        criteria: {
          propertyCriteria: [],
        },
        rules: [],
      },
    ];
    this.addRuleSetMenu.panelVisible = false;
    const identifier = data.name.replace(/[\s]/g, '-');
    this.store.dispatch(
      TypeManagerActions.createTypeRuleSet({ typeRuleSet: { name: data.name, ruleSet: template, identifier } }),
    );
  }
  select(typeRuleSet: TypeRuleSet) {
    this.routingService.go('type-manager/rule-sets/' + typeRuleSet.id);
  }
  async delete(typeRuleSet: TypeRuleSet) {
    const confirm = await this.confirmationBoxService.open(
      'Delete Rule Set?',
      'Are you sure you want to delete this rule set?',
    );
    if (confirm) {
      this.store.dispatch(TypeManagerActions.deleteTypeRuleSet({ typeRuleSet }));
      this.routingService.go('type-manager/rule-sets/');
    }
  }
  async edit(typeRuleSet: TypeRuleSet) {
    const updatedName = await this.UpdateDialogService.openDialog('Rename Rule Set', typeRuleSet.name);
    if (updatedName) {
      const name = updatedName;
      const id = typeRuleSet.id;
      if (typeRuleSet.name === name) {
        return;
      }
      this.store.dispatch(TypeManagerActions.updateTypeRuleSet({ id, changes: { name } }));
    }
  }
}
