import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCommonModule } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '@common/components/components.module';
import { LoadingIndicatorModule } from '@common/loading-indicator/loading-indicator.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BodyComponent } from './body/body.component';
import { MainComponent } from './main.component';
import { AuthModule } from '../common/auth/auth.module';
import { OrgSelectorComponent } from './header/org-selector/org-selector.component';
import { HeaderUserMenuComponent } from './header/header-user-menu/header-user-menu.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { PageHeaderComponent } from './header/page-header/page-header.component';
import { PageHeaderService } from './header/page-header/page-header.service';
import { HelpSelectorComponent } from './header/header-help/help-selector.component';
import { SelectOrgComponent } from './select-org/select-org.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    BodyComponent,
    MainComponent,
    OrgSelectorComponent,
    HeaderUserMenuComponent,
    SideNavComponent,
    PageHeaderComponent,
    HelpSelectorComponent,
    SelectOrgComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ComponentsModule,
    AuthModule,
    MatSidenavModule,
    MatCommonModule,
    BrowserAnimationsModule,
    MatListModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    LoadingIndicatorModule,
  ],
  providers: [PageHeaderService],
  exports: [MainComponent],
})
export class MainModule {}
