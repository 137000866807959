import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { RootStoreState } from '../../root-store';
import { TypeManagerActions, TypeManagerSelectors } from '../type-manager-store';
import { TypePropertyOptionSet } from '../type-manager-store/option-sets/option-sets.state';

@Injectable({
  providedIn: 'root',
})
export class TypePropertyOptionSetsResolver implements Resolve<Array<TypePropertyOptionSet>> {
  constructor(private store: Store<RootStoreState.State>) {}
  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<Array<TypePropertyOptionSet>> {
    this.loadTypePropertyOptionSets();
    return this.store.select(TypeManagerSelectors.typePropertyOptionSets).pipe(take(1));
  }

  loadTypePropertyOptionSets() {
    this.store.dispatch(TypeManagerActions.loadTypePropertyOptionSets());
  }
}
