<div class="p-5">
  <p class="mt-5 font-medium text-3xl">VibeIQ Material Theme Test</p>
  <section>
    <div class="example-label">Basic</div>
    <div class="example-button-row">
      <button mat-button>Basic</button>
      <button mat-button color="primary">Primary</button>
      <button mat-button color="accent">Accent</button>
      <button mat-button color="warn">Warn</button>
      <button mat-button color="success">Success</button>
      <button mat-button color="danger">Danger</button>
      <button mat-button color="secondary">Secondary</button>
      <button mat-button disabled>Disabled</button>
      <a mat-button href="https://www.google.com/" target="_blank">Link</a>
    </div>
  </section>

  <section>
    <div class="example-label">Raised</div>
    <div class="example-button-row">
      <button mat-raised-button>Basic</button>
      <button mat-raised-button color="primary">Primary</button>
      <button mat-raised-button color="accent">Accent</button>
      <button mat-raised-button color="warn">Warn</button>
      <button mat-raised-button color="success">Success</button>
      <button mat-raised-button color="danger">Danger</button>
      <button mat-raised-button color="secondary">Secondary</button>
      <button mat-raised-button disabled>Disabled</button>
      <a mat-raised-button href="https://www.google.com/" target="_blank">Link</a>
    </div>
  </section>

  <section>
    <div class="example-label">Stroked</div>
    <div class="example-button-row">
      <button mat-stroked-button>Basic</button>
      <button mat-stroked-button color="primary">Primary</button>
      <button mat-stroked-button color="accent">Accent</button>
      <button mat-stroked-button color="warn">Warn</button>
      <button mat-stroked-button color="success">Success</button>
      <button mat-stroked-button color="danger">Danger</button>
      <button mat-stroked-button color="secondary">Secondary</button>
      <button mat-stroked-button disabled>Disabled</button>
      <a mat-stroked-button href="https://www.google.com/" target="_blank">Link</a>
    </div>
  </section>

  <section>
    <div class="example-label">Flat</div>
    <div class="example-button-row">
      <button mat-flat-button>Basic</button>
      <button mat-flat-button color="primary">Primary</button>
      <button mat-flat-button color="accent">Accent</button>
      <button mat-flat-button color="warn">Warn</button>
      <button mat-flat-button color="success">Success</button>
      <button mat-flat-button color="danger">Danger</button>
      <button mat-flat-button color="secondary">Secondary</button>
      <button mat-flat-button disabled>Disabled</button>
      <a mat-flat-button href="https://www.google.com/" target="_blank">Link</a>
    </div>
  </section>

  <section>
    <div class="example-label">Icon</div>
    <div class="example-button-row">
      <div class="example-flex-container">
        <button mat-icon-button aria-label="Example icon button with a vertical three dot icon">
          <mat-icon>more_vert</mat-icon>
        </button>
        <button mat-icon-button color="primary" aria-label="Example icon button with a home icon">
          <mat-icon>home</mat-icon>
        </button>
        <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon">
          <mat-icon>menu</mat-icon>
        </button>
        <button mat-icon-button color="warn" aria-label="Example icon button with a heart icon">
          <mat-icon>favorite</mat-icon>
        </button>
        <button mat-icon-button color="success" aria-label="Example icon button with a heart icon">
          <mat-icon>favorite</mat-icon>
        </button>
        <button mat-icon-button color="danger" aria-label="Example icon button with a heart icon">
          <mat-icon>favorite</mat-icon>
        </button>
        <button mat-icon-button color="secondary" aria-label="Example icon button with a heart icon">
          <mat-icon>favorite</mat-icon>
        </button>
        <button mat-icon-button disabled aria-label="Example icon button with a open in new tab icon">
          <mat-icon>open_in_new</mat-icon>
        </button>
        <a mat-flat-button href="https://www.google.com/" target="_blank">Link</a>
      </div>
    </div>
  </section>

  <section>
    <div class="example-label">FAB</div>
    <div class="example-button-row">
      <div class="example-flex-container">
        <div class="example-button-container">
          <button mat-fab aria-label="Example icon button with a delete icon">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        <div class="example-button-container">
          <button mat-fab color="primary" aria-label="Example icon button with a delete icon">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        <div class="example-button-container">
          <button mat-fab color="accent" aria-label="Example icon button with a bookmark icon">
            <mat-icon>bookmark</mat-icon>
          </button>
        </div>
        <div class="example-button-container">
          <button mat-fab color="warn" aria-label="Example icon button with a home icon">
            <mat-icon>home</mat-icon>
          </button>
        </div>
        <div class="example-button-container">
          <button mat-fab color="success" aria-label="Example icon button with a home icon">
            <mat-icon>home</mat-icon>
          </button>
        </div>
        <div class="example-button-container">
          <button mat-fab color="danger" aria-label="Example icon button with a home icon">
            <mat-icon>home</mat-icon>
          </button>
        </div>
        <div class="example-button-container">
          <button mat-fab color="secondary" aria-label="Example icon button with a home icon">
            <mat-icon>home</mat-icon>
          </button>
        </div>
        <div class="example-button-container">
          <button mat-fab disabled aria-label="Example icon button with a heart icon">
            <mat-icon>favorite</mat-icon>
          </button>
        </div>
        <a mat-flat-button href="https://www.google.com/" target="_blank">Link</a>
      </div>
    </div>
  </section>

  <section>
    <div class="example-label">Mini FAB</div>
    <div class="example-button-row">
      <div class="example-flex-container">
        <div class="example-button-container">
          <button mat-mini-fab aria-label="Example icon button with a menu icon">
            <mat-icon>menu</mat-icon>
          </button>
        </div>
        <div class="example-button-container">
          <button mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
            <mat-icon>menu</mat-icon>
          </button>
        </div>
        <div class="example-button-container">
          <button mat-mini-fab color="accent" aria-label="Example icon button with a plus one icon">
            <mat-icon>plus_one</mat-icon>
          </button>
        </div>
        <div class="example-button-container">
          <button mat-mini-fab color="warn" aria-label="Example icon button with a filter list icon">
            <mat-icon>filter_list</mat-icon>
          </button>
        </div>
        <div class="example-button-container">
          <button mat-mini-fab color="success" aria-label="Example icon button with a filter list icon">
            <mat-icon>filter_list</mat-icon>
          </button>
        </div>
        <div class="example-button-container">
          <button mat-mini-fab color="danger" aria-label="Example icon button with a filter list icon">
            <mat-icon>filter_list</mat-icon>
          </button>
        </div>
        <div class="example-button-container">
          <button mat-mini-fab color="secondary" aria-label="Example icon button with a filter list icon">
            <mat-icon>filter_list</mat-icon>
          </button>
        </div>
        <div class="example-button-container">
          <button mat-mini-fab disabled aria-label="Example icon button with a home icon">
            <mat-icon>home</mat-icon>
          </button>
        </div>
        <a mat-flat-button href="https://www.google.com/" target="_blank">Link</a>
      </div>
    </div>
  </section>

  <div class="flex my-10">
    <p class="mr-8">
      <mat-form-field appearance="legacy">
        <mat-label>Legacy form field</mat-label>
        <input matInput placeholder="Placeholder" />
        <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
        <mat-hint>Hint</mat-hint>
      </mat-form-field>
    </p>
    <p class="mr-8">
      <mat-form-field appearance="standard">
        <mat-label>Standard form field</mat-label>
        <input matInput placeholder="Email Form" [formControl]="emailForm1" />
        <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
      </mat-form-field>
    </p>
    <p class="mr-8">
      <mat-form-field appearance="fill">
        <mat-label>Fill form field</mat-label>
        <input matInput placeholder="Email Form" [formControl]="emailForm2" />
        <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
        <mat-error *ngIf="emailForm2.invalid">error</mat-error>
        <mat-hint *ngIf="emailForm2.valid">Form Validated</mat-hint>
      </mat-form-field>
    </p>
    <p class="mr-8">
      <mat-form-field appearance="outline">
        <mat-label>Outline form field</mat-label>
        <input matInput placeholder="Custom Valid Message" [formControl]="emailForm3" />
        <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
        <mat-error *ngIf="emailForm3.invalid">{{ emailInValidMessage }}</mat-error>
      </mat-form-field>
    </p>
  </div>

  <h5 class="example-label text-lg mb-3">Checkbox configuration</h5>

  <section class="example-section">
    <mat-checkbox class="m-3" [(ngModel)]="checked">Checked</mat-checkbox>
    <mat-checkbox class="m-3" [(ngModel)]="indeterminate">Indeterminate</mat-checkbox>
  </section>

  <section class="example-section">
    <label class="m-3">Align:</label>
    <mat-radio-group [(ngModel)]="labelPosition">
      <mat-radio-button class="m-3" value="after" color="primary">After Primary</mat-radio-button>
      <mat-radio-button class="m-3" value="before" color="warn">Before Warn</mat-radio-button>
      <mat-radio-button class="m-3" value="after" color="success">After Success</mat-radio-button>
    </mat-radio-group>
  </section>

  <section class="example-section">
    <mat-checkbox class="m-3" [(ngModel)]="disabled">Disabled</mat-checkbox>
  </section>

  <h5 class="example-label text-lg mb-3">Result</h5>
  <section class="example-section">
    <mat-checkbox
      class="m-3"
      [(ngModel)]="checked"
      [(indeterminate)]="indeterminate"
      [labelPosition]="labelPosition"
      [disabled]="disabled"
    >
      Primary
    </mat-checkbox>
    <mat-checkbox
      class="m-3"
      color="accent"
      [(ngModel)]="checked"
      [(indeterminate)]="indeterminate"
      [labelPosition]="labelPosition"
      [disabled]="disabled"
    >
      Accent
    </mat-checkbox>
    <mat-checkbox
      class="m-3"
      color="warn"
      [(ngModel)]="checked"
      [(indeterminate)]="indeterminate"
      [labelPosition]="labelPosition"
      [disabled]="disabled"
    >
      Warn
    </mat-checkbox>
    <mat-checkbox
      class="m-3"
      color="success"
      [(ngModel)]="checked"
      [(indeterminate)]="indeterminate"
      [labelPosition]="labelPosition"
      [disabled]="disabled"
    >
      Success
    </mat-checkbox>
    <mat-checkbox
      class="m-3"
      color="danger"
      [(ngModel)]="checked"
      [(indeterminate)]="indeterminate"
      [labelPosition]="labelPosition"
      [disabled]="disabled"
    >
      Danger
    </mat-checkbox>
    <mat-checkbox
      class="m-3"
      color="secondary"
      [(ngModel)]="checked"
      [(indeterminate)]="indeterminate"
      [labelPosition]="labelPosition"
      [disabled]="disabled"
    >
      Secondary
    </mat-checkbox>
  </section>

  <h5 class="example-label text-lg mb-5">Progress Bar</h5>
  <section class="flex">
    <div class="mr-10">
      <mat-progress-bar
        style="width: 200px; margin-bottom: 20px"
        class="example-margin"
        color="primary"
        mode="determinate"
        value="50"
        bufferValue="75"
      >
      </mat-progress-bar>
      <mat-progress-bar
        style="width: 200px; margin-bottom: 20px"
        class="example-margin"
        color="accent"
        mode="determinate"
        value="50"
        bufferValue="75"
      >
      </mat-progress-bar>
      <mat-progress-bar
        style="width: 200px; margin-bottom: 20px"
        class="example-margin"
        color="warn"
        mode="determinate"
        value="50"
        bufferValue="75"
      >
      </mat-progress-bar>
      <mat-progress-bar
        style="width: 200px; margin-bottom: 20px"
        class="example-margin"
        color="success"
        mode="determinate"
        value="50"
        bufferValue="75"
      >
      </mat-progress-bar>
      <mat-progress-bar
        style="width: 200px; margin-bottom: 20px"
        class="example-margin"
        color="danger"
        mode="determinate"
        value="50"
        bufferValue="75"
      >
      </mat-progress-bar>
      <mat-progress-bar
        style="width: 200px; margin-bottom: 20px"
        class="example-margin"
        color="secondary"
        mode="determinate"
        value="50"
        bufferValue="75"
      >
      </mat-progress-bar>
    </div>
    <div class="mr-10 flex">
      <mat-progress-spinner
        style="width: 200px; margin-bottom: 20px"
        class="example-margin"
        color="primary"
        mode="indeterminate"
        value="50"
      >
      </mat-progress-spinner>
      <mat-progress-spinner
        style="width: 200px; margin-bottom: 20px"
        class="example-margin"
        color="accent"
        mode="indeterminate"
        value="50"
      >
      </mat-progress-spinner>
      <mat-progress-spinner
        style="width: 200px; margin-bottom: 20px"
        class="example-margin"
        color="warn"
        mode="indeterminate"
        value="50"
      >
      </mat-progress-spinner>
      <mat-progress-spinner
        style="width: 200px; margin-bottom: 20px"
        class="example-margin"
        color="success"
        mode="indeterminate"
        value="50"
      >
      </mat-progress-spinner>
      <mat-progress-spinner
        style="width: 200px; margin-bottom: 20px"
        class="example-margin"
        color="danger"
        mode="indeterminate"
        value="50"
      >
      </mat-progress-spinner>
      <mat-progress-spinner
        style="width: 200px; margin-bottom: 20px"
        class="example-margin"
        color="secondary"
        mode="indeterminate"
        value="50"
      >
      </mat-progress-spinner>
    </div>
  </section>

  <mat-slide-toggle color="danger">Slide me! Danger</mat-slide-toggle>
  <mat-slide-toggle color="success" class="mr-10">Slide me! Success</mat-slide-toggle>

  <mat-slider aria-label="unit(s)" step="width: 200px; margin-right: 40px" color="danger"></mat-slider>
  <mat-slider aria-label="unit(s)" step="width: 200px" color="success"></mat-slider>
</div>
