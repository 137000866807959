import { Action, createReducer, on } from '@ngrx/store';
import { State, userOrgsEntityAdapter, userOrgsInitialState } from './user-orgs.state';
import * as UserOrgsActions from './user-orgs.actions';

const setData = (state: State, { data }) => ({
  ...state,
  userOrgs: userOrgsEntityAdapter.setAll(data, state.userOrgs),
});
const add = (state: State, { userOrg }) => ({
  ...state,
  userOrgs: userOrgsEntityAdapter.addOne(userOrg, state.userOrgs),
});
const update = (state: State, { id, changes }) => ({
  ...state,
  userOrgs: userOrgsEntityAdapter.updateOne({ id, changes }, state.userOrgs),
});
const remove = (state: State, { userOrg }) => ({
  ...state,
  userOrgs: userOrgsEntityAdapter.removeOne(userOrg.id, state.userOrgs),
});
const batchRemove = (state: State, { ids }) => ({
  ...state,
  userOrgs: userOrgsEntityAdapter.removeMany(ids, state.userOrgs),
});
const setCurrentUserOrg = (state: State, { userOrg }) => ({
  ...state,
  currentUserOrg: userOrg,
});

export const userOrgsReducers = createReducer(
  userOrgsInitialState,
  on(UserOrgsActions.loadUserOrgsSuccess, setData),
  on(UserOrgsActions.createUserOrgSuccess, add),
  on(UserOrgsActions.deleteUserOrgSuccess, remove),
  on(UserOrgsActions.deleteUserOrgsSuccess, batchRemove),
  on(UserOrgsActions.updateUserOrgSuccess, update),
  on(UserOrgsActions.loadCurrentUserOrgSuccess, setCurrentUserOrg),
);

/**
 *
 * @param state
 * @param action
 */
export function reducer(state: State | undefined, action: Action): any {
  return userOrgsReducers(state, action);
}
