import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface OptionSetHierarchy {
  id?: string;
  name?: string;
  identifier?: string;
  label?: string;
  hierarchy?: Array<string>;
  options?: any;
}

export interface OptionSetHierarchiesState extends EntityState<OptionSetHierarchy> {}
export const optionSetHierarchiesEntityAdapter: EntityAdapter<OptionSetHierarchy> =
  createEntityAdapter<OptionSetHierarchy>({});

export const optionSetHierarchiesInitialState = optionSetHierarchiesEntityAdapter.getInitialState({});
