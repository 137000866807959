<app-page-section *ngIf="visible">
  <app-page-section-header>
    <app-page-section-title title="Uniqueness"></app-page-section-title>
  </app-page-section-header>
  <app-page-section-body>
    <div class="mb-3">
      <mat-slide-toggle [formControl]="uniquenessFormControl"
        >Globally unique? The property value is unique across all items.</mat-slide-toggle
      >
    </div>
    <div class="mb-3">
      <mat-slide-toggle [formControl]="familyUniquenessFormControl" [disabled]="isUniqueInFamilyDisabled"
        >Unique in family? The property value is unique for an item family.</mat-slide-toggle
      >
    </div>
    <div class="mt-5 mb-3">Only 10 properties per entity can be globally unique.</div>
  </app-page-section-body>
</app-page-section>
