<div class="main">
  <div class="body">
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput [formControl]="nameField" data-test="add-input" />
    </mat-form-field>
  </div>
  <div class="actions">
    <button mat-flat-button color="cancel" (click)="cancel()" data-test="cancel-add">Cancel</button>
    <button mat-flat-button color="primary" [disabled]="!isValid()" (click)="completeEdit()" data-test="add-value">
      Add
    </button>
  </div>
</div>
