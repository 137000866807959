<!-- // TODO: DO WE STILL NEED THIS COMPONENT? ROUTE: /type-manager/properties -->

<div class="content">
  <h6 class="py-2 border-b px-8">Properties</h6>
  <div class="px-8 py-3">
    <div class="header">
      <app-search-bar></app-search-bar>
      <app-menu name="" icon="add">
        <app-type-property-form (cancelEdit)="cancelAdd()" (done)="completeAdd()"></app-type-property-form>
      </app-menu>
    </div>
    <app-page-section>
      <app-type-properties-list
        [properties]="filterdProperties$ | async"
        (propertyDelete)="deleteProperty($event)"
        (propertyClicked)="showPropertyDetails($event)"
      ></app-type-properties-list>
    </app-page-section>
  </div>
  <app-type-property-details-side-bar></app-type-property-details-side-bar>
</div>
