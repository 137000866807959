import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { RootStoreState } from 'src/app/root-store';
import { EventWorkflowTemplatesService } from './event-templates.service';
import { EventWorkflowTemplatesActions } from '.';

@Injectable()
export class EventWorkflowTemplatesEffects {
  constructor(
    private actions$: Actions,
    private eventWorkflowTemplatesService: EventWorkflowTemplatesService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {}

  loadEventWorkflowTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventWorkflowTemplatesActions.EventWorkflowTemplatesActionTypes.LOAD_EVENTWORKFLOWTEMPLATES),
      withLatestFrom(this.store),
      switchMap(([]: [any, RootStoreState.State]) =>
        from(this.eventWorkflowTemplatesService.getEventWorkflowTemplates()).pipe(
          map((data) => EventWorkflowTemplatesActions.loadEventWorkflowTemplatesSuccess({ data })),
          catchError((error) =>
            observableOf(EventWorkflowTemplatesActions.loadEventWorkflowTemplatesFailure({ error })),
          ),
        ),
      ),
    ),
  );
  loadCurrentEventWorkflowTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventWorkflowTemplatesActions.EventWorkflowTemplatesActionTypes.LOAD_CURRENT_EVENTWORKFLOWTEMPLATE),
      switchMap((action: any) =>
        from(this.eventWorkflowTemplatesService.getEventWorkflowTemplatesById(action.id)).pipe(
          map((data) =>
            EventWorkflowTemplatesActions.loadCurrentEventWorkflowTemplateSuccess({ eventWorkflowTemplate: data }),
          ),
          catchError((error) =>
            observableOf(EventWorkflowTemplatesActions.loadCurrentEventWorkflowTemplateFailure({ error })),
          ),
        ),
      ),
    ),
  );
  loadEventWorkflowTemplatesByTriggerkey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventWorkflowTemplatesActions.EventWorkflowTemplatesActionTypes.LOAD_TRIGGERKEY_EVENTWORKFLOWTEMPLATES),
      switchMap((action: any) =>
        from(this.eventWorkflowTemplatesService.getEventWorkflowTemplatesByTriggerkey(action.triggerKey)).pipe(
          map((data) => EventWorkflowTemplatesActions.loadTriggerkeyEventWorkflowTemplatesSuccess({ data })),
          catchError((error) =>
            observableOf(EventWorkflowTemplatesActions.loadTriggerkeyEventWorkflowTemplatesFailure({ error })),
          ),
        ),
      ),
    ),
  );
  createEventWorkflowTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventWorkflowTemplatesActions.EventWorkflowTemplatesActionTypes.CREATE_EVENTWORKFLOWTEMPLATE),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) => {
        const body = {
          name: action.name,
          triggerKey: action.triggerKey,
          identifier: action.identifier,
          isActive: action.isActive,
          supportingAppId: action.supportingAppId,
          workflowDefinition: action.workflowDefinition,
          ownerKey: action.ownerKey,
          messageGroupId: action?.messageGroupId,
          dynamicMessageGroupId: action?.dynamicMessageGroupId,
          messageGroup: action?.messageGroup,
          parallelWorkerCount: action?.parallelWorkerCount,
        };
        return from(this.eventWorkflowTemplatesService.createEventWorkflowTemplate(body)).pipe(
          map((data) => {
            this.snackBar.open('EventWorkflowTemplate added.', '', { duration: 2000 });
            this.store.dispatch(EventWorkflowTemplatesActions.loadEventWorkflowTemplates()); // TODO: Confirm with BTP ; Possibility createdBy relation in the response
            return EventWorkflowTemplatesActions.createEventWorkflowTemplateSuccess({ eventWorkflowTemplate: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(EventWorkflowTemplatesActions.createEventWorkflowTemplateFailure({ error }));
          }),
        );
      }),
    ),
  );
  deleteEventWorkflowTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventWorkflowTemplatesActions.EventWorkflowTemplatesActionTypes.DELETE_EVENTWORKFLOWTEMPLATE),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.eventWorkflowTemplatesService.deleteEventWorkflowTemplate(action.eventWorkflowTemplate)).pipe(
          map((data) => {
            this.snackBar.open('EventWorkflowTemplate removed.', '', { duration: 2000 });
            return EventWorkflowTemplatesActions.deleteEventWorkflowTemplateSuccess({ eventWorkflowTemplate: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(EventWorkflowTemplatesActions.deleteEventWorkflowTemplateFailure({ error }));
          }),
        ),
      ),
    ),
  );
  deleteEventWorkflowTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventWorkflowTemplatesActions.EventWorkflowTemplatesActionTypes.DELETE_EVENTWORKFLOWTEMPLATES),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.eventWorkflowTemplatesService.deleteEventWorkflowTemplates(action.ids)).pipe(
          map((data) => {
            this.snackBar.open(`${action.ids.length} EventWorkflowTemplates removed.`, '', { duration: 2000 });
            return EventWorkflowTemplatesActions.deleteEventWorkflowTemplatesSuccess({ ids: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(EventWorkflowTemplatesActions.deleteEventWorkflowTemplatesFailure({ error }));
          }),
        ),
      ),
    ),
  );
  updateEventWorkflowTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventWorkflowTemplatesActions.EventWorkflowTemplatesActionTypes.UPDATE_EVENTWORKFLOWTEMPLATE),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.eventWorkflowTemplatesService.updateEventWorkflowTemplate(action.id, action.changes)).pipe(
          map((data) => {
            this.snackBar.open('EventWorkflowTemplate updated.', '🎉', { duration: 2000 });
            return EventWorkflowTemplatesActions.updateEventWorkflowTemplateSuccess({ id: data.id, changes: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(EventWorkflowTemplatesActions.updateEventWorkflowTemplateFailure({ error }));
          }),
        ),
      ),
    ),
  );

  updateEventWorkflowTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventWorkflowTemplatesActions.EventWorkflowTemplatesActionTypes.UPDATE_EVENTWORKFLOWTEMPLATES),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.eventWorkflowTemplatesService.updateEventWorkflowTemplates(action.data)).pipe(
          map((data) => {
            this.snackBar.open('EventWorkflowTemplates updated.', '🎉', { duration: 2000 });
            return EventWorkflowTemplatesActions.updateEventWorkflowTemplatesSuccess({ data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(EventWorkflowTemplatesActions.updateEventWorkflowTemplatesFailure({ error }));
          }),
        ),
      ),
    ),
  );
  loadAllTemplateActionLinks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventWorkflowTemplatesActions.EventWorkflowTemplatesActionTypes.LOAD_ALL_TEMPLATE_ACTION_LINKS),
      withLatestFrom(this.store),
      switchMap(([]: [any, RootStoreState.State]) =>
        from(this.eventWorkflowTemplatesService.getTemplateActionLinks()).pipe(
          map((links) => EventWorkflowTemplatesActions.loadAllTemplateActionLinksSuccess({ data: links })),

          catchError((error) => {
            console.error('encountered an error when fetching template action links', error);
            return observableOf(EventWorkflowTemplatesActions.loadAllTemplateActionLinksFailure({ error }));
          }),
        ),
      ),
    ),
  );

  loadTemplateActionLinksForApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventWorkflowTemplatesActions.EventWorkflowTemplatesActionTypes.LOAD_TEMPLATE_ACTION_LINK_FOR_APP),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.eventWorkflowTemplatesService.getTemplateActionLinksByAppId(action.appId)).pipe(
          map((links) => EventWorkflowTemplatesActions.loadTemplateActionLinkForAppSuccess({ data: links })),
          catchError((error) => {
            console.error('encountered an error when fetching template action links for app', error);
            return observableOf(EventWorkflowTemplatesActions.loadTemplateActionLinkForAppFailure({ error }));
          }),
        ),
      ),
    ),
  );
}
