<div class="flex flex-col justify-between">
  <div class="body flex flex-col">
    <mat-form-field appearance="outline">
      <mat-label>Key</mat-label>
      <input id="slugField" matInput [formControl]="slugInput" (change)="setLabel()" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Label</mat-label>
      <input matInput [formControl]="labelInput" />
    </mat-form-field>
  </div>

  <div class="mt-3 flex justify-end">
    <button mat-flat-button color="cancel" (click)="cancel()">Cancel</button>
    <button mat-flat-button color="primary" [disabled]="!isValid()" (click)="addSubType()">Add</button>
  </div>
</div>
