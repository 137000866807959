import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { SizeRange } from '@contrail/types';
import { SizeRangeAction, SizeRangeCell, SizeRangeEditorManager } from './size-range-editor-manager';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { ObjectUtil } from '@contrail/util';

@Component({
  selector: 'app-size-range-editor',
  templateUrl: './size-range-editor.component.html',
  styleUrls: ['./size-range-editor.component.scss'],
})
export class SizeRangeEditorComponent implements OnInit, OnDestroy {
  @Input() sizeRangeConstraint: SizeRange;
  @Input() sizeRange: SizeRange;
  @Input() editMode: false;
  @Output() dataChanged = new EventEmitter();
  sizeRangeData$: Observable<SizeRange>;
  selectedSizeRangeCells$: Observable<Array<SizeRangeCell>>;
  sizeRangeEditorManager: SizeRangeEditorManager;
  dimensionCells1: string[] = [];
  dimensionCells2: string[] = [];
  gridColumnStyle = '';
  selectableCells: Array<SizeRangeCell>;
  subscriptions = new Subscription();

  constructor() {
    this.sizeRangeEditorManager = new SizeRangeEditorManager();
    this.sizeRangeData$ = this.sizeRangeEditorManager.sizeRangeData$;
    this.selectedSizeRangeCells$ = this.sizeRangeEditorManager.selectedSizeRangeCells$;
    this.subscriptions.add(
      this.sizeRangeData$.subscribe((sizeRangeData) => {
        this.dataChanged.emit(sizeRangeData);
      }),
    );
  }

  ngOnInit(): void {
    if (!this.sizeRange && !this.sizeRangeConstraint) {
      return;
    }
    if (!this.sizeRange) {
      this.sizeRange = ObjectUtil.cloneDeep(this.sizeRangeConstraint);
    } else if (!this.sizeRangeConstraint) {
      this.sizeRangeConstraint = ObjectUtil.cloneDeep(this.sizeRange);
    }
    this.sizeRangeEditorManager.constructGrid(this.sizeRangeConstraint, this.sizeRange);
    this.dimensionCells1 = this.sizeRangeEditorManager.dimensionCells1;
    this.dimensionCells2 = this.sizeRangeEditorManager.dimensionCells2 || [];
    this.selectableCells = this.sizeRangeEditorManager.selectableCells || [];
    this.gridColumnStyle = `repeat(${this.dimensionCells1.length}, 1fr)`;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleAction(action: SizeRangeAction) {
    this.sizeRangeEditorManager.handleAction(action);
  }

  getData() {
    return this.sizeRangeEditorManager.sizeRangeData;
  }
}
