import { createReducer, on } from '@ngrx/store';
import * as TypePropertyUserListsAction from './user-list.actions';
import { State, typePropertyUserListsEntityAdapter, typePropertyUserListsInitialState } from './user-list.state';

const setData = (state: State, { data }) => {
  return {
    ...state,
    typePropertyUserLists: typePropertyUserListsEntityAdapter.setAll(data, state.typePropertyUserLists),
  };
};

const add = (state: State, { typePropertyUserList }) => {
  return {
    ...state,
    typePropertyUserLists: typePropertyUserListsEntityAdapter.addOne(typePropertyUserList, state.typePropertyUserLists),
  };
};

export const typePropertyUserListsReducers = createReducer(
  typePropertyUserListsInitialState,
  on(TypePropertyUserListsAction.loadTypePropertyUserListsSuccess, setData),
  on(TypePropertyUserListsAction.addTypePropertyUserList, add),
);
