import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { FileLoaderService } from '../../file-loader/file-loader.service';
import * as LoaderConfigActions from './loader-config.actions';
import { WorkspaceService } from './workspace.service';
import { AssortmentService } from './assortment.service';
import { LoaderConfigService } from '../loader-config.service';

@Injectable()
export class LoaderConfigEffects {
  constructor(
    private actions$: Actions,
    private fileLoaderService: FileLoaderService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
    private workspaceService: WorkspaceService,
    private assortmentService: AssortmentService,
    private loaderConfigService: LoaderConfigService,
  ) {}

  loadLoaderConfigs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoaderConfigActions.LoaderConfigActionTypes.LOAD_LOADER_CONFIGS),
      mergeMap(() =>
        from(this.fileLoaderService.getLoaderConfigurations()).pipe(
          map((data) => LoaderConfigActions.loadLoaderConfigsSuccess({ data })),
          catchError((error) => observableOf(LoaderConfigActions.loadLoaderConfigsFailure({ error }))),
        ),
      ),
    ),
  );
  createLoaderConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoaderConfigActions.LoaderConfigActionTypes.CREATE_LOADER_CONFIG),
      mergeMap((action: any) =>
        from(this.fileLoaderService.addLoaderConfiguration(action.loaderConfig)).pipe(
          map((data) => {
            this.snackBar.open('Loader Config created.', '', {
              duration: 2000,
            });
            this.store.dispatch(
              LoaderConfigActions.setCurrentLoaderConfig({
                currentLoaderConfig: data,
              }),
            );
            return LoaderConfigActions.createLoaderConfigSuccess({
              loaderConfig: data,
            });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(LoaderConfigActions.createLoaderConfigFailure({ error }));
          }),
        ),
      ),
    ),
  );
  deleteLoaderConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoaderConfigActions.LoaderConfigActionTypes.DELETE_LOADER_CONFIG),
      mergeMap((action: any) =>
        from(this.fileLoaderService.deleteLoaderConfiguration(action.loaderConfig)).pipe(
          map((data) => {
            this.snackBar.open('Loader Config deleted.', '', {
              duration: 2000,
            });
            return LoaderConfigActions.deleteLoaderConfigSuccess({
              loaderConfig: data,
            });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(LoaderConfigActions.deleteLoaderConfigFailure({ error }));
          }),
        ),
      ),
    ),
  );
  updateLoaderConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoaderConfigActions.LoaderConfigActionTypes.UPDATE_LOADER_CONFIG),
      mergeMap((action: any) =>
        from(this.fileLoaderService.updateLoaderConfiguration(action.id, action.changes)).pipe(
          map((data) => {
            this.snackBar.open('Loader Config updated.', '', {
              duration: 2000,
            });
            this.loaderConfigService.updatedLoader(data);
            return LoaderConfigActions.updateLoaderConfigSuccess({
              id: data.id,
              changes: data,
            });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(LoaderConfigActions.updateLoaderConfigFailure({ error }));
          }),
        ),
      ),
    ),
  );

  loadWorkspace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoaderConfigActions.LoaderConfigActionTypes.LOAD_WORKSPACE),
      mergeMap(() =>
        from(this.workspaceService.getWorkspaces()).pipe(
          map((data) => LoaderConfigActions.loadWorkspaceSuccess({ workspaces: data })),
          catchError((error) => observableOf(LoaderConfigActions.loadWorkspaceFailure({ error }))),
        ),
      ),
    ),
  );

  loadAssortment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoaderConfigActions.LoaderConfigActionTypes.LOAD_ASSORTMENT),
      mergeMap(() =>
        from(this.assortmentService.getAssortments()).pipe(
          map((data) => LoaderConfigActions.loadAssortmentSuccess({ assortments: data })),
          catchError((error) => observableOf(LoaderConfigActions.loadAssortmentFailure({ error }))),
        ),
      ),
    ),
  );
}
