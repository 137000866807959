import { createSelector } from '@ngrx/store';
import { loaderHistoryEntityAdapter } from './loader-history.state';
import { LoaderHistory } from './loader-history';

export const featureKey = 'loaderHistory';

export const { selectAll, selectEntities, selectIds, selectTotal } = loaderHistoryEntityAdapter.getSelectors();

const loaderHistoryEntities = (state: any) => state[featureKey].loaderHistory.entities;

export const loaderHistory = createSelector(
  loaderHistoryEntities,
  (state) => Object.values(state) as Array<LoaderHistory>,
);

export const getLoadingState = (state: any) => state[featureKey].loading;

export const getCurrentLoaderHistory = (state: any) => state[featureKey].currentLoaderHistory;

export const getCurrentLoaderHistoryDetail = (state: any) => state[featureKey].currentLoaderHistoryDetail;

export const getLogs = (state: any) => state[featureKey].logs;
export const getNextPagekey = (state: any) => state[featureKey].nextPageKey;

export const getDateRange = (state: any) => {
  return {
    startDate: state[featureKey].startDate,
    endDate: state[featureKey].endDate,
  };
};

export const getPreprocessingStepLoading = (state: any) => state[featureKey].processingStepLoading;

export const getPreprocessingStep = (state: any) => state[featureKey].preprocessingStep;
