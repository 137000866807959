import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Type, TypeProperty } from '@contrail/types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-type-property-level',
  templateUrl: './type-property-level.component.html',
  styleUrls: ['./type-property-level.component.scss'],
})
export class TypePropertyLevelComponent implements OnInit, OnChanges, OnDestroy {
  @Input() typeProperty: TypeProperty;
  @Input() type: Type;
  public enableLevel = false;
  @Output() changes = new EventEmitter();
  public formControl = new UntypedFormControl();
  public options = [
    { label: 'Family', slug: 'family' },
    { label: 'Option', slug: 'option' },
    { label: 'Overridable', slug: 'overridable' },
    { label: 'All', slug: 'all' },
  ];
  private formSub: Subscription;
  constructor() {}
  ngOnInit() {
    this.formSub = this.formControl.valueChanges.subscribe(() => {
      this.handleChange();
    });
  }

  ngOnChanges() {
    if (
      this.typeProperty &&
      (this.type?.typePath?.indexOf('item') === 0 || this.type?.typePath?.indexOf('project-item') === 0)
    ) {
      this.enableLevel = true;
      this.formControl.setValue(this.typeProperty.propertyLevel);
      if (this.typeProperty.core) {
        this.formControl.disable();
      } else {
        this.formControl.enable();
      }
    }
  }
  ngOnDestroy() {
    this.formSub.unsubscribe();
  }

  handleChange() {
    const newValue = this.formControl?.value;
    if (!newValue || newValue === this.typeProperty.propertyLevel) {
      return;
    }
    const valueChanges = {
      propertyLevel: newValue,
    };
    this.changes.emit(valueChanges);
  }
}
