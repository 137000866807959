import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { App } from './store-app';

@Injectable({
  providedIn: 'root',
})
export class AppsService {
  constructor() {}

  public async getApps() {
    return new Entities().get({
      entityName: 'app',
      relations: ['appOrgs', 'latestAppVersion', 'appAccessGrants'],
    });
  }
  public async getAppById(id: string) {
    return new Entities().get({
      entityName: 'app',
      relations: ['appOrgs', 'appOrgs.appVersion', 'appOrgs.appApiKey', 'appAccessGrants'],
      id,
    });
  }
  public async createApp(id: string) {
    return new Entities().create({ entityName: 'app', object: { id } });
  }
  public async deleteApp(app: App) {
    await new Entities().delete({ entityName: 'app', id: app.id });
    return app;
  }
  public async updateApp(id: string, changes: App) {
    return new Entities().update({
      entityName: 'app',
      id,
      object: { id: changes.id },
    });
  }
}
