import { Component, OnInit } from '@angular/core';
import { Entities } from '@contrail/sdk';

@Component({
  selector: 'app-clear-cache',
  templateUrl: './clear-cache.component.html',
  styleUrls: ['./clear-cache.component.scss'],
})
export class ClearCacheComponent implements OnInit {
  constructor(private entities: Entities = new Entities()) {}

  async ngOnInit() {
    await this.entities.get({ entityName: 'flush-redis' });
  }
}
