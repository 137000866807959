import { createSelector } from '@ngrx/store';
import { appVersionsEntityAdapter } from './app-versions.state';

export const { selectAll, selectEntities, selectIds, selectTotal } = appVersionsEntityAdapter.getSelectors();

const appVersionsEntities = (state: any) => {
  console.log(state);
  return state.appVersions.appVersions.entities;
};
export const appVersions = createSelector(appVersionsEntities, (state) => Object.values(state));
