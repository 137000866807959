import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Assortment, LoaderConfig, Workspace } from '../../loader-interface';

interface LoaderConfigState extends EntityState<LoaderConfig> {}
interface LoadWorkspace extends EntityState<Workspace> {}
interface LoadAssortment extends EntityState<any> {}

export interface State {
  loading: boolean;
  hasError: boolean;
  loaderConfigs: LoaderConfigState;
  currentLoaderConfig: LoaderConfig;
  loadWorkspaces: LoadWorkspace;
  loadAssortments: LoadAssortment;
  updating: boolean;
}

export const loaderConfigEntityAdapter: EntityAdapter<LoaderConfig> = createEntityAdapter<LoaderConfig>({});
export const loadWorkspacesEntityAdapter: EntityAdapter<Workspace> = createEntityAdapter<Workspace>({});
export const loadAssortmentEntityAdapter: EntityAdapter<any> = createEntityAdapter<Assortment>({});

export const loaderConfigInitialState = {
  loading: false,
  hasError: false,
  loaderConfigs: loaderConfigEntityAdapter.getInitialState({}),
  currentLoaderConfig: null,
  loadWorkspaces: loadWorkspacesEntityAdapter.getInitialState(),
  loadAssortments: loadAssortmentEntityAdapter.getInitialState(),
  updating: false,
};
