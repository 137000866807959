import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export interface LocalizationConfig {
  currencyCode: string;
  locale: string;
}

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  // CREATE LOCALIZATION CONFIG OBSERVABLE
  public localizationConfigObject: LocalizationConfig = { currencyCode: null, locale: 'en-US' };
  private localizationConfigSubject: Subject<LocalizationConfig> = new BehaviorSubject(this.localizationConfigObject);
  public localizationConfig: Observable<LocalizationConfig> = this.localizationConfigSubject.asObservable();

  private locales = navigator.languages === undefined ? [navigator.language] : navigator.languages;

  setCurrencyCode(currencyCode: string) {
    this.localizationConfigObject.currencyCode = currencyCode;
    if (this.locales?.length) {
      console.log('locales: ', this.locales);
      this.localizationConfigObject.locale = this.locales[0];
    }
    this.localizationConfigSubject.next(this.localizationConfigObject);
  }
}
