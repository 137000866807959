<app-page-section *ngIf="enableLevel">
  <app-page-section-header>
    <app-page-section-title title="Level"></app-page-section-title>
  </app-page-section-header>
  <app-page-section-body>
    <mat-form-field [appearance]="'fill'">
      <mat-label>Level</mat-label>
      <mat-select [formControl]="formControl">
        <mat-option *ngFor="let option of options" [value]="option.slug">{{ option.label }}</mat-option>
      </mat-select>
      <mat-hint>Which level of the hieararchy does this property apply?</mat-hint>
    </mat-form-field>
  </app-page-section-body>
</app-page-section>
