import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchKey',
})
export class SearchKeyPipe implements PipeTransform {
  transform(items: any[], ...args: any[]): any[] {
    const searchText = args[0];
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }

    if (args[2]) {
      return args[1]
        ? items.filter((item) => this.searchString(item[args[2]][args[1]], searchText))
        : items.filter(
            (item) => Object.keys(item[args[2]]).some((res) => this.searchString(item[args[2]][res], searchText)),
            /* || Object.keys(item).some(res => this.searchString(item[res], searchText)) */
          );
    } else if (args[1]) {
      return items.filter((item) => this.searchString(item[args[1]], searchText));
    } else {
      return items.filter((item) => Object.keys(item).some((res) => this.searchString(item[res], searchText)));
    }
  }

  searchString(value, searchText) {
    return String(value).toLowerCase().includes(searchText.toLowerCase());
  }
}

/*
 use case
    1. | searchKey : filterKey                                // search personalInfo (name && age && sex)
    2. | searchKey : filterKey : 'name'                       // search personalInfo.name
    3. | searchKey : filterKey : '' : 'location'              // search personalInfo.location (city && state && country)
    4. | searchKey : filterKey : 'city' : 'location'          // search personalInfo.location.city

    personalInfo = {
      name: 'John Doe',
      age: 30,
      sex: 'M',
      location: {
        city: 'Los Angeles',
        state: 'CA',
        country: 'US'
      }
    };
*/
