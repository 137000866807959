import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { ScheduleJobDefinition } from './schedule-job';

interface ScheduleJobActionsState extends EntityState<ScheduleJobDefinition> {}

export interface State {
  loading: boolean;
  hasError: boolean;
  scheduleJobActions: ScheduleJobActionsState;
  currentScheduleJobAction: ScheduleJobDefinition;
}

export const scheduleJobActionsEntityAdapter: EntityAdapter<ScheduleJobDefinition> =
  createEntityAdapter<ScheduleJobDefinition>({});

export const scheduleJobActionsInitialState = {
  loading: false,
  hasError: false,
  scheduleJobActions: scheduleJobActionsEntityAdapter.getInitialState({}),
  currentScheduleJobAction: null,
};
