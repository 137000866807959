import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { UserOrgsActions } from '.';
import { UserOrgsService } from '../user-orgs.service';

@Injectable()
export class UserOrgsEffects {
  constructor(
    private actions$: Actions,
    private userOrgService: UserOrgsService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {}

  loadUserOrgs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserOrgsActions.UserOrgsActionTypes.LOAD_USER_ORGS),
      withLatestFrom(this.store),
      switchMap(([, store]: [any, RootStoreState.State]) => {
        if (!(store?.auth?.authContext?.user?.orgs?.length > 0)) {
          return [];
        }
        return from(this.userOrgService.getUserOrgs()).pipe(
          map((data) => UserOrgsActions.loadUserOrgsSuccess({ data })),
          catchError((error) => observableOf(UserOrgsActions.loadUserOrgsFailure({ error }))),
        );
      }),
    ),
  );
  loadCurrentUserOrgs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserOrgsActions.UserOrgsActionTypes.LOAD_CURRENT_USER_ORG),
      switchMap((action: any) =>
        from(this.userOrgService.getUserOrgById(action.id)).pipe(
          map((data) => UserOrgsActions.loadCurrentUserOrgSuccess({ userOrg: data })),
          catchError((error) => observableOf(UserOrgsActions.loadCurrentUserOrgFailure({ error }))),
        ),
      ),
    ),
  );
  createUserOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserOrgsActions.UserOrgsActionTypes.CREATE_USER_ORG),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) => {
        const email = action.email;
        return from(this.userOrgService.createUserOrg(email)).pipe(
          map((data) => {
            this.snackBar.open('User added.', '', { duration: 2000 });
            // this.store.dispatch(UserOrgsActions.setCurrentUserOrg({ currentUserOrg: data }));
            return UserOrgsActions.createUserOrgSuccess({ userOrg: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(UserOrgsActions.createUserOrgFailure({ error }));
          }),
        );
      }),
    ),
  );
  deleteUserOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserOrgsActions.UserOrgsActionTypes.DELETE_USER_ORG),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.userOrgService.deleteUserOrg(action.userOrg)).pipe(
          map((data) => {
            this.snackBar.open('User removed.', '', { duration: 2000 });
            return UserOrgsActions.deleteUserOrgSuccess({ userOrg: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(UserOrgsActions.deleteUserOrgFailure({ error }));
          }),
        ),
      ),
    ),
  );
  deleteUserOrgs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserOrgsActions.UserOrgsActionTypes.DELETE_USER_ORGS),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.userOrgService.deleteUserOrgs(action.ids)).pipe(
          map((data) => {
            this.snackBar.open(`${action.ids.length} Users removed.`, '', { duration: 2000 });
            this.store.dispatch(UserOrgsActions.loadUserOrgs());
            return UserOrgsActions.deleteUserOrgsSuccess({ ids: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(UserOrgsActions.deleteUserOrgsFailure({ error }));
          }),
        ),
      ),
    ),
  );
  updateUserOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserOrgsActions.UserOrgsActionTypes.UPDATE_USER_ORG),
      withLatestFrom(this.store),
      switchMap(([action]: [any, RootStoreState.State]) =>
        from(this.userOrgService.updateUserOrg(action.id, action.changes)).pipe(
          map((data) => {
            this.snackBar.open('User updated.', '', { duration: 2000 });
            return UserOrgsActions.updateUserOrgSuccess({ id: data.id, changes: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(UserOrgsActions.updateUserOrgFailure({ error }));
          }),
        ),
      ),
    ),
  );
}
