import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Entities } from '@contrail/sdk';
import { TypeProperty } from '@contrail/types';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { RootStoreState } from '@rootstore';
import { TypeManagerSelectors } from '../../type-manager-store';
import { TypePropertyPolicy } from '../../type-manager-store/type-property-policies/type-property-policies.state';

@Component({
  selector: 'app-type-property-policy-assignment',
  templateUrl: './type-property-policy-assignment.component.html',
  styleUrls: ['./type-property-policy-assignment.component.scss'],
})
export class TypePropertyPolicyAssignmentComponent implements OnInit, OnChanges, OnDestroy {
  @Input() typeProperty: TypeProperty;
  @Output() changes = new EventEmitter();
  public typePropertyPolicies$: Observable<Array<TypePropertyPolicy>>;
  public assignedPolicyLinks: Array<any>;
  public addPolicyFormControl = new UntypedFormControl();
  public editableFormControl = new UntypedFormControl();
  private policiesFormControlSub: Subscription;
  private editableFormControlSub: Subscription;

  constructor(private store: Store<RootStoreState.State>) {}
  ngOnInit() {
    this.typePropertyPolicies$ = this.store.select(TypeManagerSelectors.typePropertyPolicies);
    this.policiesFormControlSub = this.addPolicyFormControl.valueChanges.subscribe((policy) => {
      if (policy) {
        this.addPolicyToProperty(policy);
        this.addPolicyFormControl.reset();
      }
    });
    this.editableFormControlSub = this.editableFormControl.valueChanges.subscribe((value) => {
      if (value !== this.typeProperty.editable) {
        this.changes.emit({ editable: value });
      }
    });
  }
  ngOnChanges() {
    this.addPolicyFormControl.reset();
    this.assignedPolicyLinks = [];
    if (this.typeProperty) {
      this.loadAssignedPolicies();
      this.editableFormControl.setValue(this.typeProperty.editable);
    }
  }

  async loadAssignedPolicies() {
    const policyLinks = await new Entities().get({
      entityName: 'type-property-policy-link',
      criteria: { typePropertyId: this.typeProperty.id },
      relations: ['typePropertyPolicy'],
    });

    // Filtering to handle situation where policy has been deleted... should be blocked in service!
    this.assignedPolicyLinks = policyLinks.filter((link) => link.typePropertyPolicy);
  }

  async removePolicyFromProperty(policyLink) {
    // PREEMPT THE DELETE.
    const filteredAssigned = this.assignedPolicyLinks.filter((l) => l.id !== policyLink.id);
    this.assignedPolicyLinks = filteredAssigned;

    await new Entities().delete({
      entityName: 'type-property-policy-link',
      id: policyLink.id,
    });
  }
  async addPolicyToProperty(policy: TypePropertyPolicy) {
    const existing = this.assignedPolicyLinks.find((p) => p.typePropertyPolicy.id === policy.id);
    if (existing) {
      return;
    }
    const link = await new Entities().create({
      entityName: 'type-property-policy-link',
      object: { typePropertyId: this.typeProperty.id, typePropertyPolicyId: policy.id },
    });
    link.typePropertyPolicy = policy;
    this.assignedPolicyLinks.push(link);
  }
  ngOnDestroy() {
    this.policiesFormControlSub.unsubscribe();
    this.editableFormControlSub.unsubscribe();
  }

  updateValue() {}
  displayFn(policy: TypePropertyPolicy): string {
    return policy?.label;
  }
}
