import { on } from '@ngrx/store';
import { State } from '../type-manager.state';
import * as TypeRootsActions from './type-roots.actions';
import { typeRootsEntityAdapter } from './type-roots.state';

const setTypeRoots = (state: State, { data }) => ({
  ...state,
  typeRoots: typeRootsEntityAdapter.setAll(data, state.typeRoots),
});

export const typeRootsReducers = [on(TypeRootsActions.loadTypeRootsSuccess, setTypeRoots)];
