import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { EventWorkflowActionDefinition } from './event-action';

interface EventWorkflowActionsState extends EntityState<EventWorkflowActionDefinition> {}

export interface State {
  eventWorkflowActions: EventWorkflowActionsState;
  ownerKeyEventWorkflowActions: EventWorkflowActionsState;
  currentEventWorkflowAction: EventWorkflowActionDefinition;
}
export const eventWorkflowActionsEntityAdapter: EntityAdapter<EventWorkflowActionDefinition> =
  createEntityAdapter<EventWorkflowActionDefinition>({});

export const eventWorkflowActionsInitialState = {
  eventWorkflowActions: eventWorkflowActionsEntityAdapter.getInitialState({}),
  ownerKeyEventWorkflowActions: eventWorkflowActionsEntityAdapter.getInitialState({}),
  currentEventWorkflowAction: null,
};
