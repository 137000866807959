import { createSelector } from '@ngrx/store';
import { appExtensionsEntityAdapter } from './app-extensions.state';
import { AppExtension } from '@contrail/entity-types';

export const { selectAll, selectEntities, selectIds, selectTotal } = appExtensionsEntityAdapter.getSelectors();

const appExtensionEntities = (state: any) => state.appExtensions.appExtensions.entities;
export const appExtensions = createSelector(
  appExtensionEntities,
  (state) => Object.values(state) as Array<AppExtension>,
);
