import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-type-policy-form',
  templateUrl: './type-policy-form.component.html',
  styleUrls: ['./type-policy-form.component.scss'],
})
export class TypePolicyFormComponent {
  @Output() cancelEdit = new EventEmitter();
  @Output() done = new EventEmitter();
  public nameField = new UntypedFormControl('', Validators.required);
  constructor() {}

  async completeEdit() {
    this.done.emit({ name: this.nameField.value });
    this.nameField.reset();
  }

  cancel() {
    this.cancelEdit.emit({});
  }

  isValid() {
    const valid = !this.nameField.invalid;
    return valid;
  }
}
