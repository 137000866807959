import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AppExtensionsSelectors } from '@common/app-extensions/app-extensions-store';
import { AuthSelectors } from '@common/auth/auth-store';
import { AuthService } from '@common/auth/auth.service';
import { AppExtension, File } from '@contrail/entity-types';
import {
  AppContext,
  AppExtensionMessage,
  AppExtensionHost,
  AppExtensionMessageHandler,
  VibeIQAppType,
} from '@contrail/extensions-sdk';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { filter, take, tap } from 'rxjs';
import { UtilityAppExtensionMessageHandler } from './utility-app-extension-message-handler';
@Component({
  selector: 'utility-extension',
  templateUrl: './utility-extension.component.html',
  styleUrls: ['./utility-extension.component.scss'],
})
export class UtilityExtensionsComponent implements OnInit {
  extensionIdentifier: string;
  extension: AppExtension;
  iframeSafeUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
  iframeUrl: string;
  private messageHandler: AppExtensionMessageHandler;
  private messageListener: any;
  public authToken: string;
  public authOrg: string;
  public user;

  constructor(
    private route: ActivatedRoute,
    private utilityAppExtensionMessageHandler: UtilityAppExtensionMessageHandler,
    private store: Store<RootStoreState.State>,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.extensionIdentifier = this.route.snapshot.params.extensionIdentifier;
    this.store.select(AppExtensionsSelectors.appExtensions).subscribe(async (extensions) => {
      this.extension = extensions.find((extension) => extension.identifier === this.extensionIdentifier);
      if (this.extension?.iframeUrl) {
        this.iframeUrl = this.extension.iframeUrl;
        this.iframeSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.extension.iframeUrl);
        this.messageHandler = this.utilityAppExtensionMessageHandler;
        await this.initExtensionConfig();
      }
    });

    // set up authentication
    this.authToken = await this.authService.getToken();
    this.store
      .select(AuthSelectors.selectAuthContext)
      .pipe(
        filter((res) => res !== null),
        take(1),
        tap((context) => {
          this.authOrg = context.currentOrg?.orgSlug;
          this.user = context.user;
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    console.log('onDestroy.. removing listener');
    window.removeEventListener('message', this.messageListener);
  }

  async initExtensionConfig() {
    const iframe = document.getElementById('localFrame') as HTMLIFrameElement;
    iframe.src = this.iframeUrl;

    const context: AppContext = {
      // todo: add appContext
      appContext: { vibeIQApp: VibeIQAppType.ADMIN_CONSOLE },
      user: this.user,
    };

    const appExtensionMessageHandlerWrapper = {
      handleMessage: async (data: AppExtensionMessage) => {
        this.messageHandler.handleMessage(data);
      },
    };

    await AppExtensionHost.registerHostWithAppExtension(iframe, context, appExtensionMessageHandlerWrapper);
  }
}
