import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { WebhooksComponent } from './webhooks.component';
import { ComponentsModule } from '../common/components/components.module';
import { WebhooksRoutingModule } from './webhooks-routing.module';

@NgModule({
  declarations: [WebhooksComponent],
  imports: [CommonModule, ComponentsModule, WebhooksRoutingModule, MatButtonModule, MatIconModule],
})
export class WebhooksModule {}
