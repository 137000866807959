import * as TypeManagerActions from './type-manager.actions';
import * as TypeManagerSelectors from './type-manager.selectors';
import * as TypeManagerStoreState from './type-manager.state';
import * as TypeRootsActions from './type-roots/type-roots.actions';
import * as TypeRootSelectors from './type-roots/type-roots.selectors';
import * as TypesActions from './types/types.actions';
import * as TypesSelectors from './types/types.selectors';
import * as TypePropertiesActions from './type-properties/type-properties.actions';
import * as TypePropertiesSelectors from './type-properties/type-properties.selectors';
import * as TypePropertyOptionSetsActions from './option-sets/option-sets.actions';
import * as TypePropertyOptionSetsSelectors from './option-sets/option-sets.selectors';

import * as TypePropertyUserListsActions from './user-lists/user-lists.actions';
import * as TypePropertyUserListsSelectors from './user-lists/user-lists.selectors';

import * as TypePropertyPolicyActions from './type-property-policies/type-property-policies.actions';
import * as TypePropertyPolicySelectors from './type-property-policies/type-property-policies.selectors';

import * as TypePolicyActions from './type-policies/type-policies.actions';
import * as TypePolicySelectors from './type-policies/type-policies.selectors';

import * as TypeRuleSetsSelectors from './type-rule-sets/type-rule-sets.selectors';
import * as TypeRuleSetsActions from './type-rule-sets/type-rule-sets.actions';
import * as OptionSetHierarchiesSelectors from './option-set-hierarchies/option-set-hierarchies.selectors';
import * as OptionSetHierarchiesActions from './option-set-hierarchies/option-set-hierarchies.actions';

export { TypeManagerStoreModule } from './type-manager-store.module';

const actions = {
  ...TypeManagerActions,
  ...TypeRootsActions,
  ...TypesActions,
  ...TypePropertiesActions,
  ...TypePropertyOptionSetsActions,
  ...TypePropertyPolicyActions,
  ...TypePolicyActions,
  ...TypeRuleSetsActions,
  ...OptionSetHierarchiesActions,
  ...TypePropertyUserListsActions,
};
const selectors = {
  ...TypeManagerSelectors,
  ...TypeRootSelectors,
  ...TypesSelectors,
  ...TypePropertiesSelectors,
  ...TypePropertyOptionSetsSelectors,
  ...TypePropertyPolicySelectors,
  ...TypePolicySelectors,
  ...TypeRuleSetsSelectors,
  ...OptionSetHierarchiesSelectors,
  ...TypePropertyUserListsSelectors,
};
export {
  actions as TypeManagerActions,
  selectors as TypeManagerSelectors,
  TypeManagerStoreState as TypeManagerStoreState,
};
