import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { from, of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as SystemNotificationActions from './system-notification.actions';
import { SystemNotificationService } from './system-notification.service';

@Injectable()
export class SystemNotificationEffects {
  constructor(
    private actions$: Actions,
    private systemNotificationService: SystemNotificationService,
    private snackBar: MatSnackBar,
  ) {}

  loadSystemNotificationActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SystemNotificationActions.SystemNotificationActionTypes.LOAD_SYSTEM_NOTIFICATIONS),
      switchMap((action: any) =>
        from(this.systemNotificationService.loadSystemNotificationsByDateRange(action.startDate, action.endDate)).pipe(
          map((data: any) =>
            SystemNotificationActions.loadSystemNotificationSuccess({
              data: data?.sort((a, b) => (a.createdOn < b.createdOn ? 1 : -1)),
            }),
          ),
          catchError((error) =>
            of(
              SystemNotificationActions.loadSystemNotificationFailure({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  loadCurrentSystemNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SystemNotificationActions.SystemNotificationActionTypes.LOAD_CURRENT_SYSTEM_NOTIFICATION),
      switchMap((action: any) =>
        from(this.systemNotificationService.loadCurrentSystemNotification(action.id)).pipe(
          map((data: any) =>
            SystemNotificationActions.loadCurrentSystemNotificationSuccess({
              systemNotification: data,
            }),
          ),
          catchError((error) =>
            of(
              SystemNotificationActions.loadCurrentSystemNotificationFailure({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  fail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SystemNotificationActions.SystemNotificationActionTypes.LOAD_CURRENT_SYSTEM_NOTIFICATION_FAILURE,
        SystemNotificationActions.SystemNotificationActionTypes.LOAD_SYSTEM_NOTIFICATIONS_FAILURE,
      ),
      map((action: any) => {
        this.snackBar.open(action.error?.statusText, '', { duration: 2000 });
        return null;
      }),
    ),
  );
}
