import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TypePropertyFormFieldBaseComponent } from './type-property-form-field-base.component';
@Component({
  selector: 'app-type-property-form-field-formula',
  template: `
    <div>
      <mat-label>{{ propertyFormConfiguration.typeProperty.label }}</mat-label>
      <ngx-monaco-editor
        class="border p-4 block h-64 w-full mb-2"
        [formControl]="formControl"
        [options]="editorOptions"
        #editor
      >
      </ngx-monaco-editor>
      <div class="text-rose-600 mb-2" *ngIf="!formControl?.valid">Function includes errors.</div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
      ngx-monaco-editor {
        height: 200px;
      }
      mat-form-field {
        width: 100%;
      }
    `,
  ],
})
export class TypePropertyFormFieldFormulaComponent
  extends TypePropertyFormFieldBaseComponent
  implements OnInit, OnChanges, OnDestroy
{
  public formControl: UntypedFormControl = new UntypedFormControl();
  public editorOptions: any = {
    theme: 'vs',
    language: 'javascript',
    minimap: {
      enabled: false,
    },
    lineNumbers: 'off',
    automaticLayout: true,
  };

  constructor() {
    super();
  }
  ngOnInit() {
    this.init();
  }
  ngOnDestroy() {
    this.onDestroy();
  }
  ngOnChanges() {
    if (this.formControl && this.formControl.value !== this.value) {
      this.formControl.setValue(this.value);
    }
  }

  async initFormControl() {
    this.formControl.valueChanges.subscribe((opt) => {
      if (!opt) {
        this.value = null;
      } else {
        this.value = opt;
      }
      this.handleChange();
    });
  }
}
