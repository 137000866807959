import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { scheduleJobActionsReducers } from './schedule-job.reducer';
import { ScheduleJobEffects } from './schedule-job.effects';
import { featureKey } from './schedule-job.selectors';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(featureKey, scheduleJobActionsReducers),
    EffectsModule.forFeature([ScheduleJobEffects]),
  ],
})
export class ScheduleJobStoreModule {}
