<div *ngIf="entity && entity.loaded">
  <div class="header">
    <div class="title">{{ entity.getType()?.getEntityType() }} ({{ entity.getType()?.getTypeName() }})</div>
    <div class="nav">
      <div (click)="go('properties')">Details</div>
      <div (click)="go('content')" *ngIf="entity.implements('content_holder')">Content</div>
      <div (click)="go('comments')" *ngIf="entity.implements('comment_holder')">Comments</div>
    </div>
  </div>
  <div class="body" *ngIf="entity">
    <div class="left">
      <div *ngIf="entity.implements('viewable')">
        <app-viewable [entity]="entity"></app-viewable>
      </div>
      <app-meta [entity]="entity"></app-meta>
    </div>
    <div class="right">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
