<app-page-section *ngIf="enabled">
  <app-page-section-header>
    <app-page-section-title title="Validation Function"></app-page-section-title>
  </app-page-section-header>
  <app-page-section-body>
    <ngx-monaco-editor
      class="border p-4 block h-64 w-full mb-2"
      [formControl]="validationFunctionControl"
      [options]="editorOptions"
      #editor
    >
    </ngx-monaco-editor>
    <div class="text-rose-600 mb-2" *ngIf="!validationFunctionControl?.valid">Function includeds errors.</div>
    <button mat-raised-button [disabled]="!validationFunctionControl?.valid" (click)="saveValidationFunction()">
      Save
    </button>
  </app-page-section-body>
</app-page-section>
