import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { appsReducers } from './apps.reducers';
import { AppsEffects } from './apps.effects';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('apps', appsReducers), EffectsModule.forFeature([AppsEffects])],
})
export class AppsStoreModule {}
