import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Entities } from '@contrail/sdk';
import { Type } from '@contrail/types';
import { Observable, Subscription, from } from 'rxjs';
import { OptionSetHierarchiesService } from '../../type-manager-store/option-set-hierarchies/option-set-hierarchies.service';
import { TypePropertyOptionSetsService } from '../../type-property-option-sets/type-property-option-sets.service';

@Component({
  selector: 'app-type-option-set-hierarchies',
  templateUrl: './type-option-set-hierarchies.component.html',
  styleUrls: ['./type-option-set-hierarchies.component.scss'],
})
export class TypeOptionSetHierarchiesComponent implements OnChanges, OnInit, OnDestroy {
  @Input() type: Type;
  public availableOptionSetHieararchies$: Observable<Array<any>>;
  public addHierachyFormControl = new UntypedFormControl();
  private optionSetHierarchiesFormControlSub: Subscription;
  public optionSetHierarchyLinks: Array<any>;
  public displayTypeOSHLengthLimit = 35;

  constructor(
    private optionSetHierarchiesService: OptionSetHierarchiesService,
    private optionSetService: TypePropertyOptionSetsService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.optionSetHierarchiesFormControlSub = this.addHierachyFormControl.valueChanges.subscribe(
      (optionSetHierarchy) => {
        if (optionSetHierarchy) {
          this.addOptionSetHierarchy(optionSetHierarchy);
          this.addHierachyFormControl.reset();
        }
      },
    );
  }

  async ngOnChanges() {
    if (!this.type) {
      return;
    }
    this.optionSetHierarchyLinks = await new Entities().get({
      entityName: 'type-option-set-hierarchy-link',
      criteria: { typeId: this.type.id },
      relations: ['optionSetHierarchy'],
    });

    this.availableOptionSetHieararchies$ = from(this.optionSetHierarchiesService.get());
  }
  async removeOptionSetHierarchy(optionSetHierarchyLink) {
    // PREEMPT THE DELETE.
    const filteredAssigned = this.optionSetHierarchyLinks.filter((l) => l.id !== optionSetHierarchyLink.id);
    this.optionSetHierarchyLinks = filteredAssigned;

    await new Entities().delete({
      entityName: 'type-option-set-hierarchy-link',
      id: optionSetHierarchyLink.id,
    });
  }

  async addOptionSetHierarchy(optionSetHierarchy: any) {
    const allOptionSets = await this.optionSetService.getOptionSets();
    const existing = this.optionSetHierarchyLinks.find((p) => p.optionSetHierarchyId === optionSetHierarchy.id);
    if (existing) {
      return;
    }
    // Need to do property binding:
    console.log('Adding hierarchy: ', optionSetHierarchy, optionSetHierarchy.hierarchy);

    const propertiesToBind = [];
    for (const optionSetId of optionSetHierarchy.hierarchy) {
      const optionSet = allOptionSets.find((os) => os.id === optionSetId);
      console.log('optionSetId: ', optionSetId, optionSet);
      const matchedProperties = this.type.typeProperties.filter((p) => p.typePropertyOptionSetId === optionSetId);
      if (matchedProperties.length === 1) {
        propertiesToBind.push(matchedProperties[0]);
      } else if (matchedProperties.length > 1) {
        this.snackBar.open(`Warning: To many possible properties map to option set '${optionSet.name}'.`, '', {
          duration: 2000,
        });
      } else if (matchedProperties.length === 0) {
        this.snackBar.open(`Warning: No property matches option set '${optionSet.name}' in hierarchy.`, '', {
          duration: 2000,
        });
      }
    }

    const link = await new Entities().create({
      entityName: 'type-option-set-hierarchy-link',
      object: {
        optionSetHierarchyId: optionSetHierarchy.id,
        typeId: this.type.id,
        hierarchyTypePropertyIds: propertiesToBind.map((p) => p.id),
      },
    });
    link.optionSetHierarchy = optionSetHierarchy;
    this.optionSetHierarchyLinks.push(link);
  }
  displayFn(policy: any): string {
    return policy?.label;
  }

  ngOnDestroy(): void {
    this.optionSetHierarchiesFormControlSub?.unsubscribe();
  }
}
