import { Injectable } from '@angular/core';
import { ChartPoint, DurationLiteral, LineChartData, MetricType, TimeseriesDataPoint } from './event-dashboard';
import { Entities } from '@contrail/sdk';
import JSURL from 'jsurl';

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  constructor() {}

  getBinDuration(startDateTime: number, endDateTime: number): DurationLiteral {
    const differenceInHours = this.getDifferenceInHours(startDateTime, endDateTime);
    if (differenceInHours <= 1) {
      return '1m'; // 1 minute
    } else if (differenceInHours <= 72) {
      // 3 days
      return '1h'; // 1 hour
    } else {
      return '1d'; // 1 day
    }
  }

  getDifferenceInHours(startTimeMs: number, endTimeMs: number): number {
    return (endTimeMs - startTimeMs) / (1000 * 60 * 60);
  }

  private async getMetricData(
    workflowId: string,
    metricType: MetricType,
    startDate: Date,
    endDate: Date,
    binDuration: DurationLiteral,
  ): Promise<TimeseriesDataPoint[]> {
    const strStartDate = startDate.toISOString();
    const strEndDate = endDate.toISOString();
    const criteria = JSURL.stringify({ time: `BETWEEN ${strStartDate} and ${strEndDate}` });
    const queryParams = { binDuration, criteria };
    const queryString = new URLSearchParams(queryParams).toString();
    const suffix = `metrics/${metricType}?${queryString}`;

    const response = (await new Entities().get({
      entityName: 'event-workflow-template',
      id: workflowId,
      suffix,
    })) as { data: TimeseriesDataPoint[] };
    return response.data;
  }

  private async fetchChartData(
    workflowId: string,
    metricType: MetricType,
    startDate: Date,
    endDate: Date,
  ): Promise<ChartPoint[]> {
    const startDateTime = startDate.getTime();
    const endDateTime = endDate.getTime();
    const binDuration = this.getBinDuration(startDateTime, endDateTime);

    const data = await this.getMetricData(workflowId, metricType, startDate, endDate, binDuration);
    return data.map((item) => ({
      x: new Date(item.time).getTime(),
      y: item.value,
    }));
  }

  async getTriggeredChartData(workflowId: string, startDate: Date, endDate: Date): Promise<LineChartData> {
    const standardBlueHex = '#1976d2';
    const standardBlueHexTransparent = standardBlueHex + '30';
    const data = await this.fetchChartData(workflowId, 'triggered-count', startDate, endDate);
    return {
      labels: [],
      datasets: [
        {
          data,
          label: 'Triggered Workflow Processes',
          pointBackgroundColor: standardBlueHex,
          borderColor: standardBlueHex,
          fill: true,
          backgroundColor: standardBlueHexTransparent,
        },
      ],
    };
  }

  async getFailedChartData(workflowId: string, startDate: Date, endDate: Date): Promise<LineChartData> {
    const failureRedHex = '#D9534F';
    const failureRedHexTransparent = failureRedHex + '30';
    const data = await this.fetchChartData(workflowId, 'failed-count', startDate, endDate);
    return {
      labels: [],
      datasets: [
        {
          data,
          label: 'Failed Workflow Processes',
          pointBackgroundColor: failureRedHex,
          borderColor: failureRedHex,
          fill: true,
          backgroundColor: failureRedHexTransparent,
        },
      ],
    };
  }
}
